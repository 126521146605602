




const SelectionHeader = ({props}) => {
  // const {isAllSelected, onSelectAll} = useListView()
  console.log(props.table)
  return (
   
      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
        <input
          className='form-check-input'
          type='checkbox'
          // data-kt-check={props.table.getIsAllRowsSelected()}
          data-kt-check-target='#kt_table_users .form-check-input'
          checked={props.table.getIsAllRowsSelected()}
          onChange={props.table.getToggleAllRowsSelectedHandler()}
        />
      </div>
   
  )
}

export {SelectionHeader}
