import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router'
import { updateCompanyCode } from '../../redux/series.slice';

const AssignCompanyBtn = ({name, code, id}) => {
    console.log("code", code)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const handlerClick = ()=>{
       dispatch( updateCompanyCode({code:code}))
       localStorage.setItem('code', code)
        navigate('/series/details/'+id)

    }
  return (
    <span className="btn btn-warning" onClick={()=> handlerClick()}> {name} </span>
  )
}

export default AssignCompanyBtn