import React, { useEffect, useState } from "react";
import Table from "../../../../Common/TableWithTan/Table";
import { assignCompanyDetailsColumn } from "./AssignCompanyDetailsColumn";
import { assignCompanyColumn } from "./AssignCompanyColumn";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyDropdown } from "../../../Company/redux/company.action";
import AccordionPlus from "../../../../Common/AccordionPlus";
import Checkbox from "../../../../Common/Checkbox";
import ButtonWithLoader from "../../../../Common/ButtonWithLoader";

const tableData = [
  {
    companyName: "Gamerji",
    assign: true,
  },
  {
    companyName: "D11",
    assign: true,
  },
  {
    companyName: "Mobily",
    assign: false,
  },
];

const AssignCompany = ({ userData, isLoading, handleAssignCompany, setCompanyData, companyData }) => {
  const dispatch = useDispatch();
  const { dropDownList, error } = useSelector((state) => state.companies);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [userDetails, setUserDetails] = useState(userData);

  useEffect(() => {
    if (companyData?.length===0){
      dispatch(fetchCompanyDropdown({}));
    }
  }, []);
  useEffect(() => {
    setUserDetails(userData);
  }, [userData]);

  useEffect(() => {
    if (companyData?.length>0){
      setCompaniesOptions(companyData);
    }else{
      setCompaniesOptions(dropDownList);
      setCompanyData(dropDownList)
    }
  }, [dropDownList, companyData]);

  // useEffect(() => {
  //   console.log(
  //     "companiesOptions::::::::::",
  //     companiesOptions,
  //     userDetails?.assigned_company
  //   );
  // }, [companiesOptions, userDetails]);

  // When user toggles a checkbox
  const handleCheckboxChange = (e, company, index) => {
    // console.log("handleCheckboxChange", e, company, index);

    setUserDetails((prevPermissions) => {
      // Check if the company._id already exists in the array
      let updatedAssignedCompany;
      if (prevPermissions.assigned_company.includes(company?._id)) {
        // Remove the company._id if it already exists
        updatedAssignedCompany = prevPermissions.assigned_company.filter(
          (id) => id !== company._id
        );
      } else {
        // Add the company._id if it's not already in the array
        updatedAssignedCompany = [...prevPermissions.assigned_company, company._id];
      }
      return {
        ...prevPermissions,
        assigned_company: updatedAssignedCompany
      };
    });
  };

  return (
    <div className="card">
      <div className="card-header card-header-stretch">
        <div className="card-title d-flex align-items-center">
          <h3 className="fw-bold m-0 text-gray-800">Assign Companies</h3>
        </div>
      </div>
      {/* <div class="card-body">
            <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
          {companiesOptions && companiesOptions?.length > 0 && companiesOptions.map((company)=>{
            return <>
             <div style={{display:"flex",flexFlow:"row",justifyContent:"center", alignItems:"center",margin:"10px",backgroundColor:"#ababab", padding:"10px"}}>
                <div> <AccordionPlus key={company._id} collapse={show} onClick={()=>{

                }} /></div>
             <div>{company.name}</div>
             <div>{company.code}</div>
             <div></div>


             </div>
 
            </>
          })
          
            }
             
              <Table
                column={assignCompanyColumn}
                tableData={companiesOptions}
                isRowExpaned = {true}
                assignCompanyDetailsColumn={assignCompanyDetailsColumn}
                // columnFilters={columnFilters}
                // setColumnFilters={setColumnFilters}
              />
            </div>
           
          </div> */}

      <div className="card-body fv-row">
        {companiesOptions?.map((company, index) => (
          <div className="mt-4" key={index}>
            <div className="d-flex justify-content-between p-4 px-4 bg-dark bg-opacity-20 align-items-center">
              <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                {company.name}
              </h4>
              <div className="d-flex align-items-center">
                <Checkbox
                  value={company._id}
                  name={company.name}
                  onChange={(e) => {
                    handleCheckboxChange(e, company, index)
                  }}
                  checked={
                    userDetails?.assigned_company?.includes(company._id)
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div>
        ))}
        {/* {sportsError && <div className="text-danger mt-2">{sportsError}</div>} */}
        <div className="mt-5 d-flex justify-content-end">
          <ButtonWithLoader
            text={"Submit"}
            isLoading={isLoading}
            handlerSubmit={()=>{
              handleAssignCompany(userDetails)
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignCompany;
