import { routesApi } from "../../../../RouterFiles/routesApi";
import instance from "../../../../utilities/axios";
import url from "../../../../utilities/url";

export const getList = (headers,body) => {
    return instance
      .post(`${url.base_api}${routesApi.sport}/list`,body)
      .then((res) => {
        return res;
      });
  };
export const getSportId = (headers, id) => {
    return instance
      .get(`${url.base_api}${routesApi.sport}/${id}`, {
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };
export const add = (headers, data) => {
    console.log("add:::",data)
    return instance
      .post(`${url.base_api}${routesApi.sport}`,data)
      .then((res) => {
        return res;
      });
  };
export const update = (headers, data,id) => {
    console.log("update:::",data)
    return instance
      .post(`${url.base_api}${routesApi.sport}/${id}`,data)
      .then((res) => {
        return res;
      });
  };
  export const remove = (headers,id) => {
    console.log("delete:::",id)
    return instance
      .delete(`${url.base_api}${routesApi.sport}/${id}`)
      .then((res) => {
        return res;
      })
     
      ;
  };
