import { createSlice } from "@reduxjs/toolkit";
import { assignCompanies, fetchFilterList, fetchList, fetchRefetchList, fetchSeriesRulesbyId, getMatchesFetchById, getMatchesListById, getPlayersFetchById, getSeriesById, saveMatches, savePlayer, updateSeriesRulesbyId } from "./series.action";



export const seriesSlice = createSlice({
  name: "seriessState",
  initialState: {
    pageNo: 1,
    recordPerPage: 10,
    totalRecord: 0,
    totalPages: 0,
    list: [],
    series: "",
    viewSeries: "",
    delSeries: "",
    loading: false,
    error: null,
    fetchSeries:"",
    fetchLoad:false,
   
    save:"",
    saveLoad:false,
    saveP:"",
    matchFetch:'',
    matchList:[],
    matchUpdate:"",
    filterData:[],
    assignCompany:"",
    assignedCompanies:[],
    companyCode:localStorage.getItem('code') ||  'SAAS',
    rules:null
  },
  reducers: {
    addAssignCompany : (state, action)=> {
      state.assignedCompanies = action.payload
    },
    updateCompanyCode : (state, action)=> {
      state.companyCode = action.payload.code
    }
  },
  extraReducers: (builder) => {

    builder.addCase(fetchList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload?.data;
      state.assignCompany = "";
      state.recordPerPage = action.payload?.recordPerPage;
      state.pageNo = action.payload?.pageNo;
      state.totalRecord = action.payload?.totalRecord;
      state.totalPages = action.payload?.totalPages;
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error?.message;
    });
    builder.addCase(fetchFilterList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFilterList.fulfilled, (state, action) => {
      state.loading = false;
      state.filterData = action.payload?.data;
    
    });
    builder.addCase(fetchFilterList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error?.message;
    });
    builder.addCase(assignCompanies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(assignCompanies.fulfilled, (state, action) => {
      state.loading = false;
      state.assignCompany = action.payload;
    
    });
    builder.addCase(assignCompanies.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
    builder.addCase(fetchRefetchList.pending, (state) => {
      state.fetchLoad = true;
    });
    builder.addCase(fetchRefetchList.fulfilled, (state, action) => {
      state.fetchLoad = false;
      state.fetchSeries = action.payload;
      
      
    });
    builder.addCase(fetchRefetchList.rejected, (state, action) => {
      state.fetchLoad = false;
      state.error = action.payload?.message;
    });
    // builder.addCase(deleteSport.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(deleteSport.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.delSport = action.payload;
    // });
    // builder.addCase(deleteSport.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload.error.message;
    // });
    builder.addCase(getSeriesById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSeriesById.fulfilled, (state, action) => {
      state.loading = false;
      state.sport = "";
      state.viewSeries = action.payload.data;
    });
    builder.addCase(getSeriesById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
 
    //Rules
    builder.addCase(fetchSeriesRulesbyId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSeriesRulesbyId.fulfilled, (state, action) => {
      state.loading = false;
      state.rules = action.payload.data;
    });
    builder.addCase(fetchSeriesRulesbyId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
    builder.addCase(updateSeriesRulesbyId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSeriesRulesbyId.fulfilled, (state, action) => {
      state.loading = false;
      state.rules = action.payload.data;
    });
    builder.addCase(updateSeriesRulesbyId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

   
  },
});

export const { addAssignCompany,updateCompanyCode } = seriesSlice.actions;
export default seriesSlice.reducer;
