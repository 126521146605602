import React, { useEffect, useState } from 'react'
import SelectDropdownWithSearch from '../../../../../Common/SelectDropdownWithSearch'
import { getPlayersFetchById,  getPlayersList,  savePlayer } from './redux/players.action.js'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../../MasterLayout/Loader'
import Table from '../../../../../Common/TableWithTan/Table'
import {playersColumn, teamsColumn} from './PlayersColumn.js';
import ButtonWithLoader from '../../../../../Common/ButtonWithLoader'

import {successMessage }from '../../../../../Common/SwalToast.js'
const countryOptions = [
  { value: 'Nigeria', name: 'Nigeria', image: 'media/flags/afghanistan.svg' },
  { value: 'United States', name: 'United States', image: 'media/flags/united-states.svg' },
  { value: 'Israel', name: 'Israel', image: 'media/flags/israel.svg' },
  { value: 'Romania', name: 'Romania', image: 'media/flags/romania.svg' },
  { value: 'Germany', name: 'Germany', image: 'media/flags/germany.svg' },
  { value: 'Bahamas', name: 'Bahamas', image: 'media/flags/bahamas.svg' },
  { value: 'China', name: 'China', image: 'media/flags/china.svg' },
  { value: 'Somalia', name: 'Somalia', image: 'media/flags/somalia.svg' }
]
const Players = ({id}) => {
  const [formData, setFormData] = useState([])
  const dispatch = useDispatch()
  const {playerLoad,players,save,saveLoad,saveP,list,loading} = useSelector(state => state.players)
  const [columnVisibility, setColumnVisibility] = useState();
  const {companyCode} = useSelector(state => state.series)

  const updateEdit = (rowIndex, name, value) => {
    setFormData(prevFormData => {
      const keyToUpdate = list[rowIndex]?.key;
  
      // Check if the item with the key already exists in formData
      const existingItemIndex = prevFormData.findIndex(ele => ele?.key === keyToUpdate);
  
      if (existingItemIndex !== -1) {
        // If it exists, update the existing item
        const updatedData = [...prevFormData];
        updatedData[existingItemIndex] = {
          ...updatedData[existingItemIndex],
          [name]: value
        };
        return updatedData;
      } else {
        // If it doesn't exist, add a new item
        return [
          ...prevFormData,
          { key: keyToUpdate, [name]: value }
        ];
      }
    });
  };
  const handleSelectChange = (name, value) => {
    setFormData(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
    // setGame(value)
  };
 

  const handlerSave = ()=>{
    let t = null
    if(companyCode == 'SAAS'){

      t = list?.reduce((acc,players)=>{
       acc.push(players?.key)
       console.log("acc",acc)
       return acc
     },[])
     dispatch(savePlayer({id:id, companyCode:companyCode,data:{players:t}}))
    }
    else{
      // t = formData?.reduce((acc,players)=>{
      //   acc.push(players?.key)
      //   acc.push(players?.new_player_name || "")
      //   acc.push(players?.auction_price || "")
      //   acc.push(players?.image || "")
      //   console.log("acc",acc)
      //   return acc
      // },[])
      console.log("form data", formData)
      dispatch(savePlayer({id:id, companyCode:companyCode,data:{players:formData}}))
    }
    console.log("t>>>>>>",formData)
    
  }
  const handleFetch = ()=>{
    dispatch(getPlayersFetchById({id:id,companyCode:'SAAS'}))
  }
  useEffect(()=>{
    if(companyCode == 'SAAS'){
      setColumnVisibility({
        new_player_name: false,
        image: false,
        auction_price:false
       
      })
    }
    dispatch(getPlayersList({id:id,companyCode:companyCode}))
    
  },[])
  useEffect(()=>{
    if(save?.message){

      successMessage(save?.message)
      dispatch(getPlayersList({id:id,companyCode:companyCode}))
    }

  },[save])
  useEffect(()=>{
    if(saveP?.message)
      successMessage(saveP?.message)
  },[saveP])
  // useEffect(()=>{
  //   console.log("list::::::", list)
  //   if(list)
  //     setFormData(list)
  // },[list])
  return (
    <>

      <div className='card'>
        <div className="card-header ">
          <div className="card-title d-flex align-items-center col-4">
            <h3 className="fw-bold m-0 text-gray-800">Players</h3>
          </div>
          <div className="card-toolbar col-6 d-flex justify-content-end">
            {/* <div className="col-6 ">
            <SelectDropdownWithSearch
              name="country"
              //   required={"true"}
              //   label="Country"
              placeholder={"Select a country"}
              options={countryOptions}
              value={countryOptions.find(
                (option) => option.value === formData?.country?.value
              )}
              onChange={(selectedOption) => {
                console.log(selectedOption);
                handleSelectChange("country", selectedOption);
              }}
            />
            </div> */}
            
             <div className="col-6 text-center">

             <ButtonWithLoader text={"Fetch & Refetch"} 
             handlerSubmit={handleFetch} 
            //  isLoading={isLoading} 
             />
             </div>
          </div>
          
        </div>
        <div className="card-body">
          <div className="row">

          </div>

        </div>
      </div>
      {/* {
        formData?.country && <> */}
{
  list?.length > 0 && 
          <div className='card'>
            <div className="card-header card-header-stretch">
              <div className="card-title d-flex align-items-center">
                <h3 className="fw-bold m-0 text-gray-800"> Players</h3>
              </div>

            </div>
            <div className="card-body">
            {
  loading ? 
  <Loader /> :
  <>
            <Table
                  column={playersColumn}
                  tableData={list}
                  columnVisibility={columnVisibility}
                  updateEdit ={updateEdit}
                  // recordPerPage={recordPerPages}
                  // totalPages={totalPages}
                  // totalRecord={totalRecord}
                  // pageIndex={pageNo}
                 
                 
                  
                />
                <div>
                <ButtonWithLoader text="Save" handlerSubmit={handlerSave} isLoading={playerLoad}  />
                </div>
                </>
}
            </div>



          </div>
}
          

        {/* </>
      } */}
    </>
  )
}

export default Players