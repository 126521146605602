import React from 'react'

const ViewDetailCell = ({props}) => {

  return (
    <button 
    className='d-flex align-items-center gap-2 text-center justify-content-center cursor-pointer btn btn-light btn-sm ' 
    data-bs-toggle="tooltip" 
    data-bs-custom-class="tooltip-inverse" 
    data-bs-placement="right" 
    title="Tooltip on right"
    onClick={()=> props.table.options.meta.updateView(props.row.index)}
    >
        <i class="ki-solid ki-eye fs-2"></i>
        
    </button>
    
  )
}

export default ViewDetailCell