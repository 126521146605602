import React from 'react'

const AccordionPlus = ({collapse, onClick}) => {
    return (
        <>
        <div class="m-0" onClick={onClick}>
            <div class={`d-flex align-items-center collapsible py-3 toggle mb-0 ${!collapse ? "collapsed" : ""}`} 
            // data-bs-toggle="collapse" 
            data-bs-target="#kt_job_6_1" aria-expanded={collapse}>

                <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <i class="ki-outline ki-minus-square toggle-on text-primary fs-1"></i>
                    <i class="ki-outline ki-plus-square toggle-off fs-1"></i>
                </div>
                <h4 class="text-gray-700 fw-bold cursor-pointer mb-0">How does it work?</h4>
            </div>
            <div id="kt_job_6_1" class={`collapse fs-6 ms-1 ${collapse ? "show" : ""}`}>
                <div class="mb-4 text-gray-600 fw-semibold fs-6 ps-10">First, a disclaimer – the entire process of writing a blog post often takes more than a couple of hours, even if you can type eighty words as per minute and your writing skills are sharp.</div>

            </div>
        </div>
        {/* <div class="m-0">
       
        <div class="d-flex align-items-center collapsible py-3 toggle collapsed mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_6_2" aria-expanded={false}>
           
            <div class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                <i class="ki-outline ki-minus-square toggle-on text-primary fs-1"></i>
                <i class="ki-outline ki-plus-square toggle-off fs-1"></i>
            </div>
           
           
            <h4 class="text-gray-700 fw-bold cursor-pointer mb-0">Do I need a designer to use this Admin Theme?</h4>
           
        </div>
       
       
        <div id="kt_job_6_2" class="collapse fs-6 ms-1">
           
            <div class="mb-4 text-gray-600 fw-semibold fs-6 ps-10">First, a disclaimer – the entire process of writing a blog post often takes more than a couple of hours, even if you can type eighty words as per minute and your writing skills are sharp.</div>
           
        </div>
       
       
        <div class="separator separator-dashed"></div>
       
    </div> */}
    </>
    )
}

export default AccordionPlus
