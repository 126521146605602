import { routesFrontend } from "../../RouterFiles/routesFrontend";

export const MenuList = {
    dashboard : {
        name: "Dashboard1",
        child:[
            {
                name: "Home",
                child:[],
                link: '/' + routesFrontend.staff + '/' + routesFrontend.create
            },
        ],
        icon:'home'
    },
    module: {
        name: "Modules",

        child: [


            {
                name: "Modules",
                child: [],
                link: '/' + routesFrontend.module + '/' + routesFrontend.viewList
            },
        ],
        icon: 'abstract-26'

    },
    staff : {
        name: "Staff",
        
        child: [
      
          
            {
                name: "Staff",
                child:[],
                link: '/' + routesFrontend.staff + '/' + routesFrontend.viewList
            },
        ],
        icon:'gift'
        
    },
   
    
    master : {
        name: "Master",
        
        child: [
            {
                name: "Currency",
                child:[],
                link: '/' + routesFrontend.currency + '/' + routesFrontend.viewList
            },
            {
                name: "Country",
                child:[],
                link: '/' + routesFrontend.country + '/' + routesFrontend.viewList
                
            },
            {
                name: "Language",
                child:[],
                link: '/' + routesFrontend.language + '/' + routesFrontend.viewList
                
            },
            {
                name: "Sport",
                child:[
                    
                    {
                        name: "Sport List",
                        child:[],
                        link: '/' + routesFrontend.sport + '/' + routesFrontend.viewList
                    },
                    {
                        name: "Series List",
                        child:[],
                        link: '/' + routesFrontend.series + '/' + routesFrontend.viewList
                    },
                   
                ],
                
            },
            
            
        ],
        icon:'abstract'
        
    },
    company: {
        name: "Comapny",
        child:[
            {
                name: "Create Company",
                child:[],
                link: '/' + routesFrontend.company + '/' + routesFrontend.create
            },
            {
                name: "Company List",
                child:[],
                link: '/' + routesFrontend.company + '/' + routesFrontend.viewList
            },
           
        ],
        icon:'office-bag'
    },
    customerCare: {
        name: "Customer Care",
        child:[
            {
                name: "Create Category",
                child:[],
                link: '/' + routesFrontend.customerCare + '/' + routesFrontend.create
            },
            {
                name: "customerCare List",
                child:[],
                link: '/' + routesFrontend.customerCare + '/' + routesFrontend.viewList
            },
           
        ],
        icon:'abstract-26'
    },
    Legality : {
        name: "Legality",
        child:[
            // {
            //     name: "Home",
            //     child:[],
            //     link: '/' + routesFrontend.staff + '/' + routesFrontend.create
            // },
        ],
        icon:'home',
        link: '/' + routesFrontend.legality 
    },
}