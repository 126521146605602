import React, { useEffect, useState } from "react";
import Toolbar from "../../Common/Toolbar";
import Content from "../../Common/Content";
import { useNavigate } from "react-router";
import WithAddButton from "../../../hooks/WithAddButton";
import CreateModule from "./CreateModule";
import { useDispatch, useSelector } from "react-redux";
import { moduleColumn } from "./moduleColumn";
import { deleteModule, fetchList, updateModuleStatus } from "./redux/module.action";
import { errorMessage, successMessage } from "../../Common/SwalToast";
import Table from "../../Common/TableWithTan/Table";
import Loader from "../../MasterLayout/Loader";
//Filter Serach
// const moduleDropdownFilter = [
//   {
//     label: "Staff",
//     value: "staff",
//   },
//   {
//     label: "Master",
//     value: "master",
//   },
//   {
//     label: "Country",
//     value: "country",
//   },
// ];


const ViewModuleList = ({ handleAddBtn, addBtn }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();


  const [columnFilters, setColumnFilters] = useState([]);
  const [data, setData] = useState([])
  const { moduleList, loading, error, delModule, module, totalPages, totalRecord } = useSelector(state => state.module)
  const [isLoading, setLoading] = useState(false)
  const [updateData, setUpdateData] = useState(null)
  const [pageNo, setPageNo] = useState(0)
  const [recordPerPages, setRecordPerPage] = useState(10)

  useEffect(() => {
    dispatch(fetchList({ pageNo: pageNo + 1, recordPerPage: recordPerPages }))
  }, [pageNo, recordPerPages])
  useEffect(() => {
    setData(moduleList)
  }, [moduleList])
  useEffect(() => {
    setLoading(loading)
  }, [loading])
  useEffect(() => {
    if (error) {
      errorMessage(error || 'Something went wrong')
    }
  }, [error])
  useEffect(() => {
    if (delModule) {
      successMessage(delModule.message)
      dispatch(fetchList({ pageNo: 0, recordPerPage: recordPerPages }))
    }
  }, [delModule])
  useEffect(() => {
    if (module) {
      console.log(module)
      successMessage(module.message)
      handleAddBtn(false)
      dispatch(fetchList({ pageNo: 0, recordPerPage: 10 }))
    }
  }, [module])
  const updateView = (rowIndex) => {
    navigate('/module/details/' + data[rowIndex]?._id)
  }
  const updateEdit = (rowIndex) => {
    handleAddBtn(false)
    handleAddBtn(true)
    setUpdateData(data[rowIndex])
    // navigate('/' + routesFrontend.language + '/edit/' + tableData[rowIndex]?._id)
  }
  const updateStatus = (rowIndex) => {
    let body = {
      is_active: !data[rowIndex]?.is_active
    }
    dispatch(updateModuleStatus({ id: data[rowIndex]?._id, data: body }))
  }
  const updateDelete = (rowIndex) => {
    dispatch(deleteModule({ id: data[rowIndex]?._id }))
  }

  return (
    <>
      <Toolbar text={"View Module"} isButton={true} handleClick={() => {
        setUpdateData(null)
        handleAddBtn(!addBtn)
      }} textBtn={"Add Module"} addBtn={addBtn} />
      <Content>
        {
          addBtn && <CreateModule data={updateData} />
        }
        <div class="card card-custom">
          <div class="card-body">
            {/* <FilterForm  /> */}
            {/* Search Filter */}
            {/* <div class="col-md-4 col-6 mt-2">
                <FilterPopover
                  option={columnFilters}
                  setOption={setColumnFilters}
                  filterValues={moduleDropdownFilter}
                  id="module"
                  text="Module"
                />
              </div> */}
            {/* Search Filter */}
            <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
              {
                isLoading ? <Loader />
                  :
                  <Table
                    column={moduleColumn}
                    tableData={data}
                    totalPages={totalPages}
                    totalRecord={totalRecord}
                    pageIndex={pageNo}
                    recordPerPage={recordPerPages}
                    updateEdit={updateEdit}
                    updateStatus={updateStatus}
                    updateView={updateView}
                    updateDelete={updateDelete}
                    handlerChangeDropDown={(index) => {
                      setPageNo(0)
                      setRecordPerPage(index)
                    }
                    }
                    handlePageChange={(index) => {
                      setPageNo(index)
                    }}
                  />
              }
            </div>

          </div>
        </div>
      </Content>
    </>
  );
};

export default WithAddButton(ViewModuleList);
