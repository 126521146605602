import React, { useEffect, useRef, useState } from "react";
import ButtonWithLoader from "../../Common/ButtonWithLoader";
import {useNavigate} from 'react-router-dom'

const OtpForm = ({ handlerSubmitOtp, otpTabData }) => {
    const navigate = useNavigate()
    const [otp, setOtp] = useState(['', '', '', '', '', '']); // Store OTP digits
    const inputsRef = useRef([]); // Create a ref to store references to input elements
    const allowedKeys = '0123456789'.split(''); // Allowed OTP keys (numbers)
    const [isLoading, setIsLoading] = useState(false)
    const [verified, setVerifed] = useState(true)

    const handleKeyUp = (event, index) => {
      const { key, keyCode } = event;
      const value = key;
  
      // Handle backspace
      if (keyCode === 8 && index > 0) {
        setVerifed(true)
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          newOtp[index] = ''; // Clear current input
          return newOtp;
        });
        inputsRef.current[index - 1].focus(); // Move to previous input
        return;
      }
  
      // Check if the entered key is valid (a number)
      if (allowedKeys.includes(value)) {
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          newOtp[index] = value; // Set the current OTP value
          return newOtp;
        });
  
        // Move to next input if it's valid and not the last one
        if (index < 5) {
            
          inputsRef.current[index + 1].focus();
        }
      }
  
      // Check if all 6 digits are entered
      const finalKey = [...otp.slice(0, index), value, ...otp.slice(index + 1)].join('');
      // console.log(finalKey)
      if(finalKey.length == 6) setVerifed(false)
   
    };

    const maskEmail = (email) => {
      const [username, domain] = email.split('@');

      if (username.length <= 4) {
        return email; // Don't mask if the username is too short
      }

      const maskedUsername = username.slice(0, 2) + '*'.repeat(username.length - 4) + username.slice(-2);
      return `${maskedUsername}@${domain}`;
    };
  return (
    <div
      class="form w-100 mb-10"
      novalidate="novalidate"
      data-kt-redirect-url="index.html"
      id="kt_sing_in_two_factor_form"
    >
      <div class="text-center mb-10">
        <img
          alt="Logo"
          class="theme-light-show mh-125px"
          src={`${process.env.REACT_APP_CLIENT_URL}assets/media/svg/misc/smartphone-2.svg`}
        />
        <img
          alt="Logo"
          class="theme-dark-show mh-125px"
          src="assets/media/svg/misc/smartphone-2-dark.svg"
        />
      </div>

      <div class="text-center mb-10">
        <h1 class="text-gray-900 mb-3" data-kt-translate="two-step-title">
          Two-Factor Verification
        </h1>

        <div
          class="text-muted fw-semibold fs-5 mb-5"
          data-kt-translate="two-step-deck"
        >
          Enter the verification code we sent to
        </div>

        <div class="fw-bold text-gray-900 fs-3">{maskEmail(otpTabData.login)}</div>
      </div>

      <div class="mb-10">
        <div
          class="fw-bold text-start text-gray-900 fs-6 mb-1 ms-1"
          data-kt-translate="two-step-label"
        >
          Type your 6 digit security code
        </div>

    
           <div>
      <div class="d-flex flex-wrap flex-stack">
        {otp.map((digit, index) => (
      
          <input
          type="text"
          name="code_1"
          data-inputmask="'mask': '9', 'placeholder': ''"
          maxlength="1"
          class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
          value={digit}
          ref={(el) => (inputsRef.current[index] = el)} // Store input reference
            onKeyUp={(event) => handleKeyUp(event, index)} // Handle input key up
        />
        ))}
      </div>
      
    </div>
      </div>

      <ButtonWithLoader isLoading={isLoading} disable={verified} handlerSubmit={()=>{
        handlerSubmitOtp(otp)
      }} text={"Submit"} />
    </div>
  );
};

export default OtpForm;
