import React, { useState } from "react";
import TextInputField from "../../../../../Common/TextInputField";
import { useDispatch, useSelector } from "react-redux";
import { addAuctionSettings } from "./redux/auctionSettings.slice";
import { errorMessage } from "../../../../../Common/SwalToast";

const AddAuctionRow = () => {
  const [formData, setFormData] = useState({});
  const { list } = useSelector(state => state.auctionSettings)
  const dispatch = useDispatch()
  const handlerChange = (e) => {
    let {name,value} = e.target
    setFormData(prev=>{
        return {
            ...prev,
            [name]: Number(value)
        }
    })
  };
  const handlerAdd = ()=>{
    console.log("formdara", formData)
    if(Object.keys(formData).length < 3){
        errorMessage("please fill all details")
        return
    }
    if(formData?.total_participants > (formData?.total_players)){
        errorMessage("Total participants should less than total players")
        return
    }
    if(formData?.players_to_be_purchased > (formData?.total_players/2)){
        errorMessage("Player  should less than total players/2")
        return
    }
    if(formData?.players_to_be_purchased * formData?.total_participants > (list?.player_count)){
        errorMessage("Player  should less than total players")
        return
    }
    dispatch(addAuctionSettings({settings: formData}))
    setFormData({})
  }
  return (
    <tr>
 { console.log("formdara", formData,formData?.total_participants)}

      <td>
        <TextInputField type="number" name="total_participants" value={formData?.total_participants || ""} onChange={(e) => handlerChange(e)} />{" "}
      </td>
      <td>
        <TextInputField type="number" name="players_to_be_purchased" value={formData?.players_to_be_purchased || ""} onChange={(e) => handlerChange(e)} />
      </td>
      <td>
        <TextInputField type="number" name="total_players" value={formData?.total_players || ""} onChange={(e) => handlerChange(e)} />
      </td>
      <td>
        <div
          className={`btn  hover-scale btn-light me-5`}
          onClick={(e) => handlerAdd()}
        >
          <span className="w-65px d-flex justify-content-between align-items-center">
            {" "}
            <i className="ki-outline ki-plus-square fs-1 me-1"></i>
            Add
          </span>
        </div>
      </td>
    </tr>
  );
};

export default AddAuctionRow;
