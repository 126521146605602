import React, { useEffect, useRef, useState } from 'react'
import Toolbar from './Toolbar'
import Content from './Content'
import TextInputField from './TextInputField'
import TextInputFieldWithIcon from './TextInputFieldWithIcon'
import SelectDropDownWithIcon from './SelectDropDownWithIcon'
import SelectDropDown from './SelectDropDown'
import DateRangePickerBasic from './DateRangePickerBasic'
import moment from 'moment'
import DateRangePickerBasic1 from './DateRangePickerBasic1'
import SingleDatePickerBasic from './SingleDatePickerBasic'
import Swal from 'sweetalert2';
import { formValidation } from '@form-validation/bundle/popular';
import { Bootstrap5 } from '@form-validation/plugin-bootstrap5';
import { Trigger } from '@form-validation/plugin-trigger';

import Switchbox from './Switchbox'
import Checkbox from './Checkbox'
import Radios from './Radios'
import RadioWithoutIcon from './RadioWithoutIcon'
import AccordionPlus from './AccordionPlus'
const Form = () => {
    const formRef = useRef()
    const [name, setName] = useState('')
    const [cvv, setCvv] = useState('')
    const [email, setEmail] = useState('')
    const [country, setCountry] = useState()
    const [option, setOption] = useState()
    const [startDate, setStartDate] = useState(moment());
    const [startDate1, setStartDate1] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    // console.log(startDate,endDate)
    // console.log(startDate.format('MMMM D, YYYY HH:MM:SS'))
    // console.log(startDate.format('MM/DD/YYYY hh:mm A'))
    const submitButtonRef = useRef(null);
    const [validator, setValidator] = useState(null);
    const [switchBox, setSwitchBox] = useState(false)
    const [checkbox, setCheckbox] = useState(false)
    const [radio, setRadio] = useState('1')
    const [collapse,setCollapse] = useState(false)
    const options = [
        { value: 'option 1', label: 'Option 1' },
        { value: 'option 2', label: 'Option 2' },
        { value: 'option 3', label: 'Option 3' },
        { value: 'option 4', label: 'Option 4' },
        { value: 'option 5', label: 'Option 5' },
    ]
    const radioValue = [
        {
        label:"Quick Online Courses",
        subLevel:"Creating a clear text structure is just one SEO",
        value:"1"
    },
    {
        label:"Quick Online Courses",
        subLevel:"",
        value:"2"
    },
    {
        label:"Quick Online Courses",
        subLevel:"Creating a clear text structure is just one SEO",
        value:"3"
    }
]
    console.log(switchBox, checkbox,radio)

    useEffect(() => {
        // Select form
        const form = formRef.current;
        if (!form) return;

        // // Dynamically create validation non-empty rule
        const requiredFields = form.querySelectorAll('.required');
        let detectedField;
        const validationFields = {
            fields: {},
            plugins: {
                trigger: new Trigger(),
                bootstrap: new Bootstrap5({
                    rowSelector: '.fv-row',
                    eleInvalidclassName: '',
                    eleValidclassName: ''
                })
            }
        };

        // Detect required fields
        requiredFields.forEach((el) => {
            const input = el.closest('.fv-row')?.querySelector('input');
            if (input) {
                detectedField = input;
            }
            console.log("inpu", input)
            // const select = el.closest('.fv-row')?.querySelector('select');
            // if (select) {
            // 	detectedField = select;
            // }
            const select = el.closest('.fv-row')?.querySelector('.react-select-styled');
            if (select) {
                detectedField = select;
            }
            console.log("selc", select)
            // Add validation rule                
            const name = detectedField?.getAttribute('name');
            if (name) {
                validationFields.fields[name] = {
                    validators: {
                        notEmpty: {
                            message: `${el.innerText} is required`
                        },
                        stringLength: {
                            verbose: false, 
                            min: 6,
                            max: 30,
                            message: 'The name must be more than 6 and less than 30 characters long',
                        },
                    }
                };
            }
        });

        // Initialize form validation
        const fv = formValidation(form, validationFields);
        console.log("fv", fv)
        setValidator(fv);
        // const fv = formValidation(
        // 	form,
        // 	{
        // 		fields: {
        // 			name: {
        // 				validators: {
        // 					notEmpty: {
        // 						message: 'The username is required'
        // 					},
        // 					stringLength: {
        // 						message: 'The username must be more than 6 and less than 30 characters long',

        // 					},
        // 					regexp: {
        // 						message: 'The username can only consist of alphabetical, number and underscore',

        // 					},
        // 				}
        // 			},

        // 		},
        // 	}
        // )
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log("clicked", validator)
        if (validator) {
            validator.validate().then((status) => {
                if (status === 'Valid') {
                    // Show loading indication
                    submitButtonRef.current.setAttribute('data-kt-indicator', 'on');
                    submitButtonRef.current.disabled = true;

                    // Simulate form submission
                    setTimeout(() => {
                        // Remove loading indication
                        submitButtonRef.current.removeAttribute('data-kt-indicator');
                        submitButtonRef.current.disabled = false;

                        // Show popup confirmation
                        Swal.fire({
                            text: 'Form has been successfully submitted!',
                            icon: 'success',
                            buttonsStyling: false,
                            confirmButtonText: 'Ok, got it!',
                            customclassName: {
                                confirmButton: 'btn btn-primary'
                            }
                        });

                        // Uncomment the following line to submit the form for real
                        // formRef.current.submit();
                    }, 2000);
                } else {
                    // Show popup error
                    Swal.fire({
                        text: 'Oops! There are some error(s) detected.',
                        icon: 'error',
                        buttonsStyling: false,
                        confirmButtonText: 'Ok, got it!',
                        customclassName: {
                            confirmButton: 'btn btn-primary'
                        }
                    });
                }
            });
        }
    };
    return (
        <>

            <Toolbar />
            <Content>
                <div className="col-xl-6">

                    <div className="card card-flush h-lg-100" id="kt_contacts_main">

                        <div className="card-header pt-7" id="kt_chat_contacts_header">

                            <div className="card-title">
                                <i className="ki-outline ki-badge fs-1 me-2"></i>
                                <h2>Add New Contact</h2>
                            </div>

                        </div>


                        <div className="card-body pt-5">
                            <form id="kt_ecommerce_settings_general_form" className="form" action="#" ref={formRef}>

                                <div className="mb-7">

                                    <label className="fs-6 fw-semibold mb-3">
                                        <span>Update Avatar</span>
                                        <span className="ms-1" data-bs-toggle="tooltip" title="Allowed file types: png, jpg, jpeg.">
                                            <i className="ki-outline ki-information fs-7"></i>
                                        </span>
                                    </label>


                                    <div className="mt-1">




                                        <div className="image-input image-input-outline image-input-placeholder image-input-empty image-input-empty" data-kt-image-input="true">

                                            <div className="image-input-wrapper w-100px h-100px" style={{ "background-image": "url('')" }}></div>


                                            <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                                <i className="ki-outline ki-pencil fs-7"></i>

                                                <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                                <input type="hidden" name="avatar_remove" />

                                            </label>


                                            <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                                                <i className="ki-outline ki-cross fs-2"></i>
                                            </span>


                                            <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                                                <i className="ki-outline ki-cross fs-2"></i>
                                            </span>

                                        </div>

                                    </div>

                                </div>
                                <TextInputField
                                    label={"name"}
                                    placeholder={"enter name"}
                                    required={"true"}
                                    value={name}
                                    name={"name"}
                                    type={"text"}
                                    minlength={"3"}
                                    maxlength={"5"}
                                    onChange={(e) => setName(e.target.value)}

                                />
                                  <div class="fv-row mb-7">
															
                                                            <label class="fs-6 fw-semibold form-label mt-3">
                                                              <span className={`${true ? "required": ""}`}>{"name"}</span>
                                                              
                                                            </label>
                                                            
                                                            
                                                            <input 
                                                            type={"text"} 
                                                            class="form-control form-control-solid" 
                                                            name={"name"} 
                                                            value={name} 
                                                            placeholder={"enter name"}
                                                            onChange={(e) => setName(e.target.value)}
                                                           
                                                             />
                                                            
                                                          </div>
                                <TextInputField
                                    label={"email"}
                                    name={"email"}
                                    value={email}
                                    type={"email"}

                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <TextInputFieldWithIcon
                                    label={"cvv"}
                                    required={"true"}
                                    value={cvv}
                                    name={"name"}
                                    type={"number"}
                                    onChange={(e) => setCvv(e.target.value)}
                                />
                                <SelectDropDownWithIcon

                                />

                                <SelectDropDown
                                    value={option}
                                    onChange={setOption}
                                    options={options}
                                />
                                <SelectDropDown
                                    value={option}
                                    onChange={setOption}
                                    isMulti={true}
                                    options={options}
                                />


                                <DateRangePickerBasic
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                />
                                <DateRangePickerBasic1
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                />
                                <SingleDatePickerBasic
                                    startDate={startDate1}

                                    setStartDate={setStartDate1}

                                />

                                <Switchbox
                                    checked={switchBox}
                                    onChange={() => setSwitchBox(!switchBox)}
                                />
                                <Checkbox
                                    value={checkbox}
                                    onChange={() => setCheckbox(!checkbox)}
                                    label={"selct me"}
                                />
                                <Radios 
                                data={radioValue}
                                name={"category"}
                                onChange={(val) => setRadio(val)}
                                />
                                {
                                    radioValue?.map((ele) => {
                                        return(

                                            <RadioWithoutIcon
                                            label = {ele?.label}
                                            name={ele?.label}
                                            onChange={(val) => setRadio(ele?.value)}
                                            value={radio}
                                            checked={ele?.value == radio}
                                            />
                                        )
                                    })
                                }
                                <AccordionPlus 
                                collapse={collapse}
                                onClick = {()=> {
                                    console.log(collapse)
                                    setCollapse(!collapse)
                                }}
                                />
                                <div className="d-flex justify-content-end">

                                    <button type="reset" data-kt-contacts-type="cancel" className="btn btn-light me-3">Cancel</button>


                                    <button
                                        type="submit"
                                        data-kt-contacts-type="submit"
                                        ref={submitButtonRef}
                                        onClick={handleSubmit}
                                        classNameName="btn btn-primary"
                                    >
                                        <span className="indicator-label">Save</span>
                                        <span className="indicator-progress">Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='col-xl-6'>
               
                </div>
            </Content>
        </>
    )

}

export default Form
