import React, { useEffect, useState } from "react";
import Content from "../Common/Content";
import SeriesTable from "./FantasyCommon/seriesTable";
import axios from "axios";
import MatchTable from "./FantasyCommon/matchTable";
import DataToStringDiv from "./FantasyCommon/DataToStringDiv";
import MatchData from "./FantasyCommon/MatchData";
import MatchDataScoreCardandOverView from "./FantasyCommon/matchDataScoreCardandOverView";

const FantasyMatcheDetails = () => {
  // useState DATA
  const [match, setMatch] = useState([]);
  const [overViewData, setOverViewData] = useState(null);
  const [matchKey, setMatchKey] = useState(null);
  const [name, setName] = useState(null);
  const [dataDiv, setshowDataDiv] = useState(false);

  // useEffect Handlers
  useEffect(() => {
    let matchKey =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    setMatchKey(matchKey);
    let selectAssociation = localStorage.getItem("associationItem");
    let selectSeries = localStorage.getItem("seriesItem");
    let selectMatch = localStorage.getItem("matchItem");
    setName(
      `/ association: ${selectAssociation} / series : ${selectSeries} / match : ${selectMatch}`
    );
  }, []);

  useEffect(() => {
    if (matchKey) {
      fetchMatch(matchKey);
    }
  }, [matchKey]);

  // support functions
  const fetchMatch = async (matchKey) => {
    try {
      if (matchKey) {
        const res = await getMatch(matchKey);
        if (res) {
          setMatch(res.data);
        } else {
          console.log("DATA NOT FOUND !!");
        }
        const resOverView = await getOverView(matchKey);
        if (resOverView) {
          setOverViewData(resOverView);
        } else {
          console.log("resOverView:DATA NOT FOUND !!");
        }
      } else {
        console.log("matchKey NOT FOUND !!");
      }
    } catch (error) {
      console.error("Support Functions ERROR:", error);
    }
  };
  // API functions
  const getMatch = async (matchKey) => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${ process.env.REACT_APP_BASE_API_URL }v5/cricket/${
        process.env.REACT_APP_PROJECT_KEY
      }/match/${ matchKey}/`,
        headers: { 'hard-refresh': 'true' },
      };
      return axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((error) => {
          console.error("API CALL ERROR:", error);
        });
    } catch (error) {
      console.error("API CALL  Functions ERROR:", error);
    }
  };
  const getOverView = async (matchKey, page) => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API_URL}v5/cricket/${
          process.env.REACT_APP_PROJECT_KEY
        }/match/${matchKey}/over-summary/${page ? page : ""}`,
        headers: {'hard-refresh': 'true'},
      };
      return axios
        .request(config)
        .then((response) => {
          // console.log("teamresdata",response.data);
          return response.data.data;
        })
        .catch((error) => {
          console.error("API CALL ERROR:", error);
        });
    } catch (error) {
      console.error("API CALL  Functions ERROR:", error);
    }
  };

  return (
    <Content>
      <div class="card card-custom">
        <div class="card-header flex-wrap flex-column border-0 pt-6 pb-0">
          <div class="card-title d-flex flex-column justify-content-between">
            <h1 className="m-1">
              <p>
                {" "}
                <span>
                  <button
                    className="p-2 m-1"
                    style={{
                      border: "0px ",
                      borderRadius: "20%",
                      backgroundColor: "#ffff",
                    }}
                    onClick={() => {
                      setshowDataDiv(!dataDiv);
                    }}
                  >
                    +
                  </button>
                </span>{" "}
                Fantasy Matche Details{" "}
              </p>
            </h1>
            <h5 className="p-2">
              <span>{name}</span>
            </h5>
          </div>
          <div class="card-toolbar">
            {dataDiv && (
              <DataToStringDiv heading={"Matche Data"} children={match} />
            )}
            {dataDiv && (
              <DataToStringDiv
                heading={"Matche OverView Data"}
                children={overViewData}
              />
            )}
          </div>
        </div>
        <div class="card-body">
          <div class="mb-7">
            <div class="row align-items-center">
              <div class="col-lg-3 col-xl-4 mt-5 mt-lg-0">
             
              </div>
            </div>
          </div>
          <div class="datatable datatable-bordered datatable-head-custom" id="kt_datatable">
            <MatchDataScoreCardandOverView overViewData={overViewData} match={match} key={match} />
          </div>
        </div>
      </div>
    </Content>
  );
};

export default FantasyMatcheDetails;
