import React, { useEffect, useRef, useState } from "react";
import OtpForm from "./OtpForm";
import LoginForm from "./LoginForm";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { errorMessage, successMessage } from "../../Common/SwalToast";
import { createToken } from "../../utilities/authHelper";
import { request, validate } from "./redux/login.action";
const Login = () => {
  const dispatch = useDispatch()
  const { token, error, otpSentStatus } = useSelector(state => state.login);
  const navigate = useNavigate()
  const [otpTab, setOtpTab] = useState(false)
  const [otpTabData, setOtpTabData] = useState(null)
  const handlerSubmit = async (data) => {
    console.log(data, "data")
    let query = { credentials: await createToken(data, 39999) }
    console.log(query, "query")
    setOtpTabData(data)
    dispatch(request({ query }))
  }
  const handlerSubmitOtp = async (data) => {
    // console.log(data, "handlerSubmitOtp", data.length)
    if (data.length === 6) {
      let tempData = {
        login: otpTabData.login,
        type: "validate",
        code: Number(data.join(''))
      }
      let query = { credentials: await createToken(tempData, 39999) }
      // console.log(query, "query")
      setOtpTabData(tempData)
      dispatch(validate({ query }))
    } else {
      errorMessage('Invalid OTP !!')
    }
  }

  useEffect(() => {
    if (otpSentStatus && otpSentStatus?.success) {
      setOtpTab(true)
    }
  }, [otpSentStatus])

  useEffect(() => {
    if (token && token?.success) {
      localStorage.setItem('token', String(token?.data.token))
      setTimeout(() => {
        window.location.href = "/"
      }, 3000);
    }
  }, [token])
  useEffect(() => {
    if (error) {
      errorMessage(error || 'Something went wrong')
    }
  }, [error])

  return (
    <div class="d-flex flex-column flex-root bg-white" id="kt_app_root">
      <div class="d-flex flex-column flex-lg-row flex-column-fluid">
        <a href="index.html" class="d-block d-lg-none mx-auto py-20">
          <img
            alt="Logo"
            src="assets/media/logos/default.svg"
            class="theme-light-show h-25px"
          />
          <img
            alt="Logo"
            src="assets/media/logos/default-dark.svg"
            class="theme-dark-show h-25px"
          />
        </a>

        <div class="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10">
          <div class="d-flex justify-content-center flex-column-fluid flex-column w-100 mw-450px">
            <div class="d-flex flex-stack py-2">
              <div class="me-2"></div>
            </div>

            <div class="py-20">
              {
                otpTab ?
                  <OtpForm otpTabData={otpTabData} handlerSubmitOtp={handlerSubmitOtp} />
                  :
                  <LoginForm handlerSubmit={handlerSubmit} />
              }
            </div>
          </div>
        </div>

        <div
          class="d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat"
          style={{ "background-image": "url(assets/media/auth/bg11.png)" }}
        ></div>
      </div>
    </div>
  );
};

export default Login;



