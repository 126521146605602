
import SwitchBoxWithoutLabel from "../../../Common/SwitchBoxWithoutLabel"
import ActionsCell from "../../../Common/TableWithSubtable/ActionsCell"
import CountryImageCell from "../Country/CountryImageCell"



const currencyColumn = [
//   {
//     header:"",
//     size:50,
//     id:"expand",
//     cell:({row}) => <ToggleIconTable row={row} />
//   },
//   {
//     header:(props) => <SelectionHeader props={props} />,
//     size:50,
//     id:"selction",
//     cell:(props) => <SelectionCell props={props} />
//   },
    {
      accessorKey:'type',
      header:'Type',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'country',
      header:'Country',
      size:150,
      cell: (props) => {
        let data = props.getValue()
        return <div style={{display:"flex",flexFlow:"column"}}>
        {data?.map((e)=>{
          return <span key={e._id}>{e.name}</span>
        })}
       </div>
         
      }},
    {
      accessorKey:'name',
      header:'Name',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'code',
      header:'Code',
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'codeISO',
      header:'Code ISO',
      cell: (props) => <p>{props.getValue()}</p>
  
    },

    {
      accessorKey:'symbol',
      header:'Symbol',
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'img',
      header:'Image',
      cell: (props) => <CountryImageCell props={props} />
  
    },
  {
    accessorKey: 'is_active',
    header: 'Is Active',
  },
  {
    accessorKey:'',
    header:'Actions',
    cell: ({table, row}) => <ActionsCell type={['edit', 'remove']} table={table} row={row}/>

  }

    
  ]
  
  export {currencyColumn}
  