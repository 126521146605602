import React, { useEffect, useState } from "react";
import DataToStringDiv from "./DataToStringDiv";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { start } from "@popperjs/core";
import MatchOverViewTab from "./matchOverViewTab";
const MatchDataScoreCardandOverView = ({ overViewData, match, associationKey }) => {
  const navigate = useNavigate();
  const [formattedData, setFormattedData] = useState([]);
  const [dataDiv, setshowDataDiv] = useState(true);
  const [playersData, setplayersData] = useState();
  const [teamsData, setteamsData] = useState();
  const [inningOrderData, setInningOrderData] = useState();
  const [inningData, setInningData] = useState();
  const [tournamentData, setTournamentData] = useState();
  const [associationData, setAssociationData] = useState();
  const [scoreCardData, setScoreCardData] = useState();
  const [commentry, setCommentry] = useState(null);

  const [OverViewData, setOverViewData] = useState(null);

  const [squad, setSquad] = useState();
  // GoTo player
  const gotoClick = (player, tournament, association) => {
    if (player.name) {
      localStorage.setItem("itemPlayer", player.name);
      navigate(`/fantasy-player-details/${player?.key}/${tournament.key}/${association.key}`);
    } else {
      localStorage.setItem("itemPlayerKey", player);
      navigate(`/fantasy-player-details/${player.key}/${tournament.key}/${association.key}`)
    }


  };
// GoTo player
///NabBar  
  const [activeTab, setactiveTab] = useState(1);
  const [navBarArr, setNavBarArr] = useState([{
    title: "ScoreCard",
    value: 1
  },
  //  {
  //   title: "Commentry",
  //   value: 2
  // }, {
  //   title: "Team",
  //   value: 3
  // },
  {
    title: "Matches OverView",
    value: 4
  }
  ]);
  const changeTab = async (value) => {
    setactiveTab(value)
  };
  ///NabBar



  useEffect(() => {
    // Formatting the data and storing it in the state
    if (match && match.players) {
      setplayersData(match.players);
    }
    if (match && match.teams) {
      setteamsData(match.teams);
    }
    if (match && match?.play?.innings_order) {
      setInningOrderData(match?.play?.innings_order);
    }
    if (match && match?.play?.innings) {
      setInningData(match?.play?.innings);
    }
    if (match && match?.squad) {
      setSquad(match?.squad);
    }
    if (match && match?.tournament) {
      setTournamentData(match?.tournament);
    }
    if (match && match?.association) {
      setAssociationData(match?.association);
    }
    if (match && match?.play?.related_balls) {
      setCommentry(match?.play?.related_balls);
    }
    if (overViewData && overViewData) {
      setOverViewData(overViewData);
    }
  }, [match, overViewData]);


  useEffect(() => {
    let tableData = []
    inningOrderData && inningOrderData.forEach((inningOrder,index) => {
      // console.log(inningData[inningOrder], index, "inningOrder")
      let battingData=[]
      let wicketData = []
      let bowlerData = []
      inningData[inningOrder].batting_order.forEach((playerKey)=>{
         let batInning = inningData[inningOrder].index.split("_")?.[1]
        battingData.push ({
          player_name: playersData[playerKey].player.name,
          player_key: playerKey,
          msg: playersData[playerKey].score[batInning]?.batting?.dismissal?.msg || 'Not Out',
          runs: playersData[playerKey].score[batInning]?.batting?.score?.runs,
          balls: playersData[playerKey].score[batInning]?.batting?.score?.balls,
          dot_balls: playersData[playerKey].score[batInning]?.batting?.score?.dot_balls,
          fours: playersData[playerKey].score[batInning]?.batting?.score?.fours,
          sixes: playersData[playerKey].score[batInning]?.batting?.score?.sixes, 
          strike_rate: playersData[playerKey].score[batInning]?.batting?.score?.strike_rate,
          threes: playersData[playerKey].score[batInning]?.batting?.score?.threes,
          twos: playersData[playerKey].score[batInning]?.batting?.score?.twos,
          fives: playersData[playerKey].score[batInning]?.batting?.score?.fives,

        })
      })
      inningData[inningOrder].wicket_order.forEach((playerKey) => {
        let batInning = inningData[inningOrder].index.split("_")?.[1]
        wicketData.push({
          player_name:playersData[playerKey].player.name,
          player_key: playerKey,
          team_runs: playersData[playerKey].score[batInning]?.batting?.dismissal?.team_runs,
          overs: playersData[playerKey].score[batInning]?.batting?.dismissal?.overs,
          wicket_number: playersData[playerKey].score[batInning]?.batting?.dismissal?.wicket_number,
          ball_key: playersData[playerKey].score[batInning]?.batting?.dismissal?.ball_key,
          wicket_type: playersData[playerKey].score[batInning]?.batting?.dismissal?.wicket_type,
        })
      })
      // Dynamic bowling inning selection
      const bowlingInningMap = {
        "a_1": inningData['b_1'],
        "b_1": inningData['a_1'],
        "a_2": inningData['b_2'],
        "b_2": inningData['a_2'],
      };
      let bowlingInning = bowlingInningMap[inningOrder] || [];
      // console.log("BOWLER INNGING=", index, "bat:", inningOrder, "bowl:", bowlingInning.index.split("_")?.[1]);
      // Collect bowler data
      bowlingInning.bowling_order.forEach((playerKey) => {
        bowlerData.push({
          player_name: playersData[playerKey].player.name,
          player_key: playerKey,
          overs: playersData[playerKey].score[bowlingInning.index.split("_")?.[1]]?.bowling?.score?.overs,
          maiden_overs: playersData[playerKey].score[bowlingInning.index.split("_")?.[1]]?.bowling?.score?.maiden_overs,
          runs: playersData[playerKey].score[bowlingInning.index.split("_")?.[1]]?.bowling?.score?.runs,
          balls_breakup: playersData[playerKey].score[bowlingInning.index.split("_")?.[1]]?.bowling?.score?.balls_breakup,
          wickets: playersData[playerKey].score[bowlingInning.index.split("_")?.[1]]?.bowling?.score?.wickets,
          wickets_breakup: playersData[playerKey].score[bowlingInning.index.split("_")?.[1]]?.bowling?.score?.wickets_breakup,
          economy: playersData[playerKey].score[bowlingInning.index.split("_")?.[1]]?.bowling?.score?.economy,
        });
      });
      let data={
        score_str:inningData[inningOrder].score_str,
        team_name: teamsData[inningData[inningOrder].index.split("_")?.[0]].name,
        inningData: inningData[inningOrder], 
        batting_data: battingData,
        wicket_data: wicketData,
        bowling_data: bowlerData
      }
      tableData.push(data)
    });
    setScoreCardData(tableData)
 
    
  }, [inningOrderData, inningData, playersData, teamsData]);
  useEffect(() => {
    console.log(scoreCardData,"scoreCardData")
  }, [scoreCardData]);


  //Match Over View Score
  const fetchMatch = async (matchKey,page) => {
    try {
      if (matchKey, page) {
        const resOverView = await getOverView(matchKey, page);
        if (resOverView) {
          setOverViewData(resOverView);
        } else {
          console.log("resOverView:DATA NOT FOUND !!");
        }
      } else {
        console.log("matchKey NOT FOUND !!");
      }
    } catch (error) {
      console.error("Support Functions ERROR:", error);
    }
  };

  const getOverView = async (matchKey, page) => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API_URL}v5/cricket/${process.env.REACT_APP_PROJECT_KEY
          }/match/${matchKey}/over-summary/${page ? page+"/" : ""}`,
        headers: { 'hard-refresh': 'true' },
      };
      return axios
        .request(config)
        .then((response) => {
          // console.log("teamresdata",response.data);
          return response.data.data;
        })
        .catch((error) => {
          console.error("API CALL ERROR:", error);
        });
    } catch (error) {
      console.error("API CALL  Functions ERROR:", error);
    }
  };
  //Match Over View Score

  return (
    <div>
      {/* {dataDiv && (
        <DataToStringDiv
          heading={"Players Data"}
          api={"matche API"}
          children={playersData}
        />
      )} */}
      {/* {dataDiv && (
        <DataToStringDiv
          heading={"Teams Data"}
          api={"matche API"}
          children={teamsData}
        />
      )} */}
      {/* {dataDiv && (
        <DataToStringDiv
          heading={"Played Innings Order"}
          api={"matche API"}
          children={inningOrderData}
        />
      )} */}
      {/* {dataDiv && (
        <DataToStringDiv
          heading={"Innings"}
          api={"matche API"}
          children={inningData}
        />
      )} */}
      {/* {dataDiv && (
        <DataToStringDiv
          heading={"Played Squad XI players"}
          api={"matche API"}
          children={squad}
        />
      )} */}
      <div class="mb-7">
        <div class="row align-items-center">
          <div class="">
            <ul
              style={{
                display: "flex",
                flexFlow: "row",
                listStyleType: "none",
                margin: 0,
                padding: 0,
                overflow: "hidden",
                backgroundColor: "#333",
              }}
            >
              {navBarArr &&
                navBarArr.map((tab) => {
                  return (
                    <li
                      style={{
                        color: "white",
                        textAlign: "center",
                        padding: "14px 16px",
                        textDecoration: "none",
                        backgroundColor:
                          activeTab === tab?.value ? "#626fc1" : "#333",
                      }}
                      onClick={(e) => {
                        changeTab(tab?.value);
                      }}
                    >
                      {tab?.title}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <div
        class="datatable datatable-bordered datatable-head-custom"
        id="kt_datatable"
      >
        {activeTab === 1 && (
          <div className="table-responsive">
            <div class="mb-7">
              <div class="row align-items-center">
                <h3
                  style={{
                    margin: "2px",
                    marginBottom: "0px",
                    padding: "10px",
                    overflow: "hidden",
                  }}
                >
                  {match?.play?.result?.msg}
                </h3>
                {scoreCardData &&
                  scoreCardData.map((table) => {
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "row",
                            listStyleType: "none",
                            margin: "2px",
                            marginBottom: "0px",
                            padding: "10px",
                            overflow: "hidden",
                            backgroundColor: "#838383",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "row",
                              justifyContent: "space-between",
                              width: "100%",
                              marginTop: "5px",
                            }}
                          >
                            <span
                              style={{
                                marginLeft: "10px",
                                marginTop: "5px",
                                color: "#ffff",
                                fontSize: "1.5rem",
                              }}
                            >
                              {table.team_name} Inning{" "}
                              {table.inningData.index.split("_")?.[1]}
                            </span>{" "}
                            <span
                              style={{
                                marginRight: "10px",
                                marginTop: "5px",
                                color: "#ffff",
                                fontSize: "1rem",
                              }}
                            >
                              {table.inningData.score_str}
                            </span>{" "}
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "row",
                              listStyleType: "none",
                              margin: 0,
                              padding: "5px",
                              paddingLeft: "10px",
                              overflow: "hidden",
                              backgroundColor: "#e1e1e1",
                            }}
                          >
                            <h5
                              style={{
                                marginLeft: "10px",
                                marginTop: "5px",
                                color: "#292828",
                              }}
                            >
                              Batter
                            </h5>
                          </div>
                          <table
                            id="kt_table_users"
                            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                            key={table.batting_data}
                            style={{ backgroundColor: "#ecf7ff" }}
                          >
                            <tr
                              className="text-start text-muted fw-bolder fs-5 text-uppercase gs-0"
                              style={{ color: "#rgb(0, 0, 0) !important" }}
                            >
                              <th>Batter</th>
                              <th>Message</th>
                              <th>R</th>
                              <th>B</th>
                              <th>4s</th>
                              <th>6s</th>
                              <th>5s</th>
                              <th>3s</th>
                              <th>2s</th>
                              <th>Dot Balls</th>
                              <th>SR</th>
                            </tr>
                            <tbody className="text-gray-600 fw-bold">
                              {table.batting_data?.map((batData) => {
                                return (
                                  <tr key={batData.player_name}>
                                    <td
                                      style={{
                                        fontSize: "1rem",
                                        color: "#4519ff ",
                                        fontWeight: "600",
                                      }}
                                      onClick={() => {
                                        gotoClick(
                                          {
                                            key: batData.player_key,
                                            name: batData.player_name,
                                          },
                                          tournamentData,
                                          associationData
                                        );
                                      }}
                                    >{`${batData.player_name}`}</td>
                                    <td>{batData.msg}</td>
                                    <td>{batData.runs}</td>
                                    <td>{batData.balls}</td>
                                    <td>{batData.fours}</td>
                                    <td>{batData.sixes}</td>

                                    <td>{batData.fives}</td>
                                    <td>{batData.threes}</td>
                                    <td>{batData.twos}</td>
                                    <td>{batData.dot_balls}</td>

                                    <td>{batData.strike_rate}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "row",
                              listStyleType: "none",
                              margin: 0,
                              padding: "5px",
                              paddingLeft: "10px",
                              overflow: "hidden",
                              backgroundColor: "#e1e1e1",
                            }}
                          >
                            <h5
                              style={{
                                marginLeft: "10px",
                                marginTop: "5px",
                                color: "#292828",
                              }}
                            >
                              Extras
                            </h5>
                          </div>
                          {/* wicket Breakout */}
                          <div>
                            {dataDiv && (
                              <DataToStringDiv
                                heading={"Extra Runs"}
                                api={""}
                                children={table.inningData.extra_runs}
                              />
                            )}
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "row",
                              listStyleType: "none",
                              margin: 0,
                              padding: "5px",
                              paddingLeft: "10px",
                              overflow: "hidden",
                              backgroundColor: "#e1e1e1",
                            }}
                          >
                            <h5
                              style={{
                                marginLeft: "10px",
                                marginTop: "5px",
                                color: "#292828",
                              }}
                            >
                              Fall of Wickets
                            </h5>
                          </div>
                          {/* wicket Breakout */}
                          <div>
                            <p>
                              {table.wicket_data?.map((wicketData) => {
                                return (
                                  <span>
                                    <span>
                                      {" "}
                                      {wicketData.team_runs}-
                                      {wicketData.wicket_number}
                                      {" ( "}
                                    </span>

                                    <span
                                      style={{
                                        fontSize: "1rem",
                                        color: "#4519ff ",
                                        fontWeight: "600",
                                      }}
                                      onClick={() => {
                                        gotoClick(
                                          {
                                            key: wicketData.player_key,
                                            name: wicketData.player_name,
                                          },
                                          tournamentData,
                                          associationData
                                        );
                                      }}
                                    >{`${wicketData.player_name} ,`}</span>
                                    <span>{`${wicketData.overs["0"]}.${wicketData.overs["1"]} )`}</span>
                                    {", "}
                                  </span>
                                );
                              })}
                            </p>
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "row",
                              listStyleType: "none",
                              margin: 0,
                              padding: "5px",
                              paddingLeft: "10px",
                              overflow: "hidden",
                              backgroundColor: "#e1e1e1",
                            }}
                          >
                            <h5
                              style={{
                                marginLeft: "10px",
                                marginTop: "5px",
                                color: "#292828",
                              }}
                            >
                              Bowler
                            </h5>
                          </div>
                          <table
                            id="kt_table_users"
                            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                            key={table.bowling_data}
                            style={{ backgroundColor: "#ecf7ff" }}
                          >
                            <tr
                              className="text-start text-muted fw-bolder fs-5 text-uppercase gs-0"
                              style={{ color: "#rgb(0, 0, 0) !important" }}
                            >
                              <th>Bowler</th>
                              <th>O</th>
                              <th>M</th>
                              <th>R</th>
                              <th>W</th>
                              <th>NB</th>
                              <th>WD</th>
                              <th>ECO</th>
                            </tr>
                            <tbody className="text-gray-600 fw-bold">
                              {table.bowling_data?.map((bowlerData) => {
                                return (
                                  <tr key={bowlerData.player_name}>
                                    <td
                                      style={{
                                        fontSize: "1rem",
                                        color: "#4519ff ",
                                        fontWeight: "600",
                                      }}
                                      onClick={() => {
                                        gotoClick(
                                          {
                                            key: bowlerData.player_key,
                                            name: bowlerData.player_name,
                                          },
                                          tournamentData,
                                          associationData
                                        );
                                      }}
                                    >
                                      {bowlerData.player_name}
                                    </td>
                                    <td>
                                      {`${bowlerData.overs["0"]}.${bowlerData.overs["1"]}`}
                                    </td>
                                    <td>{bowlerData.maiden_overs}</td>
                                    <td>{bowlerData.runs}</td>
                                    <td>{bowlerData.wickets}</td>
                                    <td>{bowlerData.balls_breakup.no_balls}</td>
                                    <td>{bowlerData.balls_breakup.wides}</td>
                                    <td>{bowlerData.economy}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        {/* {activeTab === 2 && <></>}
        {activeTab === 3 && <></>} */}
        {activeTab === 4 && (
          <>
            <div style={{ display:"flex", flexFlow:"column", justifyContent:"start", alignItems:"center" }}>
              {/* {dataDiv && (
                <> <DataToStringDiv
                  heading={"OverViewData Data"}
                  children={OverViewData?.summaries}
                // children={OverViewData}
                /></>
                  
               
              )} */}
              <div style={{
                display: "flex", flexFlow: "row", justifyContent: "space-between", alignItems: "center"
              }}>
                <button style={{
                  backgroundColor: "#626fc1",
                  border: "0px",
                  margin: "5px",
                  padding: "10px",
                  color: "#ffff",
                  borderRadius: "10px"
                }}
                  onClick={() => {
                    fetchMatch(
                      match.key, OverViewData.previous_page_key

                    );
                  }}
                >Prev Page {`(${OverViewData?.previous_page_key})`}</button>
                <button
                  style={{
                    backgroundColor: "#626fc1",
                    border: "0px",
                    margin: "5px",
                    padding: "10px",
                    color: "#ffff",
                    borderRadius: "10px"
                  }}
                  onClick={() => {
                    fetchMatch(
                      match.key, OverViewData?.next_page_key
                    );
                  }}
                >Next Page {`(${OverViewData?.next_page_key})`} </button>
              </div>
              <MatchOverViewTab heading={"OverViewData Summary Data"} data={OverViewData?.summaries} playersData={playersData} key={OverViewData?.summaries} />
              
              
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MatchDataScoreCardandOverView;
