import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import $ from "jquery"; // jQuery is needed for daterangepicker plugin
import flatpickr from "flatpickr";
const SingleDatePickerBasic = ({ startDate, setStartDate, name, text, disabled = false}) => {
  const inputRef = useRef(null);
  console.log(startDate)
  useEffect(() => {
    const input = $(inputRef.current);

    function cb(slectdates, start) {
      console.log(start);
      setStartDate(start);

      // input.html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`);
    }

    flatpickr(input, {
      // You can add options here
      dateFormat: "Y-m-d",
      onChange: cb,
    });
  }, [startDate]);
  return (
    <>
      <div class="fv-row mb-0">
        <label class="form-label mt-3 required">{text}</label>
        <input
          type="text"
          id="kt_ecommerce_report_sales_daterangepicker"
          ref={inputRef}
          value={`${startDate}`}
          readOnly
          disabled={disabled}
          class="form-control form-control-solid"
          placeholder="Pick date rage"
          name={name}
        />
      </div>
    </>
  );
};

export default SingleDatePickerBasic;
