import React from 'react'
import ModalContainer from '../../../Common/ModalContainer'

const RequiredInfoPopup = ({onClose}) => {
  return (
    <ModalContainer text={"Required Info"} isViewOnly={true} onClose={onClose} >
        <ul>
            <li>Coins</li>
            <li>Rewards</li>
            <li>Daily Login Rewards</li>
            <li>Subscription</li>
        </ul>
    </ModalContainer>
  )
}

export default RequiredInfoPopup