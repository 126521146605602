import { createSlice } from "@reduxjs/toolkit";
import { getPlayersFetchById, getPlayersList, saveAssignCompany, savePlayer } from "./assignCompany.action";







export const assignCompanySlice = createSlice({
  name: "assignCompanyState",
  initialState: {
 
    saveLoading:false,
    save:"",

  },
  reducers: {

  },
  extraReducers: (builder) => {

   
    builder.addCase(saveAssignCompany.pending, (state) => {
      state.saveLoading = true;
    });
    builder.addCase(saveAssignCompany.fulfilled, (state, action) => {
      state.saveLoading = false;
      
      state.save = action.payload;
    });
    builder.addCase(saveAssignCompany.rejected, (state, action) => {
      state.saveLoading = false;
      state.error = action.payload;
    });
  },
});

// export const { addAuctionSettings,removeAuctionSettings } = assignCompanySlice.actions;
export default assignCompanySlice.reducer;
