import { routesApi } from "../../../../RouterFiles/routesApi";
import instance from "../../../../utilities/axios";
import url from "../../../../utilities/url";

export const getList = (headers) => {
    return instance
      .get(`${url.base_api}${routesApi.currency}`, {
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };
export const getById = (headers, id) => {
    return instance
      .get(`${url.base_api}${routesApi.currency}/${id}`, {
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };
export const add = (headers, data) => {
    console.log("addcurrency:::",data)
    return instance
      .post(`${url.base_api}${routesApi.currency}`,data)
      .then((res) => {
        return res;
      })
     
      ;
  };
  export const update = (headers, data,id) => {
    console.log("update:::",data)
    return instance
      .put(`${url.base_api}${routesApi.currency}/${id}`,data)
      .then((res) => {
        return res;
      });
  };
export const remove = (headers,id) => {
    console.log("delecurrency:::",id)
    return instance
      .delete(`${url.base_api}${routesApi.currency}/${id}`)
      .then((res) => {
        return res;
      })
     
      ;
  };

  export const getListsDropdown = () => {
    return instance
      .get(`${url.base_api}${routesApi.currency}/dropdown`)
      .then((res) => {
        return res;
      });
  }
