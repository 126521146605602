import React, { useState } from 'react'
import SelectDropdownWithSearch from '../../../Common/SelectDropdownWithSearch';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyCoinPackReducer, setCompanyCouponCodeReducer, setCompanyPromoCodeReducer, setCompanyReferralCodeReducer, setCompanySignUpBonusCodeReducer } from '../CompanySlice/CompanyReducers';
import ButtonWithLoader from '../../../Common/ButtonWithLoader';
import { errorMessage, successMessage } from '../../../Common/SwalToast';
import TextInputField from '../../../Common/TextInputField';
const inCurr = [
    { name: "Coin", value: "coin", image:'media/svg/shapes/bitcoin.svg' },
    { name: "Daimond", value: "diamond", image:'media/svg/shapes/ethereum.svg'  },
  ];
const WalletConfiguration = () => {
    const {createCompanyState} = useSelector((state) => state)
    const dispatch = useDispatch()
    const [error, setError] = useState({})
    const handlerCurrencySubmit = (name)=>{
        if(name === "couponCode" && (!createCompanyState?.couponCodeConfig?.currency )) {
            setError(prev => {
            return {
                ...prev,
                [name]: "Please fill all details"
                }
            })
            errorMessage("Please all details")
        }
        else if(name === "promoCode" && (!createCompanyState?.promoCodeConfig?.currency || !createCompanyState?.promoCodeConfig?.amount)) {
            setError(prev => {
            return {
                ...prev,
                [name]: "Please fill all details"
                }
            })
            errorMessage("Please all details")
        }
        else if(name === "referralCode" && (!createCompanyState?.referralCodeConfig?.currency || !createCompanyState?.referralCodeConfig?.amount)) {
            setError(prev => {
            return {
                ...prev,
                [name]: "Please fill all details"
                }
            })
            errorMessage("Please all details")
        }
        else if(name === "singUpBonus" && (!createCompanyState?.signUpBonusConfig?.currency || !createCompanyState?.signUpBonusConfig?.amount)) {
            setError(prev => {
            return {
                ...prev,
                [name]: "Please fill all details"
                }
            })
            errorMessage("Please all details")
        }
        else{

            successMessage("submitted")
            console.log("state::::::::::::::",createCompanyState)
        }
    }
  return (
    <div className='row g-7'>
       
        <div  className=' col-sm-6 col-md-6 col-12 px-4'>
        <div className="card  ">
        <div className="card-header">
          <div className="card-title d-flex align-items-center">
            <h3 className="fw-bold m-0 text-gray-800">Coupon Code</h3>
          </div>
        </div>
        <div className="row card-body">
          <div className=" col-12">

                   <SelectDropdownWithSearch
                 name="inCurr"
                 //   required={"true"}
                 //   label="Country"
                 placeholder={"Select a In Currency"}
                 options={inCurr}
                 value={inCurr.find((option) => option.value === createCompanyState?.couponCodeConfig?.currency)}
                 onChange={(selectedOption) =>
                  { 
                   dispatch(setCompanyCouponCodeReducer({couponCodeConfig: {currency: selectedOption.value}}))
                //    handlerCurrency("inCurr", selectedOption.value)
                }
                 }
                />
          </div>

          <div className="col-12  mt-4 d-flex justify-content-end">
            <ButtonWithLoader text="Submit" handlerSubmit={()=> handlerCurrencySubmit("couponCode")} />
          </div>
        </div>
      </div>
    </div>
        <div  className=' col-sm-6 col-md-6 col-12 px-4'>
        <div className="card  ">
        <div className="card-header">
          <div className="card-title d-flex align-items-center">
            <h3 className="fw-bold m-0 text-gray-800">Refferal Code</h3>
          </div>
        </div>
        <div className="row card-body">
          <div className=" col-12">

                   <SelectDropdownWithSearch
                 name="inCurr"
                 //   required={"true"}
                 //   label="Country"
                 placeholder={"Select a In Currency"}
                 options={inCurr}
                 value={inCurr.find((option) => option.value === createCompanyState?.referralCodeConfig?.currency)}
                 onChange={(selectedOption) =>
                  { 
                   dispatch(setCompanyReferralCodeReducer({referralCodeConfig: {currency: selectedOption.value}}))
                //    handlerCurrency("inCurr", selectedOption.value)
                }
                 }
                />
          </div>
          <div className=" col-12 mt-4">
                     <TextInputField
                       label={"Amount"}
                       required={"true"}
                       value={createCompanyState?.referralCodeConfig?.amount || 0}
                       name={"Amount"}
                       type={"number"}
                       minlength={"6"}
                       maxlength={"30"}
                       onChange={(e)=> dispatch(setCompanyReferralCodeReducer({referralCodeConfig: {amount: e.target.value}}))}
                       placeholder={"Enter your amount"}
                />
          </div>
          <div className="col-12  mt-4 d-flex justify-content-end">
            <ButtonWithLoader text="Submit" handlerSubmit={()=>  handlerCurrencySubmit("referralCode")} />

          </div>
        </div>
      </div>
    </div>
        <div  className=' col-sm-6 col-md-6 col-12 px-4'>
        <div className="card  ">
        <div className="card-header">
          <div className="card-title d-flex align-items-center">
            <h3 className="fw-bold m-0 text-gray-800">Promo Code</h3>
          </div>
        </div>
        <div className="row card-body">
          <div className=" col-12">

                   <SelectDropdownWithSearch
                 name="inCurr"
                 //   required={"true"}
                 //   label="Country"
                 placeholder={"Select a In Currency"}
                 options={inCurr}
                 value={inCurr.find((option) => option.value === createCompanyState?.promoCodeConfig?.currency)}
                 onChange={(selectedOption) =>
                  { 
                   dispatch(setCompanyPromoCodeReducer({promoCodeConfig: {currency: selectedOption.value}}))
                //    handlerCurrency("inCurr", selectedOption.value)
                }
                 }
                />
          </div>
          <div className=" col-12 mt-4">
                     <TextInputField
                       label={"Amount"}
                       required={"true"}
                       value={createCompanyState?.promoCodeConfig?.amount}
                       name={"promoCodeAmount"}
                       type={"number"}
                       minlength={"6"}
                       maxlength={"30"}
                       onChange={(e)=>  dispatch(setCompanyPromoCodeReducer({amount: {currency: e.target.value}}))}
                       
                       placeholder={"Enter amount"}
                />
          </div>
          <div className="col-12  mt-4 d-flex justify-content-end">
            <ButtonWithLoader text="Submit" handlerSubmit={()=>handlerCurrencySubmit("promoCode")} />
          </div>
        </div>
      </div>
    </div>
        <div  className=' col-sm-6 col-md-6 col-12 px-4'>
        <div className="card  ">
        <div className="card-header">
          <div className="card-title d-flex align-items-center">
            <h3 className="fw-bold m-0 text-gray-800">Sign up bonus</h3>
          </div>
        </div>
        <div className="row card-body">
          <div className=" col-12">

                   <SelectDropdownWithSearch
                 name="inCurr"
                 //   required={"true"}
                 //   label="Country"
                 placeholder={"Select a In Currency"}
                 options={inCurr}
                 value={inCurr.find((option) => option.value === createCompanyState?.signUpBonusConfig?.currency)}
                 onChange={(selectedOption) =>
                  { 
                   dispatch(setCompanySignUpBonusCodeReducer({signUpBonusConfig: {currency: selectedOption.value}}))
                //    handlerCurrency("inCurr", selectedOption.value)
                }
                 }
                />
          </div>
          <div className=" col-12 mt-4">
                     <TextInputField
                       label={"Amount"}
                       required={"true"}
                       value={createCompanyState?.signUpBonusConfig?.amount}
                       name={"signUpBonusAmount"}
                       type={"number"}
                       minlength={"6"}
                       maxlength={"30"}
                       onChange={(e)=>  dispatch(setCompanySignUpBonusCodeReducer({signUpBonusConfig: {amount: e.target.value}}))}
                       
                       placeholder={"Enter amount"}
                />
          </div>
          <div className="col-12  mt-4 d-flex justify-content-end">
            <ButtonWithLoader text="Submit" handlerSubmit={()=> handlerCurrencySubmit("singUpBonus")} />
          </div>
        </div>
      </div>
    </div>
    <div  className=' col-sm-6 col-md-6 col-12 px-4'>
        <div className="card  ">
        <div className="card-header">
          <div className="card-title d-flex align-items-center">
            <h3 className="fw-bold m-0 text-gray-800">Coin Pack</h3>
          </div>
        </div>
        <div className="row card-body">
          <div className=" col-12">

                   <SelectDropdownWithSearch
                 name="inCurr"
                 //   required={"true"}
                 //   label="Country"
                 placeholder={"Select a Virtual Currency"}
                 options={inCurr}
                 value={inCurr.find((option) => option.value === createCompanyState?.coinPackConfig?.virtualCurr)}
                 onChange={(selectedOption) =>
                  { 
                   dispatch(setCompanyCoinPackReducer({coinPackConfig: {virtualCurr: selectedOption.value}}))
                //    handlerCurrency("inCurr", selectedOption.value)
                }
                 }
                />
          </div>
          <div className=" col-12 mt-4">
                      <SelectDropdownWithSearch
                 name="outCurr"
                 //   required={"true"}
                 //   label="Country"
                 placeholder={"Select a Out Currency"}
                 options={inCurr}
                 value={inCurr.find(
                   (option) => option.value  === createCompanyState?.coinPackConfig?.virtualCurr)
                 }
                 onChange={(selectedOption) =>
                 {
                    dispatch(setCompanyCoinPackReducer({coinPackConfig: {virtualCurr: selectedOption.value}}))
                   
                 }
                 }
                />
          </div>
          <div className="col-12  mt-4 d-flex justify-content-end">
            <ButtonWithLoader text="Submit" handlerSubmit={handlerCurrencySubmit} />
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default WalletConfiguration