import React from 'react'

const TextInputField = ({ required = false, label, name, type, value, onChange, minlength = 0, maxlength = 100, placeholder, disabled=false}) => {
  return (
    <div class="fv-row mb-0">
				{
          label && 
    <label class="fs-6 fw-semibold form-label mt-3">
      <span className={`${required ? "required": ""}`}>{label}</span>
      
    </label>
        }											
    
    
    <input 
    type={type} 
    class="form-control form-control-solid" 
    name={name} 
    value={value} 
    minlength={minlength} 
    maxlength={maxlength}
    onChange={onChange}
    placeholder={placeholder}
    disabled={disabled}
    onKeyDown={(e)=>{
      if( type == "number"){

        if(['.','e','+','-'].includes(e.key)){
          e.preventDefault()
      }}}
      }
     />
    
  </div>
  )
}

export default TextInputField
