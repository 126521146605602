import React, { useEffect, useState } from "react";
import Table from "../../../Common/TableWithTan/Table";
import { sportColumn } from "./SportColumn";
import { useNavigate } from "react-router";
import Toolbar from "../../../Common/Toolbar";
import Content from "../../../Common/Content";
import CreateSport from "./CreateSport";
import WithAddButton from "../../../../hooks/WithAddButton";
import { useDispatch, useSelector } from "react-redux";
import { delSport, deleteSport, fetchList } from "./redux/sport.action";
import Loader from "../../../MasterLayout/Loader";
import { errorMessage, successMessage } from "../../../Common/SwalToast";

const ViewSportList = ({ addBtn, handleAddBtn }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const { sports, loading, error, recordPerPage, delSport,sport,totalPages,totalRecord } = useSelector(state => state.sports)
  const [isLoading, setLoading] = useState(false)
  const [updateData, setUpdateData] = useState(null)
  const [pageNo, setPageNo] = useState(0)
  const [recordPerPages, setRecordPerPage] = useState(5)
  useEffect(() => {
    dispatch(fetchList({ pageNo: pageNo + 1 , recordPerPage: recordPerPages }))
  }, [pageNo,recordPerPages])
  useEffect(() => {
    setData(sports)
  }, [sports])

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  useEffect(() => {
    if (error) {
      errorMessage(error || 'Something went wrong')
    }
  }, [error])
  useEffect(() => {

    if (delSport?.message) {
      console.log(delSport)
      successMessage(delSport.message)
      dispatch(fetchList({ pageNo: 0, recordPerPage: recordPerPages }))
    }
  }, [delSport])

  useEffect(() => {
    console.log(sport,sports)
    if (sport?.message) {
      console.log(sport)
      successMessage(sport.message)
      handleAddBtn(false)
      dispatch(fetchList({ pageNo: 0, recordPerPage: recordPerPages }))
    }
  }, [sport])

  const updateView = (rowIndex) => {
    console.log("view:::",data)
    navigate('/sport/details/' + data[rowIndex]?._id)
  }
  const updateEdit = (rowIndex) => {
    handleAddBtn(false)
    handleAddBtn(true)
    console.log(data[rowIndex]?._id)
    setUpdateData(data[rowIndex])
    // navigate('/' + routesFrontend.language + '/edit/' + tableData[rowIndex]?._id)
  }
  const updateDelete = (rowIndex) => {

    console.log(data[rowIndex]?._id, error)

    dispatch(deleteSport({ id: data[rowIndex]?._id }))
  }
  console.log("data:::::", data)
  return (
    <>
      <Toolbar text={"Sports"} isButton={true} handleClick={()=> {
        setUpdateData(null)
        handleAddBtn(!addBtn)
        }} textBtn={"Add Sport"} addBtn={addBtn} />
      <Content>
        {
          addBtn &&
          <CreateSport data={updateData} />
        }
        <div className="card">
          {/* <div className="card-header card-header-stretch">
        <div className="card-title d-flex align-items-center">
          <h3 className="fw-bold m-0 text-gray-800">Assign Companies</h3>
        </div>
      </div> */}

          {/* <div className="card-body">
        <div className="row g-9 mb-8 ">
          {companies.map((company, index) => {
            return (
              <div className="col-md-9 d-flex justify-content-between">
                <h3>{company}</h3>
                <div className="d-flex justify-content-start">
                <div
                  className={`btn py-3 px-3 mw-150px  hover-scale ${
                    selected.includes(company) ? "btn-danger" : "btn-light"
                  }`}
                  onClick={(e) => {
                    console.log(e.target.innerText, e.target.className);
                    if (e.target.innerText == "Add" || e.target.className.includes('ki-plus-square')) {
                      setSelected((prev) => {
                        return [...prev, company];
                      });
                    } else
                      setSelected((prev) => {
                        return prev.filter((ele) => ele !== company);
                      });
                  }}
                >
                  {selected.includes(company) ? (
                    <span className="w-80px d-flex justify-content-between align-items-center">
                      <i className="ki-outline ki-minus-square  fs-1 me-1"></i>
                      Remove
                    </span>
                  ) : (
                    <span className="w-65px d-flex justify-content-between align-items-center">
                      {" "}
                      <i className="ki-outline ki-plus-square fs-1 me-1"></i>
                      Add
                    </span>
                  )}
                </div>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
          <div class="card-body">
            {/* <FilterForm  /> */}
            {/* <Filter columnFilters={columnFilters} setColumnFilters={setColumnFilters} /> */}
            {/* <div class="col-md-4 col-6 mt-2">
                <FilterPopover
                  option={columnFilters}
                  setOption={setColumnFilters}
                  filterValues={employeeTypeFilter}
                  id="employeeType"
                  text="Employee Type"
                />
              </div> */}
            <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
              {
                isLoading ? <Loader /> :

                  <Table
                    column={sportColumn}
                    tableData={data}
                    recordPerPage={recordPerPages}
                    totalPages={totalPages}
                    totalRecord={totalRecord}
                    pageIndex={pageNo}
                    updateView={updateView}
                    updateEdit={updateEdit}
                    updateDelete={updateDelete}
                    handlerChangeDropDown = {(index)=> 
                      {
                       
                        setPageNo(0);
                      setRecordPerPage(index)
                      }
                    }
                    handlePageChange = {(index) => {
                      
                      setPageNo(index)
                    }}
                  // columnFilters={columnFilters}
                  // setColumnFilters={setColumnFilters}
                  />
              }
            </div>

          </div>
        </div>
      </Content>
    </>
  );
};

export default WithAddButton(ViewSportList);
