import React from 'react'
import Content from '../Common/Content'

const Home = () => {
  return (
    <Content>
        <div className="card d-flex justify-content-center align-items-center" style={{minHeight:'70vh'}}>
            <h1 className='f-28'>Welcome to Gamerji Esports</h1>
        </div>
    </Content>
  )
}

export default Home
