import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../Common/Checkbox'

const SelectCell = ({props, name, data}) => {
  // console.log(props.table.getRow(props.row.index).original)
  const [val, setVal] = useState(false)
  //updateEdit
  const handlerChange = ()=>{
    props.table.options.meta.updateEdit(props.row.index, name,!val)
    setVal(!val)
  }
  useEffect(()=>{
    setVal(data)
  },[])
  return (
   <Checkbox 
   name={name+props.row.index}
        value={val || data}
        onChange={(e) => handlerChange()}
        checked = {val ? true : false }
        disable = {props.table.getRow(props.row.index).original.is_shared == true || props.table.getRow(props.row.index).original.is_shared == false }
   />
  )
}

export default SelectCell