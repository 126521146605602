import React, { useEffect } from "react";
import LightButton from "../../../../Common/LightButton";
import PrimaryButton from "../../../../Common/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { getSeriesById } from "../redux/series.action";
import Loader from "../../../../MasterLayout/Loader";
import { getDate } from "../../../../utilities/dateUtils";


const SeriesCard = ({ tabs, activeTab, setActiveTab,id }) => {
  const dispatch = useDispatch()
  const {viewSeries,loading} = useSelector(state => state.series)

  useEffect(()=>{
    dispatch(getSeriesById({id:id,companyCode:'SAAS'}))
  },[])
  return (
    <>
    {
      loading ? <Loader /> :

    <div class="card mb-6">
      <div class="card-body pt-9 pb-0">
        <div class="d-flex flex-wrap flex-sm-nowrap">
          <div class="me-7 mb-4">
            <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img
                src={`${process.env.REACT_APP_CLIENT_URL}assets/media/avatars/300-1.jpg`}
                alt="img"
              />
              <div class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
            </div>
          </div>

          <div class="flex-grow-1">
            <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2">
                  <span
                   
                    class="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                  >
                   <span className="text-capitalize">{viewSeries?.sport}</span> - {viewSeries?.series_name}{`(${viewSeries?.short_name})`}
                  </span>
                 
                </div>

                <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                  <span
                   
                    class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                  >
                    
                    <b>Format</b> - {viewSeries?.format}
                  </span>
                  <span
                   
                    class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                  >
                   
                    <b>Start Date</b> - {getDate(viewSeries?.start_date)}
                  </span>
                  <span
                   
                    class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                  >
                   
                    <b>End Date </b>- {getDate(viewSeries?.end_date)}
                  </span>
                  <span
                   
                    class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                  >
                   
                    <b>Match Count </b>- {viewSeries?.match_count}
                  </span>
                  <span
                   
                    class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                  >
                   
                    <b>metric_group </b>- {viewSeries?.metric_group}
                  </span>
                  <span
                   
                    class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                  >
                   
                    <b>Host By </b>- {viewSeries?.created_by}
                  </span>
                  
                </div>
              </div>

              <div class="d-flex my-4">
                <LightButton text="Follow" />
                <PrimaryButton text={"Hire Me"} />

             
              </div>
            </div>

           
          </div>
        </div>

        <ul
          class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
          onClick={(e) => {
            if (e.target.tagName === "SPAN") {
              const clickedTab = e.target.innerText; // Get the tab name from the clicked span
              setActiveTab(clickedTab);
            }
          }}
        >
          {tabs?.map((tab) => (
            <li class="nav-item mt-2" key={tab}>
              <span
                class={`nav-link text-active-primary ms-0 me-10 py-5 ${
                  activeTab === tab ? "active" : ""
                }`}
              >
                {tab}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
    }
    </>
  );
};

export default SeriesCard;
