import SwitchBoxWithoutLabel from "../../Common/SwitchBoxWithoutLabel";
import ActionsCell from "../../Common/TableWithSubtable/ActionsCell";
import ViewDetailCell from "../../Common/TableWithTan/ViewDetailCell";
import { getDate } from "../../utilities/dateUtils";

const staffColumn = [
  {
    accessorKey: "emp_code",
    header: "Code",
    
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "name",
    header: "Name",
    
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "email",
    header: "Email",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "phone",
    header: "Number",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "dob",
    header: "dob",
    size:200,
    cell: (props) => {
      return <p>{getDate(props.getValue())}</p>;
    },
  },
  {
    accessorKey: "emp_type",
    header: "Type",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "signInType",
    header: "Sign In Type",
    cell: (props) => <p>{props.getValue() || 'phone'}</p>,
  },
  {
    accessorKey: "is_active",
    header: "Is Active",
    cell: (props) => <SwitchBoxWithoutLabel checked={props.getValue()} row={props.row.index} onChange={props.table.options.meta.updateStatus} />,
  },
  {
    accessorKey: "",
    header: "View Details",
   
    cell: (props) => <ViewDetailCell props={props} />,
  },
  {
    accessorKey: "",
    header: "Actions",
   
    cell: ({ table, row }) => (
      <ActionsCell type={["edit", "remove"]} table={table} row={row} />
    ),
  },
];

export { staffColumn };
