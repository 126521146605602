import { createSlice } from "@reduxjs/toolkit";

import { addStaff, assignCompany, deleteStaff, fetchList, fetchModulePermission, fetchUserPermission, getStaffById, updateStaff, updateStaffStatus, updateUserPermission } from "./staff.action";

export const staffSlice = createSlice({
  name: "languageState",
  initialState: {
    pageNo: 1,
    recordPerPage: 10,
    totalRecord: 0,

    totalPages: 0,
    staffList: [],
    staff: null,
    viewstaff: null,
    delStaff:null,
    loading: false,
    error: null,
    userPermission:null,
    modulePermission:null,
    assignCompanyStatus:false,
    updateUserPermissionStatus: null
  },
  reducers: {
    // addSportReducer:createSport
  },
  
  extraReducers: (builder) => {
    builder.addCase(addStaff.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addStaff.fulfilled, (state, action) => {
      state.loading = false;
      state.staff = action.payload;
    });
    builder.addCase(addStaff.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(updateStaff.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStaff.fulfilled, (state, action) => {
      state.loading = false;
      state.staff = action.payload;
    });
    builder.addCase(updateStaff.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(updateStaffStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStaffStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.staff = action.payload;
    });
    builder.addCase(updateStaffStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(fetchList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.loading = false;
      state.staffList = action.payload?.data;
    state.recordPerPage = action.payload?.recordPerPage;
        state.pageNo = action.payload?.pageNo;
        state.totalRecord = action.payload?.totalRecord;
        state.totalPages = action.payload?.totalPages
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.loading = true;
      state.error = action.payload.error.message;
    });
    builder.addCase(deleteStaff.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteStaff.fulfilled, (state, action) => {
      state.loading = false;
      state.delStaff = action.payload;
    });
    builder.addCase(deleteStaff.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(getStaffById.pending, (state) => {
      state.loading = true;
      state.viewstaff = {}
    });
    builder.addCase(getStaffById.fulfilled, (state, action) => {
      state.loading = false;
      state.viewstaff = action.payload.data;
    });
    builder.addCase(getStaffById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });

  ///////////////////////////////////////////////////////////////////////
  // USER PERMISSION MODULE SLICE
    builder.addCase(assignCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(assignCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.assignCompanyStatus = action.payload;
    });
    builder.addCase(assignCompany.rejected, (state, action) => {
      state.loading = false;
      state.assignCompanyStatus = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(fetchUserPermission.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserPermission.fulfilled, (state, action) => {
      state.loading = false;
      state.userPermission = action.payload;
      state.updateUserPermissionStatus=null;
    });
    builder.addCase(fetchUserPermission.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(fetchModulePermission.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchModulePermission.fulfilled, (state, action) => {
      state.loading = false;
      state.modulePermission = action.payload;
      state.updateUserPermissionStatus = null;
    });
    builder.addCase(fetchModulePermission.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });

    builder.addCase(updateUserPermission.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserPermission.fulfilled, (state, action) => {
      state.loading = false;
      state.updateUserPermissionStatus = action.payload;
      state.error= null;
      state.userPermission= null;
      state.modulePermission= null;
      state.assignCompanyStatus= false;
    });
    builder.addCase(updateUserPermission.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
  },
});

// export const {addSportReducer} = staffSlice.actions
export default staffSlice.reducer;
