import ActionsCell from "../../Common/TableWithSubtable/ActionsCell"
import ViewDetailCell from "../../Common/TableWithTan/ViewDetailCell"





const legalityColumn = [

    {
      accessorKey:'slug',
      header:'Slug',
     
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'title',
      header:'Title',
     
      cell: (props) => <p>{props.getValue()}</p>
    },
    {
      accessorKey:'gameplay_type',
      header:'Game Play',
     
      cell: (props) => <p>{props.getValue()}</p>
  
    },

   
  {
    accessorKey: 'is_active',
    header: 'Is Active',
  },
  {
    accessorKey:'',
    header:'Actions',
    cell: ({table, row}) => <ActionsCell type={['edit', 'remove']} table={table} row={row}/>

  },{
    accessorKey:'',
    header:'View Details',
    size:100,
    cell: (props) => <ViewDetailCell props={props} />

  },

    
  ]
  
  export {legalityColumn}
  