import { createSlice } from "@reduxjs/toolkit";

import {
  fetchList,
  getAssignLanguage,
  getCountryDropdown,
  updateAssignLanguage,
} from "./country.action";

export const languageAssign = createSlice({
  name: "languageState",
  initialState: {
  
    assignLang:[],
    getAssignLangList:[],
    totalPages: 0,
    countries: [],
    dropDownList:[],
    loading: false,
    error: null,
  },
  reducers: {
   
  },

  extraReducers: (builder) => {

    builder.addCase(getAssignLanguage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssignLanguage.fulfilled, (state, action) => {
      state.loading = false;
      state.getAssignLangList = action.payload?.data.languages;
      
    });
    builder.addCase(getAssignLanguage.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error?.message;
    });
    builder.addCase(updateAssignLanguage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAssignLanguage.fulfilled, (state, action) => {
      state.loading = false;
      state.assignLang = action.payload.data;
      
    });
    builder.addCase(updateAssignLanguage.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error?.message;
    });
  },
});

// export const {addSportReducer} = languageAssign.actions
export default languageAssign.reducer;
