import { createSlice } from "@reduxjs/toolkit";
import { addCompany, deleteCompany, fetchCompanyDropdown, fetchList, getCompanyById, getCompanyConfigById, setCoinPackConfig, setCompanyCountry, setCompanyCurrency, setCompanyGame, setCompanyOtherConfig, setCouponCodeConfig, setPromoCodeConfig, setReferralCodeConfig, setSignUpBonusCodeConfig, updateCompany,auctionSettingsCompany, getCurrencyById, getCountriesCompany } from "./company.action";


export const companySlice = createSlice({
  name: "companysState",
  initialState: {
    pageNo: 1,
    recordPerPage: 10,
    totalRecord: 0,
    totalPages: 0,
    companies: [],
    company: "",
    viewCompany: "",
    viewLoader:false,
    delCompany: "",
    loading: false,
    error: null,
    selectedGames:[],
    currency:{},
    currencyGuest:{},
    selectedGamesGuest:[],
    countryConfigGuest:[{}],
    isGeneralConfiguration:'false',
    isCountryConfiguration:'false',
    countryConfig:[{}],
    otherConfig:{},
    coinPackConfig:{},
    couponCodeConfig:{},
    promoCodeConfig:{},
   referralCodeConfig:{},
  signUpBonusConfig:{},
  auctionSettings:{},
  updateAuctionSettings:{},
    dropDownList:null,
    companyCurrencies:[],
    compCurrLoad:false,
    companyCountries:[],
    compCnty:false
  },
  reducers:{
    setCompanyGameReducer: setCompanyGame,
    setCompanyCurrencyReducer: setCompanyCurrency,
    setCompanyCountryReducer: setCompanyCountry,
    setCompanyOtherReducer: setCompanyOtherConfig,
    setCompanyCoinPackReducer: setCoinPackConfig,
    setCompanyPromoCodeReducer: setPromoCodeConfig,
    setCompanyCouponCodeReducer: setCouponCodeConfig,
    setCompanyReferralCodeReducer: setReferralCodeConfig,
    setCompanySignUpBonusCodeReducer: setSignUpBonusCodeConfig,

},
  extraReducers: (builder) => {
    //COMMON Dropdown Call
    builder.addCase(fetchCompanyDropdown.pending, (state) => {
      state.viewLoader = true;
    });
    builder.addCase(fetchCompanyDropdown.fulfilled, (state, action) => {
      state.viewLoader = false;
      state.dropDownList = action?.payload?.data;
    });
    builder.addCase(fetchCompanyDropdown.rejected, (state, action) => {
      state.viewLoader = false;
      state.error = action.payload.message;
    });




    builder.addCase(addCompany.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(addCompany.fulfilled, (state, action) => {
      // state.loading = false;
      state.company = action.payload;
    });
    builder.addCase(addCompany.rejected, (state, action) => {
      // state.loading = false;
      state.error = action.payload.message;
    });
    builder.addCase(updateCompany.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      // state.loading = false;
      state.company = action.payload;
    });
    builder.addCase(updateCompany.rejected, (state, action) => {
      // state.loading = false;
      state.error = action.payload.message;
    });
    builder.addCase(fetchList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.loading = false;
      state.companies = action.payload?.data;
      
      state.company = "";
      state.viewCompany = "";
      state.delCompany = "";
      state.recordPerPage = action.payload?.recordPerPage;
      state.pageNo = action.payload?.pageNo;
      state.totalRecord = action.payload?.totalRecord;
      state.totalPages = action.payload?.totalPages;
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
    builder.addCase(deleteCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.delCompany = action.payload;
    });
    builder.addCase(deleteCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
    builder.addCase(getCompanyById.pending, (state) => {
      state.viewLoader = true;
    });
    builder.addCase(getCompanyById.fulfilled, (state, action) => {
      state.viewLoader = false;
      state.viewCompany = action.payload.data;
    });
    builder.addCase(getCompanyById.rejected, (state, action) => {
      state.viewLoader = false;
      state.error = action.payload.message;
    });
    builder.addCase(auctionSettingsCompany.pending, (state) => {
      state.viewLoader = true;
    });
    builder.addCase(auctionSettingsCompany.fulfilled, (state, action) => {
      state.viewLoader = false;
      state.updateAuctionSettings = action.payload;
    });
    builder.addCase(auctionSettingsCompany.rejected, (state, action) => {
      state.viewLoader = false;
      state.error = action.payload.message;
    });
    builder.addCase(getCompanyConfigById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCompanyConfigById.fulfilled, (state, action) => {
      state.loading = false;
      state.company = "";
      state.companies = action.payload.data?.data;
      state.selectedGames = action.payload?.data?.config?.general
      state.currency = action.payload?.data?.config?.currency
      state.countryConfig = action.payload?.data?.config?.countryConfig
      state.auctionSettings = action.payload?.data?.auction_settings
 
      state.currencyGuest = action.payload?.data?.guestConfig?.currency
      state.countryConfigGuest = action.payload?.data?.guestConfig?.countryConfig
    });
    builder.addCase(getCompanyConfigById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
    
    builder.addCase(getCurrencyById.pending, (state) => {
      state.compCurrLoad = true;
    });
    builder.addCase(getCurrencyById.fulfilled, (state, action) => {
      state.compCurrLoad = false;
      state.companyCurrencies = action.payload.data || [];
    });
    builder.addCase(getCurrencyById.rejected, (state, action) => {
      state.compCurrLoad = false;
      state.error = action.payload.message;
    });
    builder.addCase(getCountriesCompany.pending, (state) => {
      state.compCnty = true;
    });
    builder.addCase(getCountriesCompany.fulfilled, (state, action) => {
      state.compCnty = false;
      state.companyCountries = action.payload.data || [];
    });
    builder.addCase(getCountriesCompany.rejected, (state, action) => {
      state.compCnty = false;
      state.error = action.payload.message;
    });
  },
});

export const {  setCompanyGameReducer, 
  setCompanyCurrencyReducer,
  setCompanyCountryReducer,
  setCompanyOtherReducer,
  setCompanyCoinPackReducer,
  setCompanyCouponCodeReducer,
  setCompanyPromoCodeReducer,
  setCompanyReferralCodeReducer,
  setCompanySignUpBonusCodeReducer } = companySlice.actions;
export default companySlice.reducer;
