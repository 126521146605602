import { createAsyncThunk } from "@reduxjs/toolkit";
import { create, getList, remove, update } from "./profileIcons.api";


export const fetchList = createAsyncThunk(
  "company/profileIcons/fetchList",
  async ({ id, pageNo, recordPerPage }, { rejectWithValue }) => {
    console.log(pageNo, recordPerPage);
    let headers = {
      // pageNo:pageNo || 1,
      // recordPerPage:recordPerPage || 10,
      // sortBy: 'created_at',
      // sort:-1
    };
    let body = {
      pagination: {
        pageNo: pageNo || 1,
        recordPerPage: recordPerPage || 1,
        sortBy: "created_at",
        sortDirection: "asc",
      },
      search: "", // optional
    };
    try {
      const response = await getList(headers,id, body);
      console.log("response:::::::", response, pageNo, recordPerPage);
      if (response.success)
        return {
          ...response,
          pageNo,
          recordPerPage,
        };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const addProfileIcons = createAsyncThunk(
  "company/add/profileIcons",
  async ({id, data }, { rejectWithValue }) => {
    let headers = {};
    try {
      const response = await create(headers,id, data);
      console.log("responseSport:::::::", response);
      if (response.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);
export const updateProfileIcons = createAsyncThunk(
  "company/update",
  async ({ id, data }, { rejectWithValue }) => {
    let headers = {};
    try {
      const response = await update(headers, id, data);
      console.log("responseSport:::::::", response);
      if (response.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);
export const deleteProfileIcon= createAsyncThunk(
  "company/delete",
  async ({ id, data }, { rejectWithValue }) => {
    console.log("cratespor", id);
    let headers = {};
    try {
      const response = await remove(headers, id, data);
      console.log("responseCompany:::::::", response);
      if (response?.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error from company", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);

