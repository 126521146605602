import React, { useEffect } from 'react'
import Content from './Content'


const SearchBox = ({placeholder , value, onChange, name="search"}) => {
    useEffect(()=>{
  
    },[])
  return (
   
      
<div
    id="kt_docs_search_handler_basic"

    data-kt-search-keypress="true"
    data-kt-search-min-length="2"
    data-kt-search-enter="true"
    data-kt-search-layout="inline">

    
    <form data-kt-search-element="form" class="w-100 position-relative mb-5" autocomplete="off">
        
        <input type="hidden"/>
        <input type="text" class="form-control form-control-lg form-control-solid px-5"
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            data-kt-search-element="input"/>
        <span class="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5" data-kt-search-element="spinner">
            <span class="spinner-border h-15px w-15px align-middle text-gray-500"></span>
        </span>
        

        
        <span class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 d-none"
            data-kt-search-element="clear">

            
        </span>
        
    </form>
    

    
</div>

  )
}

export default SearchBox
