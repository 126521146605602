import React from 'react'

const LightButton = ({text, isLoading=false}) => {
  return (
    <button
   
    class="btn btn-sm btn-light me-2"
    id="kt_user_follow_button"
  >
    <i class="ki-outline ki-check fs-3 d-none"></i>

  
    {
        isLoading ? 
    <span class="indicator-progress">
      Please wait...
      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
    </span> :
  <span class="indicator-label">{text}</span>
    }
  </button>
  )
}

export default LightButton