import Swal from "sweetalert2";

export const errorMessage = (text) => {
    console.log(text)
    Swal.fire({
        text: text,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
            confirmButton: "btn btn-primary",
        }
    })
    // .then((result) => {
    //     if (result.isConfirmed) {
    //         console.log("errorMessage:", result);
    //         // Dispatch resetError function if provided and valid
    //         if (typeof resetError === 'function') {
    //             resetError(); // Assuming this dispatches the resetError action in the slice
    //         }
    //         // Call afterFun if provided and valid
    //         if (typeof afterFun === 'function') {
    //             console.log("afterFun:");
    //             afterFun();
    //         }
    //     }
    // });
};
export const warningMessage = (text,setData)=>{
    Swal.fire({
        text: text,
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes, cancel it!",
        cancelButtonText: "No, return",
        customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-active-light"
        }
    }).then((result) => {
        if (result.isConfirmed) {
            setData()
             // Reset form data
            // modal.hide(); // Hide modal
        } else if (result.dismiss === 'cancel') {
            text = "Your form has not been cancelled!!!"
            errorMessage(
                text
            )
        }
    });
}
export const successMessage = (text)=>{
    Swal.fire({
        text: text,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
            confirmButton: "btn btn-primary"
        }
    })
    // .then((result) => {
    //     if (result.isConfirmed) {
    //         if(setData) {
    //             setData()
    //         }
    //         // modal.hide(); // Hide modal if confirmed
    //     }
    // });
}

export const successResponse = ({text, resetSuccess, afterFun}) => {
    Swal.fire({
        text: text,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
            confirmButton: "btn btn-primary"
        }
    }).then((result) => {
        if (result.isConfirmed) {
            console.log("errorMessage:", result);
            // Dispatch resetError function if provided and valid
            if (typeof resetSuccess === 'function') {
                resetSuccess(); // Assuming this dispatches the resetError action in the slice
            }
            // Call afterFun if provided and valid
            if (typeof afterFun === 'function') {
                console.log("afterFun:");
                afterFun();
            }
        }
    });
}