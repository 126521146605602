
import { getDate } from "../../../../../utilities/dateUtils"
import TeamTextField from "../Teams/TeamTextField"


const matchColumn = [

    {
      accessorKey:'name',
      header:'name',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'status',
      header:'status',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'format',
      header:'format',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'start_at',
      header:'Venue',
      size:150,
      cell: (props) => <p>{getDate(props.getValue())}</p>
  
    },
    {
      accessorKey:'venue',
      header:'Venue',
      size:150,
      cell: (props) =>{
        
        return <p>{props.getValue()?.name}, {props.getValue()?.city}</p>
      }
  
    },
    {
      accessorKey:'new_match_name',
      header:'New Name',
      size:250,
      cell: (props) => <TeamTextField  props={props} data={props.getValue()} name={"new_match_name"} />
  
    },


  
   
  ]
  
  export {matchColumn}
  