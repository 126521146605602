import React from 'react'

const CustomHeaderColumn = ({column}) => {
  
  return (
   
       <>
    {column.Header && typeof column.Header === 'string' ? <th {...column.getHeaderProps(column.getSortByToggleProps())}>
      {console.log("render",column.isSorted)}
      {column.render('Header')}
      <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
      </th> : column.render('Header')}
  </>
    
  )
}

export default CustomHeaderColumn
