import { createAsyncThunk } from "@reduxjs/toolkit"
import { add, getById, getList, patch, remove, update } from "./module.api"


export const fetchList = createAsyncThunk('module/fetchList', async ({pageNo, recordPerPage},{rejectWithValue}) => {
  console.log(pageNo,recordPerPage )
    let headers = {
        pageNo:pageNo || 1,
        recordPerPage:recordPerPage || 10,
        sortBy: 'created_at',
        sort:-1
      }
      try{

          const response = await getList(headers)
          // console.log("response:::::::",response,pageNo,recordPerPage)
          if(response.success)
          return {
            ...response, 
            pageNo,
            recordPerPage
          }
      }
      catch(error){
        return rejectWithValue(error)
      }
})
export const addModule = createAsyncThunk('module/add', async ({data},{rejectWithValue}) => {
  console.log("cratespor", data)
    let headers = {
       
      }
      try{

          const response = await add(headers,data)
          // console.log("responseSport:::::::",response)
          if(response?.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error from staff", error)
        // throw error
        return rejectWithValue(error)
      }
})
export const updateModule = createAsyncThunk('module/update', async ({data,id},{rejectWithValue}) => {
  let headers = {
     
    }
    try{

        const response = await update(headers,data,id)
        // console.log("responseSport:::::::",response)
        if(response.success)
        return {
          ...response, 
         
        }
        else{
          return response
        }
    }
    catch(error){
      console.log("error", error)
      return rejectWithValue(error)
    }
})
export const updateModuleStatus = createAsyncThunk('module/status', async ({data,id},{rejectWithValue}) => {
  let headers = {
     
    }
    try{

        const response = await patch(headers,data,id)
        // console.log("responseSport:::::::",response)
        if(response.success)
        return {
          ...response, 
         
        }
        else{
          return response
        }
    }
    catch(error){
      console.log("error", error)
      return rejectWithValue(error)
    }
})
export const deleteModule = createAsyncThunk('module/delete', async ({id},{rejectWithValue}) => {
  console.log("cratespor", id)
    let headers = {
       
      }
      try{

          const response = await remove(headers,id)
          // console.log("responseSport:::::::",response)
          if(response?.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error from staff", error)
        // throw error
        return rejectWithValue(error)
      }
})
export const getModuleById = createAsyncThunk('module/getListById', async ({id}) => {
  // console.log("cratespor", id)
    let headers = {
       
      }
      try{

          const response = await getById(headers,id)
          // console.log("response:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        // return rejectWithValue(error)
      }
})