import { createSlice } from "@reduxjs/toolkit";

import {
  fetchList,
  getAssignLanguage,
  getCountryDropdown,
  updateAssignLanguage,
} from "./country.action";

export const countrySlice = createSlice({
  name: "languageState",
  initialState: {
    pageNo: 1,
    recordPerPage: 10,
    totalRecord: 0,
    assignLang:[],
    getAssignLangList:[],
    totalPages: 0,
    countries: [],
    dropDownList:[],
    loading: false,
    error: null,
  },
  reducers: {
   
  },

  extraReducers: (builder) => {
    builder.addCase(fetchList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.loading = false;
      state.countries = action.payload.data;
      state.recordPerPage = action.payload.recordPerPage;
      state.pageNo = action.payload.pageNo;
      state.totalRecord = action.payload.totalRecord;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(getCountryDropdown.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCountryDropdown.fulfilled, (state, action) => {
      state.loading = false;
      state.dropDownList = action?.payload?.data;
      
    });
    builder.addCase(getCountryDropdown.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error?.message;
    });
    // builder.addCase(getAssignLanguage.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(getAssignLanguage.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.getAssignLangList = action.payload?.data.languages;
      
    // });
    // builder.addCase(getAssignLanguage.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action?.payload?.error?.message;
    // });
    // builder.addCase(updateAssignLanguage.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(updateAssignLanguage.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.assignLang = action.payload.data;
      
    // });
    // builder.addCase(updateAssignLanguage.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action?.payload?.error?.message;
    // });
  },
});

// export const {addSportReducer} = countrySlice.actions
export default countrySlice.reducer;
