import { createSlice } from "@reduxjs/toolkit";
import { fetchDabbaList, fetchList, saveAuctionSettings, saveDabbaSettings } from "./auctionSettings.action";




export const auctionSettingsSlice = createSlice({
  name: "auctionSettingState",
  initialState: {
    fetchLoading:false,
    saveLoading:false,
    list: [],
    save:"",
    data:[],
    dabba:[],
    dabbaLoad:false,
    dabbaSave:[],
    dabbaSaveLoad:false
  },
  reducers: {
    addAuctionSettings: (state,action)=>{
      console.log("sate lis",state)
      state?.list.data?.push(action.payload.settings)
    },
    removeAuctionSettings: (state,action)=>{
      state.list.data = action.payload.settings
    }
  },
  extraReducers: (builder) => {

    builder.addCase(fetchList.pending, (state) => {
      state.fetchLoading = true;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.list = action.payload?.data || [];
     

    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.fetchLoading = false;
      state.error = action.payload.error?.message;
    });
   
    builder.addCase(fetchDabbaList.pending, (state) => {
      state.dabbaLoad = true;
    });
    builder.addCase(fetchDabbaList.fulfilled, (state, action) => {
      state.dabbaLoad = false;
      state.dabba = action.payload?.data || [];
     

    });
    builder.addCase(fetchDabbaList.rejected, (state, action) => {
      state.dabbaLoad = false;
      state.error = action.payload.message;
    });
   
    builder.addCase(saveAuctionSettings.pending, (state) => {
      state.saveLoading = true;
    });
    builder.addCase(saveAuctionSettings.fulfilled, (state, action) => {
      state.saveLoading = false;
      
      state.save = action.payload;
    });
    builder.addCase(saveAuctionSettings.rejected, (state, action) => {
      state.saveLoading = false;
      state.error = action.payload;
    });
   
    builder.addCase(saveDabbaSettings.pending, (state) => {
      state.dabbaSaveLoad = true;
    });
    builder.addCase(saveDabbaSettings.fulfilled, (state, action) => {
      state.dabbaSaveLoad = false;
      
      state.dabbaSave = action.payload;
    });
    builder.addCase(saveDabbaSettings.rejected, (state, action) => {
      state.dabbaSaveLoad = false;
      state.error = action.payload;
    });
  },
});

export const { addAuctionSettings,removeAuctionSettings } = auctionSettingsSlice.actions;
export default auctionSettingsSlice.reducer;
