import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./CompanyInitialState";
import { setCoinPackConfig, setCompanyCountry, setCompanyCurrency, setCompanyGame, setCompanyOtherConfig, setCouponCodeConfig, setPromoCodeConfig, setReferralCodeConfig, setSignUpBonusCodeConfig } from "./CompanyActions";


const companySlice = createSlice({
    name:'companyState',
    initialState: initialState,
    reducers:{
        setCompanyGameReducer: setCompanyGame,
        setCompanyCurrencyReducer: setCompanyCurrency,
        setCompanyCountryReducer: setCompanyCountry,
        setCompanyOtherReducer: setCompanyOtherConfig,
        setCompanyCoinPackReducer: setCoinPackConfig,
        setCompanyPromoCodeReducer: setPromoCodeConfig,
        setCompanyCouponCodeReducer: setCouponCodeConfig,
        setCompanyReferralCodeReducer: setReferralCodeConfig,
        setCompanySignUpBonusCodeReducer: setSignUpBonusCodeConfig,
   
    }
})

export const { 
    setCompanyGameReducer, 
    setCompanyCurrencyReducer,
    setCompanyCountryReducer,
    setCompanyOtherReducer,
    setCompanyCoinPackReducer,
    setCompanyCouponCodeReducer,
    setCompanyPromoCodeReducer,
    setCompanyReferralCodeReducer,
    setCompanySignUpBonusCodeReducer
 } = companySlice.actions
export default companySlice.reducer