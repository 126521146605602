import { createSlice } from "@reduxjs/toolkit";


import { addCurrency, delCurrency, fetchList, getCurrencyListsDropdown, updateCurrency } from "./currency.action";

export const currencySlice = createSlice({
  name: "currencyState",
  initialState: {
    pageNo: 1,
    recordPerPage: 10,
    totalRecord: 0,
    totalPages: 0,
    currencies: [],
    currency: [],
    delCurr:[],
    list:[],
    loading: false,
    error: null,
  },
  reducers: {
    // addSportReducer:createSport
  },
  
  extraReducers: (builder) => {
    builder.addCase(addCurrency.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCurrency.fulfilled, (state, action) => {
      state.loading = false;
      state.currency = action.payload;
    });
    builder.addCase(addCurrency.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(updateCurrency.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCurrency.fulfilled, (state, action) => {
      state.loading = false;
      state.currency = action.payload;
    });
    builder.addCase(updateCurrency.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(fetchList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.loading = false;
      state.currencies = action.payload?.data;
    state.recordPerPage = action.payload?.recordPerPage;
        state.pageNo = action.payload?.pageNo;
        state.totalRecord = action.payload?.totalRecord;
        state.totalPages = action.payload?.totalPages
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.loading = true;
      state.error = action.payload?.error?.message;
    });
    builder.addCase(delCurrency.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(delCurrency.fulfilled, (state, action) => {
      state.loading = false;
      state.delCurr = action.payload;
    });
    builder.addCase(delCurrency.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(getCurrencyListsDropdown.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCurrencyListsDropdown.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload.data;
    });
    builder.addCase(getCurrencyListsDropdown.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.error?.message;
    });
  },
});

// export const {addSportReducer} = currencySlice.actions
export default currencySlice.reducer;
