import React from 'react'
import SelectDropDown from '../SelectDropDown'

const FilterPopover = ({ option, setOption, filterValues, id,text }) => {
   
    return (
       
            <SelectDropDown
            label={text}
                // value={option}
                onChange={(e) => {
                    console.log(e.value)
                    setOption(prev => {
                        console.log(prev)
                        const filterValue = prev?.find(filter => filter.id == id)?.value
                        if (!filterValue) {
                            return prev.concat({
                                id: id,
                                value: e.value
                            })
                        }
                        else{
                            return [
                                ...prev,
                                prev.map(f => {
                                    if(f.id == id)
                                        f.value = e.value
                                })
                            ]
                        }
                    })
                }}
                options={filterValues}
            />
        
    )
}

export default FilterPopover
