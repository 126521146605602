import React, { useEffect, useState } from "react";
import LightButton from "../../../../Common/LightButton";
import PrimaryButton from "../../../../Common/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { getSportById } from "../redux/sport.action";
import { useSearchParams } from "react-router-dom";
import Loader from "../../../../MasterLayout/Loader";


const SportCard = ({ tabs, activeTab, setActiveTab }) => {
  const dispatch = useDispatch()
  const {loading, viewSport} = useSelector(state => state.sports)
  const [data, setData] = useState()

  useEffect(()=>{
    let id =
            window.location.href.split("/")[
            window.location.href.split("/").length - 1
            ] || null;
    dispatch(getSportById({id:id}))

  },[])

  useEffect(()=>{
    console.log(viewSport)
      setData(viewSport)

  },[viewSport])
  return (
   <> {
      loading ? <Loader />
      :

    <div class="card mb-6">
      <div class="card-body pt-9 pb-0">
        <div class="d-flex flex-wrap flex-sm-nowrap">
          <div class="me-7 mb-4">
            <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img
                src={`${process.env.REACT_APP_CLIENT_URL}assets/media/avatars/300-1.jpg`}
                alt="img"
              />
              <div class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
            </div>
          </div>

          <div class="flex-grow-1">
            <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2">
                  <a
                    href="#"
                    class="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                  >
                    {/* {console.log(data)} */}
                   {data?.name}
                  </a>
                  <a href="#">
                    <i class="ki-outline ki-verify fs-1 text-primary"></i>
                  </a>
                </div>

                <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                  <a
                    href="#"
                    class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                  >
                    <i class="ki-outline ki-profile-circle fs-4 me-1"></i>
                    Developer
                  </a>
                  <a
                    href="#"
                    class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                  >
                    <i class="ki-outline ki-geolocation fs-4 me-1"></i>SF, Bay
                    Area
                  </a>
                  <a
                    href="#"
                    class="d-flex align-items-center text-gray-500 text-hover-primary mb-2"
                  >
                    <i class="ki-outline ki-sms fs-4 me-1"></i>max@kt.com
                  </a>
                </div>
              </div>

              <div class="d-flex my-4">
                <LightButton text="Follow" />
                <PrimaryButton text={"Hire Me"} />

             
              </div>
            </div>

           
          </div>
        </div>

        <ul
          class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
          onClick={(e) => {
            if (e.target.tagName === "SPAN") {
              const clickedTab = e.target.innerText; // Get the tab name from the clicked span
              setActiveTab(clickedTab);
            }
          }}
        >
          {tabs?.map((tab) => (
            <li class="nav-item mt-2" key={tab}>
              <span
                class={`nav-link text-active-primary ms-0 me-10 py-5 ${
                  activeTab === tab ? "active" : ""
                }`}
              >
                {tab}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
    }
    </>
  );
};

export default SportCard;
