import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery'; // jQuery is needed for daterangepicker plugin
import 'daterangepicker'; 
const DateRangePickerBasic = ({startDate,endDate,setStartDate,setEndDate,label}) => {
  
    const inputRef = useRef(null);

    useEffect(() => {
        const input = $(inputRef.current);

        function cb(start, end) {
            if(setStartDate) setStartDate(start);
            if(setEndDate) setEndDate(end);
            // input.html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`);
        }

        input.daterangepicker({
            startDate: startDate,
            endDate: endDate,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }, cb);

        // Call the callback to set the initial value
        cb(startDate, endDate);

        return () => {
            // Clean up daterangepicker on unmount
            input.daterangepicker('remove');
        };
    }, [startDate, endDate]);
  return (
    <>

<div class="fv-row mb-7">
    {
        label && 
<label class="form-label">{label}</label>
    }
<input 
    type="text" 
    id="kt_ecommerce_report_sales_daterangepicker" 
    ref={inputRef} 
    value={`${startDate?.format('DD/MM/YYYY')} - ${endDate?.format('DD/MM/YYYY')}`}
    readOnly
    class="form-control form-control-solid" placeholder="Pick date rage"
/>
</div>
</>
  )
}

export default DateRangePickerBasic
