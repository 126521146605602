
// import {FC, useEffect} from 'react'
// import {useMutation, useQueryClient} from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
// import {useListView} from '../../core/ListViewProvider'
// import {useQueryResponse} from '../../core/QueryResponseProvider'
// import {deleteUser} from '../../core/_requests'

const ActionsCell = ({id}) => {
  // const {setItemIdForUpdate} = useListView()
  // const {query} = useQueryResponse()
  // const queryClient = useQueryClient()

  // useEffect(() => {
  //   MenuComponent.reinitialization()
  // }, [])

  // const openEditModal = () => {
  //   setItemIdForUpdate(id)
  // }

  // const deleteItem = useMutation(() => deleteUser(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //   },
  // })

  return (
    <>
     <td className="text-end">
															<a href="#" className="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions 
															<i className="ki-outline ki-down fs-5 ms-1"></i></a>
															
															<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
																
																<div className="menu-item px-3">
																	<a href="apps/customers/view.html" className="menu-link px-3">View</a>
																</div>
																
																
																<div className="menu-item px-3">
																	<a href="#" className="menu-link px-3" data-kt-customer-table-filter="delete_row">Delete</a>
																</div>
																
															</div>
															
														</td>
    </>
  )
}

export {ActionsCell}
