import { routesApi } from "../../../RouterFiles/routesApi";
import instance from "../../../utilities/axios";
import url from "../../../utilities/url";

export const loginRequest = (query) => {
    return instance
      .post(`${url.base_api}${routesApi.login}?credentials=${query.credentials}`)
      .then((res) => {
        return res;
      });
  };

