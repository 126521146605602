

const client_base_url = process.env.REACT_APP_CLIENT_URL;
export const base_url = process.env.REACT_APP_BASE_API_URL;
export const image_url = process.env.REACT_APP_S3_BUCKET_URL ;

const url = {
    base_api: base_url + 'api/'
    
};

export default url;
