export const initialState = {
    selectedGames:{},
    currency:{},
    isGeneralConfiguration:'false',
    isCountryConfiguration:'false',
    countryConfig:[{}],
    otherConfig:{},
    coinPackConfig:{},
    couponCodeConfig:{},
    promoCodeConfig:{},
   referralCodeConfig:{},
  signUpBonusConfig:{},
}