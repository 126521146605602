import React, { useEffect, useRef, useState } from 'react';
import { image_url } from '../utilities/url';
// import './Dropdown.css'; // Optional CSS for styling

const SelectDropdownWithSearch = ({ options, value,placeholder = "Select an option", onChange, isImage=true,handleClick,disabled=false,label}) => {
  // console.log("options::::",options,value,disabled)
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);
  // console.log(value)
// const url = 'http://localhost:3000/assets/'

  // Filter options based on search input
  useEffect(() => {
    setFilteredOptions(
      options?.filter((option) =>
        option.name?.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue, options]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false); // Close the dropdown on selection
    setSearchValue(''); // Reset search input
  };

  const toggleDropdown = () => {
    console.log("disanlekdlf", disabled)
    if(!disabled){

      if(handleClick)
      handleClick()
  
      setIsOpen((prev) => !prev); // Toggle dropdown open/close state
    }
  };

  const handleSearchClick = (event) => {
    event.stopPropagation(); // Prevent closing dropdown when clicking on search
  };


  return (
    <div className="dropdown position-relative w-100" ref={dropdownRef}>
    
      <div className="dropdown-header form-control form-control-solid py-3 px-2 d-flex justify-content-between"  onClick={toggleDropdown}>
        
        {
          value ? 
            <div className='label d-flex align-items-center text-truncate'>
          
              {isImage &&   <img src={image_url + '/' +value.img} alt='coin' className='w-20px rounded-circle me-2' />}
            <span className='text-gray-500 f-12 text-truncate'>{value.name}</span>
        </div> : <span className='text-gray-500 f-12 text-truncate'>{placeholder}</span> 
        }

      
       
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>
           {
        value &&  <span onClick={()=> onChange({}) }> <i  class="ki-outline ki-cross fs-2"></i></span>
       }
           <i class="ki-outline ki-down fs-2">
            </i></span>
      </div>
 
      {isOpen && (
        <div className="dropdown-menu">
          {/* Search Input */}

          <div className="dropdown-item" onClick={handleSearchClick}>
            <input
              type="text"
              className="dropdown-search"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              autoFocus
            />
          </div>
        <div className='scroll-menu'>
          {/* Render Filtered Options */}
          {filteredOptions?.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className="dropdown-item"
                onClick={() => handleSelect(option)}
              >
                <div className='label'>
                  {
                    isImage && <img src={image_url + '/' +option.img} alt='img' className='w-20px rounded-circle me-2' />
                  }
                                
                                <span>{option.name}</span>
                            </div>
                {/* {option?.label} */}
              </div>
            ))
          ) : (
            <div className="dropdown-item">No options found</div>
          )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectDropdownWithSearch;
