import './App.css';
import RouterFiles from './Component/RouterFiles/RouterFiles';
import './_metronic/sass/style.scss'
import './_metronic/sass/plugins.scss'
import { Provider } from 'react-redux';
import { store } from './Component/store/store';

function App() {
  return (
    <Provider store={store}>
      <RouterFiles />
    </Provider>
  );
}

export default App;
