export const routesApi = {
    staff:'staff',
    permission: 'permission',
    module: 'module',
    country:'country',
    currency: 'currency',
    sport:'sports',
    language:'language',
    company: 'company',
    login: 'admin/login',
    assign:'assign',
    uploadAudio:'upload/audio',
    uploadImage:'upload/img',
    auctionSettings:'auction-settings/',
    dabbaSettings:'dabba-settings',
    series:'series',
    list:'list',
    fetch:'fetch',
    details:'detail',
    rules: 'rules',
    teams:'teams',
    save:"save",
    players:"players",
    matches:"matches",
    filters:"filters",
    profileIcons:"profile/icons"

} 