import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataExpand from "./DataExpand";
import { JSONTree } from "react-json-tree";
const postmanTheme = {
  scheme: 'postman',
  author: 'Postman',
  base00: '#232527', // Background color
  base01: '#2c2e33', // Secondary background
  base02: '#3a3b3c', // Tertiary background
  base03: '#ffffff', // Text color
  base04: '#b1b2b3', // Placeholder text
  base05: '#ffffff', // Main text
  base06: '#ffffff', // Highlighted text
  base07: '#ffffff', // Pure white
  base08: '#ff4f4f', // Error color
  base09: '#ffcc00', // Warning color
  base0A: '#f5f0e1', // Info color
  base0B: '#3cd318', // Success color
  base0C: '#00bcd4', // Info color
  base0D: '#ff9f09', // Link color
  base0E: '#a24c93', // Purple accents
  base0F: '#e85d04', // Orange accents
}

const keyNameData = ['']

const StatesTab = ({ seriesStatesData ,heading}) => {
  const navigate = useNavigate();
  const [data, setData] = useState(seriesStatesData);

  return (
    <div className='m-2 p-2 ' style={{ backgroundColor: "#232527", border: "4px solid #ff9f09" }}>
      <p>
        <span><h3 style={{ color: "#ffff" }}>{heading}</h3></span>
        <JSONTree data={data} theme={postmanTheme} invertTheme={false}  />
      </p>

    </div>
   
  );
};

export default StatesTab;
