import SwitchBoxWithoutLabel from "../../../Common/SwitchBoxWithoutLabel"
import ActionsCell from "../../../Common/TableWithSubtable/ActionsCell"
import ViewDetailCell from "../../../Common/TableWithTan/ViewDetailCell"
import CountryImageCell from "../Country/CountryImageCell"



const sportColumn = [

    {
      accessorKey:'name',
      header:'name',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'code',
      header:'code',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },

    {
      accessorKey:'img',
      header:'Icon',
      cell: (props) => <CountryImageCell props={props} />
  
    },
    {
      accessorKey:'is_active',
      header:'is active',
      cell: (props) => <SwitchBoxWithoutLabel checked={props.getValue()} />
  
    },
    {
      accessorKey:'',
      header:'View Details',
      size:100,
      cell: (props) => <ViewDetailCell props={props} />
  
    },
    {
      accessorKey:'',
      header:'Actions',
      size:100,
      cell: ({table, row}) => <ActionsCell type={['edit', 'remove']} table={table} row={row}/>
  
    }
   
  ]
  
  export {sportColumn}
  