import React, { useState } from 'react'
import SelectDropdownWithSearch from '../../../../../Common/SelectDropdownWithSearch'
const matches = [
    { value: 'Canada vs Oman', label: 'Canada vs Oman' },
    { value: 'Namibia vs United Arab Emirates', label: 'Namibia vs United Arab Emirates' },
    { value: 'United Arab Emirates vs United States of America', label: 'United Arab Emirates vs United States of America' },
  
]
const PlayingXI = () => {
    const [formData, setFormData] = useState({})
    const handleSelectChange = (name, value) => {
       
        setFormData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
        // setGame(value)
      };
  return (
    <div className='card'>
    <div className="card-header ">
    <div className="card-title d-flex align-items-center col-4">
      <h3 className="fw-bold m-0 text-gray-800">Players</h3>
    </div>
    <div className="card-toolbar col-4">
    <SelectDropdownWithSearch
                    name="match"
                    //   required={"true"}
                    //   label="Country"
                    isImage={false}
                    placeholder={"Select a match"}
                    options={matches}
                    value={matches.find(
                      (option) => option.value === formData?.match?.value
                    )}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      handleSelectChange("match", selectedOption);
                    }}
                  />
    </div>
  </div>
  <div className="card-body">
  <div
          class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
          id="kt_datatable"
        >
            
    <table className='w-100'>
        <thead className='bg-light-success '>
            <th className='text-center py-3'><span className='f-16'>India</span> <img className='icon-20 ms-2' src={process.env.REACT_APP_CLIENT_URL + 'assets/media/flags/india.svg'} alt='cnty' /></th>
            <th className='py-3 text-center'> <span className='f-16'>Newzeland  </span><img className='icon-20 ms-2' src={process.env.REACT_APP_CLIENT_URL + 'assets/media/flags/united-states.svg'} alt='cnty' /></th>
        </thead>
        
        <tbody>
          
            <tr>
                <td className='text-center border py-2'>
                    <div >
                        <p className='mb-0'>Rohit Sharma (c)</p>
                        <p className='mb-0'>Batter</p>
                    </div>
                </td>
                <td className='text-center border'>
                <div>
                        <p className='mb-0'>Tom Lattham (c)</p>
                        <p className='mb-0'>WK-Batter</p>
                    </div>
                </td>
            </tr>
            <tr>
                <td className='text-center border py-2'>
                    <div >
                        <p className='mb-0'>Rohit Sharma (c)</p>
                        <p className='mb-0'>Batter</p>
                    </div>
                </td>
                <td className=' text-center border py-2'>
                <div>
                        <p className='mb-0'>Tom Lattham (c)</p>
                        <p className='mb-0'>WK-Batter</p>
                    </div>
                </td>
            </tr>
        
          
        </tbody>
      
    </table>
    </div>
  </div>
</div>
  )
}

export default PlayingXI