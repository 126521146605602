import { createSlice } from "@reduxjs/toolkit";
import { addProfileIcons, deleteProfileIcon, fetchList, updateProfileIcons } from "./profileIcons.action";



export const profileIconsSlice = createSlice({
  name: "companysState",
  initialState: {
    pageNo: 1,
    recordPerPage: 10,
    totalRecord: 0,
    totalPages: 0,
    list:[],
    loading:false,
    create:"",
    createLoading:false,
    update:"",
    updateLoading:false,
    remove:"",
    deleteLoading:false,
    error:""
  },
  reducers:{
   

},
  extraReducers: (builder) => {


    // builder.addCase(addCompany.pending, (state) => {
    //   // state.loading = true;
    // });
    // builder.addCase(addCompany.fulfilled, (state, action) => {
    //   // state.loading = false;
    //   state.company = action.payload;
    // });
    // builder.addCase(addCompany.rejected, (state, action) => {
    //   // state.loading = false;
    //   state.error = action.payload.message;
    // });
    // builder.addCase(updateCompany.pending, (state) => {
    //   // state.loading = true;
    // });
    // builder.addCase(updateCompany.fulfilled, (state, action) => {
    //   // state.loading = false;
    //   state.company = action.payload;
    // });
    // builder.addCase(updateCompany.rejected, (state, action) => {
    //   // state.loading = false;
    //   state.error = action.payload.message;
    // });
    builder.addCase(fetchList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.loading = false;
      
      state.list = action.payload.data.profileIcons;
      // state.viewCompany = "";
      // state.delCompany = "";
      state.recordPerPage = action.payload?.recordPerPage;
      state.pageNo = action.payload?.pageNo;
      state.totalRecord = action.payload?.totalRecord;
      state.totalPages = action.payload?.totalPages;
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
    builder.addCase(addProfileIcons.pending, (state) => {
      state.createLoading = true;
    });
    builder.addCase(addProfileIcons.fulfilled, (state, action) => {
      state.createLoading = false;
      state.create = action.payload;
    });
    builder.addCase(addProfileIcons.rejected, (state, action) => {
      state.createLoading = false;
      state.error = action.payload.message;
    });
    builder.addCase(updateProfileIcons.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateProfileIcons.fulfilled, (state, action) => {
      state.updateLoading = false;
      state.update = action.payload;
    });
    builder.addCase(updateProfileIcons.rejected, (state, action) => {
      state.updateLoading = false;
      state.error = action.payload.message;
    });
    builder.addCase(deleteProfileIcon.pending, (state) => {
      state.deleteLoading = true;
    });
    builder.addCase(deleteProfileIcon.fulfilled, (state, action) => {
      state.deleteLoading = false;
      state.remove = action.payload;
    });
    builder.addCase(deleteProfileIcon.rejected, (state, action) => {
      state.deleteLoading = false;
      state.error = action.payload.message;
    });
   
  },
});

// export const {   } = profileIconsSlice.actions;
export default profileIconsSlice.reducer;
