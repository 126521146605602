import { routesApi } from "../../../RouterFiles/routesApi";
import instance from "../../../utilities/axios";
import url from "../../../utilities/url";


export const getList = (headers) => {
    return instance
      .get(`${url.base_api}${routesApi.staff}`, {
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };
export const getById = (headers, id) => {
    return instance
      .get(`${url.base_api}${routesApi.staff}/${id}`, {
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };
export const add = (headers, data) => {
  console.log("addstaff:::", { ...data, created_by: "6718bd47f714d8369a63b5c3", updated_by: "6718bd47f714d8369a63b5c3" })
    return instance
      .post(`${url.base_api}${routesApi.staff}`,{ ...data, created_by: "6718bd47f714d8369a63b5c3", updated_by:"6718bd47f714d8369a63b5c3"})
      .then((res) => {
        return res;
      })
     
      ;
  };
  export const update = (headers, data,id) => {
    console.log("update:::",data)
    return instance
      .put(`${url.base_api}${routesApi.staff}/${id}`,data)
      .then((res) => {
        return res;
      });
  };
  export const patch = (headers, data,id) => {
    console.log("update:::",data)
    return instance
      .patch(`${url.base_api}${routesApi.staff}/${id}`,data)
      .then((res) => {
        return res;
      });
  };
export const remove = (headers,id) => {
    console.log("delestaff:::",id)
    return instance
      .delete(`${url.base_api}${routesApi.staff}/${id}`)
      .then((res) => {
        return res;
      })
     
      ;
  };



export const getUserPermission = (userId) => {
  // console.log("getUserPermission:::", userId)
  return instance
    .get(`${url.base_api}${routesApi.permission}/${userId}`)
    .then((res) => {
      return res;
    })

    ;
};

export const getModulePermission = () => {
  // console.log("getModulePermission:::")
  return instance
    .get(`${url.base_api}${routesApi.permission}/modulePermission`)
    .then((res) => {
      return res;
    })

    ;
};

export const patchAssignCompany = (data, id) => {
  // console.log("patchAssignCompany:::", data,id)
  return instance
    .patch(`${url.base_api}${routesApi.staff}/assign-company/${id}`, data)
    .then((res) => {
      return res;
    });
};

export const patchUserPermission = (data, id) => {
  // console.log("getModulePermission:::")
  return instance
    .put(`${url.base_api}${routesApi.permission}/${id}`,data)
    .then((res) => {
      console.log("res", res)
      return res;
    })

    ;
};
