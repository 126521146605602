import React, { useState } from 'react'
import PrimaryButton from '../../../Common/PrimaryButton'

import LanguagePopUp from './LanguagePopUp'

const LanguageCell = ({id}) => {
   
   
    const [show, setShow] = useState(false)
    const handlerSubmit = ()=>{

    }
    console.log("show::::::::",show)
  return (
    <>
    <div className='w-100px'>
    <PrimaryButton text={"Assign"} handlerClick= {()=> setShow(true)} onSubmit={handlerSubmit} />
  
    </div>
{
    show &&  <LanguagePopUp show={show}  setShow={setShow} id={id} />
}
    </>
  )
}

export default LanguageCell