import React from 'react'
import PrimaryButton from './PrimaryButton'
import LightButtonWithOutline from './LightButtonWithOutline'
import ButtonWithLoader from './ButtonWithLoader'

const Toolbar = ({text, isButton = false, textBtn, handleClick, addBtn, isSeries=false,isLoading=false}) => {
  return (
    <div class="d-flex flex-column flex-column-fluid">
    
      <div id="kt_app_toolbar" class="app-toolbar pt-6 pb-2">
        
          <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex align-items-stretch">
            
              <div class="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                
                  <div class="page-title d-flex flex-column justify-content-center gap-1 me-3">
                    
                      <h1 class="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">{text}</h1>
                    
                    
                   
                    
                  </div>
                
                {
                    isButton &&
                  <div class="d-flex align-items-center gap-2 gap-lg-3">
                      {/* <a href="#" class="btn btn-flex btn-outline btn-color-gray-700 btn-active-color-primary bg-body h-40px fs-7 fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">Add Member</a> */}
                      {
                        addBtn ? 
                        <LightButtonWithOutline text={"close"} handlerClick={handleClick} />

                        :
                        <PrimaryButton text={textBtn} handlerClick={handleClick} /> 
                      }
                  </div>
                }
                
                {
                  isSeries && <ButtonWithLoader text={"Fetch & Refetch"} handlerSubmit={handleClick} isLoading={isLoading} />
                }
              </div>
            
          </div>
        
      </div>
    
   
  </div>
  )
}

export default Toolbar
