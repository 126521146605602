import React, { useState } from "react";
import { bowlerColumn } from "../Scoreboard/BowlingCoulmn";
import Table from "../../../../../Common/TableWithTan/Table";
import { batterColumn } from "../Scoreboard/BatterColumn";
const tableDataB = [
    {
        name:'Yashasvi Jaiswal',
       
        over:'5',
        maiden:'1',
        runs:'35',
        wickets:'3',
        noBall:'6',
        wideBall:'1',
        economyRate:'67.4'
    },
    {
        name:'Yashasvi Jaiswal',
       
        over:'5',
        maiden:'1',
        runs:'35',
        wickets:'3',
        noBall:'6',
        wideBall:'1',
        economyRate:'67.4'
    },
    {
        name:'Yashasvi Jaiswal',
       
        over:'5',
        maiden:'1',
        runs:'35',
        wickets:'3',
        noBall:'6',
        wideBall:'1',
        economyRate:'67.4'
    },
    {
        name:'Yashasvi Jaiswal',
       
        over:'5',
        maiden:'1',
        runs:'35',
        wickets:'3',
        noBall:'6',
        wideBall:'1',
        economyRate:'67.4'
    },
   
]
const tableData = [
    {
        name:'Yashasvi Jaiswal',
        details:'st Tom Blundell b Ajaz Patel',
        runs:'35',
        balls:'34',
        fours:'6',
        sixes:'1',
        strikeRate:'67.4'
    },
    {
        name:'Rohit Sharma (c)',
        details:'b Ajaz Patel',
        runs:'35',
        balls:'34',
        fours:'6',
        sixes:'1',
        strikeRate:'67.4'
    },
    {
        name:'Virat Kohli',
        details:'c Tom Blundell b Glenn Phillips',
        runs:'35',
        balls:'34',
        fours:'6',
        sixes:'1',
        strikeRate:'67.4'
    },
    {
        name:'Sarfaraz Khan',
        details:'batting',
        runs:'35',
        balls:'34',
        fours:'6',
        sixes:'1',
        strikeRate:'67.4'
    }
]
const OverSummary = () => {
    const [active, setActive] = useState(0)
  return (
    <>
    {/* <div className="card card-xl-stretch mb-xl-8">
      <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
                 
              <Table
                column={batterColumn}
                tableData={tableData}
           
                // updateView = {updateView}
                // columnFilters={columnFilters}
                // setColumnFilters={setColumnFilters}
              />
            </div>
        <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
                
              <Table
                column={bowlerColumn}
                tableData={tableDataB}
            
                // updateView = {updateView}
                // columnFilters={columnFilters}
                // setColumnFilters={setColumnFilters}
              />
            </div>
      </div> */}
      <div class="card card-xl-stretch mb-xl-8">
        <div class="card-header align-items-center border-0 mt-4">
          <h3 class="card-title align-items-start flex-column">
            <span class="fw-bold mb-2 text-gray-900">Activities</span>
            <span class="text-muted fw-semibold fs-7">890,344 Sales</span>
          </h3>
        
        </div>

        <div class="card-body pt-5">
       
         
        <div class="d-flex align-items-center mb-8 mt-8 bg-gray-300 py-3">
            <span class="bullet bullet-vertical h-40px bg-success"></span>

            <div class="flex-grow-1 mx-5">
              <span class="text-gray-800 text-hover-primary fw-bold fs-6">
               38
              </span>
              <span class="text-muted fw-semibold d-block">Over</span>
            </div>
            <div class="flex-grow-1 mx-5">
              <span class="text-gray-800 text-hover-primary fw-bold fs-6">
               Run scored: 4
              </span>
              <span class="text-muted fw-semibold d-block">0 0 1 0 W 3</span>
            </div>
            <div class="flex-grow-1 mx-5">
              <span class="text-gray-800 text-hover-primary fw-bold fs-6">
                Score after 38 overs
              </span>
              <span class="text-muted fw-semibold d-block">BAN 105-9</span>
            </div>
            <div class="flex-grow-1 mx-5">
              <span class="text-gray-800 text-hover-primary fw-bold fs-6">
              Hasan Mahmud 3(1)
              </span>
              <span class="text-muted fw-semibold d-block">Taijul Islam 16(10)</span>
            </div>
            <div class="flex-grow-1 mx-5">
              <span class="text-gray-800 text-hover-primary fw-bold fs-6">
              Kagiso Rabada
              </span>
              <span class="text-muted fw-semibold d-block">10-3-26-3</span>
            </div>

            <span class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5"  onClick={()=> setActive(0)}>
                {
                    active == 0 ? 
                    <i class="ki-outline ki-minus-square toggle-on text-primary fs-1"></i>
                    :
              <i class="ki-outline ki-plus-square toggle-off fs-1"></i>
                }
            </span>
          </div>
          {
            active  === 0 && 
          <div class="timeline-label mx-6">
            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">37.6</div>

              <div class="timeline-badge">
               
                <div class="symbol symbol-30px ">
																		<span class="symbol-label bg-dark  bg-opacity-50 text-white">
																			0
																		</span>
																	</div>
              </div>

              <div class=" timeline-content text-muted ps-5">
              Rabada to Hasan Mahmud, 3 runs, full and veering into middle and leg, Hasan flcks off the cue end and manages to get it in the gap between deep square and deep mid-wicket
              </div>
            </div>
            <h4>Hasan Mahmud, right handed bat, comes to the crease</h4>
            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">37.5</div>

              <div class="timeline-badge">
              <div class="symbol symbol-30px ">
																		<span class="symbol-label bg-success text-white">
																			1
																		</span>
																	</div>
              </div>

              <div class="timeline-content d-flex">
                <span class=" text-muted  ps-3">Rabada to Nayeem Hasan, out Caught by Wiaan Mulder!! Wicket number three for Kagiso Rabada. Gets this short of length delivery to skid off the deck with a hint of late wobble around leg stump, Nayeem looks to work leg-side and gets a thick leading edge to the right of Mulder, at second slip, who completes the catch with a well-timed dive. Nayeem Hasan c Wiaan Mulder b Rabada 8(16) [4s-1]</span>
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">37.4</div>

              <div class="timeline-badge">
               
                <div class="symbol symbol-30px ">
																		<span class="symbol-label bg-primary text-white">
																			4
																		</span>
																	</div>
              </div>

              <div class="timeline-content   text-muted  ps-5">
              Rabada to Nayeem Hasan, no run, 137ks closer to off stump, blocked back to the bowler
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">37.3</div>

              <div class="timeline-badge">
              <div class="symbol symbol-30px ">
																		<span class="symbol-label bg-orange text-white">
																			N2
																		</span>
																	</div>
              </div>

              <div class="timeline-content  text-muted ps-5">
              Rabada to Taijul Islam, 1 run, 130ks bumper on and around off, Taijul gets rushed on the pull and ekes out a thick top-edge that dribbles to the left of third man
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">37.2</div>

              <div class="timeline-badge">
              <div class="symbol symbol-30px ">
																		<span class="symbol-label bg-danger text-white">
																			W
																		</span>
																	</div>
              </div>

              <div class="timeline-content  text-muted  ps-3">
              Rabada to Taijul Islam, no run, pitched up outside off at 140ks, Taijul drives off the cue end to the right of mid-off
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">37.1</div>

              <div class="timeline-badge">
              <div class="symbol symbol-30px ">
																		<span class="symbol-label bg-purple text-white">
																			6
																		</span>
																	</div>
              </div>

              <div class="timeline-content fw-mormal text-muted ps-3">
              Rabada to Taijul Islam, no run, 135ks good length just outside off, Taijul blocks it off the back foot
              </div>
            </div>

          </div>
          }

          <div class="d-flex align-items-center mb-8 mt-8 bg-gray-300 py-3" >
            <span class="bullet bullet-vertical h-40px bg-success"></span>

            <div class="flex-grow-1 mx-5">
              <span class="text-gray-800 text-hover-primary fw-bold fs-6">
               38
              </span>
              <span class="text-muted fw-semibold d-block">Over</span>
            </div>
            <div class="flex-grow-1 mx-5">
              <span class="text-gray-800 text-hover-primary fw-bold fs-6">
               Run scored: 4
              </span>
              <span class="text-muted fw-semibold d-block">0 0 1 0 W 3</span>
            </div>
            <div class="flex-grow-1 mx-5">
              <span class="text-gray-800 text-hover-primary fw-bold fs-6">
                Score after 38 overs
              </span>
              <span class="text-muted fw-semibold d-block">BAN 105-9</span>
            </div>
            <div class="flex-grow-1 mx-5">
              <span class="text-gray-800 text-hover-primary fw-bold fs-6">
              Hasan Mahmud 3(1)
              </span>
              <span class="text-muted fw-semibold d-block">Taijul Islam 16(10)</span>
            </div>
            <div class="flex-grow-1 mx-5">
              <span class="text-gray-800 text-hover-primary fw-bold fs-6">
              Kagiso Rabada
              </span>
              <span class="text-muted fw-semibold d-block">10-3-26-3</span>
            </div>

            <span class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5"  onClick={()=> setActive(1)}>
                {
                    active == 1 ? 
                    <i class="ki-outline ki-minus-square toggle-on text-primary fs-1"></i>
                    :
              <i class="ki-outline ki-plus-square toggle-off fs-1"></i>
                }
            </span>
          </div>
          {
            active === 1 && 
          <div class="timeline-label mx-6">
            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">08:42</div>

              <div class="timeline-badge">
                <i class="fa fa-genderless text-warning fs-1"></i>
              </div>

              <div class="fw-mormal timeline-content text-muted ps-3">
                Outlines keep you honest. And keep structure
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">10:00</div>

              <div class="timeline-badge">
                <i class="fa fa-genderless text-success fs-1"></i>
              </div>

              <div class="timeline-content d-flex">
                <span class="fw-bold text-gray-800 ps-3">AEOL meeting</span>
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">14:37</div>

              <div class="timeline-badge">
                <i class="fa fa-genderless text-danger fs-1"></i>
              </div>

              <div class="timeline-content fw-bold text-gray-800 ps-3">
                Make deposit
                <span href="#" class="text-primary">
                  USD 700
                </span>
                . to ESL
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">16:50</div>

              <div class="timeline-badge">
                <i class="fa fa-genderless text-primary fs-1"></i>
              </div>

              <div class="timeline-content fw-mormal text-muted ps-3">
                Indulging in poorly driving and keep structure keep great
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">21:03</div>

              <div class="timeline-badge">
                <i class="fa fa-genderless text-danger fs-1"></i>
              </div>

              <div class="timeline-content fw-semibold text-gray-800 ps-3">
                New order placed
                <span href="#" class="text-primary">
                  #XF-2356
                </span>
                .
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">16:50</div>

              <div class="timeline-badge">
                <i class="fa fa-genderless text-primary fs-1"></i>
              </div>

              <div class="timeline-content fw-mormal text-muted ps-3">
                Indulging in poorly driving and keep structure keep great
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">21:03</div>

              <div class="timeline-badge">
                <i class="fa fa-genderless text-danger fs-1"></i>
              </div>

              <div class="timeline-content fw-semibold text-gray-800 ps-3">
                New order placed
                <a href="#" class="text-primary">
                  #XF-2356
                </a>
                .
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-label fw-bold text-gray-800 fs-6">10:30</div>

              <div class="timeline-badge">
                <i class="fa fa-genderless text-success fs-1"></i>
              </div>

              <div class="timeline-content fw-mormal text-muted ps-3">
                Finance KPI Mobile app launch preparion meeting
              </div>
            </div>
          </div>
          }
        </div>
      </div>
      
    </>
  );
};

export default OverSummary;
