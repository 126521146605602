import React, { useEffect, useRef, useState } from "react";
import Checkbox from "../../../Common/Checkbox";
import ButtonWithLoader from "../../../Common/ButtonWithLoader";
import { formValidation } from "@form-validation/bundle/popular";
import { Bootstrap5 } from "@form-validation/plugin-bootstrap5";
import { Trigger } from "@form-validation/plugin-trigger";
import { SubmitButton } from "@form-validation/plugin-submit-button";
import { errorMessage, successMessage } from "../../../Common/SwalToast";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyGameReducer, setCompanyOtherReducer } from "../CompanySlice/CompanyReducers";

const otherConfigList = [{name:"subscription",value:"Subscription"}, {name:"coinPacks",value:"Coin Packs"}, {value:"Rewards Store",name:"rewardsStore"}, {name:"signUpBonus",value:"Sign up Bonus"}, {name:"promoCode", value:"Promo Code"}, {name:"refferalCode", value:"Referral Code"}, {name:"couponCode", value:"Coupon Code"},{name:"customerCare",value: "Customer Care"}, {name:"dailyRewards",value:"Daliy Login Rewards"}, {name:"adPartnera",value:"Ad Partners"}, {name:"invoice",value:"Invoice"}];


const OtherConfiguration = ({ setActiveTab, setTabs }) => {
  const [selected, setSelected] = useState({});
  const [isLoading, setLoading] = useState(false);
  const formRef = useRef();
  const [validator, setValidator] = useState(null);
  const [errors, setErrors] = useState({});
  const [sportsError, setSportsError] = useState("");
  const dispatch = useDispatch();
  const { createCompanyState } = useSelector((state) => state);


  const handlerSelectAll = ()=>{
    let data = {}
    otherConfigList?.map((ele)=> {
        data[ele.name] = true
    })
    setSelected(data)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (isValidGames && sportError) {
      setLoading(true);
      dispatch(setCompanyOtherReducer({ otherConfig: selected }));
     
     
      setTimeout(() => {
        successMessage("Form has been successfully submitted!");
        setLoading(false);
      }, 500);
      setTabs((prev) => {
        if (prev.includes("Wallet Configurations")) return prev;
        else return [...prev, "Wallet Configurations"];
      });
      setActiveTab("Wallet Configurations");
    // } else {
    //   errorMessage("There are some errors. Please fix them and try again.");
    // }
  };

  useEffect(() => {
    console.log(createCompanyState)
    setSelected(createCompanyState.otherConfig);
  }, []);
console.log(selected)
  return (
    <div className="card" ref={formRef}>
      <div className="card-header">
        <div className="card-title d-flex align-items-center">
          <h3 className="fw-bold m-0 text-gray-800">Other Configurations</h3>
        </div>
        <div className="card-toolbar me-4">
            {console.log(otherConfigList.length,Object.keys(selected).filter(key=> selected[key] == true ))}
          {/* <Checkbox 
          label={"Select All"}
          onChange={handlerSelectAll}
          checked={otherConfigList.length ==Object.keys(selected).filter(key=> selected[key] == true ).length}
          /> */}

        </div>
      </div>

      <div className="card-body fv-row">
        {otherConfigList.map((sport, index) => (
          <div className="mt-4" key={index}>
            <div className="d-flex justify-content-between p-4 px-4 bg-dark bg-opacity-20 align-items-center">
              <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                {sport.value}
              </h4>
              <div className="d-flex align-items-center">
                <Checkbox
                  value={sport.name}
                  name={"sport"}
                  onChange={() => {
                    setSportsError("");
                    setSelected((prev) => {
                      if (prev[sport.name]) {
                        return { ...prev, [sport.name]: false };
                      }
                      return { ...prev, [sport.name]: true };
                    });
                  }}
                  checked={selected[sport.name]}
                />
              </div>
            </div>

          
          </div>
        ))}

        {sportsError && <div className="text-danger mt-2">{sportsError}</div>}
        <div className="mt-5 d-flex justify-content-end">
          <ButtonWithLoader
            text={"Submit"}
            isLoading={isLoading}
            handlerSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default OtherConfiguration;
