import React, { useEffect, useState } from 'react'
import SelectDropdownWithSearch from '../../../../../Common/SelectDropdownWithSearch'
import SelectDropDown from '../../../../../Common/SelectDropDown'
import Select from 'react-select'
const CountryCell = ({props, data, name}) => {
    console.log(props.table.getRow(props.row.index).original.countries.map(ele => {
        return {
            ...ele,
            label: ele.name,
            value: ele.name
        }
    }))
    const [option, setOption] = useState([])
    const handlerChange = (value)=>{
        setOption(value)
        props.table.options.meta.updateEdit(props.row.index, "countries",   value.map(option => option._id))
            console.log(value)
    }
    // console.log(option)
    useEffect(()=>{
        setOption(props.table.getRow(props.row.index).original.countries.map(ele => {
            return {
                ...ele,
                label: ele.name,
                value: ele.name
            }
        }))
    },[])
  return (
    <Select
     className='react-select-styled react-select-solid'
    classNamePrefix='react-select'
    options={data}
    isMulti
    value={option}
    onChange={handlerChange}
    placeholder="Select "

    isDisabled = {props.table.getRow(props.row.index).original.is_shared == true || props.table.getRow(props.row.index).original.is_shared == false}
  />

  )
}

export default CountryCell