import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
const AssociationTable = ({ associations }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(associations)
  const [icc, seticc] = useState({
 key: "c__board__icc__c2ab7ee61",
 code: "ICC",
 name: "International Cricket Council",
 country: {
   short_code: null,
   code: null,
   name: null,
   official_name: null,
   is_region: false
    },
  parent: null
  })



  
  const gotoTournamentList = (association) => {
    localStorage.setItem("associationItem", association?.code)
    console.log("association", association)
    navigate(`/fantasy-series/${association?.key}`);
  
  };
  return (
    <>
     <div className='table-responsive'>
        <table id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer' key={associations}>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0' style={{ color: "#rgb(0, 0, 0) !important" }}>
            <th>Key</th>
            <th>Code</th>
            <th>Name</th>
            <th>Country Short Code</th>
            <th>Country Code</th>
            <th>Country Name</th>
            <th>Country Official Name</th>
            <th>Is Region</th>
            <th>Parent</th>
          </tr>

          <tbody className='text-gray-600 fw-bold'>
            <tr
              key={icc?.key}>
              <td style={{
                fontSize: "1rem",
                color: "#4519ff ",
                fontWeight: "600",
              }} onClick={() => { gotoTournamentList(icc) }}>{`${icc?.key}`}</td>
              <td>{`${icc?.code}`}</td>
              <td>{`${icc?.name}`}</td>
              <td>{`${icc?.country?.short_code}`}</td>
              <td>{`${icc?.country?.code}`}</td>
              <td>{`${icc?.country?.name}`}</td>
              <td>{`${icc?.country?.official_name}`}</td>
              <td>{`${icc?.country?.is_region}`}</td>
              <td>{`${icc?.parent}`}</td>
            </tr>
            {data.map((association) => {
              return <tr 
               
              key={association?.key}>
                <td style={{
                  fontSize: "1rem",
                  color: "#4519ff ",
                  fontWeight: "600",
                }} onClick={() => { gotoTournamentList(association) }}>{`${association?.key}`}</td>
                <td>{`${association?.code}`}</td>
                <td>{`${association?.name}`}</td>
                <td>{`${association?.country?.short_code}`}</td>
                <td>{`${association?.country?.code}`}</td>
                <td>{`${association?.country?.name}`}</td>
                <td>{`${association?.country?.official_name}`}</td>
                <td>{`${association?.country?.is_region}`}</td>
                <td>{`${association?.parent}`}</td>
              </tr>
            })
            }
         </tbody>
        </table>
      </div>
    </>
  )
}

export default AssociationTable
