import { createSlice } from "@reduxjs/toolkit";

import {
  addLanguage,
  delLanguage,
  fetchList,
  updateLanguage,
} from "./language.action";

export const languageSlice = createSlice({
  name: "languageState",
  initialState: {
    pageNo: 1,
    recordPerPage: 10,
    totalRecord: 0,
    totalPages: 0,
    languages: [],
    language: "",
    delLang: "",
    loading: false,
    error: null,
  },
  reducers: {
    changeRecordPerpage: (state, action) => {
      state.recordPerPage = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addLanguage.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(addLanguage.fulfilled, (state, action) => {
      // state.loading = false;
      state.language = action.payload;
    });
    builder.addCase(addLanguage.rejected, (state, action) => {
      // state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(updateLanguage.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(updateLanguage.fulfilled, (state, action) => {
      // state.loading = false;
      state.language = action.payload;
    });
    builder.addCase(updateLanguage.rejected, (state, action) => {
      // state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(fetchList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.loading = false;
      state.languages = action.payload?.data;
      state.language = "";
      state.delLang = "";
      state.recordPerPage = action.payload?.recordPerPage;
      state.pageNo = action.payload?.pageNo;
      state.totalRecord = action.payload?.totalRecord;
      state.totalPages = action.payload?.totalPages;
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(delLanguage.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(delLanguage.fulfilled, (state, action) => {
      // state.loading = false;
      state.delLang = action.payload;
    });
    builder.addCase(delLanguage.rejected, (state, action) => {
      // state.loading = false;
      state.error = action.payload.error.message;
    });
  },
});

export const { changeRecordPerpage } = languageSlice.actions;
export default languageSlice.reducer;
