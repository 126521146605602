import React from 'react'
import Select from 'react-select'
const SelectDropDownWithIcon = () => {
    const url = 'http://localhost:3000/'
    
    const countries = [
        { value: 'Nigeria', label: 'Nigeria', flag: 'media/flags/afghanistan.svg' },
        { value: 'United States', label: 'United States', flag: 'media/flags/united-states.svg' },
        { value: 'Israel', label: 'Israel', flag: 'media/flags/israel.svg' },
        { value: 'Romania', label: 'Romania', flag: 'media/flags/romania.svg' },
        { value: 'Germany', label: 'Germany', flag: 'media/flags/germany.svg' },
        { value: 'Bahamas', label: 'Bahamas', flag: 'media/flags/bahamas.svg' },
        { value: 'China', label: 'China', flag: 'media/flags/china.svg' },
        { value: 'Somalia', label: 'Somalia', flag: 'media/flags/somalia.svg' }
    ]

    return (
        <div class="fv-row mb-7">

            <label class="fs-6 fw-semibold form-label mt-3">
                <span class="required">Country</span>
            </label>

            <div class="w-100">

              
                <Select
                id="kt_ecommerce_select2_country" 
                    className='react-select-styled react-select-solid'
                    classNamePrefix='react-select'
                    options={countries.map((item) => {
                        item.label = (
                            <div className='label'>
                                <img src={url+item.flag} alt='flag' className='w-20px rounded-circle me-2' />
                                <span>{item.label}</span>
                            </div>
                        )
                        return item
                    })}
                    placeholder='Select an option'
                    defaultValue={countries[0]}
                    name={"country"}
                />

            </div>
        </div>
    )
}

export default SelectDropDownWithIcon
