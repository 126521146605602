import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import DataExpand from './DataExpand';

const SeriesTable = ({ series ,associationKey}) => {
  const navigate = useNavigate();
  const [data, setData] = useState(series)


  const gotoMatchesList = (serie) => {
    localStorage.setItem("seriesItem", serie?.name)
    console.log("serie", serie)
    navigate(`/fantasy-matches/${serie?.key}`);

  };

  const gotoCompetitionList = (competitionKey,serie) => {
    localStorage.setItem("seriesItem", serie?.name)
    localStorage.setItem("itemCompetitionKey", competitionKey)
    navigate(`/fantasy-competition-series/${associationKey}/${competitionKey}`);

  };
  return (
    <>
     <div className='table-responsive'>
        <table id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer' key={series}>
          <tr className='text-start text-muted fw-bolder fs-5 text-uppercase gs-0' style={{ color:"#rgb(0, 0, 0) !important"}} >
            <th>Tournament Key</th>
            <th>Name</th>
            <th>Formats</th>
            <th>Metric_Group</th>
            <th>Countries</th>
            <th>Start Date</th>
            <th>Gender</th>
            <th>Point System</th>
            <th>Competition</th>
            <th>Association-Key </th>
           
            <th>Is Date Confirmed</th>
            <th>Is Venue Confirmed</th>
            <th>Last Scheduled Match Date</th>
           
          </tr>
          <tbody className='text-gray-600 fw-bold'>
            {data.map((serie)=>{
              return <tr key={serie?.key}>
                <td 
                  style={{
                    fontSize: "1rem",
                    color: "#4519ff ",
                    fontWeight: "600",
                  }}
                onClick={() => { gotoMatchesList(serie) }}>{`${ serie.key }`}</td>
              <td>{`${ serie.name }`}</td>
                <td>{`${JSON.stringify(serie.formats).toString()}`}</td>
                <td>{serie.metric_group}</td>
                <td>
                  <DataExpand
                    head={JSON.stringify(serie.countries.map((e)=>`${e.name} `)).toString()}
                    data={serie.countries}
                  />
                </td>

              <td>{`${ serie.start_date }`}</td>
              <td>{`${ serie.gender }`}</td>
              <td>{`${serie.point_system}`}</td>
                <td
                  style={{
                    fontSize: "1rem",
                    color: "#4519ff ",
                    fontWeight: "600",
                  }}
                  onClick={() => { gotoCompetitionList(serie.competition.key, serie) }}>{`${serie.competition.key}`}</td>
                {/* <td>
                  <DataExpand
                    head={serie.competition.key}
                    data={serie.competition}
                  />
                </td> */}
              <td>{`${ serie.association_key }`}</td>
              <td>{`${ serie.is_date_confirmed }`}</td>
              <td>{`${ serie.is_venue_confirmed }`}</td>
              <td>{`${ serie.last_scheduled_match_date }`}</td>
              </tr>
          })
         }
         </tbody>
        </table>
      </div>
    </>
  )
}

export default SeriesTable
