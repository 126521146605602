export const routesFrontend = {
    'home':"/",
    'form':'form',
    'table':'table',
    'tableSubtable':'table-subtable',
    'fantasyAssociations': 'fantasy-associations',
    'fantasyMain': 'fantasy',
    'fantasySeries': 'fantasy-series/:key',
    'fantasyCompetitionSeries': '/fantasy-competition-series/:associationKey/:competitionKey',
    'fantasyMatches': 'fantasy-matches/:key',
    'fantasyMatcheDetails': 'fantasy-matches-details/:key',
    'fantasyPlayerDetails': 'fantasy-player-details/:playerkey/:tournamentKey/:associationKey',
    "formValidation": "form-validation",
    "wizards":"wizards",
    "login":'login',
    'staff':'staff',
    'module': 'module',
    'create': 'create',
    'viewList': 'list',
    'edit':'edit/:id',
    'viewPage':'details/:id',
    'currency':'currency',
    'country':'country',
    'language': 'language',
    'sport':'sport',
    'company': 'company',
    'customerCare':'customer-care',
    'series':'series',
    "legality":"legality"
}