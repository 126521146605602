import React from 'react'

const Logs = () => {
  return (
    <div class="card">
									
    <div class="card-header card-header-stretch">
    
        <div class="card-title d-flex align-items-center">
            <i class="ki-outline ki-calendar-8 fs-1 text-primary me-3 lh-0"></i>
            <h3 class="fw-bold m-0 text-gray-800">Jan 23, 2024</h3>
        </div>
    
    
        <div class="card-toolbar m-0">
        
            <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bold" role="tablist">
                <li class="nav-item" role="presentation">
                    <a id="kt_activity_today_tab" class="nav-link justify-content-center text-active-gray-800 active" data-bs-toggle="tab" role="tab" href="#kt_activity_today">Today</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a id="kt_activity_week_tab" class="nav-link justify-content-center text-active-gray-800" data-bs-toggle="tab" role="tab" href="#kt_activity_week">Week</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a id="kt_activity_month_tab" class="nav-link justify-content-center text-active-gray-800" data-bs-toggle="tab" role="tab" href="#kt_activity_month">Month</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a id="kt_activity_year_tab" class="nav-link justify-content-center text-active-gray-800 text-hover-gray-800" data-bs-toggle="tab" role="tab" href="#kt_activity_year">2024</a>
                </li>
            </ul>
        
        </div>
    
    </div>


    <div class="card-body">
    
        <div class="tab-content">
        
            <div id="kt_activity_today" class="card-body p-0 tab-pane fade show active" role="tabpanel" aria-labelledby="kt_activity_today_tab">
            
                <div class="timeline timeline-border-dashed">
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-message-text-2 fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">There are 2 new tasks for you in “AirPlus Mobile App” project:</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Added at 4:23 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Nina Nilson">
                                        <img src="assets/media/avatars/300-14.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        
                            <div class="overflow-auto pb-5">
                            
                                <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                                
                                    <a href="apps/projects/project.html" class="fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px">Meeting with customer</a>
                                
                                
                                    <div class="min-w-175px pe-2">
                                        <span class="badge badge-light text-muted">Application Design</span>
                                    </div>
                                
                                
                                    <div class="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                                    
                                        <div class="symbol symbol-circle symbol-25px">
                                            <img src="assets/media/avatars/300-2.jpg" alt="img" />
                                        </div>
                                    
                                    
                                        <div class="symbol symbol-circle symbol-25px">
                                            <img src="assets/media/avatars/300-14.jpg" alt="img" />
                                        </div>
                                    
                                    
                                        <div class="symbol symbol-circle symbol-25px">
                                            <div class="symbol-label fs-8 fw-semibold bg-primary text-inverse-primary">A</div>
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="min-w-125px pe-2">
                                        <span class="badge badge-light-primary">In Progress</span>
                                    </div>
                                
                                
                                    <a href="apps/projects/project.html" class="btn btn-sm btn-light btn-active-light-primary">View</a>
                                
                                </div>
                            
                            
                                <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-0">
                                
                                    <a href="apps/projects/project.html" class="fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px">Project Delivery Preparation</a>
                                
                                
                                    <div class="min-w-175px">
                                        <span class="badge badge-light text-muted">CRM System Development</span>
                                    </div>
                                
                                
                                    <div class="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px">
                                    
                                        <div class="symbol symbol-circle symbol-25px">
                                            <img src="assets/media/avatars/300-20.jpg" alt="img" />
                                        </div>
                                    
                                    
                                        <div class="symbol symbol-circle symbol-25px">
                                            <div class="symbol-label fs-8 fw-semibold bg-success text-inverse-primary">B</div>
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="min-w-125px">
                                        <span class="badge badge-light-success">Completed</span>
                                    </div>
                                
                                
                                    <a href="apps/projects/project.html" class="btn btn-sm btn-light btn-active-light-primary">View</a>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon me-4">
                            <i class="ki-outline ki-flag fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n2">
                        
                            <div class="overflow-auto pe-3">
                            
                                <div class="fs-5 fw-semibold mb-2">Invitation for crafting engaging designs that speak human workshop</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Sent at 4:23 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Alan Nilson">
                                        <img src="assets/media/avatars/300-1.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-disconnect fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="mb-5 pe-3">
                            
                                <a href="#" class="fs-5 fw-semibold text-gray-800 text-hover-primary mb-2">3 New Incoming Project Files:</a>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Sent at 10:30 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Jan Hummer">
                                        <img src="assets/media/avatars/300-23.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        
                            <div class="overflow-auto pb-5">
                                <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">
                                
                                    <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                                    
                                        <img alt="" class="w-30px me-3" src="assets/media/svg/files/pdf.svg" />
                                    
                                    
                                        <div class="ms-1 fw-semibold">
                                        
                                            <a href="apps/projects/project.html" class="fs-6 text-hover-primary fw-bold">Finance KPI App Guidelines</a>
                                        
                                        
                                            <div class="text-gray-500">1.9mb</div>
                                        
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                                    
                                        <img alt="apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/doc.svg" />
                                    
                                    
                                        <div class="ms-1 fw-semibold">
                                        
                                            <a href="#" class="fs-6 text-hover-primary fw-bold">Client UAT Testing Results</a>
                                        
                                        
                                            <div class="text-gray-500">18kb</div>
                                        
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="d-flex flex-aligns-center">
                                    
                                        <img alt="apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/css.svg" />
                                    
                                    
                                        <div class="ms-1 fw-semibold">
                                        
                                            <a href="#" class="fs-6 text-hover-primary fw-bold">Finance Reports</a>
                                        
                                        
                                            <div class="text-gray-500">20mb</div>
                                        
                                        </div>
                                    
                                    </div>
                                
                                </div>
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-abstract-26 fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">Task 
                                <a href="#" class="text-primary fw-bold me-1">#45890</a>merged with 
                                <a href="#" class="text-primary fw-bold me-1">#45890</a>in “Ads Pro Admin Dashboard project:</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Initiated at 4:23 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Nina Nilson">
                                        <img src="assets/media/avatars/300-14.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-pencil fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">3 new application design concepts added:</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Created at 4:23 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Marcus Dotson">
                                        <img src="assets/media/avatars/300-2.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        
                            <div class="overflow-auto pb-5">
                                <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                                
                                    <div class="overlay me-10">
                                    
                                        <div class="overlay-wrapper">
                                            <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-29.jpg" />
                                        </div>
                                    
                                    
                                        <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="overlay me-10">
                                    
                                        <div class="overlay-wrapper">
                                            <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-31.jpg" />
                                        </div>
                                    
                                    
                                        <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="overlay">
                                    
                                        <div class="overlay-wrapper">
                                            <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-40.jpg" />
                                        </div>
                                    
                                    
                                        <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                        </div>
                                    
                                    </div>
                                
                                </div>
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-sms fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">New case 
                                <a href="#" class="text-primary fw-bold me-1">#67890</a>is assigned to you in Multi-platform Database Design project</div>
                            
                            
                                <div class="overflow-auto pb-5">
                                
                                    <div class="d-flex align-items-center mt-1 fs-6">
                                    
                                        <div class="text-muted me-2 fs-7">Added at 4:23 PM by</div>
                                    
                                    
                                        <a href="#" class="text-primary fw-bold me-1">Alice Tan</a>
                                    
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-pencil fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">You have received a new order:</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Placed at 5:05 AM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Robert Rich">
                                        <img src="assets/media/avatars/300-4.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        
                            <div class="overflow-auto pb-5">
                            
                                <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                                
                                    <i class="ki-outline ki-devices-2 fs-2tx text-primary me-4"></i>
                                
                                
                                    <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                    
                                        <div class="mb-3 mb-md-0 fw-semibold">
                                            <h4 class="text-gray-900 fw-bold">Database Backup Process Completed!</h4>
                                            <div class="fs-6 text-gray-700 pe-7">Login into Admin Dashboard to make sure the data integrity is OK</div>
                                        </div>
                                    
                                    
                                        <a href="#" class="btn btn-primary px-6 align-self-center text-nowrap">Proceed</a>
                                    
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-basket fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">New order 
                                <a href="#" class="text-primary fw-bold me-1">#67890</a>is placed for Workshow Planning & Budget Estimation</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Placed at 4:23 PM by</div>
                                
                                
                                    <a href="#" class="text-primary fw-bold me-1">Jimmy Bold</a>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                </div>
            
            </div>
        
        
            <div id="kt_activity_week" class="card-body p-0 tab-pane fade show" role="tabpanel" aria-labelledby="kt_activity_week_tab">
            
                <div class="timeline timeline-border-dashed">
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon me-4">
                            <i class="ki-outline ki-flag fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n2">
                        
                            <div class="overflow-auto pe-3">
                            
                                <div class="fs-5 fw-semibold mb-2">Invitation for crafting engaging designs that speak human workshop</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Sent at 4:23 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Alan Nilson">
                                        <img src="assets/media/avatars/300-1.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-disconnect fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="mb-5 pe-3">
                            
                                <a href="#" class="fs-5 fw-semibold text-gray-800 text-hover-primary mb-2">3 New Incoming Project Files:</a>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Sent at 10:30 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Jan Hummer">
                                        <img src="assets/media/avatars/300-23.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        
                            <div class="overflow-auto pb-5">
                                <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">
                                
                                    <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                                    
                                        <img alt="" class="w-30px me-3" src="assets/media/svg/files/pdf.svg" />
                                    
                                    
                                        <div class="ms-1 fw-semibold">
                                        
                                            <a href="apps/projects/project.html" class="fs-6 text-hover-primary fw-bold">Finance KPI App Guidelines</a>
                                        
                                        
                                            <div class="text-gray-500">1.9mb</div>
                                        
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                                    
                                        <img alt="apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/doc.svg" />
                                    
                                    
                                        <div class="ms-1 fw-semibold">
                                        
                                            <a href="#" class="fs-6 text-hover-primary fw-bold">Client UAT Testing Results</a>
                                        
                                        
                                            <div class="text-gray-500">18kb</div>
                                        
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="d-flex flex-aligns-center">
                                    
                                        <img alt="apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/css.svg" />
                                    
                                    
                                        <div class="ms-1 fw-semibold">
                                        
                                            <a href="#" class="fs-6 text-hover-primary fw-bold">Finance Reports</a>
                                        
                                        
                                            <div class="text-gray-500">20mb</div>
                                        
                                        </div>
                                    
                                    </div>
                                
                                </div>
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-abstract-26 fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">Task 
                                <a href="#" class="text-primary fw-bold me-1">#45890</a>merged with 
                                <a href="#" class="text-primary fw-bold me-1">#45890</a>in “Ads Pro Admin Dashboard project:</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Initiated at 4:23 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Nina Nilson">
                                        <img src="assets/media/avatars/300-14.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-pencil fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">3 new application design concepts added:</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Created at 4:23 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Marcus Dotson">
                                        <img src="assets/media/avatars/300-2.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        
                            <div class="overflow-auto pb-5">
                                <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                                
                                    <div class="overlay me-10">
                                    
                                        <div class="overlay-wrapper">
                                            <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-29.jpg" />
                                        </div>
                                    
                                    
                                        <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="overlay me-10">
                                    
                                        <div class="overlay-wrapper">
                                            <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-31.jpg" />
                                        </div>
                                    
                                    
                                        <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="overlay">
                                    
                                        <div class="overlay-wrapper">
                                            <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-40.jpg" />
                                        </div>
                                    
                                    
                                        <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                        </div>
                                    
                                    </div>
                                
                                </div>
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-sms fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">New case 
                                <a href="#" class="text-primary fw-bold me-1">#67890</a>is assigned to you in Multi-platform Database Design project</div>
                            
                            
                                <div class="overflow-auto pb-5">
                                
                                    <div class="d-flex align-items-center mt-1 fs-6">
                                    
                                        <div class="text-muted me-2 fs-7">Added at 4:23 PM by</div>
                                    
                                    
                                        <a href="#" class="text-primary fw-bold me-1">Alice Tan</a>
                                    
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                </div>
            
            </div>
        
        
            <div id="kt_activity_month" class="card-body p-0 tab-pane fade show" role="tabpanel" aria-labelledby="kt_activity_month_tab">
            
                <div class="timeline timeline-border-dashed">
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-pencil fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">3 new application design concepts added:</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Created at 4:23 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Marcus Dotson">
                                        <img src="assets/media/avatars/300-2.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        
                            <div class="overflow-auto pb-5">
                                <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                                
                                    <div class="overlay me-10">
                                    
                                        <div class="overlay-wrapper">
                                            <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-29.jpg" />
                                        </div>
                                    
                                    
                                        <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="overlay me-10">
                                    
                                        <div class="overlay-wrapper">
                                            <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-31.jpg" />
                                        </div>
                                    
                                    
                                        <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="overlay">
                                    
                                        <div class="overlay-wrapper">
                                            <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-40.jpg" />
                                        </div>
                                    
                                    
                                        <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                        </div>
                                    
                                    </div>
                                
                                </div>
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-sms fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">New case 
                                <a href="#" class="text-primary fw-bold me-1">#67890</a>is assigned to you in Multi-platform Database Design project</div>
                            
                            
                                <div class="overflow-auto pb-5">
                                
                                    <div class="d-flex align-items-center mt-1 fs-6">
                                    
                                        <div class="text-muted me-2 fs-7">Added at 4:23 PM by</div>
                                    
                                    
                                        <a href="#" class="text-primary fw-bold me-1">Alice Tan</a>
                                    
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-basket fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">New order 
                                <a href="#" class="text-primary fw-bold me-1">#67890</a>is placed for Workshow Planning & Budget Estimation</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Placed at 4:23 PM by</div>
                                
                                
                                    <a href="#" class="text-primary fw-bold me-1">Jimmy Bold</a>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon me-4">
                            <i class="ki-outline ki-flag fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n2">
                        
                            <div class="overflow-auto pe-3">
                            
                                <div class="fs-5 fw-semibold mb-2">Invitation for crafting engaging designs that speak human workshop</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Sent at 4:23 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Alan Nilson">
                                        <img src="assets/media/avatars/300-1.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-disconnect fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="mb-5 pe-3">
                            
                                <a href="#" class="fs-5 fw-semibold text-gray-800 text-hover-primary mb-2">3 New Incoming Project Files:</a>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Sent at 10:30 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Jan Hummer">
                                        <img src="assets/media/avatars/300-23.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        
                            <div class="overflow-auto pb-5">
                                <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">
                                
                                    <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                                    
                                        <img alt="" class="w-30px me-3" src="assets/media/svg/files/pdf.svg" />
                                    
                                    
                                        <div class="ms-1 fw-semibold">
                                        
                                            <a href="apps/projects/project.html" class="fs-6 text-hover-primary fw-bold">Finance KPI App Guidelines</a>
                                        
                                        
                                            <div class="text-gray-500">1.9mb</div>
                                        
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                                    
                                        <img alt="apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/doc.svg" />
                                    
                                    
                                        <div class="ms-1 fw-semibold">
                                        
                                            <a href="#" class="fs-6 text-hover-primary fw-bold">Client UAT Testing Results</a>
                                        
                                        
                                            <div class="text-gray-500">18kb</div>
                                        
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="d-flex flex-aligns-center">
                                    
                                        <img alt="apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/css.svg" />
                                    
                                    
                                        <div class="ms-1 fw-semibold">
                                        
                                            <a href="#" class="fs-6 text-hover-primary fw-bold">Finance Reports</a>
                                        
                                        
                                            <div class="text-gray-500">20mb</div>
                                        
                                        </div>
                                    
                                    </div>
                                
                                </div>
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-abstract-26 fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">Task 
                                <a href="#" class="text-primary fw-bold me-1">#45890</a>merged with 
                                <a href="#" class="text-primary fw-bold me-1">#45890</a>in “Ads Pro Admin Dashboard project:</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Initiated at 4:23 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Nina Nilson">
                                        <img src="assets/media/avatars/300-14.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                </div>
            
            </div>
        
        
            <div id="kt_activity_year" class="card-body p-0 tab-pane fade show" role="tabpanel" aria-labelledby="kt_activity_year_tab">
            
                <div class="timeline timeline-border-dashed">
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-disconnect fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="mb-5 pe-3">
                            
                                <a href="#" class="fs-5 fw-semibold text-gray-800 text-hover-primary mb-2">3 New Incoming Project Files:</a>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Sent at 10:30 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Jan Hummer">
                                        <img src="assets/media/avatars/300-23.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        
                            <div class="overflow-auto pb-5">
                                <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">
                                
                                    <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                                    
                                        <img alt="" class="w-30px me-3" src="assets/media/svg/files/pdf.svg" />
                                    
                                    
                                        <div class="ms-1 fw-semibold">
                                        
                                            <a href="apps/projects/project.html" class="fs-6 text-hover-primary fw-bold">Finance KPI App Guidelines</a>
                                        
                                        
                                            <div class="text-gray-500">1.9mb</div>
                                        
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                                    
                                        <img alt="apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/doc.svg" />
                                    
                                    
                                        <div class="ms-1 fw-semibold">
                                        
                                            <a href="#" class="fs-6 text-hover-primary fw-bold">Client UAT Testing Results</a>
                                        
                                        
                                            <div class="text-gray-500">18kb</div>
                                        
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="d-flex flex-aligns-center">
                                    
                                        <img alt="apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/css.svg" />
                                    
                                    
                                        <div class="ms-1 fw-semibold">
                                        
                                            <a href="#" class="fs-6 text-hover-primary fw-bold">Finance Reports</a>
                                        
                                        
                                            <div class="text-gray-500">20mb</div>
                                        
                                        </div>
                                    
                                    </div>
                                
                                </div>
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-abstract-26 fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">Task 
                                <a href="#" class="text-primary fw-bold me-1">#45890</a>merged with 
                                <a href="#" class="text-primary fw-bold me-1">#45890</a>in “Ads Pro Admin Dashboard project:</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Initiated at 4:23 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Nina Nilson">
                                        <img src="assets/media/avatars/300-14.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-pencil fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">3 new application design concepts added:</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Created at 4:23 PM by</div>
                                
                                
                                    <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Marcus Dotson">
                                        <img src="assets/media/avatars/300-2.jpg" alt="img" />
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        
                            <div class="overflow-auto pb-5">
                                <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                                
                                    <div class="overlay me-10">
                                    
                                        <div class="overlay-wrapper">
                                            <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-29.jpg" />
                                        </div>
                                    
                                    
                                        <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="overlay me-10">
                                    
                                        <div class="overlay-wrapper">
                                            <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-31.jpg" />
                                        </div>
                                    
                                    
                                        <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                        </div>
                                    
                                    </div>
                                
                                
                                    <div class="overlay">
                                    
                                        <div class="overlay-wrapper">
                                            <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-40.jpg" />
                                        </div>
                                    
                                    
                                        <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                        </div>
                                    
                                    </div>
                                
                                </div>
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-sms fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mb-10 mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">New case 
                                <a href="#" class="text-primary fw-bold me-1">#67890</a>is assigned to you in Multi-platform Database Design project</div>
                            
                            
                                <div class="overflow-auto pb-5">
                                
                                    <div class="d-flex align-items-center mt-1 fs-6">
                                    
                                        <div class="text-muted me-2 fs-7">Added at 4:23 PM by</div>
                                    
                                    
                                        <a href="#" class="text-primary fw-bold me-1">Alice Tan</a>
                                    
                                    </div>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                
                    <div class="timeline-item">
                    
                        <div class="timeline-line"></div>
                    
                    
                        <div class="timeline-icon">
                            <i class="ki-outline ki-basket fs-2 text-gray-500"></i>
                        </div>
                    
                    
                        <div class="timeline-content mt-n1">
                        
                            <div class="pe-3 mb-5">
                            
                                <div class="fs-5 fw-semibold mb-2">New order 
                                <a href="#" class="text-primary fw-bold me-1">#67890</a>is placed for Workshow Planning & Budget Estimation</div>
                            
                            
                                <div class="d-flex align-items-center mt-1 fs-6">
                                
                                    <div class="text-muted me-2 fs-7">Placed at 4:23 PM by</div>
                                
                                
                                    <a href="#" class="text-primary fw-bold me-1">Jimmy Bold</a>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                </div>
            
            </div>
        
        </div>
    
    </div>

</div>
  )
}

export default Logs