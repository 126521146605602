import SwitchBoxWithoutLabel from "../../../Common/SwitchBoxWithoutLabel"
import ActionsCell from "../../../Common/TableWithSubtable/ActionsCell"
import CountryPopupCell from "./CountryPopupCell"



const languageColumn = [

    {
      accessorKey:'name',
      header:'name',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'code',
      header:'Code',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'is_active',
      header:'is active',
      cell: (props) => <SwitchBoxWithoutLabel checked={props.getValue()} />
  
    },
   
    {
      accessorKey:'country',
      header:'Countries',
      cell: (props) => <CountryPopupCell countries={props.getValue()} />
  
    },
    {
      accessorKey:'',
      header:'Actions',
      cell: ({table, row}) => <ActionsCell type={['edit', 'remove']} table={table} row={row}/>
  
    }
   
   
  ]
  
  export {languageColumn}
  