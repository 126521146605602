import React, { useState } from 'react'
import UploadSingleImage from '../../../../../Common/UploadSingleImage'

const TeamIcon = ({data,props,name}) => {
  const handlerFormData = (name,value) => {
    console.log(name, value)
    props.table.options.meta.updateEdit(props.row.index, name,value)
  }
  // const handlerChange = (e)=>{
  //   let {name, value} = e.target
  //   setVal(value)
  //   props.table.options.meta.updateEdit(props.row.index, name,value)
  // }
  return (
    <UploadSingleImage  data={data} name={name}   handlerFormData={handlerFormData} />
  )
}

export default TeamIcon