import { createSlice } from "@reduxjs/toolkit";

import {  addModule, deleteModule, fetchList, getModuleById, updateModule, updateModuleStatus } from "./module.action";


export const moduleSlice = createSlice({
  name: "moduleState",
  initialState: {
    pageNo: 1,
    recordPerPage: 10,
    totalRecord: 0,
    totalPages: 0,
    moduleList: [],
    module: null,
    viewModule: null,
    delModule:null,
    loading: false,
    error: null,
  },
  reducers: {
    // addSportReducer:createSport
  },
  
  extraReducers: (builder) => {
    builder.addCase(addModule.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addModule.fulfilled, (state, action) => {
      state.loading = false;
      state.module = action.payload;
    });
    builder.addCase(addModule.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error?.message || "Some thing went wrong (API Call)!";
    });
    builder.addCase(updateModule.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateModule.fulfilled, (state, action) => {
      state.loading = false;
      state.module = action.payload;
    });
    builder.addCase(updateModule.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error?.message || "Some thing went wrong (API Call)!";
    });
    builder.addCase(updateModuleStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateModuleStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.module = action.payload;
    });
    builder.addCase(updateModuleStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error?.message || "Some thing went wrong (API Call)!";
    });
    builder.addCase(fetchList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.loading = false;
      state.moduleList = action.payload?.data;
      state.recordPerPage = action.payload?.recordPerPage;
        state.pageNo = action.payload?.pageNo;
        state.totalRecord = action.payload?.totalRecord;
        state.totalPages = action.payload?.totalPages
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.loading = false;
      state.moduleList = [];
      state.recordPerPage = 0
      state.pageNo = 0;
      state.totalRecord = 0;
      state.totalPages = 0
      state.error = action?.payload?.error?.message || "Some thing went wrong (API Call)!";
    });
    builder.addCase(deleteModule.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteModule.fulfilled, (state, action) => {
      state.loading = false;
      state.delModule = action.payload;
    });
    builder.addCase(deleteModule.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error?.message || "Some thing went wrong (API Call)!";
    });
    builder.addCase(getModuleById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getModuleById.fulfilled, (state, action) => {
      state.loading = false;
      state.viewModule = action.payload.data;
    });
    builder.addCase(getModuleById.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error?.message || "Some thing went wrong (API Call)!";
    });
  },
});

// export const {addSportReducer} = staffSlice.actions
export default moduleSlice.reducer;
