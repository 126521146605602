import { createAsyncThunk } from "@reduxjs/toolkit"
import { getFetch, getList, save } from "./teams.api"


export const getTeamFetchById = createAsyncThunk('series/getteamsfetchById', async ({id,companyCode},{rejectWithValue}) => {
  console.log("cratespor", id)
    let headers = {
       "company-code":companyCode
      }
      try{

          const response = await getFetch(headers,id)
          console.log("responseseries:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        return rejectWithValue(error)
      }
})
export const getTeamsList = createAsyncThunk('series/getteamsList', async ({id,companyCode},{rejectWithValue}) => {
  console.log("cratespor", id)
    let headers = {
       "company-code":companyCode
      }
      try{

          const response = await getList(headers,id)
          console.log("responseseries:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        return rejectWithValue(error)
      }
})

export const saveTeam = createAsyncThunk('series/saveTeam', async ({id,companyCode,data},{rejectWithValue}) => {
  console.log("cratespor", id)
    let headers = {
       "company-code":companyCode
      }
      try{

          const response = await save(headers,id,data)
          console.log("responseseries:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        return rejectWithValue(error)
      }
})