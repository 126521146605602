import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataExpand from "./DataExpand";
import { JSONTree } from "react-json-tree";

const postmanTheme = {
  scheme: "postman",
  author: "Postman",
  base00: "#232527", // Background color
  base01: "#2c2e33", // Secondary background
  base02: "#3a3b3c", // Tertiary background
  base03: "#ffffff", // Text color
  base04: "#b1b2b3", // Placeholder text
  base05: "#ffffff", // Main text
  base06: "#ffffff", // Highlighted text
  base07: "#ffffff", // Pure white
  base08: "#ff4f4f", // Error color
  base09: "#ffcc00", // Warning color
  base0A: "#f5f0e1", // Info color
  base0B: "#3cd318", // Success color
  base0C: "#00bcd4", // Info color
  base0D: "#ff9f09", // Link color
  base0E: "#a24c93", // Purple accents
  base0F: "#e85d04", // Orange accents
};

const keyNameData = [""];

const MatchOverViewTab = ({ data, playersData }) => {
  const navigate = useNavigate();
  const [overViewData, setOverViewData] = useState(null);

  useEffect(() => {
    if (data) {
      setOverViewData(data);
    }
  }, [data]);

  useEffect(() => {
    console.log("overViewData", overViewData);
  }, [overViewData]);
  return (
    <div
      style={{
        display: "flex",
        margin:"20px",
        flexWrap:"wrap",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      {overViewData?.length > 0 &&
        overViewData?.map((element) => {
          return (
            <div
              className="m-2 p-2"
              style={{
                backgroundColor: "#232527",
                border: "2px solid #ff9f09",
                borderRadius: "5px",
                width:"230px",
                color: "#3cd318",
               
              }}
            >
              <h6 style={{ color: "#ff9f09" }}>
                Over :{" "}
                <span style={{ color: "#3cd318" }}>
                  {element?.index?.over_number}
                </span>
              </h6>
              <div>
                <div>
                  <p style={{ color: "#3cd318" }}>
                    <strong style={{ color: "#ff9f09" }}>Score:</strong>{" "}
                    {element.match_score.title} overs
                  </p>

                  <p style={{ color: "#3cd318" }}>
                    <strong style={{ color: "#ff9f09" }}>Run Rate:</strong>{" "}
                    {element.match_score.run_rate}
                  </p>
                </div>

                <div>
                  <div>Current Strikers</div>
                  {element.strikers.map((striker) => {
                    return (
                      <ul>
                        <li>
                          <strong style={{ color: "#ff9f09" }}>
                            Player: {playersData[striker.player_key]?.player?.name}
                          </strong>
                        </li>
                        <li>
                          Runs: {striker.score.runs} ({striker.score.balls} balls)
                        </li>
                        <li>
                          Fours: {striker.score.fours} | Sixes: {striker.score.sixes}
                        </li>
                        <li>
                          Ones: {striker.score.ones} | Twos: {striker.score.twos}
                        </li>
                        <li>
                          Fives: {striker.score.fives} | Threes: {striker.score.threes}
                        </li>
                        <li>Strike Rate: {striker.score.strike_rate}</li>
                        <li>
                          Status: {striker.is_dismissed ? "Dismissed" : "Not Dismissed"}
                        </li>
                      </ul>
                    );
                  })}
                </div>
                {element.bowlers.map((bowler) => {
                  return (
                    <div>
                      <div>Bowler Stats</div>
                      <ul>
                        <li>
                          <strong style={{ color: "#ff9f09" }}>
                            Bowler: {playersData[bowler.player_key]?.player?.name}
                          </strong>
                        </li>
                        <li>
                          Overs: {bowler.score.overs[0]}.{bowler.score.overs[1]} | Runs
                          Conceded: {bowler.score.runs}
                        </li>
                        <li>
                          Economy: {bowler.score.economy} | Wickets: {bowler.score.wickets}
                        </li>
                        <li>
                          Dot Balls: {bowler?.score?.balls_breakup?.dot_balls} | Maiden
                          Overs: {bowler.score.maiden_overs} | No Balls:{" "}
                          {bowler?.score?.balls_breakup?.no_balls}
                        </li>
                        <li>
                          Sixes: {bowler?.score?.balls_breakup?.sixes} | Fours:{" "}
                          {bowler?.score?.balls_breakup?.fours}
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default MatchOverViewTab;
