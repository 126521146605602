import React, { useState } from 'react'

const WithAddButton = (Component) => {

    return function Helper(props){
        const [addBtn, setAddBtn] = useState(false)
        const handleAddBtn = (val) => {
            setAddBtn(val)
        }
        return <Component addBtn={addBtn} handleAddBtn={handleAddBtn}  {...props} />
    }


}

export default WithAddButton

