import { createAsyncThunk } from "@reduxjs/toolkit"
import { add,  getList, getListById, getSportId, remove, update } from "./sport.api"

export const fetchList = createAsyncThunk('sport/fetchList', async ({pageNo, recordPerPage},{rejectWithValue}) => {
  console.log(pageNo,recordPerPage )
    let headers = {
        // pageNo:pageNo || 1,
        // recordPerPage:recordPerPage || 10,
        // sortBy: 'created_at',
        // sort:-1
      }
      let body = {
        pagination: {
            pageNo: pageNo || 1,
            recordPerPage: recordPerPage || 100,
            sortBy: "created_at",
            sortDirection: "asc"
        },
        search: "" // optional
    }
      try{

          const response = await getList(headers,body)
          console.log("response:::::::",response,pageNo,recordPerPage)
          if(response.success)
          return {
            ...response, 
            pageNo,
            recordPerPage
          }
      }
      catch(error){
        return rejectWithValue(error)
      }
})
export const addSport = createAsyncThunk('sport/add', async ({data},{rejectWithValue}) => {
    let headers = {
       
      }
      try{

          const response = await add(headers,data)
          console.log("responseSport:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        return rejectWithValue(error)
      }
})
export const updateSport = createAsyncThunk('sport/update', async ({data,id},{rejectWithValue}) => {
    let headers = {
       
      }
      try{

          const response = await update(headers,data,id)
          console.log("responseSport:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        return rejectWithValue(error)
      }
})
export const deleteSport= createAsyncThunk('sport/delete', async ({id},{rejectWithValue}) => {
  console.log("cratespor", id)
    let headers = {
       
      }
      try{

          const response = await remove(headers,id)
          console.log("responseSport:::::::",response)
          if(response?.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error from sport", error)
        // throw error
        return rejectWithValue(error)
      }
})
export const getSportById = createAsyncThunk('sport/getListById', async ({id},{rejectWithValue}) => {
  console.log("cratespor", id)
    let headers = {
       
      }
      try{

          const response = await getSportId(headers,id)
          console.log("responseSport:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        return rejectWithValue(error)
      }
})