import React, { useEffect, useMemo, useState } from "react";
import RulesEdit from "./RulesEdit";
import { useDispatch, useSelector } from "react-redux";
import { fetchSeriesRulesbyId, updateSeriesRulesbyId } from "../../redux/series.action";

const Rules = ({ id }) => {
    const dispatch = useDispatch()
  const { viewSeries, loading,rules } = useSelector(state => state.series)
  const tabs = [
    "Private Auction",
    "Public Auction",
    "Private Dabba",
    "Public Dabba",
  ];
  const [activeTab, setActiveTab] = useState("Private Auction");
  const [activeRules, setActiveRules] = useState(
    "<ul><li>1.Edit Rules here </li></ul>"
  );

    useEffect(() => {
        if(id){
          dispatch(fetchSeriesRulesbyId({ id: id}))   
        }
    }, [id]);

  useEffect(() => {
    console.log("FETCH ", rules)
  }, [rules]);

  useEffect(() => {
    if (activeTab === "Private Auction") {
      setActiveRules(rules?.private_auction_rule)
    } else if (activeTab === "Public Auction") {
      setActiveRules(rules?.public_auction_rule)
    }
    else if (activeTab === "Private Dabba") {
      setActiveRules(rules?.private_dabba_rule)
    }
    else if (activeTab === "Public Dabba") {
      setActiveRules(rules?.public_dabba_rule)
    }
  }, [activeTab, rules]);

  const haldleSubmitRules = (data,type) => {
    let tempRules = {}
    if (type === "Private Auction"){
      tempRules.private_auction_rule=data.toString()
    } else if (type === "Public Auction"){
      tempRules.public_auction_rule = data.toString()
    }
    else if (type === "Private Dabba") {
      tempRules.private_dabba_rule = data.toString()
    }
    else if (type === "Public Dabba") {
      tempRules.public_dabba_rule = data.toString()
    }
    console.log("haldleSubmitRules", data, rules, "temprules", tempRules)
    dispatch(updateSeriesRulesbyId({ id: id, data: tempRules })) 
  };

  return (
    <div className="card">
      <div className="card-header mt-10">
        <h1>Rules</h1>
      </div>
      <div className="card-body" key={activeRules}>
        <ul
          class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
          onClick={(e) => {
            if (e.target.tagName === "SPAN") {
              const clickedTab = e.target.innerText; // Get the tab name from the clicked span
              setActiveTab(clickedTab);
            }
          }}
        >
          {tabs?.map((tab) => (
            <li class="nav-item mt-2" key={tab}>
              <span
                class={`nav-link text-active-primary ms-0 me-10 py-5 ${
                  activeTab === tab ? "active" : ""
                }`}
              >
                {tab}
              </span>
            </li>
          ))}
        </ul>
        {activeTab === "Public Auction" ? (
          <RulesEdit
            id={id}
            type={"Public Auction"}
            haldleSubmitRules={haldleSubmitRules}
            rules={activeRules}
          />
        ) : activeTab === "Private Dabba" ? (
          <RulesEdit
            id={id}
            type={"Private Dabba"}
            haldleSubmitRules={haldleSubmitRules}
            rules={activeRules}
          />
        ) : activeTab === "Public Dabba" ? (
          <RulesEdit
            id={id}
            type={"Public Dabba"}
            haldleSubmitRules={haldleSubmitRules}
            rules={activeRules}
          />
        ) : (
          <RulesEdit
            id={id}
            type={"Private Auction"}
            haldleSubmitRules={haldleSubmitRules}
            rules={activeRules}
          />
        )}
      </div>
    </div>
  );
};

export default Rules;
