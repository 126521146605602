import React, { useEffect, useState } from 'react'
import Toolbar from '../../../Common/Toolbar'
import Content from '../../../Common/Content'
import Table from '../../../Common/TableWithTan/Table'
import { countryColumn } from './CountryCoulmn'
import { useDispatch, useSelector } from 'react-redux'


import Loader from '../../../MasterLayout/Loader'
import { errorMessage } from '../../../Common/SwalToast'
import { fetchList } from './redux/country.action'

const CountryList = () => {
    const dispatch = useDispatch();
    const [countryData, setCountryData] = useState()
    const [isLoading, setLoading] = useState()
    const {countries,loading,error,recordPerPage,totalPages,totalRecord } = useSelector((state => state.countries))
    const [pageNo, setPageNo] = useState(0)
    const [recordPerPages, setRecordPerPage] = useState(5)
    useEffect(()=>{
        dispatch(fetchList({pageNo:pageNo+1 , recordPerPage:recordPerPages}))
        
    },[recordPerPages, pageNo])
    useEffect(()=>{
        
        setCountryData(countries)
        
    },[countries])
    useEffect(() => {
        setLoading(loading)
      }, [loading])
    
      useEffect(() => {
        if (error) {
          errorMessage(error || 'Something went wrong')
        }
      }, [error])
   const handlerChangeDropDown = (value)=>{
    setRecordPerPage(value)
   }

  return (
    <>
    <Toolbar  text={"Country"}/>
    <Content>
        <div className='card'>
                <div className='card-body'>
                    {
                        isLoading ? <Loader />
                        : 

                    <Table
                    tableData={countryData}
                    column={countryColumn}
                    recordPerPage={recordPerPages}
                    totalPages={totalPages}
                    totalRecord={totalRecord}
                    pageIndex={pageNo}
                    handlerChangeDropDown={handlerChangeDropDown}
                    handlePageChange = {(index) => {
                        console.log("index::::", index)
                        setPageNo(index)
                      }}
                    />
                    }
                </div>
        </div>
    </Content>
    </>
  )
}

export default CountryList