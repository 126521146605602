import React from "react";
import ButtonWithLoader from "../../../../../Common/ButtonWithLoader";

const RemoveButtonCell = ({ props, saved }) => {
  const handlerSubmit = () => {
    props.table.options.meta.updateStatus(props.row.index);
  };
  return (
    <div
      className={`btn  hover-scale  p-2
      btn-danger d-flex align-items-center `}
      style={{width:'8rem'}}
       onClick={(e) => handlerSubmit()}
    >
      <span className="d-flex justify-content-between align-items-center">
        <i className="ki-outline ki-minus-square  fs-1 me-1"></i>
        Remove
      </span>
    </div>
  );
};

export default RemoveButtonCell;
