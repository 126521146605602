import React, { useEffect, useRef, useState } from 'react'
import { formValidation } from '@form-validation/bundle/popular';
import { Bootstrap5 } from '@form-validation/plugin-bootstrap5';
import { SubmitButton } from '@form-validation/plugin-submit-button';
import { Trigger } from '@form-validation/plugin-trigger';

import { errorMessage, successMessage, warningMessage, successResponse } from '../../Common/SwalToast';
import SingleDatePickerBasic from '../../Common/SingleDatePickerBasic';
import TextInputField from '../../Common/TextInputField';
import RadioWithoutIcon from '../../Common/RadioWithoutIcon';
import ButtonWithLoader from '../../Common/ButtonWithLoader';
import UploadSingleImage from '../../Common/UploadSingleImage';
import SelectDropdownWithSearch from '../../Common/SelectDropdownWithSearch';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router";

import { getUnixTimeStamp, getUnixToDatePickerFormat } from '../../utilities/dateUtils'
import { getCountryDropdown } from '../Master/Country/redux/country.action';
import { addStaff, getStaffById, updateStaff } from './redux/staff.action';
   // Dropdown options
   const signInValues = [
    { value: 'email', label: 'Email' },
    { value: 'mobile', label: 'Mobile' },
];
const employeeTypes = [
    { value: 'GamerJi', label: 'Gamerji' },
    { value: 'Consultant', label: 'Consultant' },
    { value: 'Client', label: 'Client' },
];

const CreateStaff = ({data}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {dropDownList} = useSelector(state => state.countries)
    const { viewstaff } = useSelector(state => state.staffUser)
    const [formData, setFormData] = useState({});
    const formRef = useRef()
    const [isSubmitting, setIsSubmitting] = useState(false); // State to handle loading indication
    const [validator, setValidator] = useState(null); // Store form validation instance
    const [staffUserID, setStaffUserID] = useState(null);
    const [countryOptions, setCountryOptions] = useState([]);
    useEffect(() => {
        let tempdata = [];
        dropDownList?.forEach((element) => {
            tempdata.push({
                value: element._id,
                label: element.name,
                dialingCode: element.dialingCode,
                img: element.img,
            });
        });
        setCountryOptions(dropDownList);
    }, [dropDownList]);
    
    //Handle form input changes
    const redirectToList = () => {
        setTimeout(() => {
            navigate("/staff/list");
        }, 100); // Delay of 100 milliseconds
    };
    useEffect(() => {
        dispatch(getCountryDropdown({}))
        let staffID = data?._id|| null;
        if (staffID?.toString()) {
            setStaffUserID(staffID?.toString());
            dispatch(getStaffById({ id: staffID?.toString() }))
        } else {
            setStaffUserID(null);
        }
    }, []);

    // useEffect(() => {
    //     console.log("viewstaff", viewstaff)
    //     if (viewstaff?._id) {
    //         let tempData = {};
    //         tempData = {
    //             country: viewstaff?.country?._id,
    //             name: viewstaff?.name,
    //             email: viewstaff?.email,
    //             phone: viewstaff?.phone,
    //             dob: getUnixToDatePickerFormat(viewstaff?.dob),
    //             sign_in_enabled: viewstaff?.sign_in_enabled,
    //             emp_type: viewstaff?.emp_type,
    //             img: viewstaff?.img,
    //         };
    //         console.log("tempData", tempData);
    //         setFormData(tempData);
    //     }
    // }, [viewstaff]);

    const handleChange = (e) => {

        const { name, value, type, checked } = e.target;

        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? (checked ? [...formData.notifications, value] : formData.notifications.filter(n => n !== value)) : value
        });
    };
    const handlerDate = (date) => {
        setFormData(prev => {
            return {
                ...prev,
                dob: date
            }
        }
        )
    }
    const handlerRadio = (name, value) => {

        setFormData(prev => {
            return {
                ...prev,
                [name]: value
            }
        }
        )
    }
    const handleSelectChange = (name, value) => {

        setFormData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
        // setGame(value)
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true)

        if (validator) {
            validator.validate().then(function (status) {

                let text = ''
                if (status === 'Valid') {
                    formData.dob = getUnixTimeStamp(formData.dob)
                    if (viewstaff && staffUserID) dispatch(updateStaff({ data: formData, id: staffUserID }))
                    else
                    dispatch(addStaff({data:formData}))
                    setIsSubmitting(false)
              


                } else {
                    // Show error message
                    text = "Sorry, looks like there are some errors detected, please try again."
                    errorMessage(text)
                }
            });
        }

    };
    const setData = () => {
        setFormData({
            subject: '',
            user: '',
            due_date: '',
            description: '',
            notifications: [],
        });
    }
    // Handle form cancel action
    const handleCancel = (e) => {
        e.preventDefault();
        let text = "Are you sure you would like to cancel????"

        warningMessage(text, setData)
    };

    useEffect(() => {
        const form = formRef.current;
        if (!form) return;
        const validationFields = {
            fields: {
                name: {
                    validators: {
                        notEmpty: {
                            message: 'Name is required'
                        },
                        stringLength: {
                            verbose: false,
                            min: 6,
                            max: 30,
                            message: 'The name must be more than 6 and less than 30 characters long',
                        },
                        // regexp: {
                        //     regexp: /^[a-zA-Z0-9_]+$/,
                        //     message: 'The name can only consist of alphabetical, number and underscore',
                        // },
                    }
                },
                email: {
                    validators: {
                        notEmpty: {
                            message: 'Email is required'
                        },
                        regexp: {
                            regexp: /^[a-zA-Z0-9_.]+@[a-z]+\.[a-z]{3}$/,
                            message: "The email is invalid",
                        },
                    }
                },
                phone: {
                    validators: {
                        notEmpty: {
                            message: 'Number is required'
                        }
                    }
                },
                dob: {
                    validators: {
                        notEmpty: {
                            message: 'Dob is required'
                        }
                    }
                },
                emp_type: {
                    validators: {
                        notEmpty: {
                            message: 'Employee Type is required'
                        }
                    }
                },
                sign_in_enabled: {
                    validators: {
                        notEmpty: {
                            message: 'Sign in type is required'
                        }
                    }
                },
            },
            plugins: {
                trigger: new Trigger(),
                bootstrap5: new Bootstrap5(),
                submitButton: new SubmitButton(),

            },
        };
        const fv = formValidation(form, validationFields);
        setValidator(fv);
        console.log("data:::", viewstaff)
        setFormData({
            country: viewstaff?.country?._id,
            name: viewstaff?.name,
            email: viewstaff?.email,
            phone: viewstaff?.phone,
            dob: getUnixToDatePickerFormat(viewstaff?.dob),
            sign_in_enabled: viewstaff?.sign_in_enabled,
            emp_type: viewstaff?.emp_type,
            img: viewstaff?.img,
        })
    }, [viewstaff])
    


    return (
        <>
            <div class="card card-custom">
                <div class="card-body">
                    <div className="col-xl-12">
                        <form id="kt_modal_new_ticket_form" class="form" onSubmit={handleSubmit} ref={formRef}>
                            <div class="row g-9 mb-8">
                                <div class="col-md-4 col-sm-6 col-12 ">
                                    <TextInputField
                                        label={"Name"}
                                        required={"true"}
                                        value={formData?.name}
                                        name={"name"}
                                        type={"text"}
                                        minlength={"6"}
                                        maxlength={"30"}
                                        disabled={viewstaff?._id?true:false}
                                        onChange={handleChange}
                                        placeholder={"Enter your name"}
                                    />
                                </div>
                                <div class="col-md-4 col-sm-6 col-12  ">
                                    <TextInputField
                                        label={"Email"}
                                        required={"true"}
                                        value={formData?.email}
                                        name={"email"}
                                        type={"text"}
                                        minlength={"6"}
                                        maxlength={"30"}
                                        onChange={handleChange}
                                        placeholder={"Enter your email"}

                                    />
                                </div>
                            </div>
                            <div class="row g-9 mb-8">
                                <div class="col-md-4 col-sm-6 col-12 ">
                                    <label class="fs-6 fw-semibold form-label mt-3">
                                        <span className={`required`}>Country Code</span></label>
                                    {console.log("option", countryOptions, "formData?.country",formData?.country)}
                                    <SelectDropdownWithSearch
                                        name="country"
                                        //   required={"true"}
                                        //   label="Country"
                                        placeholder={"Select a country code"}
                                        options={countryOptions}
                                        value={countryOptions.find(
                                            (option) => option?._id === (formData?.country?._id || formData?.country)
                                        )}
                                        onChange={(selectedOption) => {

                                            handleSelectChange("country", selectedOption);
                                        }}
                                        handleClick = {()=> dispatch(getCountryDropdown({}))}
                                    />
                                </div>
                                <div class="col-md-4 col-sm-6 col-12 ">

                                    <TextInputField
                                        label={"Number"}
                                        required={"true"}
                                        value={formData?.phone}
                                        name={"phone"}
                                        type={"Number"}
                                        minlength={"6"}
                                        maxlength={"30"}
                                        onChange={handleChange}
                                        placeholder={"Enter your number"}

                                    />
                                </div>
                                <div class="col-md-4 col-sm-6 col-12  ">
                                    <SingleDatePickerBasic startDate={formData?.dob} setStartDate={handlerDate} name={"dob"} text={"Date of Birth"} disabled={viewstaff?._id ? true : false} />
                                </div>
                                <div class="col-md-4 col-sm-6 col-12  ">

                                    <div className="fv-row mb-7">
                                        <label class="fs-6 fw-semibold form-label mt-3">
                                            <span className="required">Select Sign in type</span>

                                        </label>
                                        <div className=' mt-4'>
                                            {signInValues?.map((ele) => {
                                                return (

                                                    <RadioWithoutIcon
                                                        label={ele?.label}
                                                        name={"sign_in_enabled"}
                                                        onChange={() => handlerRadio("sign_in_enabled", ele?.value)}
                                                        value={formData?.sign_in_enabled}
                                                        checked={ele?.value === formData?.sign_in_enabled}
                                                    />
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5 col-sm-6 col-12 ">

                                    <div className="fv-row mb-7">
                                        <label class="fs-6 fw-semibold form-label mt-3">
                                            <span className="required">Select employee type</span>

                                        </label>
                                        <div className='d-flex gap-6 mt-4'>
                                            {employeeTypes?.map((ele) => {
                                                return (

                                                    <RadioWithoutIcon
                                                        label={ele?.label}
                                                        name={"emp_type"}
                                                        onChange={() => handlerRadio("emp_type", ele?.value)}
                                                        value={formData?.emp_type}
                                                        checked={ele?.value === formData?.emp_type}
                                                    />
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 col-sm-6 col-12 mt-2">
                                    <UploadSingleImage
                                        text={"Upload Image"}
                                        name="uploadImage"
                                        file={formData?.image}
                                        setFile={(file) =>
                                            setFormData((prev) => {
                                                return {
                                                    ...prev,
                                                    image: file,
                                                };
                                            })
                                        }
                                    />
                                </div>

                            </div>




                            <div class="text-end">
                                <button type="reset" id="kt_modal_new_ticket_cancel" class="btn btn-light me-3" onClick={handleCancel}>Cancel</button>
                                <ButtonWithLoader isLoading={isSubmitting} handlerSubmit={handleSubmit} text={"Submit"} />
                            </div>

                        </form>

                    </div>
                </div></div>
            {/* </Content> */}
        </>
    )
}
export default CreateStaff
