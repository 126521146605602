import { createSlice } from "@reduxjs/toolkit";
import { getPlayersFetchById, getPlayersList, savePlayer } from "./players.action";







export const playerSlice = createSlice({
  name: "playersState",
  initialState: {
 
    saveLoading:false,
    save:"",
    players:[],
    playerLoad:false,
    list:[],
    loading:false
  },
  reducers: {
    addAuctionSettings: (state,action)=>{
      state.list.push(action.payload.settings)
    },
    removeAuctionSettings: (state,action)=>{
      state.list = action.payload.settings
    }
  },
  extraReducers: (builder) => {

    builder.addCase(getPlayersList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPlayersList.fulfilled, (state, action) => {
      state.loading = false;
      state.players = ""
      state.save=""
      state.list = action.payload?.data;

    });
    builder.addCase(getPlayersList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error?.message;
    });
    builder.addCase(getPlayersFetchById.pending, (state) => {
      state.playerLoad = true;
    });
    builder.addCase(getPlayersFetchById.fulfilled, (state, action) => {
      state.playerLoad = false;
      state.list = action.payload?.data;
      state.players = action.payload?.message;
     

    });
    builder.addCase(getPlayersFetchById.rejected, (state, action) => {
      state.playerLoad = false;
      state.error = action.payload.error?.message;
    });
   
    builder.addCase(savePlayer.pending, (state) => {
      state.saveLoading = true;
    });
    builder.addCase(savePlayer.fulfilled, (state, action) => {
      state.saveLoading = false;
      
      state.save = action.payload;
    });
    builder.addCase(savePlayer.rejected, (state, action) => {
      state.saveLoading = false;
      state.error = action.payload;
    });
  },
});

export const { addAuctionSettings,removeAuctionSettings } = playerSlice.actions;
export default playerSlice.reducer;
