import React, { useEffect, useRef, useState } from "react";
import { formValidation } from "@form-validation/bundle/popular";
import { Bootstrap5 } from "@form-validation/plugin-bootstrap5";
import { SubmitButton } from "@form-validation/plugin-submit-button";
import { Trigger } from "@form-validation/plugin-trigger";

import {
    errorMessage,
    successMessage,
    warningMessage,
    successResponse,
} from "../../Common/SwalToast";
import SingleDatePickerBasic from "../../Common/SingleDatePickerBasic";
import TextInputField from "../../Common/TextInputField";
import RadioWithoutIcon from "../../Common/RadioWithoutIcon";
import ButtonWithLoader from "../../Common/ButtonWithLoader";
import UploadSingleImage from "../../Common/UploadSingleImage";
import SelectDropdownWithSearch from "../../Common/SelectDropdownWithSearch";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
    getUnixTimeStamp,
    getUnixToDatePickerFormat,
} from "../../utilities/dateUtils";
import { getCountryDropdown } from "../Master/Country/redux/country.action";
import { addModule, getModuleById, updateModule } from "./redux/module.action";
import Checkbox from "../../Common/Checkbox";

// Dropdown options
const permissionTypes = [
    { value: "list", label: "List" },
    { value: "get", label: "Get" },
    { value: "create", label: "Create" },
    { value: "delete", label: "Delete" },
    { value: "update", label: "Update" },
    { value: "statusUpdate", label: "Status Update" },
    { value: "export", label: "Export" },
];
const CreateModule = ({ data }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { dropDownList } = useSelector((state) => state.countries);
    const { viewModule } = useSelector((state) => state.module);
    const [formData, setFormData] = useState({});
    // const [pbsm, setPermisstionBySubmodule] = useState({});

    const [selectedSubmodule, setSelectedSubmodule] = useState(""); // State to store selected submodule
    const [permissions, setPermissions] = useState(null); // State to store current permissions for the submodule
    const [permissionObject, setPermissionObject] = useState({}); // State to store current permissions for the submodule

    const formRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false); // State to handle loading indication
    const [validator, setValidator] = useState(null); // Store form validation instance
    const [moduleUserID, setmoduleUserID] = useState(null);
    const [countryOptions, setCountryOptions] = useState([]);
    useEffect(() => {
        dispatch(getCountryDropdown({}));
        let moduleID = data?._id || null;
        if (moduleID?.toString()) {
            setmoduleUserID(moduleID?.toString());
            dispatch(getModuleById({ id: moduleID?.toString() }));
        } else {
            setmoduleUserID(null);
        }
    }, []);
    //Handle Form Module
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]:
                type === "checkbox"
                    ? checked
                        ? [...formData.notifications, value]
                        : formData.notifications.filter((n) => n !== value)
                    : value,
        });
    };
    const handlerDate = (date) => {
        setFormData((prev) => {
            return {
                ...prev,
                dob: date,
            };
        });
    };
    const handlerRadio = (name, value) => {
        setFormData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    const handleSelectChange = (name, value) => {
        setFormData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
        // setGame(value)
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        if (validator) {
            validator.validate().then(function (status) {
                let text = "";
                if (status === "Valid") {
                    formData.permission = permissionObject;
                    if (viewModule && moduleUserID)
                        dispatch(updateModule({ data: formData, id: moduleUserID }));
                    else dispatch(addModule({ data: formData }));
                    setIsSubmitting(false);
                } else {
                    // Show error message
                    text =
                        "Sorry, looks like there are some errors detected, please try again.";
                    errorMessage(text);
                }
            });
        }
    };
    const setData = () => {
        setFormData({
            subject: "",
            user: "",
            due_date: "",
            description: "",
            notifications: [],
        });
    };
    const handleCancel = (e) => {
        e.preventDefault();
        let text = "Are you sure you would like to cancel????";
        warningMessage(text, setData);
    };
    //Handle Form Module
    useEffect(() => {
        // console.log("viewModule:::::", viewModule)
        const form = formRef.current;
        if (!form) return;
        const validationFields = {
            fields: {
                name: {
                    validators: {
                        notEmpty: {
                            message: "Name is required",
                        },
                        stringLength: {
                            verbose: false,
                            min: 3,
                            max: 30,
                            message:
                                "The name must be more than 3 and less than 30 characters long",
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                bootstrap5: new Bootstrap5(),
                submitButton: new SubmitButton(),
            },
        };
        const fv = formValidation(form, validationFields);
        setValidator(fv);
        setFormData({
            name: viewModule?.name,
            permission: viewModule?.permission,
        });
        // here in form.permission  it will store previous values
        setPermissions(viewModule?.permission);
    }, [viewModule]);

    useEffect(() => {
        if (permissions && Object?.keys(permissions)?.length > 0) {
            setPermissionObject(permissions);
        }
    }, [permissions]);
    // When user selects or types a submodule
    const handleSubmoduleChange = (e, index, key) => {
        let tempPO = {};
        Object?.keys(permissionObject).forEach((k, i) => {
            if (k === key) {
                tempPO[e.target.value] = permissionObject[key];
            } else {
                tempPO[k] = permissionObject[k];
            }
        });
        // console.log("tempPO", tempPO)
        setPermissionObject(tempPO);
    };
    // When user toggles a checkbox
    const handleCheckboxChange = (e, pt, key) => {
        setPermissionObject((prevPermissions) => ({
            ...prevPermissions,
            [key]: {
                ...prevPermissions[key],
                [pt]: e.target.checked, // Update the specific permission type
            },
        }));
    };
    // When user adds a new submodule
    const handlerAddSubModule = () => {
        const newSubmoduleName = `SubModule_Name${Object.keys(permissionObject).length}`;
        const newSubmodule = {
            list: false,
            get: false,
            create: false,
            update: false,
            statusUpdate: false,
            delete: false,
            export:false
        };

        setPermissionObject((prevPermissions) => ({
            ...prevPermissions,
            [newSubmoduleName]: newSubmodule,
        }));
    };
    // When user removes a submodule
    const handlerRemoveSubModule = (key) => {
        setPermissionObject((prevPermissions) => {
            const { [key]: _, ...remainingPermissions } = prevPermissions; // Destructure to remove the key
            return remainingPermissions;
        });
    };

    return (
        <>
            <div class="card card-custom">
                <div class="card-body">
                    <div className="col-xl-12">
                        <form
                            id="kt_modal_new_ticket_form"
                            class="form"
                            onSubmit={handleSubmit}
                            ref={formRef}
                        >
                            <div class="row g-9 mb-8">
                                <div class="col-md-4 col-sm-6 col-12 ">
                                    <TextInputField
                                        label={"Name"}
                                        required={"true"}
                                        value={formData?.name}
                                        name={"name"}
                                        type={"text"}
                                        minlength={"6"}
                                        maxlength={"30"}
                                        disabled={viewModule?._id ? true : false}
                                        onChange={handleChange}
                                        placeholder={"Enter your name"}
                                    />
                                </div>
                            </div>
                            <div class="row g-9 mb-8">
                                <div class="col-md-5 col-sm-6 col-12 ">
                                    <div style={{ width: "900px" }}>
                                        <label class="fs-6 fw-semibold form-label mt-3">
                                            <span className="required">
                                                Add SubModules and Permissions
                                            </span>
                                        </label>
                                        <div className="">
                                            {permissionObject &&
                                                Object.keys(permissionObject)?.length > 0 &&
                                                Object.keys(permissionObject).map((key, index) => (
                                                    <>
                                                        {" "}
                                                        <div className="d-flex justify-content-between aline-items-center w-800"
                                                        // style={{width:"400px"}}
                                                        >
                                                            <div className="d-flex justify-content-start aline-items-center">
                                                                <div className="d-flex justify-content-center aline-items-center"><span>{`${index + 1}.`}</span></div>
                                                                <TextInputField
                                                                    // label={`${index + 1}.`}
                                                                    value={key}
                                                                    name={`submodule${index + 1}`}
                                                                    type={"text"}
                                                                    minlength={"3"}
                                                                    maxlength={"30"}
                                                                    onChange={(e) => {
                                                                        handleSubmoduleChange(e, index, key);
                                                                    }}
                                                                    placeholder={"Enter your submodule name"}
                                                                /></div>

                                                            <div className={`btn  mw-150px hover-scale 
                                   btn-danger d-flex aline-items-center mb-0 mt-11`}
                                                                onClick={(e) => handlerRemoveSubModule(key, index)}
                                                                style={{ height: "37px" }}
                                                            >
                                                                <span className="w-80px d-flex justify-content-between align-items-center">
                                                                    <i className="ki-outline ki-minus-square  fs-1 me-1"></i>
                                                                    Remove
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="py-4 px-2 d-flex mt-5 w-500 mb-11"
                                                            style={{ justifyContent: "space-evenly" }}
                                                        >
                                                            {permissionTypes &&
                                                                permissionTypes?.length > 0 &&
                                                                permissionTypes?.map((pt, idx) => {
                                                                    return (
                                                                        <div className="mx-2">
                                                                            <Checkbox
                                                                                key={idx}
                                                                                label={pt.label}
                                                                                value={pt.value}
                                                                                name={`${pt.value}`}
                                                                                onChange={(e) =>
                                                                                    handleCheckboxChange(e, pt.value, key)
                                                                                }
                                                                                checked={
                                                                                    permissionObject?.[key]?.[
                                                                                    `${pt.value}`
                                                                                    ]
                                                                                }
                                                                            />
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </>
                                                ))}
                                            <div>
                                                {selectedSubmodule &&
                                                    permissionObject[selectedSubmodule] && (
                                                        <div>
                                                            <h3>Permissions for {selectedSubmodule}:</h3>
                                                            {permissionTypes.map((permission) => (
                                                                <div key={permission.value}>
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                permissions[permission.value] || false
                                                                            } // Pre-check based on permissionObject
                                                                            onChange={() =>
                                                                                handleCheckboxChange(permission.value)
                                                                            } // Update state when toggled
                                                                        />
                                                                        {permission.label}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-end">
                                <div
                                    className={`btn py-4 px-4 hover-scale btn-light me-5`}
                                    onClick={(e) => handlerAddSubModule()}
                                >
                                    <span className="w-65px d-flex justify-content-between align-items-center">
                                        {" "}
                                        <i className="ki-outline ki-plus-square fs-1 me-1"></i>
                                        Add
                                    </span>
                                </div>
                                {/* <button
                  type="reset"
                  id="kt_modal_new_ticket_cancel"
                  class="btn btn-light me-3"
                  onClick={handleCancel}
                >
                  Cancel
                </button> */}
                                <ButtonWithLoader
                                    isLoading={isSubmitting}
                                    handlerSubmit={handleSubmit}
                                    text={"Submit"}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* </Content> */}
        </>
    );
};
export default CreateModule;
