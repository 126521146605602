import { createAsyncThunk } from "@reduxjs/toolkit"
import { getDabbaList, getList, save, saveDabba } from "./auctionSettings.api"


export const fetchList = createAsyncThunk('series/auctionSettingsList', async ({id,companyCode},{rejectWithValue}) => {

  let headers = {
    "company-code":companyCode
   }
    //   let body = {
    //     pagination: {
    //         pageNo: pageNo || 1,
    //         recordPerPage: recordPerPage || 100,
    //         sortBy: "created_at",
    //         sortDirection: "asc"
    //     },
    //     search: search // optional
    // }
      try{

          const response = await getList(headers,id)
        
          if(response.success)
          return {
            ...response, 
          
          }
      }
      catch(error){
        return rejectWithValue(error)
      }
})
export const fetchDabbaList = createAsyncThunk('series/dabbaSettingsList', async ({id,companyCode},{rejectWithValue}) => {

  let headers = {
    "company-code":companyCode
   }
    //   let body = {
    //     pagination: {
    //         pageNo: pageNo || 1,
    //         recordPerPage: recordPerPage || 100,
    //         sortBy: "created_at",
    //         sortDirection: "asc"
    //     },
    //     search: search // optional
    // }
      try{

          const response = await getDabbaList(headers,id)
        
          if(response.success)
          return {
            ...response, 
          
          }
      }
      catch(error){
        return rejectWithValue(error)
      }
})

export const saveAuctionSettings = createAsyncThunk('series/saveAuctionSettings', async ({id,companyCode,data},{rejectWithValue}) => {
  console.log("cratespor", id)
    let headers = {
       "company-code":companyCode
      }
      try{

          const response = await save(headers,id,data)
          console.log("responseseries:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        return rejectWithValue(error)
      }
})
export const saveDabbaSettings = createAsyncThunk('series/saveDabbaSettings', async ({id,companyCode,data},{rejectWithValue}) => {
  console.log("cratespor", id)
    let headers = {
       "company-code":companyCode
      }
      try{

          const response = await saveDabba(headers,id,data)
          console.log("responseseries:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        return rejectWithValue(error)
      }
})
