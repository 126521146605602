
import SwitchBoxWithoutLabel from "../../../Common/SwitchBoxWithoutLabel"
import CountryImageCell from "./CountryImageCell"
import LanguageCell from "./LanguageCell"


const countryColumn = [
//   {
//     header:"",
//     size:50,
//     id:"expand",
//     cell:({row}) => <ToggleIconTable row={row} />
//   },
//   {
//     header:(props) => <SelectionHeader props={props} />,
//     size:50,
//     id:"selction",
//     cell:(props) => <SelectionCell props={props} />
//   },
    {
      accessorKey:'name',
      header:'Name',
      
      cell: (props) => <CountryImageCell props={props} />
  
    },
    // {
    //   accessorKey:'image',
    //   header:'',
    //   cell: (props) => <CountryImageCell props={props} />
  
    // },
    {
      accessorKey:'country_alpha2',
      header:'Alpha2',
      
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'country_alpha3',
      header:'Alpha3',
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'country_numeric',
      header:'Alpha Numeric',
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'dialingCode',
      header:'Dialing Code',
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    // {
    //   accessorKey:'createAt',
    //   header:'created',
    //   cell: (props) => {
    //   return (<p>{props.getValue()}</p>)}
  
    // },
  

    {
      accessorKey:'is_active',
      header:'is active',
      cell: (props) => <SwitchBoxWithoutLabel checked={props.getValue()} />
  
    },
    {
      accessorKey:'_id',
      header:'language',
      cell: (props) => <LanguageCell id={props.getValue()} />
  
    }
    
  ]
  
  export {countryColumn}
  