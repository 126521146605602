import axios from "axios";
import url from "./url";

var header = localStorage.getItem('token') ? { 'x-access-token': localStorage.getItem('token') } : {"user-type": "appUser"};

let instance = axios.create({
    baseURL: url.base_api,
    timeout: 60000,
    headers: header
});

instance.interceptors.request.use(
    function (config) {
        if (!!config.data) {
            for (let key of Object.keys(config?.data)) {
                if (config.data[key] === "") config.data[key] = null;
            }
        }
        return config;
    },
    function (error) {
        return error; //Promise.reject(error);
    }
);

instance.refresh=()=>{
    console.log("instance.refresh CALL::")
    let header = localStorage.getItem('token') ? { 'x-access-token': localStorage.getItem('token') } : { "user-type": "appUser" };
    let tempInstance=axios.create({
        baseURL: url.base_api,
        timeout: 60000,
        headers: header
    });
    instance = tempInstance
};


// Add a response interceptor
// instance.interceptors.response.use(
//     function (response) {
//         // console.log("response:axios", response)
//         // Any status code that lie within the range of 2xx cause this function to trigger
//         // Do something with response data
//         if (response.status === 200) {
//             return response.data;
//         }
//         return response;
//     },
//     function (error) {
//         console.log("response:error",error?.status, error?.response?.data)
//         // Any status codes that falls outside the range of 4xx to 5xx cause this function to trigger
//         // Do something with response error
//         if (error?.status === 403) {
//             localStorage.clear();
//         }
//         if (error?.status === 400 || 404) {
//             throw error.response.data.message
//         }
//         return error;
//     }
// );
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.status === 200) {
        return response.data;
    }
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.statusText === "Unauthorized") {
        localStorage.clear();
        //  window.location.href = '/sign-in';
    }
    throw error?.response?.data;
});
export default instance;
