import React, { useEffect, useRef, useState } from "react";
import Toolbar from "./Toolbar";
import Content from "./Content";
import Swal from "sweetalert2";
import { formValidation } from "@form-validation/bundle/popular";
import { Bootstrap5 } from "@form-validation/plugin-bootstrap5";
import { Trigger } from "@form-validation/plugin-trigger";
import SelectDropDown from "./SelectDropDown";
import Dropzone from "react-dropzone";
import TextInputField from "./TextInputField";
import { errorMessage, successMessage, warningMessage } from "./SwalToast";
import SingleDatePickerBasic from "./SingleDatePickerBasic";
import { useDispatch, useSelector } from "react-redux";
import { submitReducer } from "./FormSlice/formSlice";
import SelectDropdownWithSearch from "./SelectDropdownWithSearch";

const FormValidation1 = () => {
  const [formData, setFormData] = useState({
    subject: "",
    user: "",
    due_date: "",
    description: "",
    product: "",
    notifications: [],
  });
  const dispatch = useDispatch();
  const { formSubmit } = useSelector((state) => state);
  const [attachments, setAttachments] = useState([]);
  const formRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle loading indication
  const [validator, setValidator] = useState(null); // Store form validation instance
  // Dropdown options
  const userOptions = [
    { value: "user1", label: "User 1" },
    { value: "user2", label: "User 2" },
  ];
  const productOptions = [
    { value: "React App", label: "React App" },
    { value: "React App", label: "React App" },
    { value: "Html App", label: "Html App" },
  ];
  const countryOptions = [
    { value: 'Nigeria', label: 'Nigeria', flag: 'media/flags/afghanistan.svg' },
    { value: 'United States', label: 'United States', flag: 'media/flags/united-states.svg' },
    { value: 'Israel', label: 'Israel', flag: 'media/flags/israel.svg' },
    { value: 'Romania', label: 'Romania', flag: 'media/flags/romania.svg' },
    { value: 'Germany', label: 'Germany', flag: 'media/flags/germany.svg' },
    { value: 'Bahamas', label: 'Bahamas', flag: 'media/flags/bahamas.svg' },
    { value: 'China', label: 'China', flag: 'media/flags/china.svg' },
    { value: 'Somalia', label: 'Somalia', flag: 'media/flags/somalia.svg' }
]
  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // console.log(name, value, type, checked, type === 'checkbox' ? (checked ? [...formData.notifications, value] : formData.notifications.filter(n => n !== value)) : value)
    setFormData({
      ...formData,
      [name]:
        type === "checkbox"
          ? checked
            ? [...formData.notifications, value]
            : formData.notifications.filter((n) => n !== value)
          : value,
    });
  };
  const handlerDate = (date) => {
    setFormData((prev) => {
      return {
        ...prev,
        due_date: date,
      };
    });
  };
  const submitHandler = () => {
    console.log(formData);
    console.log(formSubmit);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // setIsSubmitting(true)
    // console.log(formData)
    if (validator) {
      validator.validate().then(function (status) {
        if (status === "Valid") {
          setIsSubmitting(true);

          // Simulate API call delay
          dispatch(submitReducer(formData));
          setTimeout(() => {
            setIsSubmitting(false);
            let text = "Form has been successfully submitted!";
            successMessage(text, submitHandler);

            // Submit form data here or trigger actual form submission
            // form.submit(); (if needed)
          }, 1000);
        } else {
          // Show error message
          let text =
            "Sorry, looks like there are some errors detected, please try again.";
          errorMessage(text);
        }
      });
    }
  };
  const setData = () => {
    setFormData({
      subject: "",
      user: "",
      due_date: "",
      description: "",
      notifications: [],
    });
  };
  // Handle form cancel action
  const handleCancel = (e) => {
    e.preventDefault();
    let text = "Are you sure you would like to cancel????";

    warningMessage(text, setData);
  };
  const handleSelectChange = (name, value) => {
    console.log(name, value);
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
    setAttachments([...attachments, ...acceptedFiles]);
  };
  useEffect(() => {
    const form = formRef.current;
    if (!form) return;

    const validationFields = {
      fields: {
        subject: {
          validators: {
            notEmpty: {
              message: "Ticket subject is required",
            },
            stringLength: {
              verbose: false,
              min: 6,
              max: 30,
              message:
                "The name must be more than 6 and less than 30 characters long",
            },
            regexp: {
              regexp: /^[a-zA-Z0-9_]+$/,
              message:
                "The name can only consist of alphabetical, number and underscore",
            },
          },
        },
        user: {
          validators: {
            notEmpty: {
              message: "Ticket user is required",
            },
          },
        },
        product: {
          validators: {
            notEmpty: {
              message: "Product user is required",
            },
          },
        },
        due_date: {
          validators: {
            notEmpty: {
              message: "Ticket due date is required",
            },
          },
        },
        description: {
          validators: {
            notEmpty: {
              message: "Target description is required",
            },
          },
        },
        notifications: {
          validators: {
            notEmpty: {
              message: "Please select at least one notifications method",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap5({
          rowSelector: ".fv-row",
          eleInvalidclassName: "",
          eleValidclassName: "",
        }),
      },
    };

    const fv = formValidation(form, validationFields);

    setValidator(fv);
  }, []);

  const options = ["Apple", "Banana", "Cherry", "Date", "Elderberry"];
  const handleSelect = (option) => {
    alert(`Selected: ${option}`);
  };
  return (
    <>
      <Toolbar />
      <Content>
        <div className="col-xl-12">
          <div className="card card-flush h-lg-100" id="kt_contacts_main">
            <div className="card-header pt-7" id="kt_chat_contacts_header">
              <div className="card-title">
                <i className="ki-outline ki-badge fs-1 me-2"></i>
                <h2>Add New Contact</h2>
              </div>
            </div>

            <div className="card-body pt-5">
              <form
                id="kt_modal_new_ticket_form"
                class="form"
                onSubmit={handleSubmit}
                ref={formRef}
              >
                <div class="mb-13 text-center">
                  <h1 class="mb-3">Create Ticket</h1>

                  <div class="text-gray-500 fw-semibold fs-5">
                    If you need more info, please check
                    <a href="" class="fw-bold link-primary">
                      Support Guidelines
                    </a>
                    .
                  </div>
                </div>

                <SelectDropdownWithSearch
                  options={countryOptions}
                  onSelect={handleSelect}
                />
               { console.log("country",countryOptions?.find(
                    (option) => option?.value === formData.country
                  ))}
                <SelectDropdownWithSearch
                  name="country"
                  //   required={"true"}
                  //   label="Country"
                  placeholder={"Select a country"}
                  options={countryOptions}
                  value={countryOptions.find(
                    (option) => option.value === formData.country
                  )}
                  onChange={(selectedOption) =>{ 
                    console.log(selectedOption)
                    handleSelectChange("country", selectedOption.value)

                  }
                  }
                />

                <TextInputField
                  label={"subject"}
                  required={"true"}
                  value={formData?.subject}
                  name={"subject"}
                  type={"text"}
                  minlength={"6"}
                  maxlength={"30"}
                  onChange={handleChange}
                  placeholder={"Enter your ticket subject"}
                />

                <div class="row g-9 mb-8">
                  <div class="col-md-6 ">
                    <SelectDropDown
                      name="product"
                      label="Product"
                      placeholder={"Select a product"}
                      options={productOptions}
                      value={productOptions.find(
                        (option) => option.value === formData.user
                      )}
                      onChange={(selectedOption) =>
                        handleSelectChange("product", selectedOption.value)
                      }
                    />
                  </div>

                  <div class="col-md-6 ">
                    <SelectDropDown
                      name="user"
                      label={"User"}
                      placeholder={"Select a user"}
                      options={userOptions}
                      value={userOptions.find(
                        (option) => option.value === formData.user
                      )}
                      onChange={(selectedOption) =>
                        handleSelectChange("user", selectedOption.value)
                      }
                    />
                  </div>
                </div>

                <div class="row g-9 mb-8">
                  <SingleDatePickerBasic
                    startDate={formData?.due_date}
                    setStartDate={handlerDate}
                  />
                </div>

                <div class="d-flex flex-column mb-8 fv-row">
                  <label class="fs-6 fw-semibold mb-2">Description</label>
                  <textarea
                    class="form-control form-control-solid"
                    rows="4"
                    name="description"
                    placeholder="Type your ticket description"
                    value={formData?.description}
                    onChange={handleChange}
                  ></textarea>
                </div>

                {/* <div class="fv-row mb-8">
                                    <label class="fs-6 fw-semibold mb-2">Attachments</label>

                                    <div class="dropzone" id="kt_modal_create_ticket_attachments">

                                        <div class="dz-message needsclick align-items-center">

                                            <i class="ki-outline ki-file-up fs-3hx text-primary"></i>


                                            <div class="ms-4">
                                                <h3 class="fs-5 fw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>
                                                <span class="fw-semibold fs-7 text-gray-500">Upload up to 10 files</span>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="fv-row mb-8">
                                    <label class="fs-6 fw-semibold mb-2">Attachments</label>
                                    <Dropzone onDrop={handleDrop} multiple maxFiles={10} maxSize={10 * 1024 * 1024}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <div class="dz-message needsclick align-items-center">

                                                    <i class="ki-outline ki-file-up fs-3hx text-primary"></i>


                                                    <div class="ms-4">
                                                        <h3 class="fs-5 fw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>
                                                        <span class="fw-semibold fs-7 text-gray-500">Upload up to 10 files</span>
                                                    </div>

                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    {attachments.length > 0 && (
                                        <ul>
                                            {attachments.map((file, index) => (
                                                <li key={index}>{file.name}
                                                    <div class="dz-preview dz-processing dz-image-preview dz-error dz-complete">
                                                        <div class="dz-image">

                                                        </div> <a class="dz-remove" href="javascript:undefined;" data-dz-remove="">Remove file</a>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>

                                    )}
                                </div> */}
                <div class="mb-15 fv-row">
                  <div class="d-flex flex-stack">
                    <div class="fw-semibold me-5">
                      <label class="fs-6">Notifications</label>
                      <div class="fs-7 text-gray-500">
                        Allow Notifications by Phone or Email
                      </div>
                    </div>

                    <div class="d-flex align-items-center">
                      <label class="form-check form-check-custom form-check-solid me-10">
                        <input
                          class="form-check-input h-20px w-20px"
                          type="checkbox"
                          name="notifications"
                          value="email"
                          checked={formData?.notifications.includes("email")}
                          onChange={handleChange}
                        />
                        <span class="form-check-label fw-semibold">Email</span>
                      </label>

                      <label class="form-check form-check-custom form-check-solid">
                        <input
                          class="form-check-input h-20px w-20px"
                          type="checkbox"
                          name="notifications"
                          value="phone"
                          checked={formData.notifications.includes("phone")}
                          onChange={handleChange}
                        />
                        <span class="form-check-label fw-semibold">Phone</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                  <button
                    type="reset"
                    id="kt_modal_new_ticket_cancel"
                    class="btn btn-light me-3"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    id="kt_modal_new_ticket_submit"
                    class="btn btn-primary"
                  >
                    {!isSubmitting ? (
                      <span class="indicator-label">Submit</span>
                    ) : (
                      <span class="indicator-progress">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ul>
          <li>{formSubmit?.subject}</li>
          <li>{formSubmit?.description}</li>
          <li>{formSubmit?.due_date}</li>
          <li>{formSubmit?.notifications}</li>
          <li>{formSubmit?.user}</li>
        </ul>
      </Content>
    </>
  );
};
export default FormValidation1;
