import React, { useEffect, useState } from "react";
import Content from "../Common/Content";
import SeriesTable from "./FantasyCommon/seriesTable";
import axios from "axios";
import MatchTable from "./FantasyCommon/matchTable";
import DataToStringDiv from "./FantasyCommon/DataToStringDiv";
import TeamTab from "./FantasyCommon/teamTab";
import StatesTab from "./FantasyCommon/statesTab";
import MatchOverViewTab from "./FantasyCommon/matchOverViewTab";

const FantasyMatches = () => {
  // useState DATA 
  const [matches, setMatches] = useState([]);
  const [seriesDetails, setSeriesDetails] = useState(null);
  const [seriesStatesData, setSeriesStatesData] = useState(null);
  const [teamDetails, setTeamDetails] = useState([]);
  const [seriesKey, setSeriesKey] = useState(null);
  const [name, setName] = useState(null);
  const [dataDiv, setshowDataDiv] = useState(true);
  const [activeTab, setactiveTab] = useState(1);
  const [associationKey, setassociationKey] = useState(null);
  const [navBarArr, setNavBarArr] = useState([{
    title:"Matches",
    value:1
  }, {
    title: "Teams",
      value: 2
    }, {
      title: "Stats",
      value: 3
    },
]);

  // useEffect Handlers
  useEffect(() => {
    let seriesKey = window.location.href.split("/")[window.location.href.split("/").length - 1];
    setSeriesKey(seriesKey)
    let selectAssociation = localStorage.getItem("associationItem")
    let selectSeries = localStorage.getItem("seriesItem")
    setName(`/ association: ${selectAssociation} / series : ${selectSeries} / matches`)
  }, []);

  useEffect(() => {
    if (seriesKey) {
      fetchMatchesList(seriesKey)
    }
  }, [seriesKey]);


  useEffect(() => {
    const fetchTeamDetails = async () => {
      if (seriesDetails) {
        // console.log("seriesDetails", seriesDetails.data.teams);
        let teamArry = seriesDetails.teams || null;
        // console.log("teamArry", teamArry);

        // Check if teamArry is not null and is an object
        if (teamArry && typeof teamArry === 'object') {
          // Get keys from the teamArry object and fetch team details
          const teamDetailData = await Promise.all(
            Object.keys(teamArry).map(async (teamKey) => {
              // console.log("teamkey>>", teamKey);
              const resdata = await getTeamDetails(seriesKey, teamKey);
              console.log("teamKey:", resdata);
              return resdata;
            })
          );

          // Update state with the fetched team details
          if (teamDetailData.length > 0) {
            setTeamDetails(teamDetailData);
          }
        }
      }
    };

    fetchTeamDetails();
  }, [seriesDetails]);


  useEffect(() => {
    console.log("teamDetails", teamDetails);
  }, [teamDetails]);

  // support functions
  const fetchMatchesList = async (seriesKey) => {
    try {
      if (seriesKey) {
        const seriesDetails = await getSeriesDetails(seriesKey);
        const res = await getMatchesList(seriesKey);
        const seriesStatesData = await getSeriesStats(seriesKey);

        if (res) {
          setMatches(res.data.matches)
        }
        else {
          console.log("DATA NOT FOUND !!")
        }
        if (seriesDetails) {
          console.log("seriesDetails", seriesDetails.tournament.association_key)
          setassociationKey(seriesDetails.tournament.association_key)
          setSeriesDetails(seriesDetails)
        }
        else {
          console.log(" setSeriesDetails DATA NOT FOUND !!")
        }
        if (seriesStatesData) {
          console.log("seriesStatesData", seriesStatesData)
          setSeriesStatesData(seriesStatesData)
        }
        else {
          console.log(" setSeriesDetails DATA NOT FOUND !!")
        }
      } else {
        console.log("associationKey NOT FOUND !!")
      }
    } catch (error) {
      console.error("Support Functions ERROR:", error);
    }
  };

  // API functions
  const getMatchesList = async (seriesKey) => {
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API_URL}v5/cricket/${process.env.REACT_APP_PROJECT_KEY}/tournament/${seriesKey}/featured-matches-2/`,
        
      };
      return axios.request(config)
        .then((response) => {
          console.log(response.data);
          return response.data
        })
        .catch((error) => {
          console.error("API CALL ERROR:", error);
        });
    } catch (error) {
      console.error("API CALL  Functions ERROR:", error);
    }
  };


  const getSeriesDetails = async (seriesKey) => {
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API_URL}v5/cricket/${process.env.REACT_APP_PROJECT_KEY}/tournament/${seriesKey}/`,
      };
      return axios.request(config)
        .then((response) => {
          // console.log(response.data);
          return response.data.data
        })
        .catch((error) => {
          console.error("API CALL ERROR:", error);
        });
    } catch (error) {
      console.error("API CALL  Functions ERROR:", error);
    }
  };

  const getTeamDetails = async (seriesKey, teamkey) => {
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API_URL}v5/cricket/${process.env.REACT_APP_PROJECT_KEY}/tournament/${seriesKey}/team/${teamkey}/`,
      };
      return axios.request(config)
        .then((response) => {
          // console.log("teamresdata",response.data);
          return response.data.data
        })
        .catch((error) => {
          console.error("API CALL ERROR:", error);
        });
    } catch (error) {
      console.error("API CALL  Functions ERROR:", error);
    }
  };

  const getSeriesStats = async (seriesKey) => {
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API_URL}v5/cricket/${process.env.REACT_APP_PROJECT_KEY}/tournament/${seriesKey}/stats/`,
      };
      return axios.request(config)
        .then((response) => {
          // console.log("teamresdata",response.data);
          return response.data.data
        })
        .catch((error) => {
          console.error("API CALL ERROR:", error);
        });
    } catch (error) {
      console.error("API CALL  Functions ERROR:", error);
    }
  };

  const changeTab = async (value) => {
    setactiveTab(value)
  };



  return (
    <Content>
      <div class="card card-custom">
        <div class="card-header flex-wrap  flex-column border-0 pt-6 pb-0">
          <div class="card-title d-flex flex-column justify-content-between">
            <h1 className="m-1">
              <p>{" "}<span><button className="p-2 m-1" style={{
                border: "0px ",
                borderRadius: "20%",
                backgroundColor: "#ffff",
              }}
                onClick={() => {
                  setshowDataDiv(!dataDiv);
                }}
              >+</button>
              </span>{" "}
                Fantasy Matches{" "}
              </p>
            </h1>
            <h5 className="p-2">
              <span>{name}</span>
            </h5>
          </div>
          <div class="card-toolbar">
           
            {dataDiv && (
              <DataToStringDiv
                heading={"Series Details"}
                api={'tournament  API'}
                children={seriesDetails}
              />
            )}
            {dataDiv && (
              <DataToStringDiv
                heading={"Team Details"}
                api={'tournament team API'}
                children={teamDetails}
              />
            )}
            {dataDiv && (
              <DataToStringDiv
                heading={"Matches Data"}
                api={'tournament featured matches API'}
                children={matches}
              />
            )}
            {dataDiv && (
              <DataToStringDiv
                heading={"Series Stats Data"}
                api={'tournament stats API'}
                children={seriesStatesData}
              />
            )}
          </div>
          <div></div>
        </div>
        <div class="card-body">
          <div class="mb-7">
            <div class="row align-items-center">
              <div class="">
                <ul style={{  display:'flex',flexFlow:"row",listStyleType:'none',margin: 0,padding:0,overflow: 'hidden',backgroundColor: '#333'}} >
                  {navBarArr && navBarArr.map((tab)=>{
                    return <li style={{ color: 'white', textAlign: 'center', padding: '14px 16px', textDecoration: 'none', backgroundColor: activeTab === tab?.value ? '#626fc1' : '#333' }} onClick={(e) => { changeTab(tab?.value)}}>{tab?.title}</li>
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div class="datatable datatable-bordered datatable-head-custom" id="kt_datatable">
            {activeTab===1 && <MatchTable matches={matches} key={matches} />}
            {activeTab === 2 && <TeamTab teamDetails={teamDetails} key={teamDetails} associationKey={associationKey} />}
            {activeTab === 3 && <StatesTab seriesStatesData={seriesStatesData} key={teamDetails} heading={"Series States Data"} />}
            {/* {activeTab === 4 && <MatchOverViewTab seriesDetails={seriesDetails} key={seriesDetails} />} */}
          </div>
        </div>
      </div>
    </Content>
  );
};

export default FantasyMatches;
