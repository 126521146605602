import React, { useState } from 'react'
import ButtonWithLoader from '../../Common/ButtonWithLoader'
import TextInputField from '../../Common/TextInputField'

const FilterForm = () => {
    const [formData, setFormData] = useState({
        name:'',
        email:'',
        number:''
      })
      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        // console.log(name, value, type, checked, type === 'checkbox' ? (checked ? [...formData.notifications, value] : formData.notifications.filter(n => n !== value)) : value)
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? (checked ? [...formData.notifications, value] : formData.notifications.filter(n => n !== value)) : value
        });
    };
    const handlerClick = ()=>{
        
        console.log(formData)
    }
  return (
    <div class="row g-9 mb-4">
             
    <div class="col-md-4 col-6 mt-2">
      <TextInputField
        label={"name"}
        value={formData?.name}
        name={"name"}
        type={"text"}
       
        onChange={handleChange}
        placeholder={"Enter name"}
      />
    </div>
    <div class="col-md-4 col-6 mt-2 ">
    <TextInputField
        label={"Email"}
        value={formData?.email}
        name={"email"}
        type={"text"}
      
        onChange={handleChange}
        placeholder={"Enter name"}
      />
    </div>
    <div class="col-md-4 col-6 mt-2">
    <TextInputField
        label={"Number"}
        value={formData?.number}
        name={"number"}
        type={"number"}
      
        onChange={handleChange}
        placeholder={"Enter name"}
      />
    </div>
    <div class="col-md-4 col-6 d-flex align-items-center pt-3">

  <ButtonWithLoader
    text={"Submit"}
    handlerSubmit={() => {
      handlerClick()
    }}
  />
    </div>
  </div>
  )
}

export default FilterForm