import React, { useEffect, useState } from "react";


import { useNavigate } from "react-router";
import Table from "../../../../../Common/TableWithTan/Table";
import ButtonWithLoader from "../../../../../Common/ButtonWithLoader";
import { useDispatch, useSelector } from "react-redux";

import { successMessage,errorMessage } from "../../../../../Common/SwalToast";
import Loader from "../../../../../MasterLayout/Loader";
import { auctionSettingsColumn } from "./AuctionSettingsColumn";

import { fetchList, saveAuctionSettings } from "./redux/auctionSettings.action";
import { removeAuctionSettings } from "./redux/auctionSettings.slice";
import TextInputField from "../../../../../Common/TextInputField";
import SelectDropdownWithSearch from "../../../../../Common/SelectDropdownWithSearch";
import { getCurrencyById } from "../../../../Company/redux/company.action";

const AuctionSettings = ({id}) => {
  const [columnVisibility, setColumnVisibility] = useState();
  const [formData, setFormData] = useState()
  const {companyCode,viewSeries} = useSelector(state => state.series)
  const {companyCurrencies,compCurrLoad} = useSelector(state=> state.companies)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {fetchLoading, list, saveLoading,save,error} = useSelector(state => state.auctionSettings)
  
  const handlerSave = ()=>{
   
    let l = list.data.reduce((acc,item) => {
      acc.push({
        total_players: Number(item?.total_players),
            players_to_be_purchased: Number(item?.players_to_be_purchased),
            total_participants: Number(item?.total_participants)
      })
      return acc
    },[])
    console.log(l)
    dispatch(saveAuctionSettings({id:id,companyCode:companyCode,data:{settings: l}}))
  }
  const handlerCurrencySave = ()=>{
    console.log(formData)
    dispatch(saveAuctionSettings({id:id, companyCode:companyCode, data: {settings: formData}}))

  }

const handleSelectChange = (name, value) => {
  setFormData(prev => {
    return {
      ...prev,
      [name]: value
    }
  })
  // setGame(value)
};

  useEffect(() => {
    // setCompanies(["Gamerji", "D11", "Vodafone", "Mobily"]);
    if(companyCode !== 'SAAS'){
      setColumnVisibility({
        saved: false,
       
       
      })
    }
    dispatch(fetchList({id:id,companyCode:companyCode}))

   
  }, []);
  
  useEffect(()=>{
    if(viewSeries && companyCurrencies.length <= 0)
      dispatch(getCurrencyById({id:viewSeries?.companies?.[0]?.company_id}))
  },[viewSeries])
  // const updateStatus = (rowIndex) => {
   
  //   dispatch(saveAuctionSettings({id:id,companyCode:'SAAS',data:{matches:[matchList[rowIndex]?.key]}}))
  //   // navigate('/series/details/'+tableData[rowIndex]?.name)
  // }
  const updateStatus = (rowIndex)=>{
      let l = list.data.filter((ele,index) => index != rowIndex)
      console.log(l)
      dispatch(removeAuctionSettings({settings: l}))
  }
  useEffect(()=>{
      if(save?.message){
        successMessage(save?.message)
      }
  },[save])
  useEffect(()=>{
    console.log("error", error)
      if(error?.message){
        errorMessage(error?.message)
      }
  },[error])
console.log("companyCurrencies>>>>>>>>",companyCurrencies)
  return (
    <>
    <div className="card">
      <div className="card-header card-header-stretch">
        <div className="card-title d-flex align-items-center">
          <h3 className="fw-bold m-0 text-gray-800">Settings</h3>
        </div>
       
      </div>

        <div class="card-body">

              {
                fetchLoading ? <Loader /> :
            <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
              <Table
                column={auctionSettingsColumn}
                tableData={list?.data}
                isAuctionSettings={companyCode == 'SAAS' ? true: false}
                updateStatus = {updateStatus}
                columnVisibility={columnVisibility}
                // columnFilters={columnFilters}
                // setColumnFilters={setColumnFilters}
              />
          
            </div>
              }
            {
            companyCode === 'SAAS' &&
          <div>
            <ButtonWithLoader text="Save" handlerSubmit={handlerSave} />
          </div>
          }
          </div>
         
    </div>
    {
       companyCode !== 'SAAS' &&  
    <div className="card">
    <div className="card-header card-header-stretch">
        <div className="card-title d-flex align-items-center">
          <h3 className="fw-bold m-0 text-gray-800">Private Creation Fee</h3>
        </div>
      </div>
      {
        compCurrLoad ? <Loader /> : 

      <div className="card-body">
          <div className="row">
       
                <label className='form-label mt-3'><span >{"Auction Fee Online"}</span></label>
            
            <div className="col-md-6 col-12">
              <TextInputField
              type={"number"}
             label=""
             value={formData?.online_creation_amount || list.fees?.online_creation_amount}
             name="online_creation_amount"
             placeholder={"Enter Fee"}
             onChange = {(e)=>handleSelectChange("online_creation_amount", Number(e.target.value))}
              />
            </div>
            <div className="col-md-6 col-12">
              <SelectDropdownWithSearch
              type={"number"}
                placeholder="Select Currency"
                options={companyCurrencies}
                value={companyCurrencies.find(
                  (option) => option._id === formData?.online_creation_amount_currency
                ) || list.fees?.online_creation_amount_currency}
                onChange={(selectedOption) => {
                  console.log(selectedOption);
                  handleSelectChange("online_creation_amount_currency", selectedOption?._id);
                }}
              />
            </div>
          </div>
          <div className="row">
       
                <label className='form-label mt-3'><span >{"Auction Fee Offline"}</span></label>
            
            <div className="col-md-6 col-12">
              <TextInputField
             label=""
             value={formData?.offline_creation_amount || list.fees?.offline_creation_amount}
             name="offline_creation_amount"
             placeholder={"Enter Fee"}
             onChange = {(e)=>handleSelectChange("offline_creation_amount", Number(e.target.value))}
              />
            </div>
            <div className="col-md-6 col-12">
              <SelectDropdownWithSearch
              name={"offline_creation_amount_currency"}
                placeholder="Select Currency"
                options={companyCurrencies}
                value={companyCurrencies.find(
                  (option) => option._id === formData?.offline_creation_amount_currency
                ) || list.fees?.offline_creation_amount_currency}
                onChange={(selectedOption) => {
                  console.log(selectedOption);
                  handleSelectChange("offline_creation_amount_currency", selectedOption?._id);
                }}
              />
            </div>
          </div>
          <div className="mt-4">
            <ButtonWithLoader text="Save" handlerSubmit={handlerCurrencySave} isLoading={saveLoading} />
          </div>
      </div>
      }
    </div>
    }
    </>
  );
};

export default AuctionSettings;
