import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import url, { image_url } from '../utilities/url';
import { routesApi } from '../RouterFiles/routesApi';

const UploadSingleImage = ({ text,name,handlerFormData,data }) => {
  const [file, setFile] = useState()
  const [error, setError] = useState('');

  const onDrop = useCallback((acceptedFiles) => {
    const acceptedFile = acceptedFiles[0];

    if (acceptedFile.name === "wow.jpg") {
      setError("Naha, you don't.");
      return;
    }
    var formData = new FormData();
    formData.append("file", acceptedFile, "[PROXY]");

    var requestOptions = {
      method: "POST",
      // headers: { "x-access-token": localStorage.getItem("gamerjiToken") },
      body: formData,
      // redirect: "follow",
    };
    // console.log("requestIOtin", requestOptions)
    fetch(`${url.base_api}${routesApi.uploadImage}`, requestOptions)
      .then((response) => response.text())
      .then(
        (result) => {
          setFile(JSON.parse(result).data)
          handlerFormData(name,JSON.parse(result).data)}
        // setUploadLoader(t('screenshot_uploaded_successfully'))
        // console.log("uploadedddd")
      )
      .catch((error) =>
        // setUploadLoader(t('error_something_went_wrong_upload_again'))
      console.log("error",error)
      );
    // setFile(acceptedFile);
    setError('');
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1, // Single file upload
    maxSize: 10 * 1024 * 1024, // 10MB max size
    accept: 'image/png, image/jpeg, image/jpg',
  });

  const removeFile = () => {
    setFile(null);
  };
  useEffect(()=>{
    if(data){
      setFile(data)
    }
  },[data])
//   if(file)
// console.log(URL.createObjectURL(file), file)
  return (
    <div className="fv-row mb-0 pt-2">
      <label className="d-block fw-semibold fs-6 mt-3">
        {
          text && 
        <span className="required">{text}</span>
        }
        
      </label>

      <div
        className="image-input image-input-empty image-input-outline image-input-placeholder mt-5 position-relative"
      >
        <div className='position-absolute upload-icon'>
            {
                file ? 
                <span
          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
          data-bs-toggle="tooltip"
          title="Remove avatar"
          onClick={removeFile}
        >
          <i className="ki-outline ki-cross fs-2"></i>
        </span>
        :

         <label
          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
          data-bs-toggle="tooltip"
          title="Change avatar"
        >
          <i className="ki-outline ki-pencil fs-7"></i>
          <input {...getInputProps()}  name={name} />
        </label>
            }
        
        </div>
        <div className="image-input-wrapper w-125px h-125px" {...getRootProps()}>
          <input
         
          {...getInputProps()} />
       

          {file && (
            <img
              // src={URL.createObjectURL(file)}
              src={image_url+'/'+file}
              alt="preview"
              className="w-100 h-100"
            />
          )}
        </div>

        {/* Button to upload/change */}
       

        {/* Button to remove */}
        
      </div>
{/* 
      <div className="form-text">
        Allowed file types: png, jpg, jpeg.
      </div> */}

      {/* Error handling */}
      {error && <div className="text-danger">{error}</div>}
    </div>
  );
};

export default UploadSingleImage;
