import React, { useState } from 'react'
import PrimaryButton from '../../../Common/PrimaryButton'
import ButtonWithLoader from '../../../Common/ButtonWithLoader'
import ModalContainer from '../../../Common/ModalContainer'

const CountryPopupCell = ({countries}) => {

    const [show, setShow] = useState(false)
    const [selectedLang, setSelectedLang] =useState( [])
    console.log(countries)
    const handlerSubmit = ()=>{

    }

  return (
    <>
    <div className='w-100px d-flex justify-content-center'>
    <PrimaryButton text={"View"} handlerClick= {()=> setShow(true)} onSubmit={handlerSubmit} />
  
    </div>
{
    show &&  <ModalContainer text="Language" onClose={()=> setShow(false)} isViewOnly={true}>

         <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_update_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_update_role_header" data-kt-scroll-wrappers="#kt_modal_update_role_scroll" data-kt-scroll-offset="300px" style={{"max-height": "611px"}}>
                    
                    
                    
                    
                    <div class="fv-row">
                        
                        <label class="fs-5 fw-bold form-label mb-2">Country List</label>
                        
                        
                        <div class="table-responsive">
                            
                            <table class="table align-middle table-row-dashed fs-6 gy-5">
                                
                                <tbody class="text-gray-600 fw-semibold">
                                    
                            
                                    
                                    {
                                        countries?.length > 0 ?     countries?.map(cou => (
                                    <tr>
                                        
                                        <td class="text-gray-800">{cou}</td>
                                        
                                       
                
                                     
                                       
                                        
                                    </tr>

                                   
                                    
                                ))
                                :
                                <tr>
                                    
                                <td class="text-gray-800">No records found</td>
                                
                            </tr>
                            }
                                    
                                </tbody>
                                
                            </table>
                            
                        </div>
                        
                    </div>
                    
                </div>
    </ModalContainer>
}
    </>
  )
}

export default CountryPopupCell