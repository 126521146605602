
import Select from 'react-select'



const SelectDropDown = ({
    label,
    placeholder, 
    value, 
    onChange, 
    isMulti = false, 
    required = false, 
    options, 
    name = "option" ,
    disabled=false
}) => {
    console.log("values,", options)

    return (
        <div className='fv-row mb-0'>
  {label && (
    <label className='form-label mt-3'>
      <span className={`${required ? "required" : ""}`}>{label}</span>
    </label>
  )}
  <Select
    className='react-select-styled react-select-solid'
    classNamePrefix='react-select'
    options={options}
    placeholder={placeholder}
    value={isMulti ? value || [] : value} // Ensure value is an array when isMulti is true
    name={name}
    onChange={(selectedOption) => {
      if (isMulti) {
        onChange(selectedOption || []); // Pass an empty array if no option is selected
      } else {
        onChange(selectedOption); // Single select behavior
      }
    }}
    isMulti={isMulti}
    isDisabled={disabled}
  />
</div>
    )
}

export default SelectDropDown;