import React from 'react'

const ButtonWithLoader = ({text,isLoading, handlerSubmit ,disable=false}) => {
  return (
    
                    <button
                      
                      type='submit'
                      class="btn btn-primary me-2 flex-shrink-0"
                      disabled={disable}
                      onClick={handlerSubmit}
                    >
                        {
                            isLoading ? 
                            <span class="indicator-progress">
                            <span data-kt-translate="general-progress">
                              Please wait...
                            </span>
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                          :
                           

                      <span
                        class="indicator-label"
                        data-kt-translate="sign-in-submit"
                      >
                       {text}
                      </span>
                        }

                     
                    </button>
              
  )
}

export default ButtonWithLoader