import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  add,
  auctionSettings,
  getById,
  getConfigById,
  getCountries,
  getCurrency,
  getGuestConfigById,
  getList,
  remove,
  update,
  updateConfigById,
  updateGuestConfigById,
  upload,
} from "./company.api";
import { getCompanyDropdown } from "../../../utilities/api";

export const fetchCompanyDropdown = createAsyncThunk('company/fetchCompanyDropdown', async ({ }, { rejectWithValue }) => {
  try {

    const response = await getCompanyDropdown()
    // console.log("coun try res::::", response)
    if (response.success)
      return {

        ...response,

      }
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const fetchList = createAsyncThunk(
  "company/fetchList",
  async ({ pageNo, recordPerPage }, { rejectWithValue }) => {
    console.log(pageNo, recordPerPage);
    let headers = {
      // pageNo:pageNo || 1,
      // recordPerPage:recordPerPage || 10,
      // sortBy: 'created_at',
      // sort:-1
    };
    let body = {
      pagination: {
        pageNo: pageNo || 1,
        recordPerPage: recordPerPage || 1,
        sortBy: "created_at",
        sortDirection: "asc",
      },
      search: "", // optional
    };
    try {
      const response = await getList(headers, body);
      console.log("response:::::::", response, pageNo, recordPerPage);
      if (response.success)
        return {
          ...response,
          pageNo,
          recordPerPage,
        };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const addCompany = createAsyncThunk(
  "company/add",
  async ({ data }, { rejectWithValue }) => {
    let headers = {};
    try {
      const response = await add(headers, data);
      console.log("responseSport:::::::", response);
      if (response.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);
export const updateCompany = createAsyncThunk(
  "company/update",
  async ({ data, id }, { rejectWithValue }) => {
    let headers = {};
    try {
      const response = await update(headers, data, id);
      console.log("responseSport:::::::", response);
      if (response.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);
export const deleteCompany = createAsyncThunk(
  "company/delete",
  async ({ id }, { rejectWithValue }) => {
    console.log("cratespor", id);
    let headers = {};
    try {
      const response = await remove(headers, id);
      console.log("responseCompany:::::::", response);
      if (response?.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error from company", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);
export const getCompanyById = createAsyncThunk(
  "company/getListById",
  async ({ id }, { rejectWithValue }) => {
    console.log("cratespor", id);
    let headers = {};
    try {
      const response = await getById(headers, id);
      console.log("responseCompany:::::::", response);
      if (response.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);
export const getCompanyConfigById = createAsyncThunk(
  "company/getConfigById",
  async ({ id, name }, { rejectWithValue }) => {
    console.log("cratespor", id);
    let headers = {};
    try {
      const response = await getConfigById(headers, id, name);
      console.log("responseCompany:::::::", response);
      if (response.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);
export const updateCompanyConfigById = createAsyncThunk(
  "company/updateListById",
  async ({ id, name, data }, { rejectWithValue }) => {
    console.log("cratespor", id);
    let headers = {};
    try {
      const response = await updateConfigById(headers, id, name, data);
      console.log("responseCompany:::::::", response);
      if (response.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);
export const auctionSettingsCompany = createAsyncThunk(
  "company/update_auction_Settings",
  async ({ id, name, data }, { rejectWithValue }) => {
    console.log("cratespor", id);
    let headers = {};
    try {
      const response = await auctionSettings(headers, id, name, data);
      console.log("responseCompany:::::::", response);
      if (response.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);
export const getCompanyGuestConfigById = createAsyncThunk(
  "company/guest/getConfigById",
  async ({ id, name }, { rejectWithValue }) => {
    console.log("cratespor", id);
    let headers = {};
    try {
      const response = await getGuestConfigById(headers, id, name);
      console.log("responseCompany:::::::", response);
      if (response.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);
export const updateCompanyGuestConfigById = createAsyncThunk(
  "company/guest/updateListById",
  async ({ id, name, data }, { rejectWithValue }) => {
    console.log("cratespor", id);
    let headers = {};
    try {
      const response = await updateGuestConfigById(headers, id, name, data);
      console.log("responseCompany:::::::", response);
      if (response.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);
export const uploadCompanyAudio = createAsyncThunk(
  "company/updateAudio",
  async ({ file }, { rejectWithValue }) => {
    // console.log("file::",file)
    let headers = {};
    try {
      const response = await upload(headers, file);
      console.log("responseCompany:::::::", response);
      if (response.success)
        return {
          ...response,
        };
      else {
        return response;
      }
    } catch (error) {
      console.log("error", error);
      // throw error
      return rejectWithValue(error);
    }
  }
);

export const setCompanyGame = (state, { payload }) => {
  console.log(state, payload);
  let data = {
    selectedGames: payload.selectedGames,
  };
  return { ...state, ...data };
};
export const setCompanyCurrency = (state, { payload }) => {
  console.log(state, payload);
  let data = {
    currency: { ...state.currency, ...payload.currency },
  };
  return { ...state, ...data };
};
export const setCompanyCountry = (state, { payload }) => {
  console.log(state, payload);
  let data = {
    countryConfig: payload.countryConfig,
  };
  return { ...state, ...data };
};
export const setCompanyOtherConfig = (state, { payload }) => {
  console.log(state, payload);
  let data = {
    otherConfig: payload.otherConfig,
  };
  return { ...state, ...data };
};
export const setCoinPackConfig = (state, { payload }) => {
  console.log(state, payload);
  let data = {
    coinPackConfig: { ...state.coinPackConfig, ...payload.coinPackConfig },
  };
  return { ...state, ...data };
};
export const setCouponCodeConfig = (state, { payload }) => {
  console.log(state, payload);
  let data = {
    couponCodeConfig: {
      ...state.couponCodeConfig,
      ...payload.couponCodeConfig,
    },
  };
  return { ...state, ...data };
};
export const setPromoCodeConfig = (state, { payload }) => {
  console.log(state, payload);
  let data = {
    promoCodeConfig: { ...state.promoCodeConfig, ...payload.promoCodeConfig },
  };
  return { ...state, ...data };
};
export const setReferralCodeConfig = (state, { payload }) => {
  console.log(state, payload);
  let data = {
    referralCodeConfig: {
      ...state.referralCodeConfig,
      ...payload.referralCodeConfig,
    },
  };
  return { ...state, ...data };
};
export const setSignUpBonusCodeConfig = (state, { payload }) => {
  console.log(state, payload);
  let data = {
    signUpBonusConfig: {
      ...state.signUpBonusConfig,
      ...payload.signUpBonusConfig,
    },
  };
  return { ...state, ...data };
};
export const getCurrencyById = createAsyncThunk("company/getCurreciess",async ({ id }, { rejectWithValue }) => {
  
    let headers = {
      // pageNo:pageNo || 1,
      // recordPerPage:recordPerPage || 10,
      // sortBy: 'created_at',
      // sort:-1
    };
 
    try {
      const response = await getCurrency(headers, id);
     
      if (response.success)
        return {
          ...response,
         
        };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getCountriesCompany = createAsyncThunk("company/getCompanies",async ({  }, { rejectWithValue }) => {
  
    let headers = {
      // pageNo:pageNo || 1,
      // recordPerPage:recordPerPage || 10,
      // sortBy: 'created_at',
      // sort:-1
    };
 
    try {
      const response = await getCountries(headers);
     
      if (response.success)
        return {
          ...response,
         
        };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);