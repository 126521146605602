import { routesApi } from "../RouterFiles/routesApi";
import instance from "./axios";
import url from "./url";

export const getStaffListAll = (headers) => {
    return instance
      .get(`${url.base_api}${routesApi.staff}`, {
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };
export const getCountryAllList = (headers) => {
    return instance
      .get(`${url.base_api}${routesApi.country}`, {
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };

export const getCountryDropdown = () => {
  return instance
    .get(`${url.base_api}${routesApi.country}/dropdown`)
    .then((res) => {
      return res;
    });
}

export const getCompanyDropdown = () => {
  return instance
    .get(`${url.base_api}${routesApi.company}/dropdown`)
    .then((res) => {
      return res;
    });
}