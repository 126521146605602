import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import ButtonWithLoader from "../../../../../Common/ButtonWithLoader";



const RulesEdit = ({ type, haldleSubmitRules ,placeholder,rules}) => {
  const [value, setValue] = useState('');
  const handlerSave = () => {
    haldleSubmitRules(value.toString(),type)
  }
  useEffect(() => {
    if (rules) {
      setValue(rules)
    }
  }, [rules])
  return (
    <div>
      <div>
        <h4 className="mt-10 mb-5">{type} Rules</h4>
        <ReactQuill theme="snow" value={value} onChange={setValue} />
      </div>
      <div className="mt-10">
        <ButtonWithLoader text="Save" handlerSubmit={handlerSave} />
      </div>
    </div>
  );
};

export default RulesEdit;
