import React, { useEffect, useState } from "react";


import { useNavigate } from "react-router";
import Table from "../../../../../Common/TableWithTan/Table";
import ButtonWithLoader from "../../../../../Common/ButtonWithLoader";
import { useDispatch, useSelector } from "react-redux";

import { successMessage,errorMessage } from "../../../../../Common/SwalToast";
import Loader from "../../../../../MasterLayout/Loader";
import { auctionSettingsColumn } from "./AuctionSettingsColumn";

import { fetchDabbaList, fetchList, saveAuctionSettings, saveDabbaSettings } from "./redux/auctionSettings.action";
import { removeAuctionSettings } from "./redux/auctionSettings.slice";
import TextInputField from "../../../../../Common/TextInputField";
import SelectDropdownWithSearch from "../../../../../Common/SelectDropdownWithSearch";
import { getCurrencyById } from "../../../../Company/redux/company.action";

const DabbaSettings = ({id}) => {
  const [columnVisibility, setColumnVisibility] = useState();
  const [formData, setFormData] = useState()
  const {companyCode,viewSeries} = useSelector(state => state.series)
  const {companyCurrencies,compCurrLoad} = useSelector(state=> state.companies)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {dabba,dabbaLoad, error, dabbaSaveLoad,dabbaSave} = useSelector(state => state.auctionSettings)
  const [onlineCurr, setOnlineCurr] = useState({})
  const [offlineeCurr, setOfflineeCurr] = useState({})

  const handlerCurrencySave = ()=>{
      
      dispatch(saveDabbaSettings({id:id, companyCode:companyCode, data: {settings: formData}}))
  }
  const handleSelectChange = (name, value) => {
    setFormData(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
    // setGame(value)
  };
 
  useEffect(() => {
  

      setColumnVisibility({
        saved: false,
       
       
      })
    
  
    dispatch(fetchDabbaList({id:id,companyCode:companyCode}))
    console.log(viewSeries?.companies)
    if(dabba?.fees?.online_creation_amount_currency){
      setOnlineCurr({name:dabba?.fees?.online_creation_amount_currency})
    }
    if(dabba?.fees?.offline_creation_amount_currency){
      setOfflineeCurr({name:dabba?.fees?.offline_creation_amount_currency})
    }
  }, []);

  useEffect(()=>{
    if(viewSeries && companyCurrencies.length <= 0)
      dispatch(getCurrencyById({id:viewSeries?.companies?.[0]?.company_id}))
  },[viewSeries])
  useEffect(()=>{
    // console.log("error", error)
      if(error?.message){
        errorMessage(error?.message)
      }
  },[error])
  useEffect(()=>{
    // console.log("error", error)
      if(dabbaSave?.message){
        successMessage(dabbaSave?.message)
      }
  },[dabbaSave])
console.log("dabba>>>>>",dabba)
  return (
    <>
    <div className="card">
      <div className="card-header card-header-stretch">
        <div className="card-title d-flex align-items-center">
          <h3 className="fw-bold m-0 text-gray-800">Settings</h3>
        </div>
       
      </div>

        <div class="card-body">

              {
                dabbaLoad ? <Loader /> :
            <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
              <Table
                column={auctionSettingsColumn}
                tableData={dabba?.data}
                isAuctionSettings={ false}
              
                columnVisibility={columnVisibility}
                // columnFilters={columnFilters}
                // setColumnFilters={setColumnFilters}
              />
          
            </div>
              }
           
          </div>
         
    </div>
    {
          companyCode !== 'SAAS' && 
    <div className="card">
    <div className="card-header card-header-stretch">
        <div className="card-title d-flex align-items-center">
          <h3 className="fw-bold m-0 text-gray-800">Private Creation Fee</h3>
        </div>
      </div>
      {console.log( companyCode !== 'SAAS')}
      {
      compCurrLoad ? <Loader /> : 

      <div className="card-body">
          <div className="row">
       
                <label className='form-label mt-3'><span >{"Auction Fee Online"}</span></label>
            
            <div className="col-md-6 col-12">
              <TextInputField
              type={"number"}
              label=""
              value={formData?.online_creation_amount || dabba?.fees?.online_creation_amount}
              name="online_creation_amount"
              placeholder={"Enter Fee"}
              onChange = {(e)=>handleSelectChange("online_creation_amount", Number(e.target.value))}
              />
            </div>
            <div className="col-md-6 col-12">
              <SelectDropdownWithSearch
                name={"online_creation_amount_currency"}
                placeholder="Select Currency"
                options={companyCurrencies}
                value={companyCurrencies.find(
                  (option) => option._id === formData?.online_creation_amount_currency
                ) || onlineCurr  || dabba.fees?.online_creation_amount_currency
              }
                onChange={(selectedOption) => {
                  console.log(selectedOption);
                  handleSelectChange("online_creation_amount_currency", selectedOption?._id);
                }}
              />
            </div>
          </div>
         
          <div className="mt-4">
            <ButtonWithLoader text="Save" handlerSubmit={handlerCurrencySave} isLoading={dabbaLoad} />
          </div>
      </div>
      }
    </div>
    }
   
    </>
  );
};

export default DabbaSettings;
