import React from 'react'
import {usePagination, useSortBy, useTable} from 'react-table'
import CustomHeaderColumn from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useMemo} from 'react'
import { column } from './columns/column'
import Pagination from './columns/Pagination'

// import {UsersListLoading} from '../components/loading/UsersListLoading'
// import {UsersListPagination} from '../components/pagination/UsersListPagination'
const Table1 = () => {
    const tableData = [
        {
            name: 'Amma Smith',
            email:'amith@kpmg.com',
            status:"Locked",
            ipAddress:"89.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Active",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },{
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        },
        {
            name: 'Emma Smith',
            email:'smith@kpmg.com',
            status:"Locked",
            ipAddress:"99.62.84.59",
            date:"20 Dec 2024, 9:23 p"
        }
    ]
 
    const data = useMemo(() => tableData.slice(0,5), [tableData])
    console.log(data.length)
    const columns = useMemo(() => column, [])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable(
      {
        columns,
        data,
        manualSortBy: true, // Manual sorting for server-side sorting
        manualPagination: true, // Manual pagination for server-side pagination
        // pageCount: Math.ceil(totalRecords / pageSize),
      },
      useSortBy,
      usePagination
    );
  return (
    <>
     <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination />
      {/* <UsersListPagination />
      
      {isLoading && <UsersListLoading />} */}
    </>
  )
}

export default Table1
