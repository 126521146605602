import { createAsyncThunk } from "@reduxjs/toolkit"
import { add, getById, getList, getModulePermission, getUserPermission, patch, patchAssignCompany, patchUserPermission, remove, update } from "./staff.api"


export const fetchList = createAsyncThunk('staff/fetchList', async ({pageNo, recordPerPage},{rejectWithValue}) => {
  console.log(pageNo,recordPerPage )
    let headers = {
        pageNo:pageNo || 1,
        recordPerPage:recordPerPage || 10,
        sortBy: 'created_at',
        sort:-1
      }
      try{

          const response = await getList(headers)
          // console.log("response:::::::",response,pageNo,recordPerPage)
          if(response.success)
          return {
            ...response, 
            pageNo,
            recordPerPage
          }
      }
      catch(error){
        return rejectWithValue(error)
      }
})
export const addStaff = createAsyncThunk('staff/add', async ({data},{rejectWithValue}) => {
  console.log("cratespor", data)
    let headers = {
       
      }
      try{

          const response = await add(headers,data)
          // console.log("responseSport:::::::",response)
          if(response?.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error from staff", error)
        // throw error
        return rejectWithValue(error)
      }
})
export const updateStaff = createAsyncThunk('staff/update', async ({data,id},{rejectWithValue}) => {
  let headers = {
     
    }
    try{

        const response = await update(headers,data,id)
        // console.log("responseSport:::::::",response)
        if(response.success)
        return {
          ...response, 
         
        }
        else{
          return response
        }
    }
    catch(error){
      console.log("error", error)
      return rejectWithValue(error)
    }
})
export const updateStaffStatus= createAsyncThunk('staff/status', async ({data,id},{rejectWithValue}) => {
  let headers = {
     
    }
    try{

        const response = await patch(headers,data,id)
        // console.log("responseSport:::::::",response)
        if(response.success)
        return {
          ...response, 
         
        }
        else{
          return response
        }
    }
    catch(error){
      console.log("error", error)
      return rejectWithValue(error)
    }
})
export const deleteStaff = createAsyncThunk('staff/delete', async ({id},{rejectWithValue}) => {
  console.log("cratespor", id)
    let headers = {
       
      }
      try{

          const response = await remove(headers,id)
          // console.log("responseSport:::::::",response)
          if(response?.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error from staff", error)
        // throw error
        return rejectWithValue(error)
      }
})
export const getStaffById = createAsyncThunk('staff/getStaffById', async ({id}) => {
  // console.log("cratespor", id)
    let headers = {
       
      }
      try{

          const response = await getById(headers,id)
          // console.log("response:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        // return rejectWithValue(error)
      }
})


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// USERS PERMISSTION API CALLS
export const assignCompany = createAsyncThunk('staff/assignCompany', async ({ assigned_company ,id }) => {
  try {
    let data={
      assigned_company: assigned_company
    }
    const response = await patchAssignCompany(data, id)
    // console.log("response:::::::",response)
    if (response.success)
      return {
        ...response,

      }
    else {
      return response
    }
  }
  catch (error) {
    console.log("error", error)
    // throw error
    // return rejectWithValue(error)
  }
})
export const fetchUserPermission = createAsyncThunk('staff/fetchUserPermission', async ({userID}, { rejectWithValue }) => {
  // console.log('fetchUserPermission:::::::', userID)
  try {
    const response = await getUserPermission(userID)
    // console.log("response:::::::",response,pageNo,recordPerPage)
    if (response.success)
      return response.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const fetchModulePermission = createAsyncThunk('staff/fetchModulePermission', async ({},{ rejectWithValue }) => {
  // console.log('fetchModulePermission:::::::')
  try {
    const response = await getModulePermission()
    // console.log("response:::::::",response,pageNo,recordPerPage)
    if (response.success)
      return response.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const updateUserPermission = createAsyncThunk('staff/updateUserPermission', async ({ data,id}, { rejectWithValue }) => {
  // console.log('fetchModulePermission:::::::')
  try {
    const response = await patchUserPermission(data,id)
    // console.log("response:::::::",response,pageNo,recordPerPage)
    if (response.success)
      return response.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})