import React, { useEffect, useState } from "react";
import Checkbox from "../../../../Common/Checkbox";
const permissionTypes = [
  { value: "submodule", label: "Submodule" },
  { value: "all", label: "All" },
  { value: "list", label: "List" },
  { value: "get", label: "Get" },
  { value: "create", label: "Create" },
  { value: "delete", label: "Delete" },
  { value: "update", label: "Update" },
  { value: "statusUpdate", label: "Status Update" },
  { value: "export", label: "Export" },
];
const PermissionsModuleTable = ({
  module,
  company,
  userPermission,
  handlerPermissionCheckBox,
  handlerPermissionAllCheckBox
}) => {
  const [moduleData, setmoduleData] = useState(module);
  const [userPermissionData, setUserPermissionData] = useState(userPermission);
  useEffect(() => {
    // console.log(">>PermissionsModuleTable<<", module, company, userPermission);
    setmoduleData(module);
    setUserPermissionData(userPermission)
  }, [module, company, userPermission]);
  return (
    <>
      {moduleData && moduleData?.is_active && (
        <div className="d-flex-row justify-content-between p-2 px-4 align-items-center">
          <h4 class="text-gray-700 fw-bold cursor-pointer mb-0">
            {moduleData?.name}
          </h4>
          <div className="table-responsive">
            <table
              id="kt_table_users"
              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              // {...getTableProps()}
              // width={table.getTotalSize()}
            >
              <thead className="bg-light-dark px-1">
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <>
                    {permissionTypes.map((PT, idx) => {
                      return <>
                        {PT.value !== 'submodule' ?
                          <>
                            <td style={{ width: "8%" }}>
                              {PT.label}
                            </td>
                          </>
                          : <>
                            <td style={{width:"20%"}}>
                              {PT.label}
                            </td>
                          </>

                        }
                      </>
                    })}
                    </>
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-bold">
                {Object?.keys(moduleData.permission).map((submodule)=>{
                  {/* console.log("submodule", submodule, moduleData) */}
                  return<>
                  <tr>
                      {permissionTypes.map((PT,idx) => {
                        return <>
                          {PT.value !== 'submodule' ?
                            <>
                              <td style={{ width: "8%" }}>
                                {(moduleData?.permission?.[submodule]?.[PT.value] || PT.value==="all") &&
                                  <Checkbox
                                    key={idx}
                                    // label={PT.label}
                                    value={PT.value}
                                    name={`${PT.value}`}
                                  onChange={(e) => {PT.value !== "all"?
                                    handlerPermissionCheckBox(e, company, moduleData?._id, submodule, PT.value):
                                    handlerPermissionAllCheckBox(e, company, moduleData?._id, submodule,moduleData?.permission?.[submodule])}
                                    }
                                  checked={userPermissionData?.[company]?.[moduleData?._id]?.includes(`${submodule}.${PT.value}`)===true?true:false}
                                  />
                                }
                              
                            </td>
                            </>

                            :<>
                              <td style={{ width: "20%" }}>
                                {submodule}
                              </td>
                            </>
                          }
                        </>
                      })}
                  </tr>
                  </>
                })}
                  
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default PermissionsModuleTable;
