import React, { useEffect, useRef, useState } from "react";

import { formValidation } from '@form-validation/bundle/popular';
import { Bootstrap5 } from '@form-validation/plugin-bootstrap5';
import { Trigger } from '@form-validation/plugin-trigger';
import { errorMessage, successMessage } from "../../Common/SwalToast";
import Toolbar from "../../Common/Toolbar";
import Content from "../../Common/Content";
import SelectDropDown from "../../Common/SelectDropDown";
import TextInputField from "../../Common/TextInputField";
import ButtonWithLoader from "../../Common/ButtonWithLoader";
import Switchbox from "../../Common/Switchbox";
const CreateCategory = () => {
  const [formData, setFormData] = useState({
    country: "",
    name: "",
    symbol: "",
    image: "",
    code:""
  });
  const formRef = useRef()
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle loading indication
  const [validator, setValidator] = useState(null); // Store form validation instance
  const countryOptions = [
    { value: "India", label: "India" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Pakistan", label: "Pakistan" },
  ];
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]:
        type === "checkbox"
          ? checked
            ? [...formData.notifications, value]
            : formData.notifications.filter((n) => n !== value)
          : value,
    });
  };
  const handleSelectChange = (name, value) => {
    console.log(name, value);
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handlerSubmit = () => {
    console.log(formData);
  };
  const checkValidation = (e)=>{
    e.preventDefault();
    // setIsSubmitting(true)
    console.log(validator)
    if (validator) {
        validator.validate().then(function (status) {
            

            if (status === 'Valid') {
                setIsSubmitting(true);

                // Simulate API call delay
          
                setTimeout(() => {
                    setIsSubmitting(false);
                    let text="Form has been successfully submitted!"
                   successMessage(text,handlerSubmit)

                    // Submit form data here or trigger actual form submission
                    // form.submit(); (if needed)
                    
                }, 1000);
                
                
            } else {
                // Show error message
                let text= "Sorry, looks like there are some errors detected, please try again."
               errorMessage(text)
            }
        });
    }
    
  }
  useEffect(() => {
    const form = formRef.current;
    if (!form) return;

    const validationFields = {
        fields: {
            name: {
                validators: {
                    notEmpty: {
                        message: 'Name is required'
                    },
                    stringLength: {
                        verbose: false, 
                        min: 6,
                        max: 30,
                        message: 'The name must be more than 6 and less than 30 characters long',
                    },
                    // regexp: {
                    //     regexp: /^[a-zA-Z0-9_]+$/,
                    //     message: 'The name can only consist of alphabetical, number and underscore',
                    // },
                }
            },
            symbol: {
                validators: {
                    notEmpty: {
                        message: 'Symbol is required'
                    },
                    stringLength: {
                        verbose: false, 
                        min: 3,
                        max: 4,
                        message: 'The name must be more than 3 characters long',
                    },
                }
            },
            code: {
                validators: {
                    notEmpty: {
                        message: 'Code is required'
                    }
                }
            },
            country: {
                validators: {
                    notEmpty: {
                        message: 'Country is required'
                    }
                }
            },
            uploadImage: {
                validators: {
                    notEmpty: {
                        message: 'Upload image is required'
                    }
                }
            },
          
        },
        plugins: {
            trigger: new Trigger(),
            bootstrap: new Bootstrap5({
                rowSelector: '.fv-row',
                eleInvalidclassName: '',
                eleValidclassName: ''
            })
        }
    };

    const fv = formValidation(form, validationFields);

    setValidator(fv);
}, [])
console.log(countryOptions.find(
                    (option) => option.value === formData.country
                  ))
  return (
    <>
      <Toolbar text={"Create currency"} />
      <Content>
        <div className="card">
          <div className="card-body"  ref={formRef}>
            <div class="row g-9 mb-4">
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <SelectDropDown
                  name="country"
                  required={"true"}
                  label="Country"
                  placeholder={"Select a country"}
                  options={countryOptions}
                  value={countryOptions.find(
                    (option) => option.value === formData.country
                  )}
                  onChange={(selectedOption) =>
                    handleSelectChange("country", selectedOption.value)
                  }
                />
              </div>
              <div class="col-md-6 col-sm-6 col-12 mt-7 pt-6">
                <Switchbox text={"is Parent"} />
              </div>
              
         
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <SelectDropDown
                  name="country"
                  required={"true"}
                  label="Country"
                  placeholder={"Select a country"}
                  options={countryOptions}
                  value={countryOptions.find(
                    (option) => option.value === formData.country
                  )}
                  onChange={(selectedOption) =>
                    handleSelectChange("country", selectedOption.value)
                  }
                />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <SelectDropDown
                  name="country"
                  required={"true"}
                  label="Country"
                  placeholder={"Select a country"}
                  options={countryOptions}
                  value={countryOptions.find(
                    (option) => option.value === formData.country
                  )}
                  onChange={(selectedOption) =>
                    handleSelectChange("country", selectedOption.value)
                  }
                />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  required={"true"}
                  label={"name"}
                  value={formData?.name}
                  name={"name"}
                  type={"text"}
                  onChange={handleChange}
                  placeholder={"Enter name"}
                />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-7 pt-6">
                <Switchbox text={"is Dependency"} />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-7 pt-6">
                <Switchbox text={"is Active"} />
              </div>
              <div class="col-md-4 col-6 d-flex align-items-center justify-content-start pt-3">
                <ButtonWithLoader
                  text={"Submit"}
                  handlerSubmit={checkValidation}
                />
             
            </div>
            </div>

             
          </div>
        </div>
      </Content>
    </>
  );
};

export default CreateCategory;
