import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataExpand from "./DataExpand";

const TeamTab = ({ teamDetails , associationKey}) => {
  const navigate = useNavigate();
  const [data, setData] = useState(teamDetails);
  console.log("data", teamDetails)
  // const { team, tournament, tournament_team } = data;
  const gotoPlayerDetails = (player, tournamentKey) => {
    if(player.name){
      localStorage.setItem("itemPlayer", player.name);
      navigate(`/fantasy-player-details/${player?.key}/${tournamentKey}/${associationKey}`);
    }else{
      localStorage.setItem("itemPlayerKey", player);
      navigate(`/fantasy-player-details/${player}/${tournamentKey}/${associationKey}`)
    }
   
    
  };
  return (
    <ul>
       {data && data.map(({team, tournament, tournament_team })=>{
        console.log("")
         return <li>
           <div style={{borderBlockEnd:"solid 2px #000", marginBlockEnd:"10px"}}>
             {/* Team Information */}
             <h2>Team Information : {team.name}  | code : {team.code}</h2>
             {/* Players List */}
             <h3>Players Information</h3>
             <table border="1" cellPadding="5">
               <thead>
                 <tr>
                   <th>Name</th>
                   <th>Jersey Name</th>
                   <th>Role</th>
                   <th>Skills</th>
                   <></>
                 </tr>
               </thead>
               <tbody >
                 {Object.values(tournament_team.players).map(player => (
                   <tr key={player.key} >
                     <td style={{
                       fontSize: "1rem",
                       color: "#4519ff ",
                       fontWeight: "600",
                       marginInline: "10px"
                     }} onClick={() => { gotoPlayerDetails(player, tournament.key) }}>{player.name}</td>
                     <td>{player.jersey_name}</td>
                     <td>{player.seasonal_role}</td>
                     <td>{player.skills.join(', ')}</td>
                   </tr>
                 ))}
               </tbody>
             </table>

             {/* Player Formats */}
             <h2>Players by Format</h2>
             <ul>
               {Object.entries(tournament_team.players_by_format).map(([format, players]) => (
                 <li key={format}>
                   <strong>{format.toUpperCase()}:</strong> <ul>{players ? players.map((player)=>{
                     return <li style={{
                       fontSize: "1rem",
                       color: "#4519ff ",
                       fontWeight: "600",
                     }} className="m-2" onClick={() => { gotoPlayerDetails(player, tournament.key) }}>{player}</li>
                   }): 'No players available'}
                   </ul>
                 </li>
               ))}
             </ul>
           </div>
         </li>
       })}
    </ul>
   
  );
};

export default TeamTab;
