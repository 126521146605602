import { createAsyncThunk } from "@reduxjs/toolkit"
import { loginRequest } from "./login.api"


export const request = createAsyncThunk('login/request', async ({query },{rejectWithValue}) => {
  console.log("loginRequest:data:::::::", query)
      try{
          const response = await loginRequest(query)
        console.log("loginRequest:response:::::::", response)
          if(response.success)
          return response
      }
      catch(error){
        return rejectWithValue(error)
      }
})

export const validate = createAsyncThunk('login/validate', async ({ query }, { rejectWithValue }) => {
  console.log("loginRequest:data:::::::", query)
  try {
    const response = await loginRequest(query)
    console.log("loginRequest:response:::::::", response)
    if (response.success)
      return response
  }
  catch (error) {
    return rejectWithValue(error)
  }
})




// export const addLanguage = createAsyncThunk('language/add', async ({data},{rejectWithValue}) => {
//   console.log("cratespor", data)
//     let headers = {
       
//       }
//       try{

//           const response = await add(headers,data)
//           console.log("responseSport:::::::",response)
//           if(response?.success)
//           return {
//             ...response, 
           
//           }
//           else{
//             return response
//           }
//       }
//       catch(error){
//         console.log("error from language", error)
//         // throw error
//         return rejectWithValue(error)
//       }
// })
// export const updateLanguage = createAsyncThunk('language/update', async ({data,id},{rejectWithValue}) => {
//   let headers = {
     
//     }
//     try{

//         const response = await update(headers,data,id)
//         console.log("responseSport:::::::",response)
//         if(response.success)
//         return {
//           ...response, 
         
//         }
//         else{
//           return response
//         }
//     }
//     catch(error){
//       console.log("error", error)
//       // throw error
//       return rejectWithValue(error)
//     }
// })
// export const delLanguage = createAsyncThunk('language/delete', async ({id},{rejectWithValue}) => {
//   console.log("cratespor", id)
//     let headers = {
       
//       }
//       try{

//           const response = await remove(headers,id)
//           console.log("responseSport:::::::",response)
//           if(response?.success)
//           return {
//             ...response, 
           
//           }
//           else{
//             return response
//           }
//       }
//       catch(error){
//         console.log("error from language", error)
//         // throw error
//         return rejectWithValue(error)
//       }
// })
// export const getLanguageById = createAsyncThunk('language/getListById', async ({id}) => {
//   console.log("cratespor", id)
//     let headers = {
       
//       }
//       try{

//           const response = await getById(headers,id)
//           console.log("responseSport:::::::",response)
//           if(response.success)
//           return {
//             ...response, 
           
//           }
//           else{
//             return response
//           }
//       }
//       catch(error){
//         console.log("error", error)
//         // throw error
//         // return rejectWithValue(error)
//       }
// })