import React from 'react'

const SwitchBoxWithoutLabel = ({checked, onChange,row}) => {

  return (
    <div>
          <label class="form-check form-switch form-check-custom form-check-solid"> 
          <input class="form-check-input" name="billing" type="checkbox" value="1" checked={checked} onChange={()=> onChange(row)} />          
       </label>
    </div>
  )
}

export default SwitchBoxWithoutLabel