import React, { useMemo, useState } from "react";



import Toolbar from "../../../../Common/Toolbar";
import Content from "../../../../Common/Content";
import SeriesCard from "./SeriesCard";
import MatchesList from "./Matches/MatchesList";
import Scoreboard from "./Scoreboard/Scoreboard";
import Logs from "../../../Staff/Logs";
import Players from "./Players/Players";
import OverSummary from "./OverSummary/OverSummary";
import Teams from "./Teams/Teams";
import PlayingXI from "./PlayingXI/PlayingXI";
import AuctionSettings from "./AuctionSettings/AuctionSettings";
import DabbaSettings from "./AuctionSettings/DabbaSettings";
import AssignCompnay from "./AssignCompany/AssginCompany";
import Rules from "./Rules/Rules";




const ViewSeriesPage = () => {
  const tabs = [  "Teams","Players","Matches", "Scoreboard","Over Summary", "Playing XI", "Auction Settings", "Dabba Settings", "Assign Company","Rules"]
  const [activeTab, setActiveTab] = useState(tabs[7])
  const id = useMemo(() => {
    return (
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] || null
    );
  }, []);
  return (
    <>
      <Toolbar text={"View series Page"} />
      <Content>
      
								<SeriesCard tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} id={id}/>
								{
                  activeTab === "Matches" ?  <MatchesList  id={id} />
                  : activeTab === "Players" ? <><Players id={id}/></>
                  : activeTab === tabs[2] ? <><Scoreboard  id={id} /></>
                  : activeTab === tabs[3] ? <><OverSummary  id={id} /></>
                  : activeTab === "Teams" ?  <><Teams id={id} /></>
                  : activeTab === tabs[5] ? <><PlayingXI /></>
                  : activeTab ==="Dabba Settings" ? <><DabbaSettings id={id} /></>
                  : activeTab ==="Assign Company" ? <><AssignCompnay id={id} /></>
                  : activeTab === "Rules" ? <><Rules id={id}/></>
                  : <AuctionSettings id={id}  />
                }
      </Content>
    </>
  );
};

export default ViewSeriesPage;
