import React, { useCallback, useEffect, useRef, useState } from 'react'
import ModalContainer from '../../../Common/ModalContainer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchList } from '../Language/redux/language.action'
import Loader from '../../../MasterLayout/Loader'
import { getAssignLanguage,updateAssignLanguage } from './redux/country.action'

const languagesOption = [
    {
        name:'English',
        code:'EN'
    },
    {
        name:'Hindi',
        code:'HI'
    },
    {
        name:'Arabic',
        code:'AR'
    }
]
const LanguagePopUp = ({setShow,id}) => {
    const [selectedLang, setSelectedLang] =useState( [])
    const [pageNo, setPageNo] = useState(0)
      const [recordPerPages, setRecordPerPage] = useState(12)
    const dispatch = useDispatch()
    const {languages, loading, totalPages} = useSelector(state => state.languages)
    const [languagesOption, setLanguageOptions] = useState([])
    const handlerSelectedAll = ()=>{
        setSelectedLang(languagesOption.map(lang => lang._id))

    }
    useEffect(()=>{
        dispatch(fetchList({pageNo:pageNo+1, recordPerPage: recordPerPages}))
        dispatch(getAssignLanguage({id:id}))
        
    },[])
    useEffect(()=>{
        if(pageNo < totalPages)
        dispatch(fetchList({pageNo:pageNo+1, recordPerPage: recordPerPages}))
    },[pageNo])
    useEffect(()=>{
        if(languages?.length > 0)
        setLanguageOptions([...languagesOption,...languages])
    },[languages])
    // useEffect(()=>{
    //     console.log(getAssignLangList)
    //     if(getAssignLangList?.length > 0)
    //     setSelectedLang(getAssignLangList)
    // },[getAssignLangList])


  const observer = useRef();
  const lastPostElementRef = useCallback(
    async (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
            setPageNo((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading]
  );
  const handleSubmit = ()=>{
    console.log("sel lang", selectedLang)
    dispatch(updateAssignLanguage({data:{languages:languages}, id:id}))

  }
  return (
    <ModalContainer text="Language" onClose={()=> setShow(false)} onSubmit={handleSubmit}>

    <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_update_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_update_role_header" data-kt-scroll-wrappers="#kt_modal_update_role_scroll" data-kt-scroll-offset="300px" style={{"max-height": "611px"}}>
               
               
               
               
               <div class="fv-row">
                   
                   <label class="fs-5 fw-bold form-label mb-2">LanguageName</label>
                   
                   
                   <div class="table-responsive">
                       
                       <table class="table align-middle table-row-dashed fs-6 gy-5">
                           
                           <tbody class="text-gray-600 fw-semibold">
                               
                               <tr>
                                   <td class="text-gray-800">Select All Language
                                   <span class="ms-1" data-bs-toggle="tooltip" aria-label="Allows a full access to the system" data-bs-original-title="Allows a full access to the system" data-kt-initialized="1">
                                       <i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
                                   </span></td>
                                   <td>
                                       
                                       <label class="form-check form-check-sm form-check-custom form-check-solid me-9">
                                           <input 
                                           class="form-check-input" 
                                           type="checkbox" value="" 
                                           id="kt_roles_select_all"
                                           onChange={handlerSelectedAll}
                                            />
                                           {/* <span class="form-check-label" for="kt_roles_select_all">Select all</span> */}
                                       </label>
                                       
                                   </td>
                               </tr>
                               
                               {
                                
                                     languagesOption?.map((lang,ind) => (
                               <tr  ref={languagesOption?.length === ind + 1 ? lastPostElementRef : null}>
                                   
                                   <td class="text-gray-800">{lang.name}</td>
                                   
                                  
                                           <td>
                                       

                                           <label class="form-check form-check-custom form-check-solid">
                                               <input 
                                               class="form-check-input" 
                                               type="checkbox" 
                                               // value={} 
                                               name="user_management_create"
                                               onChange={()=>setSelectedLang(prev => {
                                                   return [
                                                       ...prev,
                                                       lang?._id
                                                   ]
                                               })}
                                               checked={selectedLang.includes(lang?._id)}
                                                />
                                               {/* <span class="form-check-label">Create</span> */}
                                           </label>
                                           
                                   
                                       
                                   </td>
                                
                                  
                                   
                               </tr>

                                              
                               
                           )
                        
                           )
                       }
                               { loading && <Loader />}
                           </tbody>
                           
                       </table>
                       
                   </div>
                   
               </div>
               
           </div>
</ModalContainer>
  )
}

export default LanguagePopUp
