import React, { useEffect, useRef, useState } from 'react'
import Content from './Content'
import { KTStepper } from '../../_metronic/_components/_stepper'
import RadioWithoutIcon from './RadioWithoutIcon'
import TextInputField from './TextInputField'
import Radios from './Radios'
import SelectDropDown from './SelectDropDown'
import { warningMessage } from './SwalToast'

const Wizard = () => {
    const stepperRef = useRef()
    const formRef = useRef()
    const [stepper, setStepper] = useState()
    const [currentIndex, setCurrentIndex] = useState()
    const  totalStepsNumber = 5
    const productOptions = [
        { value: 'user1', label: 'React App' },
        { value: 'user2', label: 'Vue App' },
        { value: 'user2', label: 'Html App' },
    ]
    const options = {startIndex:1}
    let formDetails = {
        appName:'',
        category:'',
        product:''
    }
    const [formData, setFormData] = useState(formDetails, formRef)
    useEffect(()=>{
            setStepper(KTStepper.createInsance(stepperRef.current,options))
        
    },[])
    const setData = ()=>{
        setFormData({
            subject: '',
            user: '',
            due_date: '',
            description: '',
            notifications: [],
        });
    }
    const handleCancel = (e) => {
        e.preventDefault();
        let text = "Are you sure you would like to cancel????"

       warningMessage(text,setData)
    };
    const handleSelectChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const prevStep = () => {
        if (!stepper) {
          return
        }
    
        stepper.goPrevious()
    
        setCurrentIndex(prev => prev -1)
      }
    
      const submitStep = (values, actions) => {
        
        if (!stepper) {
          return
        }
    
        if (currentIndex !== totalStepsNumber) {

          stepper.goNext()
        } else {
          stepper.goto(1)
          
        }
    
        console.log(values);
    
        setCurrentIndex(prev => prev +1)
      }
    const [appName, setAppName] = useState()
    const [radio, setRadio] = useState('1')
    const radioValue = [
        {
        label:"Quick Online Courses",
        subLevel:"Creating a clear text structure is just one SEO",
        value:"1"
    },
    {
        label:"Quick Online Courses",
        subLevel:"",
        value:"2"
    },
    {
        label:"Quick Online Courses",
        subLevel:"Creating a clear text structure is just one SEO",
        value:"3"
    }
]
  return (
    <>
    <Content>
    <div class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_app_stepper" data-kt-stepper={false} ref={stepperRef}>
							
							<div class="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px" >
								
								<div class="stepper-nav ps-lg-10">
									
									<div class="stepper-item current" data-kt-stepper-element="nav">
										
										<div class="stepper-wrapper">
											
											<div class="stepper-icon w-40px h-40px">
												<i class="ki-outline ki-check stepper-check fs-2"></i>
												<span class="stepper-number">1</span>
											</div>
											
											
											<div class="stepper-label">
												<h3 class="stepper-title">Details</h3>
												<div class="stepper-desc">Name your App</div>
											</div>
											
										</div>
										
										
										<div class="stepper-line h-40px"></div>
										
									</div>
									
									
									<div class="stepper-item" data-kt-stepper-element="nav">
										
										<div class="stepper-wrapper">
											
											<div class="stepper-icon w-40px h-40px">
												<i class="ki-outline ki-check stepper-check fs-2"></i>
												<span class="stepper-number">2</span>
											</div>
											
											
											<div class="stepper-label">
												<h3 class="stepper-title">Frameworks</h3>
												<div class="stepper-desc">Define your app framework</div>
											</div>
											
										</div>
										
										
										<div class="stepper-line h-40px"></div>
										
									</div>
									
									
									<div class="stepper-item" data-kt-stepper-element="nav">
										
										<div class="stepper-wrapper">
											
											<div class="stepper-icon w-40px h-40px">
												<i class="ki-outline ki-check stepper-check fs-2"></i>
												<span class="stepper-number">3</span>
											</div>
											
											
											<div class="stepper-label">
												<h3 class="stepper-title">Database</h3>
												<div class="stepper-desc">Select the app database type</div>
											</div>
											
										</div>
										
										
										<div class="stepper-line h-40px"></div>
										
									</div>
									
									
									<div class="stepper-item" data-kt-stepper-element="nav">
										
										<div class="stepper-wrapper">
											
											<div class="stepper-icon w-40px h-40px">
												<i class="ki-outline ki-check stepper-check fs-2"></i>
												<span class="stepper-number">4</span>
											</div>
											
											
											<div class="stepper-label">
												<h3 class="stepper-title">Billing</h3>
												<div class="stepper-desc">Provide payment details</div>
											</div>
											
										</div>
										
										
										<div class="stepper-line h-40px"></div>
										
									</div>
									
									
									<div class="stepper-item mark-completed" data-kt-stepper-element="nav">
										
										<div class="stepper-wrapper">
											
											<div class="stepper-icon w-40px h-40px">
												<i class="ki-outline ki-check stepper-check fs-2"></i>
												<span class="stepper-number">5</span>
											</div>
											
											
											<div class="stepper-label">
												<h3 class="stepper-title">Completed</h3>
												<div class="stepper-desc">Review and Submit</div>
											</div>
											
										</div>
										
									</div>
									
								</div>
								
							</div>
							
							
							<div class="flex-row-fluid py-lg-5 px-lg-15">
								
								<form class="form" novalidate="novalidate" id="kt_modal_create_app_form" ref={formRef}>
									
									<div class="current" data-kt-stepper-element="content">
										<div class="w-100">
											
											
                                            <TextInputField
                                    label={"app name"}
                                    required={"true"}
                                    value={formData?.appName}
                                    name={"appName"}
                                    type={"text"}
                                    minlength={"6"}
                                    maxlength={"30"}
                                    onChange={(e)=> setAppName(e.target.value)}
                                    placeholder={""}

                                />
											
                              
											<div class="fv-row">
												
												<label class="d-flex align-items-center fs-5 fw-semibold mb-4">
													<span class="required">Category</span>
													<span class="ms-1" data-bs-toggle="tooltip" title="Select your app category">
														<i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
													</span>
												</label>
												
                                                <Radios 
                                data={radioValue}
                                name={"category"}
                                onChange={(val) => setRadio(val)}
                                />
												
											</div>
											<select class="form-select" data-control="select2" data-placeholder="Select an option">
    <option></option>
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
</select>
										</div>
									</div>
									
									
									<div data-kt-stepper-element="content">
										<div class="w-100">
											
                                        <SelectDropDown
                                            name="product"
                                            label="Product"
                                            placeholder={'Select a product'}
                                            options={productOptions}
                                            value={productOptions.find(option => option.value === formData.user)}
                                            onChange={(selectedOption) => handleSelectChange('product', selectedOption.value)}

                                        />
											
										</div>
									</div>
									
									
									<div data-kt-stepper-element="content">
										<div class="w-100">
											
											<div class="fv-row mb-10">
												
												<label class="required fs-5 fw-semibold mb-2">Database Name</label>
												
												
												<input type="text" class="form-control form-control-lg form-control-solid" name="dbname" placeholder="" value="master_db" />
												
											</div>
											
											
											<div class="fv-row">
												
												<label class="d-flex align-items-center fs-5 fw-semibold mb-4">
													<span class="required">Select Database Engine</span>
													<span class="ms-1" data-bs-toggle="tooltip" title="Select your app database engine">
														<i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
													</span>
												</label>
												
												
												<label class="d-flex flex-stack cursor-pointer mb-5">
													
													<span class="d-flex align-items-center me-2">
														
														<span class="symbol symbol-50px me-6">
															<span class="symbol-label bg-light-success">
																<i class="ki-outline ki-note text-success fs-2x"></i>
															</span>
														</span>
														
														
														<span class="d-flex flex-column">
															<span class="fw-bold fs-6">MySQL</span>
															<span class="fs-7 text-muted">Basic MySQL database</span>
														</span>
														
													</span>
													
													
													<span class="form-check form-check-custom form-check-solid">
														<input class="form-check-input" type="radio" name="dbengine" checked="checked" value="1" />
													</span>
													
												</label>
												
												
												<label class="d-flex flex-stack cursor-pointer mb-5">
													
													<span class="d-flex align-items-center me-2">
														
														<span class="symbol symbol-50px me-6">
															<span class="symbol-label bg-light-danger">
																<i class="ki-outline ki-google text-danger fs-2x"></i>
															</span>
														</span>
														
														
														<span class="d-flex flex-column">
															<span class="fw-bold fs-6">Firebase</span>
															<span class="fs-7 text-muted">Google based app data management</span>
														</span>
														
													</span>
													
													
													<span class="form-check form-check-custom form-check-solid">
														<input class="form-check-input" type="radio" name="dbengine" value="2" />
													</span>
													
												</label>
												
												
												<label class="d-flex flex-stack cursor-pointer">
													
													<span class="d-flex align-items-center me-2">
														
														<span class="symbol symbol-50px me-6">
															<span class="symbol-label bg-light-warning">
																<i class="ki-outline ki-microsoft text-warning fs-2x"></i>
															</span>
														</span>
														
														
														<span class="d-flex flex-column">
															<span class="fw-bold fs-6">DynamoDB</span>
															<span class="fs-7 text-muted">Microsoft Fast NoSQL Database</span>
														</span>
														
													</span>
													
													
													<span class="form-check form-check-custom form-check-solid">
														<input class="form-check-input" type="radio" name="dbengine" value="3" />
													</span>
													
												</label>
												
											</div>
											
										</div>
									</div>
									
									
									<div data-kt-stepper-element="content">
										<div class="w-100">
											
											<div class="d-flex flex-column mb-7 fv-row">
												
												<label class="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
													<span class="required">Name On Card</span>
													<span class="ms-1" data-bs-toggle="tooltip" title="Specify a card holder's name">
														<i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
													</span>
												</label>
												
												<input type="text" class="form-control form-control-solid" placeholder="" name="card_name" value="Max Doe" />
											</div>
											
											
											<div class="d-flex flex-column mb-7 fv-row">
												
												<label class="required fs-6 fw-semibold form-label mb-2">Card Number</label>
												
												
												<div class="position-relative">
													
													<input type="text" class="form-control form-control-solid" placeholder="Enter card number" name="card_number" value="4111 1111 1111 1111" />
													
													
													<div class="position-absolute translate-middle-y top-50 end-0 me-5">
														<img src="assets/media/svg/card-logos/visa.svg" alt="" class="h-25px" />
														<img src="assets/media/svg/card-logos/mastercard.svg" alt="" class="h-25px" />
														<img src="assets/media/svg/card-logos/american-express.svg" alt="" class="h-25px" />
													</div>
													
												</div>
												
											</div>
											
											
											<div class="row mb-10">
												
												<div class="col-md-8 fv-row">
													
													<label class="required fs-6 fw-semibold form-label mb-2">Expiration Date</label>
													
													
													<div class="row fv-row">
														
														<div class="col-6">
															<select name="card_expiry_month" class="form-select form-select-solid" data-control="select2" data-hide-search="true" data-placeholder="Month">
																<option></option>
																<option value="1">1</option>
																<option value="2">2</option>
																<option value="3">3</option>
																<option value="4">4</option>
																<option value="5">5</option>
																<option value="6">6</option>
																<option value="7">7</option>
																<option value="8">8</option>
																<option value="9">9</option>
																<option value="10">10</option>
																<option value="11">11</option>
																<option value="12">12</option>
															</select>
														</div>
														
														
														<div class="col-6">
															<select name="card_expiry_year" class="form-select form-select-solid" data-control="select2" data-hide-search="true" data-placeholder="Year">
																<option></option>
																<option value="2024">2024</option>
																<option value="2025">2025</option>
																<option value="2026">2026</option>
																<option value="2027">2027</option>
																<option value="2028">2028</option>
																<option value="2029">2029</option>
																<option value="2030">2030</option>
																<option value="2031">2031</option>
																<option value="2032">2032</option>
																<option value="2033">2033</option>
																<option value="2034">2034</option>
															</select>
														</div>
														
													</div>
													
												</div>
												
												
												<div class="col-md-4 fv-row">
													
													<label class="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
														<span class="required">CVV</span>
														<span class="ms-1" data-bs-toggle="tooltip" title="Enter a card CVV code">
															<i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
														</span>
													</label>
													
													
													<div class="position-relative">
														
														<input type="text" class="form-control form-control-solid" minlength="3" maxlength="4" placeholder="CVV" name="card_cvv" />
														
														
														<div class="position-absolute translate-middle-y top-50 end-0 me-3">
															<i class="ki-outline ki-credit-cart fs-2hx"></i>
														</div>
														
													</div>
													
												</div>
												
											</div>
											
											
											<div class="d-flex flex-stack">
												
												<div class="me-5">
													<label class="fs-6 fw-semibold form-label">Save Card for further billing?</label>
													<div class="fs-7 fw-semibold text-muted">If you need more info, please check budget planning</div>
												</div>
												
												
												<label class="form-check form-switch form-check-custom form-check-solid">
													<input class="form-check-input" type="checkbox" value="1" checked="checked" />
													<span class="form-check-label fw-semibold text-muted">Save Card</span>
												</label>
												
											</div>
											
										</div>
									</div>
									
									
									<div data-kt-stepper-element="content">
										<div class="w-100 text-center">
											
											<h1 class="fw-bold text-gray-900 mb-3">Release!</h1>
											
											
											<div class="text-muted fw-semibold fs-3">Submit your app to kickstart your project.</div>
											
											
											<div class="text-center px-4 py-15">
												<img src="assets/media/illustrations/sketchy-1/9.png" alt="" class="mw-100 mh-300px" />
											</div>
											
										</div>
									</div>
									
									
									<div class="d-flex flex-stack pt-10">
										
										<div class="me-2">
											<button type="button" class="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous" onClick={prevStep}>
											<i class="ki-outline ki-arrow-left fs-3 me-1"></i>Back</button>
										</div>
										
										
										<div>
											<button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="submit" onClick={submitStep}>
												<span class="indicator-label">Submit 
												<i class="ki-outline ki-arrow-right fs-3 ms-2 me-0"></i></span>
												<span class="indicator-progress">Please wait... 
												<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
											</button>
											<button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="next" onClick={submitStep}>Continue 
											<i class="ki-outline ki-arrow-right fs-3 ms-1 me-0"></i></button>
										</div>
										
									</div>
									
								</form>
								
							</div>
							
						</div>
    </Content>
    </>
  )
}

export default Wizard
