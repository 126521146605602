import React from "react";

const Content = ({ children }) => {
  return (
    <div id="kt_app_content" class="app-content flex-column-fluid">
      <div id="kt_app_content_container" class="app-container container-fluid">
        <div class="row g-7">{children}</div>
      </div>
    </div>
  );
};

export default Content;
