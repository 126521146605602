import React, { useState } from "react";


import SportCard from "./SportCard";
import Toolbar from "../../../../Common/Toolbar";
import Content from "../../../../Common/Content";


const ViewSportPage = () => {
  const tabs = ["API Configuration Page", "Formats", "Roles","Players", "Country", "Cricket Boards", "Association Cricket Board", "Teams"]
  const [activeTab, setActiveTab] = useState(tabs[1])
  return (
    <>
      <Toolbar text={"Sports"} />
      <Content>
      
								<SportCard tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
								{/* {
                  activeTab === tabs[0] ?  <AssignCompany />
                  : activeTab === tabs[1] ? <Permissions />
                  : <Logs />
                } */}
      </Content>
    </>
  );
};

export default ViewSportPage;
