export const submitAction = (state,action)=>{
    // console.log("submit",action)
    const data = {
    subject: action.payload.subject,
    user: action.payload.user,
    due_date: action.payload.due_date,
    description: action.payload.description,
    product:action.payload.product,
    notifications: action.payload.notifications,
}
    return {
        ...state,
        ...data
    }
}