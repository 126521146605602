import { routesApi } from "../../../../RouterFiles/routesApi";
import instance from "../../../../utilities/axios";
import url from "../../../../utilities/url";

export const getList = (headers,body) => {
    return instance
      .post(`${url.base_api}${routesApi.series}/${routesApi.list}`,body)
      .then((res) => {
        return res;
      });
  };
  export const getFilterList = (headers) => {
    return instance
      .get(`${url.base_api}${routesApi.series}/${routesApi.filters}`)
      .then((res) => {
        return res;
      });
  };
export const getFetch = (headers,body) => {
    return instance
      .get(`${url.base_api}${routesApi.series}/${routesApi.fetch}`,body)
      .then((res) => {
        return res;
      });
  };
export const assignCompany = (headers,id,body) => {
    return instance
      .post(`${url.base_api}${routesApi.series}/${routesApi.assign}/${id}`,body,{
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };
export const getSeriesId = (headers, id) => {
    return instance
      .get(`${url.base_api}${routesApi.series}/${routesApi.details}/${id}`, {
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };

export const getSeriesRulesbyId = (headers, id) => {
  return instance
    .get(`${url.base_api}${routesApi.series}/${routesApi.rules}/${id}`, {
      headers: headers
    })
    .then((res) => {
      return res;
    });
};

export const patchSeriesRulesbyId = (headers, id,data) => {
  return instance
    .patch(`${url.base_api}${routesApi.series}/${routesApi.rules}/${id}`, data,{
      headers: headers
    })
    .then((res) => {
      return res;
    });
};




export const add = (headers, data) => {
    console.log("add:::",data)
    return instance
      .post(`${url.base_api}${routesApi.series}`,data)
      .then((res) => {
        return res;
      });
  };
export const update = (headers, data,id) => {
    console.log("update:::",data)
    return instance
      .post(`${url.base_api}${routesApi.series}/${id}`,data)
      .then((res) => {
        return res;
      });
  };
  export const remove = (headers,id) => {
    console.log("delete:::",id)
    return instance
      .delete(`${url.base_api}${routesApi.series}/${id}`)
      .then((res) => {
        return res;
      })
     
      ;
  };

  