import React, { useEffect, useState } from 'react'

const DataExpand = ({ data, head }) => {
  const [show, setShow] = useState(false);

  return (
    <div onClick={() => setShow(!show)}>
      {!show && <span style={{ fontSize: "1rem", color: "#bf13e7", fontWeight: "600" }}>{head}</span>}
      {show && <pre style={{ margin: 0 }}>{JSON.stringify(data, null, 2)}</pre>}
    </div>
  );
};

export default DataExpand;
