import React, { useEffect, useRef, useState } from "react";
import SelectDropdownWithSearch from "../../Common/SelectDropdownWithSearch";
import { getCountryDropdown } from "../Master/Country/redux/country.action";
import { useDispatch, useSelector } from "react-redux";
import ButtonWithLoader from "../../Common/ButtonWithLoader";
import { errorMessage } from "../../Common/SwalToast";
import { formValidation } from "@form-validation/bundle/popular";
import { Bootstrap5 } from "@form-validation/plugin-bootstrap5";
import { Trigger } from "@form-validation/plugin-trigger";
import Content from "../../Common/Content";
import Table from "../../Common/TableWithTan/Table";
import { legalityColumn } from "./LegalityCoulmn";
import TextInputField from "../../Common/TextInputField";
import TextEditor from "../../Common/TextEditor";
import SelectDropDown from "../../Common/SelectDropDown";
import { fetchCompanyDropdown } from "../Company/redux/company.action";
const gameplay = [
    {label:"Dabba", value:"Dabba"},
    {label:"Auction", value:"Auction"},
]
const Legality = () => {
  const [countryOptions, setCountryOptions] = useState([]);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  const { dropDownList, error } = useSelector((state) => state.companies);

  const formRef = useRef();
  const [validator, setValidator] = useState(null); // Store form validation instance
  const [add, setAdd] = useState(true)
    const [gp, setGameplay] = useState('')
    const [company, setCompany] = useState({})
  const handleSelectChange = (name, value) => {
    console.log("handleSelectChange",name, value);
    if(name == "company"){

        setCompany(value)
        setFormData((prev) => {
          return {
            ...prev,
            [name]: value?.code,
          };
        });
    }
    else if(name == "gameplay_type"){
        setGameplay(value)
        setFormData((prev) => {
            return {
              ...prev,
              [name]: value?.value,
            };
          })
    }
    else{
        setFormData((prev) => {
            return {
              ...prev,
              [name]: value,
            };
          })
    }
  };
  const checkValidation = (e) => {
    e.preventDefault();
    if (validator) {
      validator.validate().then(function (status) {
        if (status === "Valid") {
          //   setIsSubmitting(true);
          //   if (data) dispatch(updateCurrency({ data: formData, id: data?._id }));
          //   else dispatch(addCurrency({ data: formData }));
        } else {
          let text =
            "Sorry, looks like there are some errors detected, please try again.";
          errorMessage(text);
        }
      });
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handlerCreate = ()=>{
    console.log(formData)
  }
  useEffect(() => {
    const form = formRef.current;
    if (!form) return;
    const validationFields = {
      fields: {
        country: {
          validators: {
            notEmpty: {
              message: "Country is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap5({
          rowSelector: ".fv-row",
          eleInvalidclassName: "",
          eleValidclassName: "",
        }),
      },
    };
    const fv = formValidation(form, validationFields);
    setValidator(fv);
  }, []);
  useEffect(() => {
    setCountryOptions(dropDownList);
  }, [dropDownList]);
  return (
    <>
      <Content>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <div className="fv-row mb-7 d-flex flex-column">
                  <label class="fs-6 fw-semibold form-label mt-3">
                    <span className={`required`}>Country Code</span>
                  </label>
                  <SelectDropdownWithSearch
                    name="country"
                    required={"true"}
                    //   label="Country"
                    placeholder={"Select a country code"}
                    options={countryOptions}
                    value={
                      countryOptions?.find(
                        (option) => option.name === company.name
                      ) || formData?.country
                    }
                    onChange={(selectedOption) => {
                      handleSelectChange("country", selectedOption);
                    }}
                    handleClick={() => {
                        // if (dropDownList?.length==0){
                            dispatch(fetchCompanyDropdown({}));
                        //   }
                    }}
                  />
                </div>
              </div>
              <div class="col-md-4 col-6 d-flex align-items-center justify-content-end pt-3">
                <ButtonWithLoader
                  text={"Submit"}
                  handlerSubmit={checkValidation}
                />
              </div>
              <div class="col-md-4 col-6 d-flex align-items-center justify-content-start pt-3">
                <ButtonWithLoader
                  text={"Add"}
                  handlerSubmit={checkValidation}
                />
              </div>
            </div>
          </div>
        </div>
        {
            add && 
            <div className="card">
            <div className="card-body">
                <div className="row">
                <div class="col-md-4 col-sm-6 col-12 mt-2">
                <div className="fv-row mb-7 d-flex flex-column">
                  <label class="fs-6 fw-semibold form-label mt-3">
                    <span className={`required`}>Country Code</span>
                  </label>
                  <SelectDropdownWithSearch
                    name="company"
                    required={"true"}
                    //   label="company"
                    placeholder={"Select a company code"}
                    options={countryOptions}
                    value={
                      countryOptions?.find(
                        (option) => option.name === company.name
                      ) 
                    }
                    onChange={(selectedOption) => {
                      handleSelectChange("company", selectedOption);
                    }}
                    handleClick={() => {
                        // if (dropDownList?.length==0){
                            dispatch(fetchCompanyDropdown({}));
                        //   }
                    }}
                  />
                </div>
              </div>
                <div class="col-md-4 col-sm-6 col-12 mt-2">
              <TextInputField
                required={"true"}
                label={"slug"}
                value={formData?.slug}
                name={"slug"}
                type={"text"}
                onChange={handleChange}
                placeholder={"Enter slug"}
              />
            </div>
            <div class="col-md-4 col-sm-6 col-12 mt-2">
              <TextInputField
                required={"true"}
                label={"title"}
                value={formData?.title}
                name={"title"}
                type={"text"}
                onChange={handleChange}
                placeholder={"Enter title"}
              />
            </div>
            <div class="col-md-4 col-sm-6 col-12 mt-2">
 
              <SelectDropDown 
               required={"true"}
               label={"gameplay_type"}
              
               name={"gameplay_type"}
              options={gameplay}
            
               placeholder={"Enter gameplay_type"}
             
                    value={
                      gameplay?.find(
                        (option) => option.label === gp.label
                      ) 
                    }
                    onChange={(selectedOption) => {
                      handleSelectChange("gameplay_type", selectedOption);
                    }}
              />
            </div>
            <div class=" col-12 mt-2">
             <TextEditor 
             name={"gameplay_type"}
             value={formData?.content}
             label={"Content"}
             onChange={handleSelectChange}
             />
            </div>
            <div class="col-md-4 col-6 d-flex align-items-center justify-content-start pt-3 mt-4">
                <ButtonWithLoader
                  text={"Save"}
                  handlerSubmit={handlerCreate}
                />
              </div>
                </div>
            </div>
          </div>

        }
        {
          // formData?.country?.name &&
          <div className="card">
            <div className="card-body">
              {
                //   isloading ? <Loader />
                //     :
                <Table
                  column={legalityColumn}
                  tableData={[
                    {
                        "_id": "6737308b00064954b972c903",
                        "title": "Privicy Policies",
                        "slug": "privicypolicies",
                        "gameplay_type": "dabba",
                        "content": [
                            "<ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul><ul><li>Public Auction </li><li>1.Edit Rules here </li></ul>"
                        ],
                        "is_active": true
                    }
                ]}
                //   totalPages={totalPages}
                //   totalRecord={totalRecord}
                //   pageIndex={pageNo}
                //   recordPerPage={recordPerPages}
                //   updateEdit={updateEdit}
                //   updateDelete={updateDelete}
                //   handlerChangeDropDown={(index) => {
                //     setPageNo(0)
                //     setRecordPerPage(index)
                //   }
                //   }
                //   handlePageChange={(index) => {
                //     setPageNo(index)
                //   }}
                />
              }
            </div>
          </div>
        }
      </Content>
    </>
  );
};

export default Legality;
