import React, { useEffect, useState } from "react";
import Table from "../../../Common/TableWithTan/Table";
import { sportColumn } from "../Sport/SportColumn";
import { useNavigate } from "react-router";
import FilterPopover from "../../../Common/TableWithTan/FilterPopover";
import Toolbar from "../../../Common/Toolbar";
import Content from "../../../Common/Content";
import { useDispatch, useSelector } from "react-redux";
import { fetchFilterList, fetchList, fetchRefetchList } from "./redux/series.action";
import { seriesColumn } from "./SeriesColumn";
import Loader from "../../../MasterLayout/Loader";
import { errorMessage, successMessage } from "../../../Common/SwalToast";
import { error } from "jquery";
import SearchBox from "../../../Common/SearchBox";
import ButtonWithLoader from "../../../Common/ButtonWithLoader";
import DateRangePickerBasic from "../../../Common/DateRangePickerBasic";
import moment from "moment";
import SelectDropdownWithSearch from "../../../Common/SelectDropdownWithSearch";
import { getDate, getUnixTimeStamp } from "../../../utilities/dateUtils";
import { addAssignCompany, updateCompanyCode } from "./redux/series.slice";

const ViewSeriesList = () => {

  const [pageNo, setPageNo] = useState(0)
  const [recordPerPages, setRecordPerPage] = useState(10)
  const [searchValue, setSearchValue] = useState({})
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const dispatch = useDispatch()
  const {list,loading, totalPages,totalRecord,fetchLoad,fetchSeries,error,filterData,assignCompany} = useSelector(state => state.series)
  const navigate = useNavigate()

  const updateView = (rowIndex) => {
    // console.log(list[rowIndex]?._id)
    dispatch( updateCompanyCode({code:'SAAS'}))
    localStorage.setItem('code','SAAS')
    navigate('/series/details/'+list[rowIndex]?._id)
  }
const handleFetch = ()=>{
    dispatch(fetchRefetchList({}))
}
  const updateStatus = (rowIndex)=>{
    console.log(list[rowIndex])
    dispatch(addAssignCompany({data: list[rowIndex]?.companies?.[0]?.code}))
  }
useEffect(()=>{
  dispatch(fetchList({pageNo:pageNo+1, recordPerPage: recordPerPages}))
},[pageNo,recordPerPages])
useEffect(()=>{
  console.log(fetchSeries,"fetctseries")
  if(fetchSeries?.message){
    successMessage(fetchSeries?.message)
  }
},[fetchSeries])
useEffect(()=>{
  console.log(assignCompany,"assignCompany")
  if(assignCompany?.message){
    successMessage(assignCompany?.message)
    dispatch(fetchList({pageNo:0, recordPerPage: recordPerPages}))
  }
},[assignCompany])
useEffect(()=>{
  if(error){
    errorMessage(error)
  }
},[error])
useEffect(()=>{
    dispatch(fetchFilterList({}))
},[])
  return (
    <>
    <Toolbar text={"Series"}  isSeries={true} handleClick={handleFetch} isLoading={fetchLoad} />
    <Content >
    
    <div className="card">
    
 
        <div className="card-body">
          {
            loading ? <Loader /> : 
        <div className="row">
            <div className="col-md-6 col-12 mt-4">
            <SelectDropdownWithSearch
                  name="association_key"
                  //   required={"true"}
                  //   label="Country"
                  placeholder={"Select a Association key"}
                  options={filterData?.association}
                  value={filterData?.association?.find((option) => option.key === searchValue?.association_key) || searchValue?.association_key}
                  onChange={(selectedOption) => {
                    // handlerCurrency("inCurrency", selectedOption)
                    setSearchValue(prev => {
                      return { 
                      ...prev,
                      "association_key":selectedOption?.key
                    }
                    })
                  }
                  }
                  isImage={false}
                />

            </div>
            <div className="col-md-6 col-12 mt-4">
            <SelectDropdownWithSearch
                  name="country_code"
                  //   required={"true"}
                  //   label="Country"
                  placeholder={"Search by country code"}
                  options={filterData?.countries}
                  value={filterData?.countries?.find((option) => option.short_code === searchValue?.country_code) || searchValue?.country_code}
                  onChange={(selectedOption) => {
                    // handlerCurrency("inCurrency", selectedOption)
                    setSearchValue(prev => {
                      return { 
                      ...prev,
                      "country_code":selectedOption?.short_code
                    }
                    })
                  }
                  }
                  isImage={false}
                />
        
        
            </div>
            <div className="col-md-6 col-12 mt-4">
            <SelectDropdownWithSearch
                  name="gender"
                  //   required={"true"}
                  //   label="Country"
                  placeholder={"Search by country code"}
                  options={filterData?.gender}
                  value={filterData?.gender?.find((option) => option.name === searchValue?.gender) || searchValue?.gender}
                  onChange={(selectedOption) => {
                    // handlerCurrency("inCurrency", selectedOption)
                    setSearchValue(prev => {
                      return { 
                      ...prev,
                      "gender":selectedOption?.name
                    }
                    })
                  }
                  }
                  isImage={false}
                />
              
            </div>
            <div className="col-md-6 col-12 mt-4">
            <SelectDropdownWithSearch
                  name="format"
                  //   required={"true"}
                  //   label="Country"
                  placeholder={"Search by country code"}
                  options={filterData?.format}
                  value={filterData?.format?.find((option) => option.name === searchValue?.format) || searchValue?.format}
                  onChange={(selectedOption) => {
                    // handlerCurrency("inCurrency", selectedOption)
                    setSearchValue(prev => {
                      return { 
                      ...prev,
                      "format":selectedOption?.name
                    }
                    })
                  }
                  }
                  isImage={false}
                />
           
            </div>
            <div className="col-md-6 col-12 mt-4">

            <DateRangePickerBasic 
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            />
            </div>
            <div className="col-6 mt-4 text-end">
              <ButtonWithLoader text="Submit"  handlerSubmit= {()=>{
                console.log(startDate, endDate, getDate(getUnixTimeStamp(startDate)))
                
                dispatch(fetchList({ pageNo: pageNo+1, recordPerPage: recordPerPages, search : searchValue ,date_range:{start:getUnixTimeStamp(startDate), end: getUnixTimeStamp(endDate)}}))
              }}/>
            </div>
            </div>
          }
          {/* <FilterForm  /> */}
            {/* <Filter columnFilters={columnFilters} setColumnFilters={setColumnFilters} /> */}

            {/* <div className="col-md-4 col-6 mt-2">
                <FilterPopover
                  option={columnFilters}
                  setOption={setColumnFilters}
                  filterValues={seriesList}
                  id="series"
                  text="Series Type"
                />
              </div> */}
            <div
              className="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
              {
                loading ? <Loader />  :
                <Table
                  column={seriesColumn}
                  tableData={list}
                  isCompanyAssign = {true}
                  recordPerPage={recordPerPages}
                  totalPages={totalPages}
                  totalRecord={totalRecord}
                  pageIndex={pageNo}
                  updateView={updateView}
                  updateStatus={updateStatus}
                  handlerChangeDropDown = {(index)=> 
                    {
                     
                      setPageNo(0);
                    setRecordPerPage(index)
                    }
                  }
                  handlePageChange = {(index) => {
                    
                    setPageNo(index)
                  }}
                />
              }
            </div>
           
          </div>
    </div>
    </Content>
    </>
  );
};

export default ViewSeriesList;
