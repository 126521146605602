import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataExpand from "./DataExpand";

const MatchTable = ({ matches }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(matches);
  const gotoMatchesList = (match) => {
    localStorage.setItem("matchItem", match.name);
    console.log("match", match);
    navigate(`/fantasy-matches-details/${match?.key}`);
  };
  return (
    <>
      <div className="table-responsive">
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          key={matches}
          style={{ color: "#rgb(0, 0, 0) !important" }}
        >
          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
            <th>Match Key</th>
            <th>Name</th>
            <th>Short Name</th>
            <th>Sub Title</th>
            <th>Status</th>
            <th>Format</th>
            <th>Start Time</th>
            <th>Expected StartAt</th>
            <th>Completed Date Approximate</th>
            <th>Tournament</th>
            <th>TEAMS</th>
            <th>Venue</th>
          </tr>
          <tbody className="text-gray-600 fw-bold">
            {data.map((match) => {
              return (
                <tr key={match?.key}>
                  <td
                    style={{
                      fontSize: "1rem",
                      color: "#4519ff",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      gotoMatchesList(match);
                    }}
                  >{`${match.key}`}</td>
                  <td>{`${match.name}`}</td>
                  <td>{`${match.short_name}`}</td>
                  <td>{`${match.sub_title}`}</td>
                  <td>{`${match.status}`}</td>
                  <td>{`${match.format}`}</td>
                  <td>{`${match.start_at}`}</td>
                  <td>{`${match.expected_start_at}`}</td>
                  <td>{`${match.completed_date_approximate}`}</td>
                  <td>
                    <DataExpand
                      head={match?.tournament?.key}
                      data={match?.tournament}
                    />
                  </td>
                  <td>
                    <DataExpand
                      head={`${match?.teams?.a?.name} VS ${match?.teams?.b?.name}`}
                      data={match.teams}
                    />
                  </td>
                  <td>
                    <DataExpand
                      head={match?.venue?.key}
                      data={match?.venue}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MatchTable;
