
const StatusCell = ({ status }) => {

  return (

    <> {status == "Active" ? <div className='badge badge-light-success fw-bolder'>{status}</div>
      : <div className="badge badge-light-danger">{status}</div>
    }</>
  )
}

export { StatusCell }
