import React, { useEffect, useState } from 'react'
import SelectDropdownWithSearch from '../../../../../Common/SelectDropdownWithSearch'

import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../../MasterLayout/Loader'
import Table from '../../../../../Common/TableWithTan/Table'
import {teamsColumn} from './TeamsColumn.js';
import ButtonWithLoader from '../../../../../Common/ButtonWithLoader'

import {successMessage }from '../../../../../Common/SwalToast.js'
import { getTeamFetchById, getTeamsList, saveTeam } from './redux/teams.action.js'
const Teams = ({id}) => {
  const [formData, setFormData] = useState([])
  const dispatch = useDispatch()
  const {teamLoad,teams,save,saveLoad,list,loading} = useSelector(state => state.teams)
  const {companyCode} = useSelector(state => state.series)
  const [columnVisibility, setColumnVisibility] = useState();

  const updateEdit = (rowIndex, name,value)=>{
    console.log(rowIndex, name,value)
      let temp = [...formData]
      temp[rowIndex] ={...temp[rowIndex], [name]:value}
      setFormData(temp)
  }
  const handlerSave = ()=>{
    let t = null
    if(companyCode == 'SAAS'){

      t = list?.reduce((acc,team)=>{
       acc.push(team?.key)
       console.log("acc",acc)
       return acc
     },[])
     dispatch(saveTeam({id:id, companyCode:companyCode,data:{teams:formData}}))
    }
    else{
      dispatch(saveTeam({id:id, companyCode:companyCode,data:{teams:formData}}))
    }
    console.log("t>>>>>>",formData)
    
  }
  const handleFetch = ()=>{
    dispatch(getTeamFetchById({id:id,companyCode:companyCode}))
    // dispatch(getTeamsList({id:id,companyCode:companyCode}))
    
  }
  useEffect(()=>{
    if(companyCode == 'SAAS'){
      setColumnVisibility({
        new_team_name: false,
        icon: false, //hide this column by default
       
      })
    }
    dispatch(getTeamsList({id:id,companyCode:companyCode}))
    
  },[])
  useEffect(()=>{
    console.log("save message", save)
    if(save?.message)
      successMessage(save?.message)
  },[save])
  useEffect(()=>{
    console.log("teams message", teams)
    if(teams?.message)
      successMessage(teams?.message)
  },[teams])
  useEffect(()=>{
    if(list)
      setFormData(list)
  },[list])

  return (
    <>

      <div className='card'>
        <div className="card-header ">
          <div className="card-title d-flex align-items-center col-4">
            <h3 className="fw-bold m-0 text-gray-800">Teams</h3>
          </div>
          <div className="card-toolbar col-6 d-flex">
            {/* <div className="col-6 ">
            <SelectDropdownWithSearch
              name="country"
              //   required={"true"}
              //   label="Country"
              placeholder={"Select a country"}
              options={countryOptions}
              value={countryOptions.find(
                (option) => option.value === formData?.country?.value
              )}
              onChange={(selectedOption) => {
                console.log(selectedOption);
                handleSelectChange("country", selectedOption);
              }}
            />
            </div> */}
            
             <div className="col-6 text-center">

             <ButtonWithLoader text={"Fetch & Refetch"} 
             handlerSubmit={handleFetch} 
            //  isLoading={isLoading} 
             />
             </div>
          </div>
          
        </div>
        <div className="card-body">
          <div className="row">

          </div>

        </div>
      </div>
      {/* {
        formData?.country && <> */}
{
  loading ? <Loader /> :
  list?.length > 0 && 
          <div className='card'>
            <div className="card-header card-header-stretch">
              <div className="card-title d-flex align-items-center">
                <h3 className="fw-bold m-0 text-gray-800"> list</h3>
              </div>

            </div>
            <div className="card-body">
            {

  <>
            <Table
                  column={teamsColumn}
                  tableData={list}
                  updateEdit ={updateEdit}
                  columnVisibility={columnVisibility}
                  // recordPerPage={recordPerPages}
                  // totalPages={totalPages}
                  // totalRecord={totalRecord}
                  // pageIndex={pageNo}
                 
                 
                  
                />
                <div>
                <ButtonWithLoader text="Save" handlerSubmit={handlerSave} isLoading={saveLoad}  />
                </div>
                </>
}
            </div>



          </div>
}
          

        {/* </>
      } */}
    </>
  )
}

export default Teams