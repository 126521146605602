import React, { useEffect, useState } from 'react'

import {  saveAssignCompany,  savePlayer } from './redux/assignCompany.action.js'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../../MasterLayout/Loader.jsx'
import Table from '../../../../../Common/TableWithTan/Table.jsx'
import {assginCompanyColumn} from './AssignCompanyColumn.js'
import ButtonWithLoader from '../../../../../Common/ButtonWithLoader.jsx'

import {successMessage }from '../../../../../Common/SwalToast.js'
import { getCountriesCompany } from '../../../../Company/redux/company.action.js'
import { getSeriesById } from '../../redux/series.action.js'

const AssignCompnay = ({id}) => {
  const [formData, setFormData] = useState([ {
    "company_id": "",
    "code": "",
    "is_shared": "",
    "is_auction": "",
    "is_dabba:": "",
    "countries": []
}])
const [payload, setPayload] = useState([])
  const dispatch = useDispatch()
  const {companyCountries, compCnty} = useSelector(state => state.companies)
  const [columnVisibility, setColumnVisibility] = useState();
  const {companyCode, viewSeries} = useSelector(state => state.series)
  const {saveLoading, save} = useSelector(state => state.assignCompanySeries)
  const updateEdit = (rowIndex, name, value) => {
    console.log(rowIndex, name,value)
    setPayload(prevFormData => {
      const keyToUpdate = companyCountries[rowIndex]?._id;
  
      // Check if the item with the key already exists in formData
      const existingItemIndex = prevFormData.findIndex(ele => ele?.company_id === keyToUpdate);
  
      if (existingItemIndex !== -1) {
        // If it exists, update the existing item
        const updatedData = [...prevFormData];
        updatedData[existingItemIndex] = {
          ...updatedData[existingItemIndex],
          [name]: value
        };
        return updatedData;
      } else {
        // If it doesn't exist, add a new item
        return [
          ...prevFormData,
          { company_id: keyToUpdate, code: companyCountries[rowIndex]?.code, [name]: value }
        ];
      }
    });
  };
  const handleSelectChange = (name, value) => {
    setFormData(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
    // setGame(value)
  };
 

  const handlerSave = ()=>{

    console.log("t>>>>>>",payload)
    dispatch(saveAssignCompany({id:id,companyCode:'SAAS',data:{companies:payload}}))
  }

  useEffect(()=>{
    if(companyCode == 'SAAS'){
      setColumnVisibility({
        new_player_name: false,
        image: false,
        auction_price:false
       
      })
    }
    dispatch(getCountriesCompany({}))
    
  },[])
  useEffect(()=>{
    if(save?.message){

      successMessage(save?.message)
      dispatch(getSeriesById({id:id,companyCode:'SAAS'}))
    }

  },[save])
  // useEffect(()=>{
  //   if(saveP?.message)
  //     successMessage(saveP?.message)
  // },[saveP])
  const companies = ()=>{
    const mergedData = companyCountries.map(item => {
      // Find a matching company by _id
      const matchingCompany = viewSeries?.companies.find(company => company.company_id === item._id);
    
      if (matchingCompany) {
        // If a match is found, merge the company data and set disabled to true
        return {
          ...item,
          ...matchingCompany,
        
        };
      }
    
      // If no match is found, return the item as is
      return item;
    });
    setFormData( mergedData)
  }
  useEffect(()=>{
   if(companyCountries)
    companies()

  },[companyCountries])
  // console.log(companyCountries)
  return (
    <>

      <div className='card'>
        <div className="card-header ">
          <div className="card-title d-flex align-items-center col-4">
            <h3 className="fw-bold m-0 text-gray-800">Assign Company</h3>
          </div>

          
        </div>
        <div className="card-body">
            {
  compCnty ? 
  <Loader /> :
  <>
            <Table
                  column={assginCompanyColumn}
                  tableData={formData}
                  columnVisibility={columnVisibility}
                  updateEdit ={updateEdit}
                  // recordPerPage={recordPerPages}
                  // totalPages={totalPages}
                  // totalRecord={totalRecord}
                  // pageIndex={pageNo}
                 
                 
                  
                />
                <div>
                <ButtonWithLoader text="Save" handlerSubmit={handlerSave} isLoading={saveLoading}  />
                </div>
                </>
}
            </div>
      </div>
      {/* {
        formData?.country && <> */}

          

        {/* </>
      } */}
    </>
  )
}

export default AssignCompnay