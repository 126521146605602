import { createSlice } from "@reduxjs/toolkit";
import {
  addSport,
  deleteSport,
  fetchList,
  getSportById,
  updateSport,
} from "./sport.action";

export const sportSlice = createSlice({
  name: "sportsState",
  initialState: {
    pageNo: 1,
    recordPerPage: 10,
    totalRecord: 0,
    totalPages: 0,
    sports: [],
    sport: "",
    viewSport: "",
    delSport: "",
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSport.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(addSport.fulfilled, (state, action) => {
      // state.loading = false;
      state.sport = action.payload;
    });
    builder.addCase(addSport.rejected, (state, action) => {
      // state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(updateSport.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(updateSport.fulfilled, (state, action) => {
      // state.loading = false;
      state.sport = action.payload;
    });
    builder.addCase(updateSport.rejected, (state, action) => {
      // state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(fetchList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.loading = false;
      state.sports = action.payload?.data;
      state.sport = "";
      state.viewSport = "";
      state.delSport = "";
      state.recordPerPage = action.payload?.recordPerPage;
      state.pageNo = action.payload?.pageNo;
      state.totalRecord = action.payload?.totalRecord;
      state.totalPages = action.payload?.totalPages;
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error?.message;
    });
    builder.addCase(deleteSport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSport.fulfilled, (state, action) => {
      state.loading = false;
      state.delSport = action.payload;
    });
    builder.addCase(deleteSport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(getSportById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSportById.fulfilled, (state, action) => {
      state.loading = false;
      state.sport = "";
      state.viewSport = action.payload.data;
    });
    builder.addCase(getSportById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
  },
});

export const { addSportReducer } = sportSlice.actions;
export default sportSlice.reducer;
