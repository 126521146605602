import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery'; // jQuery is needed for daterangepicker plugin
import 'daterangepicker'; 
const DateRangePickerBasic1 = ({startDate,endDate,setStartDate,setEndDate}) => {
  
    const inputRef = useRef(null);
   
    useEffect(() => {
        const input = $(inputRef.current);

        function cb(start, end) {
            setStartDate(start);
            setEndDate(end);
            input.html(`${start.format('MM/DD/YYYY hh:mm A')} - ${end.format('MM/DD/YYYY hh:mm A')}`);
        }

        input.daterangepicker({
            timePicker: true,
            startDate: moment().startOf("hour"),
            endDate: moment().startOf("hour").add(32, "hour"),
            locale: {
                format: "MM/DD/YYYY hh:mm A"
            }
        },cb);

        // Call the callback to set the initial value
        cb(startDate, endDate);

        return () => {
            // Clean up daterangepicker on unmount
            input.daterangepicker('remove');
        };
    }, [startDate, endDate]);
  return (
    <>

<div class="fv-row mb-7">
<label class="form-label">Basic example</label>
<input 
    type="text" 
    id="kt_ecommerce_report_sales_daterangepicker" 
    ref={inputRef} 
    // value={`${startDate.format('MM/DD/YYYY hh:mm A')} - ${endDate.format('MM/DD/YYYY hh:mm A')}`}
    readOnly
    class="form-control form-control-solid" placeholder="Pick date rage"
/>
</div>
</>
  )
}

export default DateRangePickerBasic1
