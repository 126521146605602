import React from 'react'
import { image_url } from '../../../utilities/url'

const CountryImageCell = ({props}) => {

  return (
    <div className='d-flex gap-3'>
      {
        props.getValue() &&  <img className='icon-40' src={image_url+ '/'+props.getValue()} alt="flag" />
      }
      {
        props.row.original.type === 'Virtual currency' && 
        <img className='icon-30' src={image_url+ '/'+props.row.original.img} alt="flag" />
      }
      <p>{props.row.original.name}</p>
    </div>
  )
}

export default CountryImageCell