import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Toolbar from '../../Common/Toolbar'
import Content from '../../Common/Content'
import { routesFrontend } from '../../RouterFiles/routesFrontend'
import Table from '../../Common/TableWithTan/Table'
import { companyColumn } from './CompanyColumn'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCompany, fetchList, getCompanyById } from './redux/company.action'
import { errorMessage, successMessage } from '../../Common/SwalToast'
import Loader from '../../MasterLayout/Loader'
import CreateCompany from './CreateCompany'
import WithAddButton from '../../../hooks/WithAddButton'


const ViewCompanyList = ({handleAddBtn, addBtn}) => {
    const navigate = useNavigate()
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const { loading, error, recordPerPage, totalPages,totalRecord,companies, company,delCompany,viewCompany } = useSelector(state => state.companies)
  const [isLoading, setLoading] = useState(false)
  const [updateData, setUpdateData] = useState(null)
  const [pageNo, setPageNo] = useState(0)
  const [recordPerPages, setRecordPerPage] = useState(10)
  useEffect(() => {
    dispatch(fetchList({ pageNo: pageNo + 1 , recordPerPage: recordPerPages }))
  }, [pageNo,recordPerPages])
  useEffect(() => {
    console.log("companiess::::", companies)
    setData(companies)
  }, [companies])

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  useEffect(() => {
    if (error) {
      errorMessage(error || 'Something went wrong')
    }
  }, [error])
  useEffect(() => {

    if (delCompany?.message) {
      console.log(delCompany)
      successMessage(delCompany.message)
      dispatch(fetchList({ pageNo: 0, recordPerPage: recordPerPages }))
    }
  }, [delCompany])

  useEffect(() => {
    console.log("company::",  company)
    if (company?.message) {
      console.log(company)
      successMessage(company.message)
      handleAddBtn(false)
      dispatch(fetchList({ pageNo: 0, recordPerPage: recordPerPages }))
    }
  }, [company])

  const updateView = (rowIndex) => {
    console.log("view:::",data)
    navigate('/company/details/' + data[rowIndex]?._id)
  }

  const updateEdit = (rowIndex) => {
    handleAddBtn(false)
    handleAddBtn(true)
    console.log(data[rowIndex]?._id)
    dispatch(getCompanyById({id:data[rowIndex]?._id}))
    setUpdateData(data[rowIndex]?._id)
    // navigate('/' + routesFrontend.language + '/edit/' + tableData[rowIndex]?._id)
  }
  const updateDelete = (rowIndex) => {

    console.log(data[rowIndex]?._id, error)

    dispatch(deleteCompany({ id: data[rowIndex]?._id }))
  }
  console.log("data:::::", data)

  return (
    <>
    <Toolbar text={"Companies"} isButton={true} handleClick={()=> {
        setUpdateData(null)
        handleAddBtn(!addBtn)
        }} textBtn={"Add Company"} addBtn={addBtn} />
    <Content>
    {
          addBtn &&
          <CreateCompany data={updateData} />
        }
        <div className='card'>
                <div className='card-body'>
                <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
              {
                isLoading ? <Loader /> :

                  <Table
                    column={companyColumn}
                    tableData={data}
                    recordPerPage={recordPerPages}
                    totalPages={totalPages}
                    totalRecord={totalRecord}
                    pageIndex={pageNo}
                    updateView={updateView}
                    updateEdit={updateEdit}
                    updateDelete={updateDelete}
                    handlerChangeDropDown = {(index)=> 
                      {
                       
                        setPageNo(0);
                      setRecordPerPage(index)
                      }
                    }
                    handlePageChange = {(index) => {
                      
                      setPageNo(index)
                    }}
                  // columnFilters={columnFilters}
                  // setColumnFilters={setColumnFilters}
                  />
              }
            </div>
                </div>
        </div>
    </Content>
    </>
  )
}

export default WithAddButton(ViewCompanyList)