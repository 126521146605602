
import RemoveButtonCell from "./RemoveButtonCell"


const auctionSettingsColumn = [

    {
      accessorKey:'total_participants',
      header:'Set Participants',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'players_to_be_purchased',
      header:'player to be purchased',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'total_players',
      header:'Total Player',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
   
  
    {
      accessorKey:'saved',
      header:'Action',
      size:150,
      cell: (props) =>{
        
        return <RemoveButtonCell saved={props.getValue()}   props={props} />
      }
  
    },

  
   
  ]
  
  export {auctionSettingsColumn}
  