import React, { useEffect, useState } from "react";
import Toolbar from "../../Common/Toolbar";
import Content from "../../Common/Content";

import FilterPopover from "../../Common/TableWithTan/FilterPopover";
import FilterForm from "./FilterForm";
import { useNavigate } from "react-router";
import WithAddButton from "../../../hooks/WithAddButton";
import CreateStaff from "./CreateStaff";
import { useDispatch, useSelector } from "react-redux";
import { staffColumn } from "./staffColumn";
import { deleteStaff, fetchList, updateStaffStatus } from "./redux/staff.action";
import { errorMessage, successMessage } from "../../Common/SwalToast";
import Table from "../../Common/TableWithTan/Table";
import Loader from "../../MasterLayout/Loader";

const employeeTypeFilter = [
  {
    label: "Gamerji",
    value: "gamerji",
  },
  {
    label: "Consultant",
    value: "consultant",
  },
  {
    label: "Client",
    value: "client",
  },
];


const ViewStaffList = ({handleAddBtn, addBtn}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
 

  const [columnFilters, setColumnFilters] = useState([]);
  const [data, setData] = useState([])
  const { staffList, loading, error, recordPerPage, delStaff, staff, totalPages, totalRecord } = useSelector(state => state.staffUser)
  const [isLoading, setLoading] = useState(false)
  const [updateData, setUpdateData] = useState(null)
  const [pageNo, setPageNo] = useState(0)
  const [recordPerPages, setRecordPerPage] = useState(10)
  useEffect(() => {
    dispatch(fetchList({ pageNo: pageNo+1, recordPerPage: recordPerPages }))
  }, [pageNo,recordPerPages])
  useEffect(() => {
    setData(staffList)
  }, [staffList])

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  useEffect(() => {
    if (error) {
      errorMessage(error || 'Something went wrong')
    }
  }, [error])

  useEffect(() => {
    // console.log("del",delStaff)
    if (delStaff) {
      // console.log(delStaff)
      successMessage(delStaff.message)
      dispatch(fetchList({ pageNo: 0, recordPerPage: recordPerPages }))
    }
  }, [delStaff])


  useEffect(() => {
    // console.log("staff",staff)
    if (staff) {
      console.log(staff)
      successMessage(staff.message)
      handleAddBtn(false)
      dispatch(fetchList({ pageNo: 0, recordPerPage: 10 }))
    }
  }, [staff])

  const updateView = (rowIndex) => {
    // console.log(data)
    navigate('/staff/details/' + data[rowIndex]?._id)
  }
  const updateEdit = (rowIndex) => {
    handleAddBtn(false)
    handleAddBtn(true)
    // console.log(data[rowIndex]?._id)
    setUpdateData(data[rowIndex])
    // navigate('/' + routesFrontend.language + '/edit/' + tableData[rowIndex]?._id)
  }

  const updateStatus = (rowIndex) => {
    console.log("updateStatus", data[rowIndex])
    let body = {
      is_active: !data[rowIndex]?.is_active
    }
    dispatch(updateStaffStatus({id:data[rowIndex]?._id,data:body}))
  }
  const updateDelete = (rowIndex) => {
    // console.log(data[rowIndex]?._id, error)
    dispatch(deleteStaff({ id: data[rowIndex]?._id }))
  }
  // console.log("data:::::", data)
  // const updateStaffUserStatus = ({row}) => {
  //   setSelectedRow(row)
  //   let id = row.original._id+""
  //   let data={
  //     is_active: !row.original.is_active
  //   }
    
  //   dispatch(updateStatus({data, id}))
  // }

  // useEffect(() => {
  //   if (list && list?.length>0){
  //     setTableData(list)
  //     setTablePage(totalPages)
  //     setTableRecord(totalRecord)
  //   }
  // }, [list, totalPages, totalRecord])

  // useEffect(() => {
  //   fetchStaffUsers();
  // }, [])

  // const fetchStaffUsers = () => {
  //   setTableData([])
  //   setTablePage(0)
  //   setTableRecord(0)
  //   let pagination = {
  //     pageNo: 1,
  //     recordPerPage: 10,
  //     sortBy: 'created_at',
  //     sort: -1
  //   }
  //   dispatch(fetchList({ pagination }))
  // }



  // useEffect(() => {
  //   let tempcolumn = []
  //   viewPageColumn.forEach((element)=>{
  //     if (element.header === 'Is Active'){
  //       tempcolumn.push({
  //         accessorKey: 'is_active',
  //         header: 'Is Active',
  //         cell: (props) => <SwitchBoxWithoutLabel checked={props.getValue()} onChange={() => {
  //           updateStaffUserStatus(props)
  //         }} />
  //       })
  //     } else if (element.header === 'Actions') {
  //       tempcolumn.push({
  //         accessorKey: '',
  //         header: 'Actions',
  //         cell: ({ table, row }) => <ActionsCell type={['edit', 'remove']} table={table} row={row} />
  //       })
  //     } else if (element.header === 'View Details') {
  //       tempcolumn.push({
  //         accessorKey: '',
  //         header: 'View Details',
  //         size: 100,
  //         cell: (props) => <ViewDetailCell props={props} />
  //       })
  //     }else{
  //       tempcolumn.push(element)
  //     }
  //   })
   
  //   setColumns(tempcolumn)

  // }, [viewPageColumn])

  // useEffect(() => {
  //   if (error) {
  //     console.log("errorMSG", error);
  //     errorMessage({
  //       text: error,
  //       resetError: () => dispatch(resetError())// Dispatch the action when confirmed
  //     }); 
       
  //   }
  //   if (successmsg) {
  //     console.log("successMSG", successmsg)
  //     successResponse({
  //       text: successmsg,
  //       resetSuccess: () => dispatch(resetSuccess()), // Dispatch the action when confirmed
  //       afterFun: () => (fetchStaffUsers()) // Dispatch the action when confirmed
  //     });
  //   }
  // }, [error, successmsg]);



  return (
    <>
      <Toolbar text={"View Staff"} isButton={true} handleClick={()=> {
        setUpdateData(null)
        handleAddBtn(!addBtn)
        }}  textBtn = {"Add Staff"} addBtn={addBtn} />
      <Content>
        {
          addBtn && <CreateStaff data={updateData}/>
        }
        <div class="card card-custom">
          <div class="card-body">
          <FilterForm  />
            <div class="col-md-4 col-6 mt-2">
                <FilterPopover
                  option={columnFilters}
                  setOption={setColumnFilters}
                  filterValues={employeeTypeFilter}
                  id="employeeType"
                  text="Employee Type"
                />
              </div>
            <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
             {/* {
                isLoading ? <Loader />
                :
              <Table
                column={staffColumn}
                tableData={data}
                totalPages={totalPages}
                pageIndex={pageNo}
                updateEdit={updateEdit}
                updateView={updateView}
                updateDelete={updateDelete}
                handlerChangeDropDown = {(index)=> setRecordPerPage(index)}
                handlePageChange = {(index) => {
                  // console.log("index::::", index)
                  setPageNo(index)
                }}

              // columnFilters={columnFilters}
              // setColumnFilters={setColumnFilters}
              />
              } */}

              {
                isLoading ? <Loader />
                  :
                  <Table
                    column={staffColumn}
                    tableData={data}
                    totalPages={totalPages}
                    totalRecord={totalRecord}
                    pageIndex={pageNo}
                    recordPerPage={recordPerPages}
                    updateEdit={updateEdit}
                    updateStatus={updateStatus}
                    updateView={updateView}
                    updateDelete={updateDelete}
                    handlerChangeDropDown={(index) => {
                      setPageNo(0)
                      setRecordPerPage(index)
                    }
                    }
                    handlePageChange={(index) => {
                      setPageNo(index)
                    }}
                  />
              }
            </div>
           
          </div>
        </div>
      </Content>
    </>
  );
};

export default WithAddButton(ViewStaffList);
