import { routesApi } from "../../../../../../RouterFiles/routesApi";
import instance from "../../../../../../utilities/axios";
import url from "../../../../../../utilities/url";


export const getFetch = (headers, id) => {
  return instance
    .get(`${url.base_api}${routesApi.series}/${routesApi.matches}/${routesApi.fetch}/${id}`, {
      headers:headers
    })
    .then((res) => {
      return res;
    });
};
export const getList = (headers, id) => {
  return instance
    .get(`${url.base_api}${routesApi.series}/${routesApi.matches}/${routesApi.list}/${id}`, {
      headers:headers
    })
    .then((res) => {
      return res;
    });
};
export const save = (headers, id,data) => {
  return instance
    .post(`${url.base_api}${routesApi.series}/${routesApi.matches}/${routesApi.save}/${id}`,data, {
      headers:headers,
     
    })
    .then((res) => {
      return res;
    });
};