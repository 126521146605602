import { routesApi } from "../../../../../../RouterFiles/routesApi";
import instance from "../../../../../../utilities/axios";
import url from "../../../../../../utilities/url";


export const getList = (headers,id) => {
    return instance
      .get(`${url.base_api}${routesApi.series}/${routesApi.auctionSettings}${routesApi.list}/${id}`,{
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };

export const save = (headers, id,data) => {
    return instance
      .post(`${url.base_api}${routesApi.series}/${routesApi.auctionSettings}${routesApi.save}/${id}`,data, {
        headers:headers,
       
      })
      .then((res) => {
        return res;
      });
  };
export const saveDabba = (headers, id,data) => {
    return instance
      .post(`${url.base_api}${routesApi.series}/${routesApi.dabbaSettings}/${routesApi.save}/${id}`,data, {
        headers:headers,
       
      })
      .then((res) => {
        return res;
      });
  };

  
export const getDabbaList = (headers,id) => {
  return instance
    .get(`${url.base_api}${routesApi.series}/${routesApi.dabbaSettings}/${routesApi.list}/${id}`,{
      headers:headers
    })
    .then((res) => {
      return res;
    });
};