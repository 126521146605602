import React, { useEffect, useState } from "react";
import AccordionPlus from "../../../../Common/AccordionPlus";
import Checkbox from "../../../../Common/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchList,
  fetchModulePermission,
  fetchUserPermission,
  updateUserPermission,
} from "../../redux/staff.action";
import PermissionsModuleTable from "./PermissionsModuleTable";
import ButtonWithLoader from "../../../../Common/ButtonWithLoader";
import { successMessage } from "../../../../Common/SwalToast";

const data = [
  {
    _id: "65676e13ec1aa296c2d240a6",
    isActive: true,
    isShared: false,
    noOfPagePermission: 0,
    noOfButtonPermission: 6,
    noOfAPIPermission: 0,
    pagePermission: [],
    buttonPermission: [
      {
        name: "buttons1",
        value: "scrim.scrims.list",
        _id: "65045a157adbe101a3efe43e",
      },
      {
        name: "buttons2",
        value: "scrim.hostReports.list",
        _id: "65045a157adbe101a3efe43f",
      },
      {
        name: "buttons3",
        value: "scrim.scrimReports.list",
        _id: "65045a157adbe101a3efe440",
      },
      {
        name: "buttons4",
        value: "scrim.scrimTransactionsReports.list",
        _id: "65045a157adbe101a3efe441",
      },
      {
        name: "buttons5",
        value: "scrim.gamesReports.list",
        _id: "65045a157adbe101a3efe442",
      },
      {
        name: "buttons6",
        value: "scrim.scrimTransactionsReports.export",
        _id: "65045a157adbe101a3efe443",
      },
    ],
    apiPermission: [],
    company: ["634082e312598b8e50ef0d78"],
    moduleName: "Scrims",
  },
  {
    _id: "65676e13ec1aa296c2d240a7",
    isActive: true,
    isShared: false,
    noOfPagePermission: 0,
    noOfButtonPermission: 6,
    noOfAPIPermission: 0,
    pagePermission: [],
    buttonPermission: [
      {
        name: "buttons1",
        value: "timesolts.timeSlotList.list",
        _id: "6502b1b8737b4cf01ae54e22",
      },
      {
        name: "buttons2",
        value: "timesolts.timeSlotList.add",
        _id: "6502b1b8737b4cf01ae54e23",
      },
      {
        name: "buttons3",
        value: "timesolts.timeSlotList.update",
        _id: "6502b1b8737b4cf01ae54e24",
      },
      {
        name: "buttons4",
        value: "timesolts.timeSlotList.get",
        _id: "6502b1b8737b4cf01ae54e25",
      },
      {
        name: "buttons5",
        value: "timesolts.timeSlotList.delete",
        _id: "6502b1b8737b4cf01ae54e26",
      },
      {
        name: "buttons6",
        value: "timesolts.addTimeSlot.add",
        _id: "6502b1b8737b4cf01ae54e27",
      },
    ],
    apiPermission: [],
    company: ["634082e312598b8e50ef0d78"],
    moduleName: "Time Solt",
  },
];

// Dropdown options
const permissionTypes = [
  { value: "list", label: "List" },
  { value: "get", label: "Get" },
  { value: "create", label: "Create" },
  { value: "delete", label: "Delete" },
  { value: "update", label: "Update" },
  { value: "statusUpdate", label: "Status Update" },
  { value: "export", label: "Export" },
];

const Permissions = ({ userID, userData, companyData }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(null);
  const { modulePermission, userPermission, loading, updateUserPermissionStatus } = useSelector(
    (state) => state.staffUser
  );
  const [modulePermissionObject, setModulePermissionObject] = useState(null);
  const [userPermissionObject, setUserPermissionObject] = useState(null);

  const handleClick = () => setShow((prevShow) => !prevShow);

  useEffect(() => {
    // If userID and userData are available, proceed with the logic
    if (userID && userData) {
      // Fetch module permissions if not already available
      if (!modulePermission) {
        dispatch(fetchModulePermission({}));
      } else {
        setModulePermissionObject(modulePermission);
      }

      // Fetch user-specific permissions if not already available
      if (!userPermission) {
        dispatch(fetchUserPermission({ userID }));
      } else {
        setUserPermissionObject(userPermission);
      }
    }
  }, [dispatch, modulePermission, userPermission, userID, userData]);

  useEffect(() => {
    console.log("updateUserPermissionStatus", updateUserPermissionStatus)
   
    if (updateUserPermissionStatus?._id && updateUserPermissionStatus?.user) {
      successMessage("Users Permission Update successfully", setShow(null))
      }
   
  }, [updateUserPermissionStatus]);

  // useEffect(() => {
  //   // Log permissions when all necessary data is available
  //   if (userPermissionObject && modulePermissionObject && userData && userID) {
  //     console.log(
  //       "UPO", userPermissionObject,
  //       "MPO", modulePermissionObject,
  //     );
  //   }
  // }, [userPermissionObject, modulePermissionObject, userData, userID]);

  // When user removes a submodule
  const plusIconToggle = (company) => {
    if (company !== null) {
      setShow(company);
    } else {
      setShow(null);
    }
  };
  const handlerPermissionCheckBox = async (e, company, moduleId, sm, pt) => {
    let tempData = JSON.parse(JSON.stringify(userPermissionObject)); // Deep clone to avoid read-only errors
    // Ensure nested objects exist
    tempData.permission = tempData?.permission || {};
    tempData.permission[company] = tempData?.permission?.[company] || {};
    tempData.permission[company][moduleId] =
      tempData?.permission?.[company]?.[moduleId] || {};
    const permissions = tempData.permission[company][moduleId];
    if (pt !== "all") {
      const permissionKey = `${sm}.${pt}`;
      if (permissions?.includes(permissionKey)) {
        // Remove permission if it exists
        tempData.permission[company][moduleId] =
          permissions?.length > 0
            ? await permissions?.filter((p) => p !== permissionKey)
            : [];
      } else {
        // Add permission if it doesn't exist
        tempData.permission[company][moduleId] = [
          ...permissions,
          permissionKey,
        ];
      }
    } else {
    }
    // console.log("Updated Permissions:", tempData );
    setUserPermissionObject(tempData);
  };
  const handlerPermissionAllCheckBox = async (e, company, moduleId, sm, pt) => {
    let tempData = JSON.parse(JSON.stringify(userPermissionObject)); // Deep clone to avoid read-only errors
    // Ensure nested objects exist
    tempData.permission = tempData?.permission || {};
    tempData.permission[company] = tempData?.permission?.[company] || {};
    tempData.permission[company][moduleId] =
      tempData?.permission?.[company]?.[moduleId] || {};
    const permissions = tempData.permission[company][moduleId];
    let tempper =
      permissions?.length > 0
        ? await permissions?.filter((p) => p.split(".")[0] !== sm)
        : [];
    let newper = [];
    if (
      !e.target.checked ||
      permissions?.length === 0 ||
      Object.keys(tempData.permission[company][moduleId]).length === 0
    ) {
      newper.push(`${sm}.all`);
      Object.keys(pt).forEach((ptname) => {
        if (pt[ptname] === true) {
          newper.push(`${sm}.${ptname}`);
        }
      });
    }
    tempData.permission[company][moduleId] = [...tempper, ...newper];
    // console.log("Updated Permissions:", tempData.permission);
    setUserPermissionObject(tempData);
  };
  // const handleAssignPermission = async (userPermissionData) => {
  //   console.log(userPermissionData.permission, "userPermissionData");

  //   delete userPermissionData._id
  //   userPermissionData.permission=await userData.assigned_company.map((company)=>{
  //     if (userPermissionData.permission.inclues(company)){
  //       return userPermissionData.permission[company];
  //     }
  //   })
  //   console.log(userPermissionData.permission, "userPermissionData updated");
  //   userPermissionData.permission=userPermissionData.permission.
  //   // dispatch(updateUserPermission({ data: userPermissionData, id: userID }));
  // };
  const handleAssignPermission = async (userPermissionData) => {
    // console.log(userPermissionData.permission, "userPermissionData", userData.assigned_company);
    // Remove the _id property
    delete userPermissionData._id;
    // Dispatch the update action
    dispatch(updateUserPermission({ data: userPermissionData, id: userID }));
  };

  return (
    <div class="card">
      <div class="card-header card-header-stretch">
        <div class="card-title d-flex align-items-center">
          <h3 class="fw-bold m-0 text-gray-800">Permissions</h3>
        </div>
      </div>

      <div class="card-body">
        {userData?.assigned_company?.length > 0 &&
          userData.assigned_company?.map((company, index) => {
            return (
              <>
                <div
                  key={index}
                  className="d-flex justify-content-between p-2 px-4 align-items-center"
                >
                  <h4 class="text-gray-700 fw-bold cursor-pointer mb-0">
                    {
                      (companyData?.filter(
                        (e) => e._id.toString() === company.toString()
                      ))?.[0]?.name
                    }
                  </h4>
                  <div className="d-flex align-items-center">
                    <Checkbox
                    // key={idx}
                    // label={pt.label}
                    // value={pt.value}
                    // name={`${pt.value}`}
                    // onChange={(e) =>
                    // handleCheckboxChange(e, pt.value, key)
                    // }
                    // checked={
                    // permissionObject?.[key]?.[
                    // `${pt.value}`
                    // ]
                    // }
                    />
                    <div
                      class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5"
                      onClick={() => {
                        plusIconToggle(show === company ? null : company);
                      }}
                    >
                      <i class="ki-outline ki-plus-square toggle-off fs-1"></i>
                    </div>
                  </div>
                </div>
                {show === company && (
                  <div id="kt_job_6_1" class={`fs-6 ms-1 `}>
                    <div class="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                      {modulePermissionObject &&
                        modulePermissionObject?.map((module, idxs) => {
                          return (
                            <PermissionsModuleTable
                              key={idxs}
                              module={module}
                              company={company}
                              handlerPermissionCheckBox={
                                handlerPermissionCheckBox
                              }
                              handlerPermissionAllCheckBox={
                                handlerPermissionAllCheckBox
                              }
                              userPermission={userPermissionObject.permission}
                            />
                          );
                        })}
                    </div>
                  </div>
                )}
              </>
            );
          })}
        <div className="mt-20 d-flex justify-content-end">
          <ButtonWithLoader
            text={"Submit"}
            isLoading={loading}
            handlerSubmit={() => {
              handleAssignPermission(userPermissionObject);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Permissions;
