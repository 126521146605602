import TeamIcon from "./TeamIcon"
import TeamTextField from "./TeamTextField"



const teamsColumn = [

    {
      accessorKey:'key',
      header:'Key',
      
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'code',
      header:'Code',
      
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'name',
      header:'Name',
      
      cell: (props) => <p>{props.getValue()}</p>
  
    },
   
    {
      accessorKey:'alternate_name',
      header:'Alternate Name',
      
      cell: (props) => <p>{props.getValue()}</p>
  
    },

    {
      accessorKey:'alternate_code',
      header:'Alternate Code',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'gender_name',
      header:'Gender',
      size:150,
      cell: (props) =>{
        console.log(props.getValue())
        return <p>{props.getValue()}</p>}
  
    },
    {
      accessorKey:'new_team_name',
      header:'New Name',
      size:250,
      cell: (props) => <TeamTextField  props={props} data={props.getValue()} name={"new_team_name"} />
  
    },
    {
      accessorKey:'icon',
      header:'Icon',
      size:250,
      cell: (props) => <TeamIcon props={props} data={props.getValue()} name={"icon"}/>
  
    },


   
   
  ]
  
  export {teamsColumn}
  