import React from 'react'
import { batterColumn } from './BatterColumn'
import Table from '../../../../../Common/TableWithTan/Table'
import { bowlerColumn } from './BowlingCoulmn'
const tableData = [
    {
        name:'Yashasvi Jaiswal',
        details:'st Tom Blundell b Ajaz Patel',
        runs:'35',
        balls:'34',
        fours:'6',
        sixes:'1',
        strikeRate:'67.4'
    },
    {
        name:'Rohit Sharma (c)',
        details:'b Ajaz Patel',
        runs:'35',
        balls:'34',
        fours:'6',
        sixes:'1',
        strikeRate:'67.4'
    },
    {
        name:'Virat Kohli',
        details:'c Tom Blundell b Glenn Phillips',
        runs:'35',
        balls:'34',
        fours:'6',
        sixes:'1',
        strikeRate:'67.4'
    },
    {
        name:'Sarfaraz Khan',
        details:'batting',
        runs:'35',
        balls:'34',
        fours:'6',
        sixes:'1',
        strikeRate:'67.4'
    }
]
const tableDataB = [
    {
        name:'Yashasvi Jaiswal',
       
        over:'5',
        maiden:'1',
        runs:'35',
        wickets:'3',
        noBall:'6',
        wideBall:'1',
        economyRate:'67.4'
    },
    {
        name:'Yashasvi Jaiswal',
       
        over:'5',
        maiden:'1',
        runs:'35',
        wickets:'3',
        noBall:'6',
        wideBall:'1',
        economyRate:'67.4'
    },
    {
        name:'Yashasvi Jaiswal',
       
        over:'5',
        maiden:'1',
        runs:'35',
        wickets:'3',
        noBall:'6',
        wideBall:'1',
        economyRate:'67.4'
    },
    {
        name:'Yashasvi Jaiswal',
       
        over:'5',
        maiden:'1',
        runs:'35',
        wickets:'3',
        noBall:'6',
        wideBall:'1',
        economyRate:'67.4'
    },
   
]
const Scoreboard = () => {
  return (
    <div className='card'>
<div className="card-header card-header-stretch">
        <div className="card-title d-flex align-items-center">
          <h3 className="fw-bold m-0 text-gray-800">India 2nd innings</h3>
        </div>
      <div className="card-toolbar d-flex align-items-center ">
      <h4 className="fw-bold m-0 text-gray-800">89-3(49 Ov)</h4>
      </div>
      </div>
      <div className="card-body">
       
      <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
                 
              <Table
                column={batterColumn}
                tableData={tableData}
                isMatch={true}
                // updateView = {updateView}
                // columnFilters={columnFilters}
                // setColumnFilters={setColumnFilters}
              />
            </div>
            <div className="bg-light-dark px-3 py-3 mt-4"><h6 className="fw-bold m-0 text-gray-500"> Fall of wickets</h6></div>
            <div><p className="fw-bold m-0 text-gray-600 mx-4 mt-4">72-1 (Yashasvi Jaiswal, 17.1), 95-2 (Rohit Sharma, 21.5), 231-3 (Virat Kohli, 48.6)</p></div>
      <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
                
              <Table
                column={bowlerColumn}
                tableData={tableDataB}
            
                // updateView = {updateView}
                // columnFilters={columnFilters}
                // setColumnFilters={setColumnFilters}
              />
            </div>
      </div>
    </div>
  )
}

export default Scoreboard