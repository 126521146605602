import TeamIcon from "../Teams/TeamIcon"
import TeamTextField from "../Teams/TeamTextField"
import RoleCell from './RoleCell.jsx'


const playersColumn = [

    {
      accessorKey:'key',
      header:'Key',
      
      cell: (props) => <p>{props.getValue()}</p>
  
    },

    {
      accessorKey:'name',
      header:'Name',
      size:250,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'skills',
      header:'roles',
      
      cell: (props) => {
       
        return(
          <RoleCell  props={props}/>
        )
      }
  
    },
    {
      accessorKey:'new_player_name',
      header:'New Name',
      size:250,
      cell: (props) => <TeamTextField  props={props} data={props.getValue()} name={"new_player_name"} />
  
    },
    {
      accessorKey:'auction_price',
      header:'Auction Price',
      size:250,
      cell: (props) => <TeamTextField  props={props} data={props.getValue()} name={"auction_price"} />
  
    },
    {
      accessorKey:'image',
      header:'Icon',
      size:250,
      cell: (props) => <TeamIcon props={props} data={props.getValue()} name={"image"}/>
  
    },

    
   
   
  ]
  
  export {playersColumn}
  