import React from 'react'

const PrimaryButton = ({text, handlerClick}) => {
  return (
    <button 
    onClick={handlerClick}
     class="btn btn-sm btn-primary me-3" 
    //  data-bs-toggle="modal" 
    //  data-bs-target="#kt_modal_offer_a_deal"
     >{text}</button>
  )
}

export default PrimaryButton