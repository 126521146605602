import { createSlice } from '@reduxjs/toolkit'

import { formState } from './formState'
import { submitAction } from './formActions'

export const formSlice = createSlice({
  name: 'formState',
  initialState: formState,
  reducers: {
    submitReducer: submitAction,
  
  },
})

// Action creators are generated for each case reducer function
export const { submitReducer } = formSlice.actions

export default formSlice.reducer