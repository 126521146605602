import { useEffect, useState } from "react";
import LightButton from "../../../Common/LightButton";
import PrimaryButton from "../../../Common/PrimaryButton";
import OtpPopup from "../../../Common/OtpPopup";
import RequiredInfoPopup from "./RequiredInfoPopup";
import { useSelector } from "react-redux";


const CompanyCard = ({ tabs, activeTab, setActiveTab }) => {
  const [otpPopup, setOtpPopup] = useState(false);
  const [showUrls, setShowUrls] = useState(false)
  const [infoPopup, setInfoPopup] = useState(false);
  const {companies} = useSelector(state => state.companies)
  const handlerSubmit = ()=>{
    setOtpPopup(false)
    setShowUrls(true)
}
const onClosePopup = ()=>{
  setOtpPopup(false)
  setInfoPopup(false)
}
useEffect(()=>{
  console.log("Compaied",companies)
},[])
  return (
    <div class="card mb-6">
      <div class="card-body pt-9 pb-0">
        <div class="d-flex flex-wrap flex-sm-nowrap">
          <div class="me-7 mb-4">
            <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img
                src={`${process.env.REACT_APP_CLIENT_URL}assets/media/avatars/300-1.jpg`}
                alt="img"
              />
              <div class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
            </div>
          </div>

          <div class="flex-grow-1">
            <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2">
                  <span
                  
                    class="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                  >
                   {companies?.name}
                  </span>
                  {/* <a href="#">
                    <i class="ki-outline ki-verify fs-1 text-primary"></i>
                  </a> */}
                </div>

                <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                  <span
                   
                    class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 cursor-pointer"
                  >
                    {
                      showUrls ? <>
                       <i class="ki-solid ki-eye fs-2 me-2"></i>
                       {companies?.web_url}
                      </>
                      :
                      <>
                       <i class="ki-solid ki-eye-slash fs-2 me-2" onClick={()=> setOtpPopup(true)}></i>
                       
                       client url
                       </>
                    }
                   
                  </span>
                  <span
                   
                   class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 cursor-pointer"
                 >
                   {
                     showUrls ? <>
                      <i class="ki-solid ki-eye fs-2 me-2"></i>
                      {companies?.database_url}
                     </>
                     :
                     <>
                      <i class="ki-solid ki-eye-slash fs-2 me-2" onClick={()=> setOtpPopup(true)}></i>
                      DB URL</>
                   }
                  
                 </span>
                 <span
                   
                   class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 cursor-pointer"
                 >
                   {
                     showUrls ? <>
                      <i class="ki-solid ki-eye fs-2 me-2"></i>
                      {companies?.api_url}
                     </>
                     :
                     <>
                      <i class="ki-solid ki-eye-slash fs-2 me-2" onClick={()=> setOtpPopup(true)}></i>
                     API URL</>
                   }
                  
                 </span>
                </div>
              </div>

              <div class="d-flex my-4">
                <LightButton text="Follow" />
                <PrimaryButton text={"Rquired Info"} handlerClick={()=> setInfoPopup(true)}  />

                {/* <div class="me-0">
                  <button
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                  >
                    <i class="ki-solid ki-dots-horizontal fs-2x"></i>
                  </button>

                  <div
                    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3 show"
                    data-kt-menu="true"
                    style={{
                      "z-index": "107",
                      position: "fixed",
                      inset: "0px 0px auto auto",
                      margin: "0px",
                      transform: "translate(-146px, 264px)",
                    }}
                    data-popper-placement="bottom-end"
                  >
                    <div class="menu-item px-3">
                      <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                        Payments
                      </div>
                    </div>

                    <div class="menu-item px-3">
                      <a href="#" class="menu-link px-3">
                        Create Invoice
                      </a>
                    </div>

                    <div class="menu-item px-3">
                      <a href="#" class="menu-link flex-stack px-3">
                        Create Payment
                        <span
                          class="ms-2"
                          data-bs-toggle="tooltip"
                          aria-label="Specify a target name for future usage and reference"
                          data-bs-original-title="Specify a target name for future usage and reference"
                          data-kt-initialized="1"
                        >
                          <i class="ki-outline ki-information fs-6"></i>
                        </span>
                      </a>
                    </div>

                    <div class="menu-item px-3">
                      <a href="#" class="menu-link px-3">
                        Generate Bill
                      </a>
                    </div>

                    <div
                      class="menu-item px-3"
                      data-kt-menu-trigger="hover"
                      data-kt-menu-placement="right-end"
                    >
                      <a href="#" class="menu-link px-3">
                        <span class="menu-title">Subscription</span>
                        <span class="menu-arrow"></span>
                      </a>

                      <div class="menu-sub menu-sub-dropdown w-175px py-4">
                        <div class="menu-item px-3">
                          <a href="#" class="menu-link px-3">
                            Plans
                          </a>
                        </div>

                        <div class="menu-item px-3">
                          <a href="#" class="menu-link px-3">
                            Billing
                          </a>
                        </div>

                        <div class="menu-item px-3">
                          <a href="#" class="menu-link px-3">
                            Statements
                          </a>
                        </div>

                        <div class="separator my-2"></div>

                        <div class="menu-item px-3">
                          <div class="menu-content px-3">
                            <label class="form-check form-switch form-check-custom form-check-solid">
                              <input
                                class="form-check-input w-30px h-20px"
                                type="checkbox"
                                value="1"
                                checked="checked"
                                name="notifications"
                              />

                              <span class="form-check-label text-muted fs-6">
                                Recuring
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="menu-item px-3 my-1">
                      <a href="#" class="menu-link px-3">
                        Settings
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            {/* <div class="d-flex flex-wrap flex-stack">
              <div class="d-flex flex-column flex-grow-1 pe-8">
                <div class="d-flex flex-wrap">
                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div class="d-flex align-items-center">
                      <i class="ki-outline ki-arrow-up fs-3 text-success me-2"></i>
                      <div
                        class="fs-2 fw-bold"
                        data-kt-countup="true"
                        data-kt-countup-value="4500"
                        data-kt-countup-prefix="$"
                      >
                        0
                      </div>
                    </div>

                    <div class="fw-semibold fs-6 text-gray-500">Earnings</div>
                  </div>

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div class="d-flex align-items-center">
                      <i class="ki-outline ki-arrow-down fs-3 text-danger me-2"></i>
                      <div
                        class="fs-2 fw-bold"
                        data-kt-countup="true"
                        data-kt-countup-value="80"
                      >
                        0
                      </div>
                    </div>

                    <div class="fw-semibold fs-6 text-gray-500">Projects</div>
                  </div>

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div class="d-flex align-items-center">
                      <i class="ki-outline ki-arrow-up fs-3 text-success me-2"></i>
                      <div
                        class="fs-2 fw-bold"
                        data-kt-countup="true"
                        data-kt-countup-value="60"
                        data-kt-countup-prefix="%"
                      >
                        0
                      </div>
                    </div>

                    <div class="fw-semibold fs-6 text-gray-500">
                      Success Rate
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                  <span class="fw-semibold fs-6 text-gray-500">
                    Profile Compleation
                  </span>
                  <span class="fw-bold fs-6">50%</span>
                </div>
                <div class="h-5px mx-3 w-100 bg-light mb-3">
                  <div
                    class="bg-success rounded h-5px"
                    role="progressbar"
                    style={{ width: "50%;" }}
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <ul
          class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
          onClick={(e) => {
            if (e.target.tagName === "SPAN") {
              const clickedTab = e.target.innerText; // Get the tab name from the clicked span
              setActiveTab(clickedTab);
            }
          }}
        >
          {tabs?.map((tab) => (
            <li class="nav-item mt-2" key={tab}>
              <span
                class={`nav-link text-active-primary ms-0 me-10 py-5 ${
                  activeTab === tab ? "active" : ""
                }`}
              >
                {tab}
              </span>
            </li>
          ))}
        </ul>
      </div>
      {
        otpPopup && <OtpPopup onClose={onClosePopup} handlerSubmit={handlerSubmit} />
      }
      {
        infoPopup && <RequiredInfoPopup onClose={onClosePopup} />
      }
    </div>
  );
};

export default CompanyCard;
