import React, { useEffect, useRef, useState } from "react";
import TextInputField from "../../../../Common/TextInputField.jsx";
import ButtonWithLoader from "../../../../Common/ButtonWithLoader.jsx";
import SelectDropDown from "../../../../Common/SelectDropDown.jsx";
import UploadSingleImage from "../../../../Common/UploadSingleImage.jsx";
import { formValidation } from "@form-validation/bundle/popular";
import { Bootstrap5 } from "@form-validation/plugin-bootstrap5";
import { Trigger } from "@form-validation/plugin-trigger";
import RadioWithoutIcon from "../../../../Common/RadioWithoutIcon.jsx";
import { useDispatch, useSelector } from "react-redux";

import {
  errorMessage,
  successMessage,
  successResponse,
} from "../../../../Common/SwalToast.js";
import { useNavigate } from "react-router";
import SelectDropdownWithSearch from "../../../../Common/SelectDropdownWithSearch.jsx";

import Table from "../../../../Common/TableWithTan/Table.jsx";
import { profileIconColumn } from "./ProfileIconColumn.js";
import { addProfileIcons, deleteProfileIcon, fetchList, updateProfileIcons } from "./redux/profileIcons.action.js";
import Loader from "../../../../MasterLayout/Loader.jsx";

const ProfileIcons = ({ id }) => {
  console.log("id:::", id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dropDownList } = useSelector((state) => state.countries);

  const [formData, setFormData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const formRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle loading indication
  const [validator, setValidator] = useState(null); // Store form validation instance
  const { list, loading, create, createLoading, update, updateLoading, remove, deleteLoading , error} = useSelector((state) => state.profileIcons);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]:
        type === "checkbox"
          ? checked
            ? [...formData.notifications, value]
            : formData.notifications.filter((n) => n !== value)
          : value,
    });
  };
  const handleSelectChange = (name, value) => {
    // console.log("handleSelectChange",name, value);
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handlerRadio = (name, value) => {
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const checkValidation = (e) => {
    e.preventDefault();
    console.log("form Data", formData);
    if (validator) {
      validator.validate().then(function (status) {
        if (status === "Valid") {
          setIsSubmitting(true);
            if(isEdit) dispatch(updateProfileIcons({data:formData ,id: id}))
              else
            dispatch(addProfileIcons({data:formData, id: id}))
        } else {
          let text =
            "Sorry, looks like there are some errors detected, please try again.";
          errorMessage(text);
        }
      });
    }
  };
  const updateEdit = (rowIndex) =>{
    console.log(list, rowIndex)
    setIsEdit(true)
    setFormData(list[rowIndex])
  }
  const updateDelete = (rowIndex)=>{
    dispatch(deleteProfileIcon({id:id, data:{_id: list[rowIndex]?._id}}))
  }
  useEffect(() => {
    const form = formRef.current;
    if (!form) return;
    const validationFields = {
      fields: {
        icon_name: {
          validators: {
            notEmpty: {
              message: "Name is required",
            },
          },
        },
        order: {
          validators: {
            notEmpty: {
              message: "Code is required",
            },
          },
        },
        // country: {
        //   validators: {
        //     notEmpty: {
        //       message: "Country is required",
        //     },
        //   },
        // },
        icon_img: {
          validators: {
            notEmpty: {
              message: "Upload image is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap5({
          rowSelector: ".fv-row",
          eleInvalidclassName: "",
          eleValidclassName: "",
        }),
      },
    };
    const fv = formValidation(form, validationFields);
    setValidator(fv);
    // console.log("update data:::", data);
    // if (data) {
    //   setFormData({
    //     country: data?.country[0],
    //     name: data?.name,
    //     symbol: data?.symbol,
    //     img: data?.image,
    //     code: data?.code,
    //     codeISO: data?.codeISO,
    //     type: data?.type == "Virtual currency" ? 1 : 2,
    //   });
    // }

    dispatch(fetchList({ id: id }));
  }, []);

  useEffect(()=>{
    if(create?.message){
      successMessage(create?.message)
      setFormData({})
      dispatch(fetchList({ id: id }));
    }
  },[create])
  useEffect(()=>{
    if(remove?.message){
      successMessage(remove?.message)
    }
  },[remove])
  useEffect(()=>{
    if(update?.message){
      successMessage(update?.message)
      setFormData({})
      setIsEdit(false)
      dispatch(fetchList({ id: id }));
    }
  },[update])
  useEffect(()=>{
    if(error?.message){
      successMessage(error?.message)
    }
  },[error])

  return (
    <>
      <div className="card">
        <div className="card-body" ref={formRef}>
          <div class="row g-9 mb-4">
            <div class="col-md-4 col-sm-6 col-12 mt-2">
              <TextInputField
                required={"true"}
                label={"Icon Name"}
                value={formData?.icon_name}
                name={"icon_name"}
                type={"text"}
                onChange={handleChange}
                placeholder={"Enter name"}
              />
            </div>

            <div class="col-md-4 col-sm-6 col-12 mt-2">
              <TextInputField
                required={"true"}
                label={"order"}
                value={formData?.order}
                name={"order"}
                type={"text"}
                onChange={handleChange}
                placeholder={"Enter order"}
              />
            </div>

            <div class="col-md-4 col-sm-6 col-12 mt-2">
              <UploadSingleImage
                text={"Upload image"}
                name="icon_image"
                handlerFormData={handleSelectChange}
                data={formData?.icon_image}
              />
            </div>
            <div class="col-md-4 col-6 d-flex align-items-center justify-content-start pt-3">
              <ButtonWithLoader
                text={"Submit"}
                handlerSubmit={checkValidation}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-title">List</div>
        </div>
        <div className="card-body">
          <div
            class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
            id="kt_datatable"
          >
      
            {loading ? (
              <Loader />
            ) : (
              <Table
                column={profileIconColumn}
                tableData={list}
                // recordPerPage={recordPerPages}
                // totalPages={totalPages}
                // totalRecord={totalRecord}
                // pageIndex={pageNo}
                // updateView={updateView}
                updateEdit={updateEdit}
                updateDelete={updateDelete}
                // handlerChangeDropDown = {(index)=>
                //   {

                //     setPageNo(0);
                //   setRecordPerPage(index)
                //   }
                // }
                // handlePageChange = {(index) => {

                //   setPageNo(index)
                // }}
                // columnFilters={columnFilters}
                // setColumnFilters={setColumnFilters}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileIcons;
