import React, { useEffect, useMemo, useState } from "react";
import Toolbar from "../../../Common/Toolbar";
import Content from "../../../Common/Content";
import { Link } from "react-router-dom";
import ProfileCard from "../ProfileCard";

import Logs from "../Logs";
import AssignCompany from "./AssignCompany/AssignCompany";
import Permissions from "./Permission/Permissions";
import { useDispatch, useSelector } from "react-redux";
import { assignCompany, getStaffById } from "../redux/staff.action";

const ViewStaffPage = () => {
  const tabs = ["Assign Company", "Permission", "Logs"];
  const dispatch = useDispatch();
  const { viewstaff, loading, assignCompanyStatus } = useSelector(
    (state) => state.staffUser
  );
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [data, setData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const id = useMemo(() => {
    return (
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ] || null
    );
  }, []);

  useEffect(() => {
    dispatch(getStaffById({ id: id }));
  }, []);
  useEffect(() => {
    // console.log("staff::", viewstaff)
    if (viewstaff?._id) setData(viewstaff);
  }, [viewstaff]);

  // When user toggles a checkbox
  const handleAssignCompany = (userDetails) => {
    dispatch(
      assignCompany({
        assigned_company: userDetails.assigned_company,
        id: userDetails._id,
      })
    );
  };

  useEffect(() => {
    if (assignCompanyStatus) {
      dispatch(getStaffById({ id: id }));
      setActiveTab(tabs[1]);
    }
  }, [assignCompanyStatus]);

  return (
    <>
      <Toolbar text={data?.name + "'s details" || "Staff User"} />
      <Content>
        <ProfileCard
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          data={data}
        />
        {activeTab === tabs[0] ? (
          <AssignCompany
            userData={viewstaff}
            isLoading={loading}
            handleAssignCompany={handleAssignCompany}
            setCompanyData={setCompanyData}
            companyData={companyData}
          />
        ) : activeTab === tabs[1] ? (
          <Permissions
            key={viewstaff}
            userID={viewstaff?._id}
            userData={viewstaff}
            companyData={companyData}
          />
        ) : (
          <Logs />
        )}
      </Content>
    </>
  );
};

export default ViewStaffPage;
