import { routesApi } from "../../../../../RouterFiles/routesApi";
import instance from "../../../../../utilities/axios";
import url from "../../../../../utilities/url";



  export const getList= (headers,id, body) => {
    return instance
      .post(`${url.base_api}${routesApi.company}/${routesApi.profileIcons}/${routesApi.list}/${id}`,body,{
        headers
      })
      .then((res) => {
        return res;
      });
  };
  export const create = (headers,id,data) => {
    return instance
      .post(`${url.base_api}${routesApi.company}/${routesApi.profileIcons}/${id}`,data,{
        headers
      })
      .then((res) => {
        return res;
      });
  };
  export const update = (headers,id,data) => {
    return instance
      .put(`${url.base_api}${routesApi.company}/${routesApi.profileIcons}/${id}`,data,{
        headers
      })
      .then((res) => {
        return res;
      });
  };
  export const remove = (headers,id,data) => {
    return instance
      .post(`${url.base_api}${routesApi.company}/${routesApi.profileIcons}/${"del"}/${id}`,data,{
        headers
      })
      .then((res) => {
        return res;
      });
  };