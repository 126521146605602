import { createAsyncThunk } from "@reduxjs/toolkit"
import { getById, getLanguage, getList, getListsDropdown, patch } from "./country.api"


export const fetchList = createAsyncThunk('country/fetchList', async ({pageNo, recordPerPage,search=''},{rejectWithValue}) => {
  console.log(pageNo,recordPerPage )
    let headers = {
        // pageNo:pageNo || 1,
        // recordPerPage:recordPerPage || 10,
        // sortBy: 'created_at',
        // sort:-1
      }
      let body = {
        pagination: {
            pageNo: pageNo || 1,
            recordPerPage: recordPerPage || 1,
            sortBy: "created_at",
            sortDirection: "asc"
        },
        search: search // optional
        
    }
      try{

          const response = await getList(headers,body)
          console.log("response:::::::",response,pageNo,recordPerPage)
          if(response.success)
          return {
            ...response, 
            pageNo,
            recordPerPage
          }
      }
      catch(error){
        return rejectWithValue(error)
      }
})
export const getCountryDropdown = createAsyncThunk('country/dropdownist', async ({},{rejectWithValue}) => {
  // console.log("getdropdown")
    let headers = {
        
      }
      try{

          const response = await getListsDropdown(headers)
          // console.log("coun try res::::", response)
          if(response.success)
          return {

            ...response, 
           
          }
      }
      catch(error){
        return rejectWithValue(error)
      }
})

export const getLanguageById = createAsyncThunk('language/getListById', async ({id}) => {
  console.log("cratespor", id)
    let headers = {
       
      }
      try{

          const response = await getById(headers,id)
          console.log("responseSport:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        // return rejectWithValue(error)
      }
})
export const getAssignLanguage = createAsyncThunk('language/getAssignLang', async ({id},{rejectWithValue}) => {
  console.log("headerss",id)
  let headers = {
     
    }
    try{

        const response = await getLanguage(headers,id)
        console.log("responseSport:::::::",response)
        if(response.success)
        return {
          ...response, 
         
        }
        else{
          return response
        }
    }
    catch(error){
      console.log("error", error)
      // throw error
      return rejectWithValue(error)
    }
})
export const updateAssignLanguage = createAsyncThunk('languages/updateAssignLang', async ({data,id},{rejectWithValue}) => {
  let headers = {
     
    }
    try{

        const response = await patch(headers,data,id)
        console.log("responseSport:::::::",response)
        if(response.success)
        return {
          ...response, 
         
        }
        else{
          return response
        }
    }
    catch(error){
      console.log("error", error)
      // throw error
      return rejectWithValue(error)
    }
})