import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { routesFrontend } from "./routesFrontend";
import MasterLayout from "../MasterLayout/MasterLayout";
import Form from "../Common/Form";

import FormValidation from "../Common/FormValidation";
import Wizard from "../Common/Wizard";
import Tables from "../Common/Table/Tables";
import BaseTable from "../Common/TableWithSubtable/BaseTable";
import FantasyAssociations from "../Fantasy/fantasyAssociations";
import FantasySeries from "../Fantasy/fantasySeries";
import Login from "../Pages/Login/Login";
import CreateStaff from "../Pages/Staff/CreateStaff";
import ViewStaffList from "../Pages/Staff/ViewStaffList";
import FantasyMatches from "../Fantasy/fantasyMatches";
import FantasyMatchesDetails from "../Fantasy/fantasyMatcheDetails";
import FantasyMatcheDetails from "../Fantasy/fantasyMatcheDetails";
import CreateCurrency from "../Pages/Master/Currency/CreateCurrency";
import ViewStaffPage from "../Pages/Staff/ViewStaff/ViewStaffPage";
import CountryList from "../Pages/Master/Country/CountryList";
import CreateLanguage from "../Pages/Master/Language/CreateLanguage";
import ViewLanguageList from "../Pages/Master/Language/ViewLanguageList";
import CreateSport from "../Pages/Master/Sport/CreateSport";
import ViewSportList from "../Pages/Master/Sport/ViewSportList";
import CreateCompany from "../Pages/Company/CreateCompany";
import CreateCategory from "../Pages/CustomerCare/CreateCategory";
import CurrencyList from "../Pages/Master/Currency/CurrencyList";
import ViewCompanyList from "../Pages/Company/ViewCompanyList";
import ViewCompanyPage from "../Pages/Company/ViewCompanyDetails/ViewCompanyPage";
import FantasyPlayerDetails from "../Fantasy/fantasyPlayerDetails";
import FantasyCompetitionSeries from "../Fantasy/fantasyCompetitionSeries";
import ViewSportPage from "../Pages/Master/Sport/ViewSportDetails/ViewSportPage";
import FantasyMain from "../Fantasy/fantasyMain";
import Home from "../MasterLayout/Home";
import instance from "../utilities/axios";
import CreateModule from "../Pages/Module/CreateModule";
import ViewModuleList from "../Pages/Module/ViewModuleList";
import ViewSeriesList from "../Pages/Master/Series/ViewSeriesList";
import ViewSeriesPage from "../Pages/Master/Series/ViewSeriesDetails/ViewSeriesPage";
import Legality from "../Pages/Legality/Legality";
const RouterFiles = () => {
  const navigate = useNavigate()

  //UseEffect that check token from local storage and if available refresh axios instance
  useEffect(() => {
    let tempToken = localStorage.getItem("token")?.toString() || null
    if (!tempToken){
      navigate('/login')
    }else{
      instance.refresh();
    }
  }, [])


  return (
    <Routes>

      <Route path={routesFrontend.login} element={<Login />} />
      <Route path={routesFrontend.home} element={<MasterLayout />}>
        <Route path={"/"} element={<Home />} />
        <Route path={routesFrontend.legality} element={<Legality />} />
        <Route path={routesFrontend.form} element={<Form />} />
        <Route path={routesFrontend.table} element={<Tables />} />
        <Route path={routesFrontend.tableSubtable} element={<BaseTable />} />

        {/* Added by Kuldeep */}
        <Route
          path={routesFrontend.fantasyAssociations}
          element={<FantasyAssociations />}
        />
        <Route path={routesFrontend.fantasyMain} element={<FantasyMain />} />
        <Route
          path={routesFrontend.fantasySeries}
          element={<FantasySeries />}
        />
        <Route
          path={routesFrontend.fantasyCompetitionSeries}
          element={<FantasyCompetitionSeries />}
        />
        <Route
          path={routesFrontend.fantasySeriesDetails}
          element={<fantasySeriesDetails />}
        />
        <Route
          path={routesFrontend.fantasyMatches}
          element={<FantasyMatches />}
        />
        <Route
          path={routesFrontend.fantasyMatcheDetails}
          element={<FantasyMatcheDetails />}
        />
        <Route
          path={routesFrontend.fantasyPlayerDetails}
          element={<FantasyPlayerDetails />}
        />
        {/* Added by Kuldeep */}

        <Route
          path={routesFrontend.formValidation}
          element={<FormValidation />}
        />
        <Route path={routesFrontend.wizards} element={<Wizard />} />
        {/* Modules Pages */}
        <Route path={routesFrontend.module}>

          <Route path={routesFrontend.create} element={<CreateModule />} />
          <Route path={routesFrontend.edit} element={<CreateModule />} />
          <Route path={routesFrontend.viewList} element={<ViewModuleList />} />
          {/* <Route path={routesFrontend.viewPage} element={<ViewStaffPage />} /> */}

        </Route>
        {/* Staff Pages */}
        <Route path={routesFrontend.staff}>
          <Route path={routesFrontend.create} element={<CreateStaff />} />
          <Route path={routesFrontend.edit} element={<CreateStaff />} />
          <Route path={routesFrontend.viewList} element={<ViewStaffList />} />
          <Route path={routesFrontend.viewPage} element={<ViewStaffPage />} />
        </Route>
        {/* Currency  Page*/}
        <Route path={routesFrontend.currency}>
          <Route path={routesFrontend.create} element={<CreateCurrency />} />
          <Route path={routesFrontend.viewList} element={<CurrencyList />} />
          <Route path={routesFrontend.edit} element={<CreateCurrency />} />
        </Route>
        {/* Country  Page*/}
        <Route path={routesFrontend.country}>
          <Route path={routesFrontend.viewList} element={<CountryList />} />
        </Route>
        {/* Language  Page*/}
        <Route path={routesFrontend.language}>
          <Route path={routesFrontend.create} element={<CreateLanguage />} />
          <Route path={routesFrontend.edit} element={<CreateLanguage />} />
          <Route
            path={routesFrontend.viewList}
            element={<ViewLanguageList />}
          />
        </Route>
        {/* Sport  Page*/}
        <Route path={routesFrontend.sport}>
          <Route path={routesFrontend.create} element={<CreateSport />} />
          <Route path={routesFrontend.viewList} element={<ViewSportList />} />
          <Route path={routesFrontend.viewPage} element={<ViewSportPage />} />
        </Route>
        {/* series list */}
        <Route path={routesFrontend.series}>
          <Route path={routesFrontend.viewList} element={<ViewSeriesList />} />
          <Route path={routesFrontend.viewPage} element={<ViewSeriesPage />} />
        </Route>
        {/* Company  Page*/}
        <Route path={routesFrontend.company}>
          <Route path={routesFrontend.create} element={<CreateCompany />} />
          <Route path={routesFrontend.viewList} element={<ViewCompanyList />} />
          <Route path={routesFrontend.viewPage} element={<ViewCompanyPage />} />
        </Route>
        {/* Customer care  Page*/}
        <Route path={routesFrontend.customerCare}>
          <Route path={routesFrontend.create} element={<CreateCategory />} />
          <Route path={routesFrontend.viewList} element={<ViewSportList />} />
        </Route>
      </Route>

    </Routes>
  );
};

export default RouterFiles;
