

const batterColumn = [

    {
      accessorKey:'name',
      header:'Batter',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'details',
      header:'',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'runs',
      header:'R',
      size:70,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'balls',
      header:'B',
      size:70,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'fours',
      header:'4s',
      size:70,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'sixes',
      header:'6s',
      size:70,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'strikeRate',
      header:'SR',
      size:70,
      cell: (props) => <p>{props.getValue()}</p>
  
    },

   
  ]
  
  export {batterColumn}
  