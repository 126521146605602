import React, { useState } from "react";
import SelectDropDown from "../SelectDropDown";


const Pagination = ({ pagination, handlePagination, handlerChangeDropDown, recordPerPage, totalRecord }) => {
  // console.log(recordPerPage)
  const [value, setValue] = useState(recordPerPage);
  const options = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
  ]

  return (
    <div className="d-flex justify-content-between">
      <ul className="pagination mt-6">
        <li
          className={`page-item previous ${pagination.pageIndex <= 0 ? "disabled" : ""
            }`}
          onClick={() => handlePagination(pagination.pageIndex - 1)}
        >
          <span className="page-link">
            <i className="previous"></i>
          </span>
        </li>
        {Array.from({ length: pagination.pageSize }, (_, key) => (
          <li
            className={`page-item ${key == pagination.pageIndex ? "active" : ""
              }`}
            onClick={() => handlePagination(key)}
          >
            <span className="page-link">
              {_}
              {key + 1}
            </span>
          </li>
        ))}

        <li
          className={`page-item next ${pagination.pageIndex == pagination.pageSize - 1 ? "disabled" : ""
            }`}
          onClick={() => handlePagination(pagination.pageIndex + 1)}
        >
          <span className="page-link">
            <i className="next"></i>
          </span>
        </li>
      </ul>
      <div class="datatable-pager-info mb-sm-0 d-flex align-items-center ">
        <span class="datatable-pager-detail me-4">{`Showing ${((pagination.pageIndex * recordPerPage)+ 1)} - ${(pagination.pageIndex + 1) * recordPerPage} of ${totalRecord}`}</span>
        <div
          class="dropdown bootstrap-select datatable-pager-size dropup show"
          style={{ width: "100px" }}
        >
          <SelectDropDown
            name="value"
            label=""
            placeholder={""}
            options={options}
            value={options.find((option) => option.value === value)}
            onChange={(sel) => handlerChangeDropDown(sel.value)}
            
          />

        </div>

      </div>
    </div>
  );
};

export default Pagination;
