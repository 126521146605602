import { createSlice } from "@reduxjs/toolkit";
import { addCompany, deleteCompany, fetchList, getCompanyById, getCompanyConfigById, getCompanyGuestConfigById, setCoinPackConfig, setCompanyCountry, setCompanyCurrency, setCompanyGame, setCompanyOtherConfig, setCouponCodeConfig, setPromoCodeConfig, setReferralCodeConfig, setSignUpBonusCodeConfig, updateCompany } from "./company.action";


export const guestSlice = createSlice({
  name: "companysGuestState",
  initialState: {
    pageNo: 1,
    recordPerPage: 10,
    totalRecord: 0,
    totalPages: 0,
    companies: [],
    company: "",
    viewCompany: "",
    viewLoader:false,
    delCompany: "",
    loading: false,
    error: null,
    selectedGames:{},
    currency:{},
    isGeneralConfiguration:'false',
    isCountryConfiguration:'false',
    countryConfig:[{}],
    otherConfig:{},
    coinPackConfig:{},
    couponCodeConfig:{},
    promoCodeConfig:{},
   referralCodeConfig:{},
  signUpBonusConfig:{},
  },
  reducers:{
    setCompanyGameReducer: setCompanyGame,
    setCompanyCurrencyReducer: setCompanyCurrency,
    setCompanyCountryReducer: setCompanyCountry,
    setCompanyOtherReducer: setCompanyOtherConfig,
    setCompanyCoinPackReducer: setCoinPackConfig,
    setCompanyPromoCodeReducer: setPromoCodeConfig,
    setCompanyCouponCodeReducer: setCouponCodeConfig,
    setCompanyReferralCodeReducer: setReferralCodeConfig,
    setCompanySignUpBonusCodeReducer: setSignUpBonusCodeConfig,

},
  extraReducers: (builder) => {
    
    builder.addCase(getCompanyGuestConfigById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCompanyGuestConfigById.fulfilled, (state, action) => {
      state.loading = false;
      state.company = "";
      state.companies = action.payload.data?.data;
      state.selectedGames = action.payload?.data?.config?.general
      state.currency = action.payload?.data?.config?.currency
    });
    builder.addCase(getCompanyGuestConfigById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
    
  },
});

export const {  setCompanyGameReducer, 
  setCompanyCurrencyReducer,
  setCompanyCountryReducer,
  setCompanyOtherReducer,
  setCompanyCoinPackReducer,
  setCompanyCouponCodeReducer,
  setCompanyPromoCodeReducer,
  setCompanyReferralCodeReducer,
  setCompanySignUpBonusCodeReducer } = guestSlice.actions;
export default guestSlice.reducer;
