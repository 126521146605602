import React from "react";

const Checkbox = ({ label, value, onChange ,checked=false,name , disable=false}) => {
  return (
   
      <div class="form-check form-check-sm form-check-custom form-check-solid me-3 form-check-inline mt-2">
        <label class="fs-6 fw-semibold form-label me-3">
          <span>{label}</span>
        </label>
        <input
          class="form-check-input"
          type="checkbox"
          name={name}
          value={value}
          onChange={onChange}
          checked={checked}
          disabled={disable}
        />
      </div>
  
  );
};

export default Checkbox;
