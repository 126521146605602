import { createAsyncThunk } from "@reduxjs/toolkit"
import { assignCompany, getFetch, getFilterList, getList, getSeriesId, getSeriesRulesbyId, patchSeriesRulesbyId } from "./series.api"

export const fetchList = createAsyncThunk('series/fetchList', async ({pageNo, recordPerPage, search="",date_range},{rejectWithValue}) => {
  console.log(pageNo,recordPerPage )
    let headers = {
        // pageNo:pageNo || 1,
        // recordPerPage:recordPerPage || 10,
        // sortBy: 'created_at',
        // sort:-1
      }
      let body = {
        pagination: {
            pageNo: pageNo || 1,
            recordPerPage: recordPerPage || 100,
            sortBy: "created_at",
            sortDirection: "asc"
        },
        search: search, // optional
        date_range:date_range
    }
      try{

          const response = await getList(headers,body)
          console.log("response:::::::",response,pageNo,recordPerPage)
          if(response.success)
          return {
            ...response, 
            pageNo,
            recordPerPage
          }
      }
      catch(error){
        return rejectWithValue(error)
      }
})
export const fetchFilterList = createAsyncThunk('series/fetchFilterList', async ({},{rejectWithValue}) => {

    let headers = {
        // pageNo:pageNo || 1,
        // recordPerPage:recordPerPage || 10,
        // sortBy: 'created_at',
        // sort:-1
      }
    
      try{

          const response = await getFilterList(headers)
        
          if(response.success)
          return {
            ...response, 
           
          }
      }
      catch(error){
        return rejectWithValue(error)
      }
})
export const fetchRefetchList = createAsyncThunk('series/refetchList', async ({pageNo, recordPerPage},{rejectWithValue}) => {
  console.log(pageNo,recordPerPage )
    let headers = {
        // pageNo:pageNo || 1,
        // recordPerPage:recordPerPage || 10,
        // sortBy: 'created_at',
        // sort:-1
      }
      let body = {
        pagination: {
            pageNo: pageNo || 1,
            recordPerPage: recordPerPage || 100,
            sortBy: "created_at",
            sortDirection: "asc"
        },
        search: "" // optional
    }
      try{

          const response = await getFetch(headers,body)
          console.log("response:::::::",response,pageNo,recordPerPage)
          if(response.success)
          return {
            ...response, 
            pageNo,
            recordPerPage
          }
      }
      catch(error){
        return rejectWithValue(error)
      }
})




export const getSeriesById = createAsyncThunk('series/getListById', async ({id,companyCode},{rejectWithValue}) => {
  console.log("cratespor", id)
    let headers = {
       "company-code":companyCode
      }
      try{

          const response = await getSeriesId(headers,id)
          console.log("responseseries:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        return rejectWithValue(error)
      }
})



export const assignCompanies = createAsyncThunk('series/assignCompany', async ({id,companyCode,data},{rejectWithValue}) => {
  console.log("cratespor", id)
    let headers = {
       "company-code":companyCode
      }
      try{

          const response = await assignCompany(headers,id,data)
          console.log("responseseries:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        return rejectWithValue(error)
      }
})

export const fetchSeriesRulesbyId = createAsyncThunk('series/fetchSeriesRulesbyId', async ({ id,companyCode }, { rejectWithValue }) => {
  let headers = {
    "company-code": 'SAAS'
  }
  try {

    const response = await getSeriesRulesbyId(headers, id)
    console.log("responseseries:::::::getSeriesRulesbyId", response)
    if (response.success)
      return {
        ...response,

      }
    else {
      return response
    }
  }
  catch (error) {
    console.log("error", error)
    // throw error
    return rejectWithValue(error)
  }
})

export const updateSeriesRulesbyId = createAsyncThunk('series/updateSeriesRulesbyId', async ({ id,data, companyCode }, { rejectWithValue }) => {
  let headers = {
    "company-code": 'SAAS'
  }
  try {

    const response = await patchSeriesRulesbyId(headers, id,data)
    console.log("responseseries:::::::getSeriesRulesbyId", response)
    if (response.success)
      return {
        ...response,

      }
    else {
      return response
    }
  }
  catch (error) {
    console.log("error", error)
    // throw error
    return rejectWithValue(error)
  }
})
