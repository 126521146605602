import SwitchBoxWithoutLabel from "../../Common/SwitchBoxWithoutLabel";
import ActionsCell from "../../Common/TableWithSubtable/ActionsCell";
import ViewDetailCell from "../../Common/TableWithTan/ViewDetailCell";
import { getDate, getTime } from "../../utilities/dateUtils";

const moduleColumn = [
  {
    accessorKey: "_id",
    header: "Id",
    
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "updated_by",
    header: "Updated By",
    cell: (props) => <p>{props.getValue().name}</p>,
  },
  {
    accessorKey: "created_by",
    header: "Created By",
    cell: (props) => <p>{props.getValue().name}</p>,
  },
  {
    accessorKey: "created_at",
    header: "Created At",
    cell: (props) => <p>{getDate(props.getValue()) + " " + getTime(props.getValue())}</p>,
  },
  {
    accessorKey: "updated_at",
    header: "Updated At",
    cell: (props) => <p>{getDate(props.getValue()) + " " + getTime(props.getValue())}</p>,
  },
  {
    accessorKey: "is_active",
    header: "Is Active",
    cell: (props) => <SwitchBoxWithoutLabel checked={props.getValue()} row={props.row.index} onChange={props.table.options.meta.updateStatus} />,
  },
  {
    accessorKey: "",
    header: "Actions",
    cell: ({ table, row }) => (
      <ActionsCell type={["edit", "remove"]} table={table} row={row} />
    ),
  },
];

export { moduleColumn };
