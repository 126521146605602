import React from 'react'

const Switchbox = ({checked,onChange,text}) => {
  return (
    <div class="fv-row mb-7">
   
    <div class="d-flex flex-stack">
       
        {/* <div class="me-5">
           
            <label class="fs-5 fw-semibold">{text}</label>
           
           
            <div class="fs-7 fw-semibold text-muted">If you need more info, please check budget planning</div>
           
        </div> */}
       
       
        <label class="form-check form-switch form-check-custom form-check-solid">
           
            <input class="form-check-input" name="billing" type="checkbox" value="1" checked={checked} onChange={onChange} />
           
           
            <span class="form-check-label fw-semibold text-muted">{text}</span>
           
        </label>
       
    </div>
   
</div>
  )
}

export default Switchbox
