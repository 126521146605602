import { createSlice } from "@reduxjs/toolkit";

import {
  request,
  validate,
} from "./login.action";

export const loginState = createSlice({
  name: "loginState",
  initialState: {
    token:null,
    otpSentStatus:null,
    error: null,
  },
  reducers: {
    changeRecordPerpage: (state, action) => {
      state.recordPerPage = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(request.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(request.fulfilled, (state, action) => {
      // state.loading = false;
      state.otpSentStatus = action.payload;
    });
    builder.addCase(request.rejected, (state, action) => {
      // state.loading = false;
      state.error = action.payload.error.message;
    });


    builder.addCase(validate.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(validate.fulfilled, (state, action) => {
      // state.loading = false;
      state.token = action.payload;
    });
    builder.addCase(validate.rejected, (state, action) => {
      // state.loading = false;
      state.error = action.payload.error.message;
    });
  },
});

export const { changeRecordPerpage } = loginState.actions;
export default loginState.reducer;
