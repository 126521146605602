import React from "react";

const RadioWithoutIcon = ({ label, value, onChange, name, checked , disable=false}) => {
  return (
    <div className="form-check form-check-custom form-check-solid mb-3 form-check-inline">
      <input
        className="form-check-input"
        type="radio"
        value={value}
        id="flexRadioChecked"
        name={name}
        checked={checked}
        onChange={() => onChange(value)}
        disabled={disable}
      />
      <label className="form-check-label" for="flexRadioChecked">
        {label}
      </label>
    </div>
  );
};

export default RadioWithoutIcon;
