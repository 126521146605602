import React, { useEffect, useRef, useState } from "react";
import Checkbox from "../../../Common/Checkbox";
import ButtonWithLoader from "../../../Common/ButtonWithLoader";
import { formValidation } from "@form-validation/bundle/popular";
import { Bootstrap5 } from "@form-validation/plugin-bootstrap5";
import { Trigger } from "@form-validation/plugin-trigger";
import { SubmitButton } from "@form-validation/plugin-submit-button";
import { errorMessage, successMessage } from "../../../Common/SwalToast";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyGameReducer } from "../redux/company.slice";
import { updateCompanyConfigById } from "../redux/company.action";
import { fetchList } from "../../Master/Sport/redux/sport.action";
// const sportList = ["Basketball", "Cricket", "Football", "Volleyball"];
// const gamePlay = ["Auction", "Dabba"];

const GeneralConfigurations = ({ setActiveTab, setTabs, id }) => {
  const [selected, setSelected] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [sportList, setSportList] = useState([])
  const { sports } = useSelector(state => state.sports)
  const formRef = useRef();
  const [errors, setErrors] = useState({});
  const [sportsError, setSportsError] = useState('')
  const dispatch = useDispatch()
  const { selectedGames, currency } = useSelector((state) => state.companies)
  const handleGamePlayChange = (sport, game) => {
    setSelected((prev) => {
      // Clone the previous state
      const newSelected = [...prev];
  
      // Find the index of the sport in the selected array based on the ID
      const sportIndex = newSelected.findIndex((item) => item._id === sport._id);
  
      if (sportIndex !== -1) {
        // If the sport exists in selected array, toggle the game in the game_play array
        if (!newSelected[sportIndex].game_play) {
          newSelected[sportIndex].game_play = [];
        }
  
        // Check if the game is already selected
        if (newSelected[sportIndex].game_play.includes(game)) {
          // Remove the game if it's already selected
          newSelected[sportIndex].game_play = newSelected[sportIndex].game_play.filter(
            (g) => g !== game
          );
        } else {
          // Add the game if it's not selected
          newSelected[sportIndex].game_play.push(game);
        }
      } else {
        // If sport is not found, add a new entry for it with the selected game
        newSelected.push({ _id: sport._id, game_play: [game] });
      }
      
      return newSelected;
    });
  };
  useEffect(() => {
    console.log("selectedganmes", selectedGames);
    if (selectedGames)
      setSelected(selectedGames)
  }, [selectedGames]);
  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    // Validate each selected sport for game selection
    Object.entries(selected).forEach(([sport, games]) => {
      if (games?.length === 0) {
        newErrors[sport] = "Please select at least one game.";
        isValid = false;
      }
    });
    console.log(isValid);
    setErrors(newErrors);
    return isValid;
  };
  const validateSport = () => {
    let isValid = true;
    console.log(selected);
    if (Object.keys(selected).length === 0) {
      setSportsError("Please select atleast 1 sport ");
      isValid = false;
    }
    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("selecteed:::",selected)
    let sportError = validateSport();
    const isValidGames = validateForm();
    console.log(sportError, isValidGames);
    if (isValidGames && sportError) {
      setLoading(true);
      dispatch(
        updateCompanyConfigById({
          id: id,
          name: "general",
          data: { general: selected },
        })
      );
      dispatch(setCompanyGameReducer({ selectedGames: selected }));

      setTimeout(() => {
        successMessage("Form has been successfully submitted!");
        setLoading(false);
      }, 500);
      setTabs(prev => {
        if (prev.includes("Country & Currency Configurations"))
          return prev
        if (prev.includes("Guest Country & Currency Configurations"))
          return prev

        return [
          ...prev,
          "Country & Currency Configurations",
          "Guest Country & Currency Configurations",
        ]
      })
      setActiveTab("Country & Currency Configurations")
    } else {
      errorMessage("There are some errors. Please fix them and try again.");
    }
  };
  useEffect(() => {
    if (sports)
      setSportList(sports)
  }, [sports])
  useEffect(() => {
    if (selectedGames?.length > 0) {
      setSelected(selectedGames)
      setTabs(prev => {
        if (prev.includes("Country & Currency Configurations"))
          return prev
        if (prev.includes("Guest Country & Currency Configurations"))
          return prev

        return [
          ...prev,
          "Country & Currency Configurations",
          "Guest Country & Currency Configurations"
        ]
      })
      if (currency) {
        setTabs((prev) => {
          if (prev.includes("Auction Settings")) return prev;
          else return [...prev, "Auction Settings"];
        });
        setTabs((prev) => {
          if (prev.includes("Profile Icons")) return prev;
          else return [...prev, "Profile Icons"];
        });
        setTabs((prev) => {
          if (prev.includes("Other Configuration")) return prev;
          else return [...prev, "Other Configuration"];
        });
        setTabs((prev) => {
          if (prev.includes("Wallet Settings")) return prev;
          else return [...prev, "Wallet Settings"];
        });
      }
      // setActiveTab("Country & Currency Configurations");
    }

    if (currency) {
      console.log("selectedGames:::::", selectedGames, currency)


      // setTabs((prev) => {
      //   if (prev.includes("Auction settings")) return prev;
      //   else return [...prev, "Auction settings"];
      // });
      // setActiveTab("Auction settings");
    }
    // setTabs((prev) => {
    //   if (prev.includes("Auction settings")) return prev;
    //   else return [...prev, "Auction settings"];
    // });
    setActiveTab("Profile Icons");
    dispatch(fetchList({}))
  }, []);
// console.log("seldtdesadas", selected)
  return (
    <div className="card" ref={formRef}>
      <div className="card-header">
        <div className="card-title d-flex align-items-center">
          <h3 className="fw-bold m-0 text-gray-800">Select Sports</h3>
        </div>
        <div className="card-toolbar me-4"></div>
      </div>

      <div className="card-body fv-row">
        {sportList.map((sport, index) => (
          <div className="mt-4" key={index}>
            <div className="d-flex justify-content-between p-4 px-4 bg-dark bg-opacity-20 align-items-center">
              <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                {sport?.name}
              </h4>
              <div className="d-flex align-items-center">
                <Checkbox
                  value={sport}
                  name={"sport"}
                  onChange={() => {
                    setSportsError("");
                    setSelected((prev) => {
                      // Check if sport is already selected
                      const isSelected = prev.find((ele) => ele?._id === sport?._id);

                      if (isSelected) {
                        // If selected, remove it by filtering out the existing item
                        return prev.filter((ele) => ele?._id !== sport?._id);
                      } else {
                        // If not selected, add it to the array
                        return [...prev, { _id: sport?._id, name: sport?.name }];
                      }
                    });
                  }}
                  checked={!!selected.find((ele) => ele?._id === sport?._id)}
                />
              </div>
            </div>
                  {console.log("gameplaydsyd",sport?.game_play)}
            {
              selected.find(ele => ele?._id == sport?._id) &&
              (
                <div
                  id={`kt_job_6_${index}`}
                  className="collapse show fs-6 ms-1 mt-3 ps-6"
                >
                  <div className="mb-10 fv-row">
                    <div className="d-flex gap-10">
                      {sport?.game_play.map((game, idx) => (
                        <Checkbox
                          key={idx}
                          label={game}
                          value={game}
                          name={`game-${sport}`}
                          onChange={() => handleGamePlayChange(sport, game, index)}
                          checked={selected.filter(ele=> ele?._id == sport?._id)?.[0]?.game_play?.includes(game)}
                        />
                      ))}
                    </div>

                    {/* Display validation error if no game is selected */}
                    {errors[sport] && (
                      <div className="text-danger mt-2">{errors[sport]}</div>
                    )}
                  </div>
                </div>
              )}
          </div>
        ))}

        {sportsError && <div className="text-danger mt-2">{sportsError}</div>}
        <div className="mt-5 d-flex justify-content-end">
          <ButtonWithLoader
            text={"Submit"}
            isLoading={isLoading}
            handlerSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralConfigurations;
