import React, { useState, useEffect } from "react";

import { useMemo } from "react";

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import Pagination from "./Pagination";

import { useNavigate } from "react-router";
import TextInputField from "../TextInputField";
import AddAuctionRow from "../../Pages/Master/Series/ViewSeriesDetails/AuctionSettings/AddAuctionRow";
import AssignCompanyBtn from "../../Pages/Master/Series/ViewSeriesDetails/Teams/AssignCompanyBtn";

const rowExpandedData1 = [
  {
    companyName: "Gamerji",
    updatedDate: "24/06/2024",
    updatedBy: "Sakshi Rawat",
  },
  {
    companyName: "D11",
    updatedDate: "24/06/2024",
    updatedBy: "Drashit Patel",
  },
  {
    companyName: "Mobily",
    updatedDate: "24/06/2024",
    updatedBy: "Kuldepp Malviya",
  },
];
const Table = ({
  column,
  tableData,
  setColumnFilters,
  columnFilters,
  isRowExpaned = false,
  assignCompanyDetailsColumn,
  updateView,
  updateEdit,
  isMatch = false,
  handlerChangeDropDown,
  handlePageChange,
  totalPages,
  pageIndex,
  updateDelete,
  recordPerPage,
  totalRecord,
  updateStatus,
  isAuctionSettings = false,
  isCompanyAssign = false,
  columnVisibility
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 1,
  });
  const [totalRecords, setTotalRecords] = useState(14);
  const data1 = useMemo(() => tableData, [tableData]);
  const [data, setData] = useState(data1);
  const columns = useMemo(() => column, []);
  const [status, setStatus] = useState();
  const [selected, setSelected] = useState([]);

  const [value, setValue] = useState("");
  const [rowExpandedData, setRowExpandedData] = useState([]);
 
  
  const [expanded, setExpanded] = useState([]);
  const [loading, setLoading] = useState();
  const [currentIndex, setCurrentIndex] = useState();
  const navigate = useNavigate();
  const handlerExpand = (rowId) => {
    setExpanded((expanded) => {
      if (expanded.includes(Object.keys(rowId())[0])) {
        return expanded.filter((id) => id !== Object.keys(rowId())[0]);
      } else {
        return [...expanded, Object.keys(rowId())[0]];
      }
      // return [ Object.keys(rowId())[0]];
    });
    setCurrentIndex(Object.keys(rowId())[0]);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setRowExpandedData(rowExpandedData1);
    }, 1000);
  };
  const table = useReactTable({
    data,
    columns,

    state: {
      columnFilters,
      pagination,
      expanded,
      columnVisibility
    },

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // console.log(rowIndex, columnId, value);
        setSelected((prev) => [...prev, rowIndex]);
      },
      updateView: updateView,
      updateEdit: updateEdit,
      updateDelete: updateDelete,
      updateStatus: updateStatus,
    },
    manualPagination: true,
    pageCount: 3,
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false,
    enableRowSelection: true,
    getRowCanExpand: () => isRowExpaned,
    manualExpanding: true,
    onExpandedChange: handlerExpand,
  });

  const handlePagination = (pageIndex) => {
    // console.log(pageIndex)
    setPagination((prev) => {
      return {
        ...prev,
        pageIndex: pageIndex,
      };
    });
    handlePageChange(pageIndex);
  };

  useEffect(() => {
    // console.log(tableData?.data,tableData?.data?.totalRecord,tableData?.data?.totalPages)
    setPagination((prev) => {
      return {
        ...prev,
        pageIndex: pageIndex,
        pageSize: totalPages,
      };
    });
    setData(tableData);
  }, [tableData]);
  return (
    <>
      <div className="table-responsive">
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          // {...getTableProps()}
          width={table.getTotalSize()}
        >
          <thead className="bg-light-dark px-1">
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              {table.getHeaderGroups().map((headerGroup) => (
                <>
                  {headerGroup?.headers?.map((header) => (
                    <th
                      width={header.getSize()}
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.column.columnDef.header &&
                      typeof header.column.columnDef.header === "string"
                        ? header.column.columnDef.header
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}

                      {header.column.getIsSorted() &&
                        (header.column.getIsSorted() == "desc" ? (
                          <i class="ki-solid ki-black-up"></i>
                        ) : (
                          <i class="ki-solid ki-black-down"></i>
                        ))}
                    </th>
                  ))}
                </>
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold">
            {/* { console.log("fsdn:::", data)} */}
            {data?.length > 0 && table.getRowModel().rows?.length > 0 ? (
              table.getRowModel().rows.map((row, index) => {
                return (
                  <>
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td width={cell.column.getSize()} key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                    {/* {console.log( currentIndex)} */}
                    {expanded?.includes(row?.id) && (
                      <tr>
                        <td colSpan={row.getVisibleCells().length}>
                          {loading && currentIndex == row?.id ? (
                            <h1>Loading.....</h1>
                          ) : (
                            <Table
                              column={assignCompanyDetailsColumn}
                              tableData={rowExpandedData1}
                            />
                          )}
                        </td>
                      </tr>
                    )}
                    {
                      isCompanyAssign && data[index]?.companies?.length > 0 && 
                      <tr>
                        <td colSpan={9}>
                          <div className="company-assign">
                           
                           {
                            data[index]?.companies?.map(comp => {
                              return  <AssignCompanyBtn name={comp?.name} code={comp?.code} id={data[index]?._id} />
                            })
                           }
                          </div>
                        </td>
                      </tr>
                    }
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
            {isMatch && (
              <>
                <tr>
                  <td colSpan={4}>Extras</td>
                  <td colSpan={3}>4 (b 0, lb 3, w 0, nb 1, p 0)</td>
                </tr>
                <tr>
                  <td colSpan={4}>Total</td>
                  <td colSpan={3}>231 (3 wkts, 49 Ov)</td>
                </tr>
                <tr>
                  <td colSpan={1}>Yet to bat</td>
                  <td colSpan={7}>
                    KL Rahul , Rishabh Pant (wk) , Ravindra Jadeja ,
                    Ravichandran Ashwin , Kuldeep Yadav , Jasprit Bumrah ,
                    Mohammed Siraj
                  </td>
                </tr>
              </>
            )}
            {isAuctionSettings && (
              <>
                <AddAuctionRow />
              </>
            )}
          </tbody>
        </table>
      </div>
      {totalRecord > 10 && (
        <Pagination
          pagination={pagination}
          handlePagination={handlePagination}
          handlerChangeDropDown={handlerChangeDropDown}
          recordPerPage={recordPerPage}
          totalRecord={totalRecord}
        />
      )}
      {/* <UsersListPagination />
      
      {isLoading && <UsersListLoading />} */}
    </>
  );
};

export default Table;
