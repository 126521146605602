import React, { useEffect, useRef, useState } from "react";

import { formValidation } from '@form-validation/bundle/popular';
import { Bootstrap5 } from '@form-validation/plugin-bootstrap5';
import { Trigger } from '@form-validation/plugin-trigger';
import { errorMessage, successMessage } from "../../Common/SwalToast";
import Toolbar from "../../Common/Toolbar";
import Content from "../../Common/Content";
import TextInputField from "../../Common/TextInputField";
import UploadSingleImage from "../../Common/UploadSingleImage";
import ButtonWithLoader from "../../Common/ButtonWithLoader";
import { useDispatch, useSelector } from "react-redux";
import { addCompany, getCompanyById, updateCompany } from "./redux/company.action";
import Loader from "../../MasterLayout/Loader";

const CreateCompany = ({data}) => {
  const [formData, setFormData] = useState({img:'dhbhw.jpg'});
  const formRef = useRef()
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle loading indication
  const [validator, setValidator] = useState(null); // Store form validation instance
  const dispatch = useDispatch()
  const {viewLoader,viewCompany} = useSelector(state => state?.companies)
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]:
        type === "checkbox"
          ? checked
            ? [...formData.notifications, value]
            : formData.notifications.filter((n) => n !== value)
          : value,
    });
  };
  const handleSelectChange = (name, value) => {
    console.log(name, value);
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handlerSubmit = () => {
    console.log(formData);
  };
  const checkValidation = (e)=>{
    e.preventDefault();
    // setIsSubmitting(true)
    console.log(validator)
    if (validator) {
        validator.validate().then(function (status) {
            

            if (status === 'Valid') {
                setIsSubmitting(true);

                console.log("formdata:::", formData)
                if(data){
                  dispatch(updateCompany({data:formData, id:data}))
                }
                else{
                  dispatch(addCompany({data:formData}))
                }
                setIsSubmitting(false)
            } else {
                // Show error message
                let text= "Sorry, looks like there are some errors detected, please try again."
               errorMessage(text)
            }
        });
    }
    
  }
  useEffect(() => {
    
    const form = formRef.current;
    console.log("useedfdw",form)

    if (!form) return;

    const validationFields = {
        fields: {
            name: {
                validators: {
                    notEmpty: {
                        message: 'Name is required'
                    },
                    stringLength: {
                        verbose: false, 
                        min: 3,
                        max: 30,
                        message: 'The name must be more than 6 and less than 30 characters long',
                    },
                    // regexp: {
                    //     regexp: /^[a-zA-Z0-9_]+$/,
                    //     message: 'The name can only consist of alphabetical, number and underscore',
                    // },
                }
            },
            symbol: {
                validators: {
                    notEmpty: {
                        message: 'Symbol is required'
                    },
                    stringLength: {
                        verbose: false, 
                        min: 3,
                        max: 4,
                        message: 'The name must be more than 3 characters long',
                    },
                }
            },
            code: {
                validators: {
                    notEmpty: {
                        message: 'Code is required'
                    }
                }
            },
            country: {
                validators: {
                    notEmpty: {
                        message: 'Country is required'
                    }
                }
            },
            uploadImage: {
                validators: {
                    notEmpty: {
                        message: 'Upload image is required'
                    }
                }
            },
          
        },
        plugins: {
            trigger: new Trigger(),
            bootstrap: new Bootstrap5({
                rowSelector: '.fv-row',
                eleInvalidclassName: '',
                eleValidclassName: ''
            })
        }
    };
    console.log("useedfdw")

    const fv = formValidation(form, validationFields);

    setValidator(fv);
}, [])
  useEffect(()=>{
    console.log("view",viewCompany)
    setFormData({
      name: viewCompany?.name,
      code: viewCompany?.code,
      api_url: viewCompany?.api_url,
      web_url: viewCompany?.web_url,
      database_url : viewCompany?.database_url,
      contact_us : viewCompany?.contact_us
    })
  },[viewCompany])


  return (
    <>
      <Toolbar text={"Create Company"} />
      {
        viewLoader ? <Loader />
        :
      <Content>
        <div className="card">
          <div className="card-body"  ref={formRef}>
            <div class="row g-9 mb-4">
             
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  required={"true"}
                  label={"Comapny name"}
                  value={formData?.name}
                  name={"name"}
                  type={"text"}
                  onChange={handleChange}
                  placeholder={"Enter Company name"}
                />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  required={"true"}
                  label={"Code"}
                  value={formData?.code}
                  name={"code"}
                  type={"text"}
                  onChange={handleChange}
                  placeholder={"Enter Code"}
                />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  required={"true"}
                  label={"Frontend Url"}
                  value={formData?.web_url}
                  name={"web_url"}
                  type={"text"}
                  onChange={handleChange}
                  placeholder={"Enter Frontend Url"}
                />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  required={"true"}
                  label={"API Url"}
                  value={formData?.api_url}
                  name={"api_url"}
                  type={"text"}
                  onChange={handleChange}
                  placeholder={"Enter API Url"}
                />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  required={"true"}
                  label={"DB Url"}
                  value={formData?.database_url}
                  name={"database_url"}
                  type={"text"}
                  onChange={handleChange}
                  placeholder={"Enter DB Url"}
                />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  // required={"true"}
                  label={"Contact Person Name"}
                  value={formData?.contact_us?.name}
                  name={"pocName"}
                  type={"text"}
                  onChange={(e)=>setFormData(prev => {
                    return {
                      ...prev,
                      contact_us: {
                        ...prev.contact_us,
                        name: e.target.value
                      }
                    }
                  })}
                  placeholder={"Enter contact person mobile number"}
                />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  // required={"true"}
                  label={"Contact Person Email"}
                  value={formData?.contact_us?.email}
                  name={"pocemail"}
                  type={"text"}
                  onChange={(e)=>setFormData(prev => {
                    return {
                      ...prev,
                      contact_us: {
                        ...prev.contact_us,
                        email: e.target.value
                      }
                    }
                  })}
                  placeholder={"Enter contact person email"}
                />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  required={"true"}
                  label={"Contact Person Mobile Number"}
                  value={formData?.contact_us?.phone}
                  name={"pocPhone"}
                  type={"number"}
                  onChange={(e)=>setFormData(prev => {
                    return {
                      ...prev,
                      contact_us: {
                        ...prev.contact_us,
                        phone: e.target.value
                      }
                    }
                  })}
                  placeholder={"Enter contact person mobile number"}
                />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <UploadSingleImage 
                text={"Upload logo of company"}
                name="uploadImage"
                // file={URL.createObjectURL(formData?.img)}
                // setFile={(file) => setFormData(prev => {
                //     return {
                //         ...prev,
                //         img: file
                //     }
                // })}
                 />
              </div>

              <div class="col-md-4 col-6 d-flex align-items-center justify-content-center pt-3">
                <ButtonWithLoader
                  text={"Submit"}
                  isLoading={isSubmitting}
                  handlerSubmit={checkValidation}
                />
              </div>
            </div>
          </div>
        </div>
      </Content>
      }
    </>
  );
};

export default CreateCompany;
