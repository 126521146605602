import { useMemo, useState } from "react"
import { column } from "../../TableWithTan/column"


const SelectionCell= ({props}) => {
  // const {selected, onSelect} = useListView()
  // console.log(object)
  // const [selected, setSelected] = useState([])
  // const isSelected = useMemo(() => selected.includes(id), [id, selected])
  // console.log(props.row.getIsSelected())
  const handlerOnSelect = ()=>{
    console.log(props)

    props?.table?.options?.meta?.updateData(props.row.index,props.column.id)
  }
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        // data-kt-check={props.row.getIsSelected()}
        data-kt-check-target='#kt_table_users .form-check-input'
        checked={props.row.getIsSelected()}
        onChange={props.row.getToggleSelectedHandler()}
      />

    </div>
    
  )
}

export {SelectionCell}
