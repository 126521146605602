import React from 'react'
import Content from '../Content'
import DateRangePickerBasic from '../DateRangePickerBasic'
import Table from './Table'
import Table1 from './Table1'

const Tables = () => {
    
  return (
    <Content >									
									<div class="card card-flush">
										
										<div class="card-header align-items-center py-5 gap-2 gap-md-5">
											
											<div class="card-title">
												
												<div class="d-flex align-items-center position-relative my-1">
													<i class="ki-outline ki-magnifier fs-3 position-absolute ms-4"></i>
													<input type="text" data-kt-ecommerce-order-filter="search" class="form-control form-control-solid w-250px ps-12" placeholder="Search Report" />
												</div>
												
												
												<div id="kt_ecommerce_report_sales_export" class="d-none"></div>
												
											</div>
											
											
											<div class="card-toolbar flex-row-fluid justify-content-end gap-5">
												
												<input class="form-control form-control-solid w-100 mw-250px" placeholder="Pick date range" id="kt_ecommerce_report_sales_daterangepicker" />
												
												<DateRangePickerBasic />
												<button type="button" class="btn btn-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
												<i class="ki-outline ki-exit-up fs-2"></i>Export Report</button>
												
												<div id="kt_ecommerce_report_sales_export_menu" class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4" data-kt-menu="true">
													
													<div class="menu-item px-3">
														<a href="#" class="menu-link px-3" data-kt-ecommerce-export="copy">Copy to clipboard</a>
													</div>
													
													
													<div class="menu-item px-3">
														<a href="#" class="menu-link px-3" data-kt-ecommerce-export="excel">Export as Excel</a>
													</div>
													
													
													<div class="menu-item px-3">
														<a href="#" class="menu-link px-3" data-kt-ecommerce-export="csv">Export as CSV</a>
													</div>
													
													
													<div class="menu-item px-3">
														<a href="#" class="menu-link px-3" data-kt-ecommerce-export="pdf">Export as PDF</a>
													</div>
													
												</div>
												
												
											</div>
											
										</div>
										
										
										<div class="card-body pt-0">
											
											<Table1 />
											
										</div>
										
									</div>
									
                                    </Content>

  )
}

export default Tables
