import { routesApi } from "../../../../../../RouterFiles/routesApi";
import instance from "../../../../../../utilities/axios";
import url from "../../../../../../utilities/url";




export const save = (headers, id,data) => {
  return instance
    .post(`${url.base_api}${routesApi.series}/${routesApi.assign}/${id}`,data, {
      headers:headers,
     
    })
    .then((res) => {
      return res;
    });
};