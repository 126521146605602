import React from 'react'
import ButtonWithLoader from './ButtonWithLoader'

const ModalContainer = ({text,children,onSubmit, onClose,isViewOnly,loading}) => {
  return (
    <div class="modal fade show modal-bg"  tabindex="-1" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered mw-750px">
        
    <div class="modal-content">
        
        <div class="modal-header">
            
            <h2 class="fw-bold">{text}</h2>
            
            
            <div class="btn btn-icon btn-sm btn-active-icon-primary" 
            onClick={onClose}
            >
                <i class="ki-outline ki-cross fs-1"></i>
            </div>
            
        </div>
        
        
        <div class="modal-body scroll-y mx-5 my-7">
            
            <div  class="form fv-plugins-bootstrap5 fv-plugins-framework" >
                
               {children}
                
                {
                    !isViewOnly && 
                <div class="text-center pt-15">
                <ButtonWithLoader
                isLoading={loading}
                 text={"submit"}
                  handlerSubmit={onSubmit} />
                </div>
                }
                
            </div>
            
        </div>
        
    </div>
    
</div>
</div>
  )
}

export default ModalContainer