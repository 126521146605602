import SwitchBoxWithoutLabel from "../../../../Common/SwitchBoxWithoutLabel"
import ActionsCell from "../../../../Common/TableWithSubtable/ActionsCell"
import CountryImageCell from "../../../Master/Country/CountryImageCell"

const profileIconColumn = [


    {
      accessorKey:'icon_name',
      header:'Name',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'order',
      header:'Order',
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'icon_image',
      header:'Image',
      cell: (props) => <CountryImageCell props={props} />
  
    },

    {
      accessorKey:'is_active',
      header:'is active',
      cell: (props) => <SwitchBoxWithoutLabel checked={props.getValue()} />
    },
    {
      accessorKey:'',
      header:'Actions',
      size:100,
      cell: ({table, row}) => <ActionsCell type={['edit', 'remove']} table={table} row={row}/>
  
    }
    
  ]
  
  export {profileIconColumn}
  