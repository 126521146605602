
const moment = require('moment-timezone');

// Country-specific default time zones mapping
const countryTimeZones = {
     'India': 'Asia/Kolkata',
     'USA': 'America/New_York',
     'UK': 'Europe/London',
     'Australia': 'Australia/Sydney',
     'Japan': 'Asia/Tokyo',
     'Germany': 'Europe/Berlin',
     'Brazil': 'America/Sao_Paulo',
     'China': 'Asia/Shanghai',
     'UTC': 'UTC',
     // Add more countries and their primary time zones as needed
};

// Get the default country from the environment variable or fallback to 'India'
const DEFAULT_COUNTRY = process.env.REACT_APP_DEFAULT_COUNTRY || 'India';
/**
 * Helper function to get the default timezone for a given country
 * @param {} - The country for which to get the timezone.
 * @returns {string} - The timezone string.
 */
function getTimeStamp(country) {
     const timezone = getDefaultTimezone(country);
     return moment().tz(timezone).format();
}

/**
 * Helper function to get the default timezone for a given country
 * @param {} - The country for which to get the timezone.
 * @returns {string} - The timezone string.
 */
function getUnixTimeStamp(dataTime) {
     if (dataTime){
          return moment(dataTime).unix();
     }else{
          return moment().unix();
     }
     
}
function getUnixToDatePickerFormat(unixTime) {
     if (unixTime) {
          return moment.unix(unixTime).tz(countryTimeZones[DEFAULT_COUNTRY]).format("YYYY-MM-DD");
     } else {
          return moment.unix().tz(countryTimeZones[DEFAULT_COUNTRY]).format("YYYY-MM-DD");
     }

}
/**
 * Helper function to get the default timezone for a given country
 * @param {string} country - The country for which to get thze timezone.
 * @returns {string} - The timezone string.
 */
function getDefaultTimezone(country) {
     return countryTimeZones[country] || countryTimeZones[DEFAULT_COUNTRY] || 'UTC';
}

/**
 * Converts a Unix timestamp to a Moment object in the specified country's timezone.
 * @param {number} unixTimestamp - The Unix timestamp to convert.
 * @param {string} [country] - The country for which to get the timezone.
 * @returns {moment.Moment} - The Moment object in the specified timezone.
 */
function getMomentFromUnix(unixTimestamp, country) {
     const timezone = getDefaultTimezone(country);
     return moment.unix(unixTimestamp).tz(timezone);
}

/**
 * Gets the formatted date string from a Unix timestamp.
 * @param {number} unixTimestamp - The Unix timestamp to format.
 * @param {string} [country] - The country for which to get the timezone.
 * @returns {string} - The formatted date (YYYY-MM-DD).
 */
function getDate(unixTimestamp, country) {
     return getMomentFromUnix(unixTimestamp, country).format('YYYY-MM-DD');
}

/**
 * Gets the formatted time string from a Unix timestamp.
 * @param {number} unixTimestamp - The Unix timestamp to format.
 * @param {string} [country] - The country for which to get the timezone.
 * @returns {string} - The formatted time (HH:mm:ss).
 */
function getTime(unixTimestamp, country) {
     return getMomentFromUnix(unixTimestamp, country).format('HH:mm:ss');
}

/**
 * Gets the minutes from a Unix timestamp.
 * @param {number} unixTimestamp - The Unix timestamp to extract minutes from.
 * @param {string} [country] - The country for which to get the timezone.
 * @returns {number} - The minutes (0-59).
 */
function getMinutes(unixTimestamp, country) {
     return getMomentFromUnix(unixTimestamp, country).minute();
}

/**
 * Gets the day of the week from a Unix timestamp.
 * @param {number} unixTimestamp - The Unix timestamp to get the day from.
 * @param {string} [country] - The country for which to get the timezone.
 * @returns {string} - The day of the week (e.g., Monday).
 */
function getDay(unixTimestamp, country) {
     return getMomentFromUnix(unixTimestamp, country).format('dddd');
}

/**
 * Adds a specified number of days to a Unix timestamp.
 * @param {number} unixTimestamp - The Unix timestamp to add days to.
 * @param {number} daysToAdd - The number of days to add.
 * @param {string} [country] - The country for which to get the timezone.
 * @returns {number} - The new Unix timestamp after adding days.
 */
function addDays(unixTimestamp, daysToAdd, country) {
     return getMomentFromUnix(unixTimestamp, country).add(daysToAdd, 'days').unix();
}

/**
 * Subtracts a specified number of days from a Unix timestamp.
 * @param {number} unixTimestamp - The Unix timestamp to subtract days from.
 * @param {number} daysToSubtract - The number of days to subtract.
 * @param {string} [country] - The country for which to get the timezone.
 * @returns {number} - The new Unix timestamp after subtracting days.
 */
function subtractDays(unixTimestamp, daysToSubtract, country) {
     return getMomentFromUnix(unixTimestamp, country).subtract(daysToSubtract, 'days').unix();
}

// Export the functions for use in other modules
module.exports = {
     getTimeStamp,
     getUnixTimeStamp,
     getDate,
     getTime,
     getMinutes,
     getDay,
     addDays,
     subtractDays,
     getUnixToDatePickerFormat
};
