import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectDropDown from "../../../Common/SelectDropDown";
import ButtonWithLoader from "../../../Common/ButtonWithLoader";
import Checkbox from "../../../Common/Checkbox";

import { errorMessage, successMessage } from "../../../Common/SwalToast";
import SelectDropdownWithSearch from "../../../Common/SelectDropdownWithSearch";
import { setCompanyCountryReducer, setCompanyCurrencyReducer } from "../redux/company.slice";
import { getCompanyConfigById, updateCompanyConfigById, updateCompanyGuestConfigById } from "../redux/company.action";
import { getCurrencyListsDropdown } from "../../Master/Currency/redux/currency.action";
import Loader from "../../../MasterLayout/Loader";
import { getAssignLanguage, getCountryDropdown } from "../../Master/Country/redux/country.action";
import LightButtonWithOutline from "../../../Common/LightButtonWithOutline";


// const language = [
//   { label: "English", value: "English" },
//   { label: "Hindi", value: "Hindi" },
// ];

const CurrencyCountryConfiguration = ({ setActiveTab, setTabs, id }) => {
  const { currencyGuest,selectedGames ,countryConfigGuest } = useSelector((state) => state.companies);
  const { languageAssign } = useSelector((state) => state);
  const { list, loading } = useSelector(state => state.currencies)
  const { countries } = useSelector(state => state)
  const [inCurr, setIncurr] = useState([])
  const [countryOptions, setCountryOptions] = useState([])
  const [language, setLanguages] = useState()
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // const [language, setLanguages] = useState([])
  const [inCur, setIncur] = useState();
  const [OutCur, setOutcur] = useState();
  const [index, setIndex] = useState(0);
  const [games, setGames] = useState([]);
  const [isCurrEdit, setIsCurrEdit] = useState(currencyGuest ? true : false)
  const [isCntyEdit, setIsCntyEdit] = useState([])
  const [formData, setFormData] = useState({
    country: [{}],
  });

  const handleSelectChange = (name, value, index) => {
    console.log(name, value, index);

    let temp = formData["country"];
    // console.log(temp[index]);
    temp[index] = { ...temp[index], data: value, game: [], id: index };
    console.log("temp::::", temp);
    setFormData((prev) => {
      return {
        ...prev,
        ...(formData["country"] = [...temp]),
      };
    });
    // setGame(value)
    console.log("languages::::", language, language.length <= 0, value._id)
    // if (language.length <= 0)
      dispatch(getAssignLanguage({ id: value._id }))
    dispatch(getCurrencyListsDropdown({ id: value._id }))
  };
  const handleSelectlangMany = (name, value, index) => {
    console.log(name, value);
    let temp = formData;
    let d = [];
    value?.map((ele) => d.push(ele.name));
    // temp["country"][index][name] = d;
    temp["country"][index] = {
      ...temp["country"][index],
      [name]: value,
    };
    console.log("temop data:::::", d)
    setFormData((prev) => {
      return {
        ...prev,
        ...temp,
      };
    });
    // setGame(value)
  };
  const handleSelectlang = (name, value, index) => {
    console.log(name, value);
    let temp = formData;
    temp["country"][index] = {
      ...temp["country"][index],
      [name]: value,
    };
    // temp["country"][index][name] = value;
    // console.log(temp["country"]);
    setFormData((prev) => {
      return {
        ...prev,
        ...temp,
      };
    });
  };
  const handlerGame = (game, index) => {
    console.log(game);
    let temp = formData;
    // console.log(
    //   temp?.country[index]["game"]?.filter((g) => g.name == e.target.value)
    // );
    if (
      temp?.country[index]["game"]?.filter((g) => g.name === game)
        .length > 0
    ) {
      temp.country[index]["game"] = temp?.country[index]["game"]?.filter(
        (g) => g.name !== game?.name
      );
      // console.log(temp);
      setFormData((prev) => {
        return {
          ...temp,
        };
      });
    } else {
      temp?.country[index]["game"].push({
        name: game?.name,
        gamePlay: [],
        id: temp?.country[index]["game"].length,
      });
      setFormData((prev) => {
        return {
          ...prev,
          ...temp,
        };
      });
    }
  };
  console.log("formData::::", formData)

  const handlerGamePlay = (e, ind, index) => {
    setFormData((prevFormData) => {
      // Make a deep copy of prevFormData to avoid directly modifying the state
      const newFormData = JSON.parse(JSON.stringify(prevFormData));
      console.log("newFormData", newFormData,newFormData?.country[index],ind)
      // Check if gamePlay array exists; if not, initialize it
      if (!newFormData?.country[index]?.game[ind]?.gamePlay) {
        newFormData.country[index].game[ind].gamePlay = [];
      }

      const currentGamePlay = newFormData.country[index].game[ind]?.gamePlay;
      const value = e.target.value;

      // Check if the value exists in gamePlay array
      if (currentGamePlay.includes(value)) {
        // Remove the value if it already exists
        newFormData.country[index].game[ind].gamePlay = currentGamePlay.filter(
          (g) => g !== value
        );
      } else {
        // Add the value if it does not exist
        newFormData.country[index].game[ind].gamePlay.push(value);
      }

      return newFormData;
    });
  };
  const handlerRemove = (e, index) => {
    let temp = formData;
    temp.country = formData.country.filter((ele, ind) => ind != index);
    // console.log(temp);
    setFormData((prev) => {
      return { ...prev, ...temp };
    });
  };
  const handlerCurrency = (name, value) => {
    let temp = formData;
    // console.log(temp, temp["currency"], temp.currency);

    // console.log(temp);
    setFormData((prev) => {
      return {
        ...prev,
        currency: { ...temp.currency, [name]: value },
      };
    });
  };
  const handlerCurrencySubmit = () => {
    // console.log("formadata.curency", formData?.currency)
    if (Object.keys(formData?.currency).length !== 2) {
      let text = "Please select currencies";
      errorMessage(text);
      return;
    }
    dispatch(updateCompanyGuestConfigById({ id: id, name: 'currency', data: { "currency": formData?.currency } }))
    dispatch(setCompanyCurrencyReducer({ currency: formData?.currency }))
    setIsCurrEdit(true)

    setFormData((prev) => {
      return {
        ...prev,
        currency: formData.currency,
      };
    });
    let text = "Currency Submit successfully";
    successMessage(text);
  };
  const handlerCountrySubmit = () => {
    console.log("currencyGuest::::",currencyGuest)
    if (currencyGuest && Object.keys(currencyGuest).length != 2) {
      let text = "Please select country";
      errorMessage(text);
      return;
    }
    if (formData.country[index]?.game < 1) {
      let text = "Please select at lease one game";
      errorMessage(text);
      return;
    }
    dispatch(updateCompanyGuestConfigById({ id: id, name: 'countryConfig', data: { "countryConfig": formData.country } }))
    dispatch(setCompanyCountryReducer({ countryConfig: formData.country }));
    let text = "country Submit successfully";
    successMessage(text);
    setTabs((prev) => {
      if (prev.includes("Auction Settings")) return prev;
      else return [...prev, "Auction Settings"];
    });
    setActiveTab("Auction Settings");
  };
  const handlerAdd = (e, index) => {
    setIndex(index);
    // console.log(index, formData.country[index]?.game);
    if (formData.country[index]?.game < 1) {
      let text = "Please select at lease one game";
      errorMessage(text);
      return;
    }
    if (!formData.country[index]?.defaultLang) {
      let text = "Please select default lang";
      errorMessage(text);
      return;
    }
    if (
      !formData.country[index]?.other ||
      formData.country[index]?.other?.length < 1
    ) {
      let text = "Please select other lang";
      errorMessage(text);
      return;
    }
    setFormData({ ...formData, ...formData.country.push({}) });
    setLanguages([])
  };
  const handlerEditCountry = (indC) => {
    setIndex(indC)
    const newArray = [...isCntyEdit];
    newArray[indC] = !newArray[indC]; 
    setIsCntyEdit(newArray);
    console.log("id?>>>>>>>>",formData?.country?.[indC])
    dispatch(getAssignLanguage({ id: countryConfigGuest?.[indC]?.data?._id }))
    dispatch(getCurrencyListsDropdown({id: countryConfigGuest?.[indC]?.data?._id}))
    // if (isCntyEdit[indC] == false) {
    //   countryConfig?.map((ele, index) => {
        
    //   })
    // }

  }
  useEffect(() => {

    setIncurr(list)
  }, [list])
  useEffect(() => {
    setCountryOptions(countries?.dropDownList)
  }, [countries?.dropDownList])
  useEffect(() => {
    let lang = languageAssign?.getAssignLangList.reduce((acc, item) => {
      acc.push({
        ...item,
        label: item?.name,
        // value: item?.name
      })
      return acc
    }, [])
    setLanguages(lang)
  }, [languageAssign?.getAssignLangList])
  useEffect(() => {

    let g = selectedGames//Object.keys(selectedGames);
    console.log("hgamesss>>>>>>",countryConfigGuest)
    setGames(g);
    if (currencyGuest) {
      console.log("::::::::::", currencyGuest, formData)
      setFormData((prev) => {
        return {
          ...prev,
          currency: currencyGuest,

        };
      });
    }
    if (countryConfigGuest)
      setFormData((prev) => {
        return {
          ...prev,
          country: [...countryConfigGuest],

        };
      });


    if (!currencyGuest || !isCurrEdit) {
      dispatch(getCurrencyListsDropdown({}))

    }
    // console.log("countryconfig::::::::",countryConfig)
    if (currencyGuest)
      setIsCurrEdit(true)
    countryConfigGuest?.map((ele, ind) => {
      if (ele?.data)
        setIsCntyEdit(prev => {
          return [
            ...prev,
            true
          ]
        })
    })
    dispatch(getCountryDropdown({}))
  }, []);

  
  return (
    <>
      {/* <div className='row g-9 m-0 p-0'>
         <div className='col-md-6 col-12 px-3'> */}
      {
        loading ? <Loader />
          :
          <div className="card  ">
            <div className="card-header">
              <div className="card-title d-flex align-items-center">
                <h3 className="fw-bold m-0 text-gray-800">Select Currency</h3>
              </div>
            </div>
            <div className="row card-body">
              <div className="col-md-4 col-sm-6 col-12">

                <SelectDropdownWithSearch
                  name="inCurr"
                  //   required={"true"}
                  //   label="Country"
                  placeholder={"Select a inCurrency"}
                  options={inCurr}
                  value={inCurr.find((option) => option._id === formData?.currency?.inCurrency._id) || formData?.currency?.inCurrency}
                  onChange={(selectedOption) => {
                    handlerCurrency("inCurrency", selectedOption)
                  }
                  }
                  disabled={!isCurrEdit}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-12">

                <SelectDropdownWithSearch
                  name="outCurr"
                  //   required={"true"}
                  //   label="Country"
                  placeholder={"Select a outCurrency"}
                  options={inCurr}
                  value={inCurr.find((option) => option?._id === formData?.currency?.outCurrency?._id) || formData?.currency?.outCurrency}
                  onChange={(selectedOption) => {
                    handlerCurrency("outCurrency", selectedOption)
                  }

                  }
                  disabled={!isCurrEdit}
                />
              </div>
              {/* {console.log("isCurr", isCurrEdit,currency)} */}
              {
                !isCurrEdit ?
                  <div className="col-md-4 col-sm-6 col-12  mt-2 d-flex justify-content-end">
                    <ButtonWithLoader
                      text="Submit"
                      handlerSubmit={handlerCurrencySubmit}
                    />
                  </div>
                  :
                  <div className="col-md-4 col-sm-6 col-12  mt-2 d-flex justify-content-end">
                    <LightButtonWithOutline
                      text="Edit"
                      handlerClick={() => {
                        setIsCurrEdit(false)
                        dispatch(getCurrencyListsDropdown({}))
                      }}
                    />
                  </div>
              }

            </div>
          </div>
      }
      {console.log("cntyjdsncjs", isCntyEdit)}
      {countries?.loading ?
        <Loader /> :
        formData?.country?.map((id, indC) => {
          // console.log(id, indC);
          return (
            <div className="card ">
              <div className="card-header">
                <div className="card-title d-flex align-items-center">
                  <h3 className="fw-bold m-0 text-gray-800">Select Country</h3>
                </div>
                <div className="card-toolbar">
                  <div className="w-200px d-flex justify-content-between">
                    {indC !== 0 && (
                      <div
                        className={`btn py-3 px-3 mw-150px  hover-scale 
                           btn-danger `}
                        onClick={(e) => handlerRemove(e, indC)}
                      >
                        {/* {console.log(
                        formData.country.filter((ele, ind) => ind != indC)
                      )} */}
                        <span className="w-80px d-flex justify-content-between align-items-center">
                          <i className="ki-outline ki-minus-square  fs-1 me-1"></i>
                          Remove
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {console.log("countryoptions::::::", countryOptions)}
              <div className="row card-body">
                <div className="row ">
                  <div className="col-md-4 col-sm-6 col-12">

                    <SelectDropdownWithSearch
                      name="country"
                      //   required={"true"}
                      //   label="Country"
                      placeholder={"Select a country"}
                      options={countryOptions}
                      value={countryOptions.find(
                        (option) => option._id === formData.country[indC].data?._id
                      )}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        handleSelectChange("country", selectedOption, indC);

                      }}
                      disabled={!isCntyEdit[indC]}
                    />
                  </div>
                  <div className="row g-4 mx-3 ">
                    {formData?.country[indC]?.data?.name ? (
                      <>
                        <div className="row gap-10">
                          {games && games?.map((game, ind) => {
                            console.log(game,formData['country'][index])
                            return (
                              <div className=" col-5 justify-content-between border-gray-300 border-dashed py-4">
                                <div className="d-flex">
                                  <label> {game?.name}</label>
                                  <Checkbox
                                    name={game}
                                    value={game}
                                    onChange={(e) => handlerGame(game, indC)}
                                    checked={
                                      formData["country"]?.[indC]?.[
                                        "game"
                                      ]?.filter((g) => g.name == game?.name).length > 0
                                    }
                                  />
                                </div>
                                {console.log("gp>>>>>", game?.game_play)}

                                {
                              formData["country"]?.[indC]?.[
                                "game"
                              ]?.filter((g) => g.name == game?.name).length > 0 &&
                                (
                                    <div className="d-flex mt-4">
                                      {game?.game_play?.map(
                                        (gp, i) => {
                                          return (
                                            <>
                                              <label>{gp}</label>
                                              <Checkbox
                                                value={gp}
                                                onChange={(e) =>
                                                  handlerGamePlay(
                                                    e,
                                                    formData["country"]?.[indC]?.[
                                                      "game"
                                                    ]?.filter(
                                                      (g) => g.name == game.name
                                                    )[0]?.id,
                                                    indC
                                                  )
                                                }
                                                checked={
                                                  formData?.country?.[indC][
                                                    "game"
                                                  ]?.[
                                                    formData["country"]?.[indC]?.[
                                                      "game"
                                                    ]?.filter(
                                                      (g) => g.name == game.name 
                                                    )[0]?.id
                                                  ]?.["gamePlay"]?.filter(
                                                    (g) => g === gp
                                                  )?.length > 0
                                                }
                                              />
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                              </div>
                            );
                          })}
                        </div>

                          
                        {
                          (languageAssign?.loading && index == indC ) ? <Loader /> :
                            <>

                              <div className="col-md-4 col-sm-6 col-12">
                                {console.log("isCntyEdit[indC]",isCntyEdit[indC])}
                              { index==indC &&  console.log("language*******",languageAssign?.loading,language, formData.country[indC].defaultLang,language?.find(
                                    (option) => option._id === formData.country[indC].defaultLang?._id),language.length > 0 ? language?.find(
                                      (option) => option._id === formData.country[indC].defaultLang?._id
                                    ) : formData.country[indC].defaultLang)}
                                <SelectDropDown
                                  label={"Default Languague"}
                                  options={language}
                                  placeholder={"Select Default Lagugae"}
                                  value={language.length > 0 ? language?.find(
                                    (option) => option._id === formData.country[indC].defaultLang?._id
                                  ) : formData.country[indC].defaultLang}
                                  onChange={(selectedOption) =>
                                    handleSelectlang(
                                      "defaultLang",
                                      selectedOption,
                                      indC
                                    )
                                  }
                                  disabled={isCntyEdit[indC] || false}
                                />
                              </div>

                              <div className="col-md-4 col-sm-6 col-12">
                                <SelectDropDown
                                  label={"Other Languagues"}
                                  options={language}
                                  placeholder={"Select Other Language"}
                                  isMulti={true}
                                  value={language?.find(
                                    (option) => formData.country[indC]?.other?.find(ele => ele?.name == option.name)
                                  ) || formData.country[indC]?.other}
                                  onChange={(selectedOption) =>
                                    handleSelectlangMany("other", selectedOption, indC)
                                  }
                                  disabled={isCntyEdit[indC] || false}
                                />
                              </div>
                            </>
                        }
                      {console.log("icurrrr", inCurr)}
                        <div className="col-md-4 col-sm-6 col-12">
                          <label className='form-label mt-3'><span className={`required`}>{"Select Real Currency"}</span></label>
                          <SelectDropdownWithSearch
                            name="inCurr"
                            //   required={"true"}
                            //   label="Country"
                            placeholder={"Select Real Currency"}
                            options={inCurr}
                            value={inCurr.find(
                              (option) => option._id === formData?.country[indC].realCurr?._id
                            ) || formData?.country[indC].realCurr}
                            onChange={(selectedOption) => {
                              setIncur(selectedOption.value);
                              handleSelectlang("realCurr", selectedOption, indC);
                            }}
                            disabled={!isCntyEdit[indC]}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>


                <div className="d-flex align-items-center mt-6 gap-10 justify-content-end">
                  {indC == formData.country.length - 1 &&
                    formData.country[indC]?.data?.name && (
                      <div
                        className={`btn py-4 px-4   hover-scale 
                            btn-light `}
                        onClick={(e) => handlerAdd(e, indC)}
                      >
                        <span className="w-65px d-flex justify-content-between align-items-center">
                          {" "}
                          <i className="ki-outline ki-plus-square fs-1 me-1"></i>
                          Add
                        </span>
                      </div>
                    )}
                  <div>
                    {
                      isCntyEdit[indC] == true ?
                        <LightButtonWithOutline text={"Edit"} handlerClick={()=>handlerEditCountry(indC)} />
                        :
                        // indC == formData.country.length - 1 &&
                          formData.country[indC]?.data?.name ?
                        <ButtonWithLoader
                          text={"Submit"}
                          handlerSubmit={handlerCountrySubmit}
                          isLoading={isLoading}
                        />
                        :""

                    }
                  </div>


                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default CurrencyCountryConfiguration;
