import React, { useEffect, useState } from 'react'
import RadioWithoutIcon from '../../../../../Common/RadioWithoutIcon'
import { useDispatch, useSelector } from 'react-redux'
const roles = ['shared', "non-shared","none"]
const RoleCell = ({props, data}) => {
  // console.log("data", data)
    const {companyCode} = useSelector(state => state.series)
    
    const [val, setval] = useState()
    const handlerChange = (ele)=>{

      setval(ele);
       props.table.options.meta.updateEdit(props.row.index, "is_shared",  ele== "shared" ? true : ele == "non-shared" ? false : "null")
    }
    useEffect(()=>{
      if(data == true) setval("shared")
        else if(data == false)  setval("non-shared")
      else setval("none")
    },[])
  return (
    <>
    {
        companyCode !== 'SAAS' ? 
        <p>{roles?.map(ele=> ele + ", ")}</p>
        :
       roles?.map(ele=> {
            return <RadioWithoutIcon
            label = {ele}
            name={ele+props.row.index}
            onChange={(e) => {
             handlerChange(ele)
            }}
            value={val}
            checked={ele == val}
            disable = {data == true || data == false}
            />
        })
    }
    </>
  )
}

export default RoleCell