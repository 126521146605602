import React, { useEffect, useState } from "react";
import axios from "axios";

import Content from "../Common/Content";
import Table from "../Common/TableWithTan/Table";
import AssociationTable from "./FantasyCommon/associationTable";
import DataToStringDiv from "./FantasyCommon/DataToStringDiv";

const FantasyAssociations = () => {
  // useState DATA
  const [associations, setAssociations] = useState([]);
  const [dataDiv, setshowDataDiv] = useState(false);
  // useEffect Handlers
  useEffect(() => {
    fetchAssociationsList();
  }, []);
  //Support Functions
  const fetchAssociationsList = async () => {
    try {
      const res = await getAssociationsList();
      if (res?.data?.associations) {
        setAssociations(res?.data?.associations);
      } else {
        // console.log("DATA NOT FOUND !!");
      }
    } catch (error) {
      console.error("Support Functions ERROR:", error);
    }
  };
  //API Calls Functions
  const getAssociationsList = async () => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API_URL}v5/cricket/${process.env.REACT_APP_PROJECT_KEY
          }/association/list/`,
      };
      return axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          return response.data;
        })
        .catch((error) => {
          // console.error("API CALL ERROR:", error);
        });
    } catch (error) {
      // console.error("API CALL  Functions ERROR:", error);
    }
  };
  return (
    <Content>
      <div class="card card-custom">
        <div class="card-header flex-wrap flex-column border-0 pt-6 pb-0">
          <div class="card-title d-flex flex-column justify-content-between">
            <h1 className="m-1">
              <p>{" "}<span><button className="p-2 m-1" style={{
                      border: "0px ",
                      borderRadius: "20%",
                      backgroundColor: "#ffff",
                    }}
                    onClick={() => {
                      setshowDataDiv(!dataDiv);
                    }}
                  >+</button>
                </span>{" "}
                Fantasy Associations{" "}
              </p>
            </h1>
            <h5 className="p-2">
              <span>Association /all</span>
            </h5>
          </div>
          <div>
            {dataDiv && (
              <DataToStringDiv
                heading={"Association Data : association API "}
                children={associations}
              />
            )}
          </div>
        </div>
        <div class="card-body">
          <div class="mb-7">
            <div class="row align-items-center">
              <div class="col-lg-3 col-xl-4 mt-5 mt-lg-0"></div>
            </div>
          </div>
          <div
            class="datatable datatable-bordered datatable-head-custom"
            id="kt_datatable"
          >
            <AssociationTable associations={associations} key={associations} />
          </div>
        </div>
      </div>
    </Content>
  );
};

export default FantasyAssociations;
