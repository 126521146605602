import React, { useEffect, useMemo, useState } from "react";
import Toolbar from "../../../Common/Toolbar";
import Content from "../../../Common/Content";

import GeneralConfigurations from "./GeneralConfigurations";
import CurrencyCountryConfiguration from "./CurrencyCountryConfiguration";
import OtherConfiguration from "./OtherConfiguration";
import CompanyCard from "./CompanyCard";
import WalletConfiguration from "./WalletConfiguration";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyById, getCompanyConfigById } from "../redux/company.action";
import Loader from "../../../MasterLayout/Loader";
import { errorMessage } from "../../../Common/SwalToast";
import { getCurrencyListsDropdown } from "../../Master/Currency/redux/currency.action";
import GuestCurrencyCountryConfiguration from "./GuestCurrencyCountryConfiguration";
import AuctionSettings from "./AuctionSettings";
import ProfileIcons from "./ProfileIcons/ProfileIcons";


const ViewCompanyPage = () => {
  const[ tabs, setTabs] = useState(["General Configuration"])
  const [activeTab, setActiveTab] = useState(tabs[0])
  const dispatch = useDispatch()
  const {loading, error, companies} = useSelector(state => state?.companies)
  const id = useMemo(() =>  window.location.href.split("/")[
    window.location.href.split("/").length - 1
    ] || null , []);
  
  useEffect(()=>{
    dispatch(getCompanyConfigById({id:id, name:'general'}))
   
  },[])
  useEffect(()=>{
    if(error){
      errorMessage(error)
    }
  },[error])
  return (
    <>
      <Toolbar text={"Company Details"} />
      {
        loading ? <Loader />
        :
      <Content>
      
								<CompanyCard tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
								{
                  activeTab === tabs[0] ?  <>
                  <GeneralConfigurations setTabs={setTabs} setActiveTab = {setActiveTab} id={companies?._id} />
                  </> 
                  : activeTab === tabs[1] ? <>
                   <CurrencyCountryConfiguration setTabs={setTabs} setActiveTab = {setActiveTab} id={companies?._id} />
                  
                  </>
                  : activeTab === tabs[2] ? <>
                   <GuestCurrencyCountryConfiguration setTabs={setTabs} setActiveTab = {setActiveTab} id={companies?._id} />
                  
                  </>
                  : activeTab === "Auction Settings" ? <>
                   <AuctionSettings setTabs={setTabs} setActiveTab = {setActiveTab}  id={companies?._id}/>
                  </>
                  : activeTab === "Profile Icons" ? <>
                   <ProfileIcons setTabs={setTabs} setActiveTab = {setActiveTab}  id={id}/>
                  </>
                  : activeTab === tabs[4] ? <>
                   <OtherConfiguration setTabs={setTabs} setActiveTab = {setActiveTab} id={companies?._id} />
                  </>
                  : <>
                  <WalletConfiguration />
                  </>
                }
      </Content>
      }
    </>
  );
};

export default ViewCompanyPage;
