import { createSlice } from "@reduxjs/toolkit";
import { getTeamFetchById, getTeamsList, saveTeam } from "./teams.action";





export const auctionSettingsSlice = createSlice({
  name: "auctionSettingState",
  initialState: {
 
    saveLoading:false,
    save:"",
    teams:[],
    teamLoad:false,
    list:[],
    loading:false
  },
  reducers: {
    addAuctionSettings: (state,action)=>{
      state.list.push(action.payload.settings)
    },
    removeAuctionSettings: (state,action)=>{
      state.list = action.payload.settings
    }
  },
  extraReducers: (builder) => {

    builder.addCase(getTeamsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamsList.fulfilled, (state, action) => {
      state.loading = false;
      state.teams = ""
      state.save = ""
      state.list = action.payload?.data;
      
    });
    builder.addCase(getTeamsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error?.message;
    });
    builder.addCase(getTeamFetchById.pending, (state) => {
      state.teamLoad = true;
    });
    builder.addCase(getTeamFetchById.fulfilled, (state, action) => {
      state.teamLoad = false;
      state.list = action.payload?.data;
      state.teams = action.payload?.message;
     

    });
    builder.addCase(getTeamFetchById.rejected, (state, action) => {
      state.teamLoad = false;
      state.error = action.payload.error?.message;
    });
   
    builder.addCase(saveTeam.pending, (state) => {
      state.saveLoading = true;
    });
    builder.addCase(saveTeam.fulfilled, (state, action) => {
      state.saveLoading = false;
      
      state.save = action.payload;
    });
    builder.addCase(saveTeam.rejected, (state, action) => {
      state.saveLoading = false;
      state.error = action.payload;
    });
  },
});

export const { addAuctionSettings,removeAuctionSettings } = auctionSettingsSlice.actions;
export default auctionSettingsSlice.reducer;
