import React, { useEffect, useState } from "react";
import axios from "axios";

import Content from "../Common/Content";
import Table from "../Common/TableWithTan/Table";
import AssociationTable from "./FantasyCommon/associationTable";
import DataToStringDiv from "./FantasyCommon/DataToStringDiv";

const FantasyMain = () => {
  // useState DATA
  const [associations, setAssociations] = useState([]);
  const [dataDiv, setshowDataDiv] = useState(false);


  // State for the first dropdown
  const [seriesOptions, setSeriesOption] = useState([{
    value: "all",
    label: "All Series"
  }]);
  const [selectedSeries, setSelectedSeries] = useState();
  const [associationOptions, setAssociationOption] = useState([{
    value: "all",
    label: "All Associations"
  }]);
  const [selectedassociation, setSelectedAssociation] = useState();

  // useEffect Handlers
  useEffect(() => {
    fetchAssociationsList(); 
  }, []);

  useEffect(() => {
    let assolist=[]
    associations.forEach((association)=>{
    assolist.push({
      value: association.key,
      label: `${association.name}-(${association.code})`,
    })});
    setAssociationOption(prev => [...prev, ...assolist])
  }, [associations]);



  //Funtional changes handler DROPDOWN
  // State for the second dropdown
  const [secondOption, setSecondOption] = useState('');
  // Handle change for the first dropdown
  const handleFirstChange = (event) => {
    setSelectedAssociation(event.target.value);
  };
  // Handle change for the second dropdown
  const handleSecondChange = (event) => {
    setSecondOption(event?.target?.value);
  };
  //Funtional changes handler DROPDOWN






  //Support Functions
  const fetchAssociationsList = async () => {
    try {
      const res = await getAssociationsList();
      if (res?.data?.associations) {
        setAssociations(res?.data?.associations);
      } else {
        console.log("DATA NOT FOUND !!");
      }
    } catch (error) {
      console.error("Support Functions ERROR:", error);
    }
  };
  //API Calls Functions
  const getAssociationsList = async () => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API_URL}v5/cricket/${process.env.REACT_APP_PROJECT_KEY}/association/list/`,
      };
      return axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((error) => {
          console.error("API CALL ERROR:", error);
        });
    } catch (error) {
      console.error("API CALL  Functions ERROR:", error);
    }
  };
  return (
    <Content>
      <div class="card card-custom">
        <div class="flex-wrap flex-column border-0">
          <p className="">
            <p
              style={{
                fontSize: "1.5rem",
                fontWeight: 600,
              }}
            >
              {" "}
              <span>
                <button
                  className="mt-3"
                  style={{
                    border: "0px ",
                    borderRadius: "20%",
                    backgroundColor: "#ffff",
                  }}
                  onClick={() => {
                    setshowDataDiv(!dataDiv);
                  }}
                >
                  +
                </button>
              </span>
              <span> Fantasy </span>
            </p>
          </p>
          <p className="p=0 m-0">
            <span>BreadCrumb</span>
          </p>

          <div>
            {dataDiv && (
              <DataToStringDiv
                heading={"Association Data : association API "}
                children={associations}
              />
            )}
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#e7e7e7",
            width: "100%",
            minHeight: "400px",
          }}
        >
          <div
            style={{
              backgroundColor: "#cbcbcb",
              width: "100%",
              minHeight: "30px",
              display:"flex",
            
            }}
          >
            <div style={{
              padding:"5px"
            }} >
              <select
                id="associationSelect"
                value={selectedassociation?.label}
                onChange={handleFirstChange}> {
                  associationOptions?.map((element)=>{
                    console.log("association", element?.value)
                    return <option value={element?.value}>{element?.label}</option>
                  })
              }
              </select>
            </div>
            <div style={{
              padding: "5px"
            }}>
              <select
                id="seriesSelect"
                value={selectedSeries?.label}
                onChange={handleFirstChange}> {
                  seriesOptions?.map((element) => {
                    console.log("series", element?.value)
                    return <option value={element?.value}>{element?.label}</option>
                  })
                }
              </select>
            </div>
            {/* <div style={{
              padding: "5px"
            }}>
              <select
                id="secondSelect"
                value={secondOption}
                onChange={handleSecondChange}
              >
                <option value="">All Series</option>
                <option value="optionA">Option A</option>
                <option value="optionB">Option B</option>
                <option value="optionC">Option C</option>
              </select>
            </div> */}
          </div>


          <div
            style={{
              backgroundColor: "#adadad",
              width: "100%",
              minHeight: "120px",
            }}
          >
            series
          </div>
          <div
            style={{
              backgroundColor: "#bdbdbd",
              width: "100%",
              minHeight: "120px",
            }}
          >
            matches
          </div>
          <div
            style={{
              backgroundColor: "#cdcdcd",
              width: "100%",
              minHeight: "230px",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <div
              style={{
                backgroundColor: "#9d9d9d",
                width: "50%",
                minHeight: "230px",
              }}
            >
              scoreboard
            </div>
            <div
              style={{
                backgroundColor: "#9d9d9d",
                width: "50%",
                minHeight: "230px",
              }}
            >
              match overview
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default FantasyMain;
