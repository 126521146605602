import React, { useEffect, useRef, useState } from "react";
import Toolbar from "../../../Common/Toolbar";
import Content from "../../../Common/Content";
import TextInputField from "../../../Common/TextInputField";
import ButtonWithLoader from "../../../Common/ButtonWithLoader";
import UploadSingleImage from "../../../Common/UploadSingleImage";
import { formValidation } from '@form-validation/bundle/popular';
import { Bootstrap5 } from '@form-validation/plugin-bootstrap5';
import { Trigger } from '@form-validation/plugin-trigger';
import { errorMessage, successMessage } from "../../../Common/SwalToast";
import { useDispatch, useSelector } from "react-redux";
import { addLanguage, updateLanguage} from './redux/language.action'
const CreateLanguage = ({data}) => {
  const [formData, setFormData] = useState({
  
    name: "",
    code: "",
   
  });
  const formRef = useRef()
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle loading indication
  const [validator, setValidator] = useState(null); // Store form validation instance
  const dispatch = useDispatch()
  const { language,error, loading } = useSelector(state => state.languages)
  const [errMsg, setErrMsg] = useState('')
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]:
        type === "checkbox"
          ? checked
            ? [...formData.notifications, value]
            : formData.notifications.filter((n) => n !== value)
          : value,
    });
  };
  
  const handlerSubmit = () => {
    console.log(language,error, loading)
  };
  useEffect(()=>{
    
    if(error){
      console.log(error)
      setErrMsg(error)
      errorMessage(error || 'Something went wrong')
    }
  },[error])
  useEffect(()=>{
    console.log(loading,language)
    if(language.length > 0){
      console.log(language[0]?.data?.message)
      successMessage(language[0]?.data?.message)

    }
    else{
      console.log(loading)
    }
  },[loading])
  const checkValidation = (e)=>{
    e.preventDefault();
    
    if (validator) {
        validator.validate().then(function (status) {
            
          console.log(":data:::::", data)
            if (status === 'Valid') {
                setIsSubmitting(true);
                if(data)
                  dispatch(updateLanguage({data: formData, id: data?._id})) 

              else{
                console.log(
                  "else bloack"
                )
                dispatch(addLanguage({data: formData}))
              }
               
                
                
            } else {
                // Show error message
                let text= "Sorry, looks like there are some errors detected, please try again."
               errorMessage(text)
            }
        });
    }
    
  }
  useEffect(() => {
    const form = formRef.current;
    if (!form) return;

    const validationFields = {
        fields: {
            name: {
                validators: {
                    notEmpty: {
                        message: 'Name is required'
                    },
                    // stringLength: {
                    //     verbose: false, 
                    //     min: 6,
                    //     max: 30,
                    //     message: 'The name must be more than 6 and less than 30 characters long',
                    // },
                    // regexp: {
                    //     regexp: /^[a-zA-Z0-9_]+$/,
                    //     message: 'The name can only consist of alphabetical, number and underscore',
                    // },
                }
            },

            code: {
                validators: {
                    notEmpty: {
                        message: 'Code is required'
                    }
                }
            },
         
          
        },
        plugins: {
            trigger: new Trigger(),
            bootstrap: new Bootstrap5({
                rowSelector: '.fv-row',
                eleInvalidclassName: '',
                eleValidclassName: ''
            })
        }
    };

    const fv = formValidation(form, validationFields);

    setValidator(fv);
    if(data){
      console.log("data:::",data)
      setFormData(prev =>
         {
          return {
        ...prev,
        name: data?.name,
        code: data?.code
      }
      })
    }
}, [data])


  
  return (
    <>
      {/* <Toolbar text={"Create currency"} />
      <Content> */}
        <div className="card pt-4">
          <div className="card-body"  ref={formRef}>
            <div class="row g-9 mb-4">
          
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  required={"true"}
                  label={"name"}
                  value={formData?.name}
                  name={"name"}
                  type={"text"}
                  onChange={handleChange}
                  placeholder={"Enter name"}
                />
              </div>
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  required={"true"}
                  label={"Code"}
                  value={formData?.code}
                  name={"code"}
                  type={"text"}
                  onChange={handleChange}
                  placeholder={"Enter code"}
                />
              </div>
           

              <div class="col-md-4 col-6 d-flex align-items-center justify-content-center pt-3">
                <ButtonWithLoader
                  text={"Submit"}
                  handlerSubmit={checkValidation}
                />
              </div>
            </div>
          </div>
        </div>
      {/* </Content> */}
    </>
  );
};

export default CreateLanguage;
