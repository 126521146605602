
const bowlerColumn = [

    {
      accessorKey:'name',
      header:'Bowler',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'details',
      header:'',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'over',
      header:'o',
      size:70,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'maiden',
      header:'M',
      size:70,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'runs',
      header:'R',
      size:70,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'wickets',
      header:'W',
      size:70,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'noBall',
      header:'NB',
      size:70,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'wideBall',
      header:'WD',
      size:70,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'economyRate',
      header:'ECO',
      size:70,
      cell: (props) => <p>{props.getValue()}</p>
  
    },


   
  ]
  
  export {bowlerColumn}
  