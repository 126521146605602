import React, { useEffect, useState } from "react";
import Toolbar from "../../../Common/Toolbar";
import Content from "../../../Common/Content";
import Table from "../../../Common/TableWithTan/Table";
import { currencyColumn } from "./CurrencyCoulmn";
import { useNavigate } from "react-router";
import { routesFrontend } from "../../../RouterFiles/routesFrontend";
import PrimaryButton from "../../../Common/PrimaryButton";
import CreateCurrency from "./CreateCurrency";
import WithAddButton from "../../../../hooks/WithAddButton";
import { useDispatch, useSelector } from "react-redux";

import {
  errorMessage,
  successMessage,

} from "../../../Common/SwalToast.js";

import { delCurrency, fetchList } from "./redux/currency.action.js";
import Loader from "../../../MasterLayout/Loader.jsx";

const CurrencyList = ({ handleAddBtn, addBtn }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([])
  const [updateData, setUpdateData] = useState()
  const { currency, currencies, loading, totalPages, delCurr, error, totalRecord } = useSelector(state => state.currencies);
  const [pageNo, setPageNo] = useState(0)
  const [recordPerPages, setRecordPerPage] = useState(10 )
  const [isloading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  useEffect(() => {
    setLoading(loading)
  }, [loading])


  useEffect(()=>{
    
    if(error){
      console.log(error)
      setErrMsg(error)
      errorMessage(error || 'Something went wrong')
    }
  },[error])
  useEffect(()=>{
    console.log(delCurr)
    if(delCurr?.message){
      dispatch(fetchList({ pageNo: pageNo+1, recordPerPage: recordPerPages }))
      successMessage(delCurr?.message)
    }
  },[delCurr])
  useEffect(() => {
    console.log("staff",currency)
    if (currency?.success) {
      console.log(currency)
      successMessage(currency.message)
      handleAddBtn(false)
      dispatch(fetchList({ pageNo: 0, recordPerPage: recordPerPages }))
    }
  }, [currency])
  const updateEdit = (rowIndex) => {
    handleAddBtn(false)
    handleAddBtn(true)
    console.log(data[rowIndex]?._id)
    setUpdateData(data[rowIndex])
    // navigate('/' + routesFrontend.language + '/edit/' + tableData[rowIndex]?._id)
  }
  const updateDelete = (rowIndex) => {

    console.log(data[rowIndex]?._id,error)

    dispatch(delCurrency({id:data[rowIndex]?._id}))
  }
  useEffect(() => {
    dispatch(fetchList({ pageNo: pageNo+1, recordPerPage: recordPerPages }))

  }, [pageNo,recordPerPages])
  useEffect(()=>{
    setLoading(loading)
  },[loading])
  useEffect(() => {
    console.log("currencies:::",currencies)
    setData(currencies)
    
  }, [currencies])


  return (
    <>
      <Toolbar
        text={"Currency List"}
        isButton={true}
        handleClick={()=> {
          setUpdateData(null)
          handleAddBtn(!addBtn)
          }} 
        textBtn={"Add Currency"}
        addBtn={addBtn}
      />
      <Content>
        {addBtn && <CreateCurrency data={updateData} />}
        <div className="card">
          <div className="card-body">
            {
              isloading ? <Loader />
                :
                <Table
                  column={currencyColumn}
                  tableData={data}
                  totalPages={totalPages}
                  totalRecord={totalRecord}
                  pageIndex={pageNo}
                  recordPerPage={recordPerPages}
                  updateEdit={updateEdit}
                  updateDelete={updateDelete}
                  handlerChangeDropDown={(index) => {
                    setPageNo(0)
                    setRecordPerPage(index)
                  }
                  }
                  handlePageChange={(index) => {
                    setPageNo(index)
                  }}
                />
            }
          </div>
        </div>
      </Content>
    </>
  );
};

export default WithAddButton(CurrencyList);
