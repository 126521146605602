
import ViewDetailCell from "../../../Common/TableWithTan/ViewDetailCell"
import { getDate } from "../../../utilities/dateUtils"
import CompanyCell from "./CompanyCell"



const seriesColumn = [

    {
      accessorKey:'series_name',
      header:'name',
      
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'association_name',
      header:'Assocication',
      
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'team_count',
      header:'Team Count',
      size:250,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'format',
      header:'Format',
      
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'start_date',
      header:'start_date',
      size:150,
      cell: (props) => <p>{getDate(props.getValue())}</p>
  
    },
    {
      accessorKey:'end_date',
      header:'end_date',
      size:150,
      cell: (props) => <p>{getDate(props.getValue())}</p>
  
    },
    {
      accessorKey:'format',
      header:'code',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },

    {
      accessorKey:'',
      header:'View Details',
      size:100,
      cell: (props) => <ViewDetailCell props={props} />
  
    },
    // {
    //   accessorKey:'_id',
    //   header:'Company',
    //   cell: (props) => <CompanyCell id={props.getValue()} props={props} />
  
    // }
   
  ]
  
  export {seriesColumn}
  