import React from 'react'
import ModalContainer from './ModalContainer'
import OtpForm from '../Pages/Login/OtpForm'

const OtpPopup = ({onClose, handlerSubmit}) => {
  return (
   <ModalContainer text={"Verify Otp"} isViewOnly={true} onClose={onClose}>
    <OtpForm   handlerSubmit={handlerSubmit}/>
   </ModalContainer>
  )
}

export default OtpPopup