import React, { useEffect, useState } from "react";
import Table from "../../../Common/TableWithTan/Table";
import { languageColumn } from "./LanguageColumn";
import Toolbar from "../../../Common/Toolbar";
import Content from "../../../Common/Content";
import { useNavigate } from "react-router";
import { routesFrontend } from "../../../RouterFiles/routesFrontend";
import WithAddButton from "../../../../hooks/WithAddButton";
import CreateLanguage from "./CreateLanguage";
import { delLanguage, fetchList } from "./redux/language.action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../MasterLayout/Loader";
import { errorMessage, successMessage } from "../../../Common/SwalToast";
import SearchBox from "../../../Common/SearchBox";
import ButtonWithLoader from "../../../Common/ButtonWithLoader";

const ViewLanguageList = ({handleAddBtn,addBtn}) => {

  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [updateData, setUpdateData] = useState()
  const { languages,recordPerPage,  loading,totalPages,delLang ,error,totalRecord,language} = useSelector(state => state.languages);
  const [searchValue, setSearchValue] = useState('')
  const [pageNo, setPageNo] = useState(0)
  const [isloading, setLoading] = useState(false)
    const [recordPerPages, setRecordPerPage] = useState(10)
  useEffect(()=>{
    
    if(error){
      errorMessage(error || 'Something went wrong')
    }
  },[error])
  useEffect(()=>{
    console.log(delLang)
    if(delLang?.message){
      dispatch(fetchList({ pageNo: pageNo+1, recordPerPage: recordPerPages }))
      successMessage(delLang?.message)
    }
  },[delLang])
  useEffect(() => {

    if (language?.message) {
      console.log("updatesssss::::", languages,language)
      successMessage(language.message)
      handleAddBtn(false)
      dispatch(fetchList({ pageNo: 0, recordPerPage: recordPerPages }))
    }
  }, [language])
  const updateEdit = (rowIndex) => {
    handleAddBtn(false)
    handleAddBtn(true)
    console.log(data[rowIndex]?._id)
    setUpdateData(data[rowIndex])
    // navigate('/' + routesFrontend.language + '/edit/' + tableData[rowIndex]?._id)
  }
  const updateDelete = (rowIndex) => {

    console.log(data[rowIndex]?._id,error)

    dispatch(delLanguage({id:data[rowIndex]?._id}))
  }
  useEffect(() => {
    dispatch(fetchList({ pageNo: pageNo+1, recordPerPage: recordPerPages }))

  }, [pageNo,recordPerPages])
  useEffect(()=>{
    setLoading(loading)
  },[loading])
  useEffect(() => {
   
    setData(languages)
    
  }, [languages])

  return (
    <>
      <Toolbar text={"Languages"} isButton={true} handleClick={()=> {
        setUpdateData(null)
        handleAddBtn(!addBtn)
        }} textBtn={"Add Language"} addBtn={addBtn} />
      <Content>
        {
          addBtn &&
          <CreateLanguage data={updateData} />
        }
        <div className="card">

          <div class="card-body">
            <div className="row">
            <div className="col-6">

            < SearchBox  
              value={searchValue}
              onChange={(e)=> setSearchValue(e.target.value)}
            placeholder={'Search by name or code'}
            />
            </div>
            <div className="col-6">
              <ButtonWithLoader text="Submit"  handlerSubmit= {()=>{
                dispatch(fetchList({ pageNo: pageNo+1, recordPerPage: recordPerPages, search : searchValue }))
              }}/>
            </div>
            </div>
            {/* <FilterForm  /> */}
            {/* <Filter columnFilters={columnFilters} setColumnFilters={setColumnFilters} /> */}
            {/* <div class="col-md-4 col-6 mt-2">
                <FilterPopover
                  option={columnFilters}
                  setOption={setColumnFilters}
                  filterValues={employeeTypeFilter}
                  id="employeeType"
                  text="Employee Type"
                />
              </div> */}
            <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
             
              {
                isloading ? <Loader />
                :
              <Table
                column={languageColumn}
                tableData={data}
                totalPages={totalPages}
                totalRecord={totalRecord}
                pageIndex={pageNo}
                recordPerPage={recordPerPages}
                updateEdit={updateEdit}
                updateDelete={updateDelete}
                handlerChangeDropDown = {(index)=> 
                {
                  setPageNo(0)
                  setRecordPerPage(index)
                }
                }
                handlePageChange = {(index) => {
                  // console.log("index::::", index)
                  setPageNo(index)
                }}

              // columnFilters={columnFilters}
              // setColumnFilters={setColumnFilters}
              />
              }
            </div>

          </div>
        </div>
      </Content>
    </>
  );
};

export default WithAddButton(ViewLanguageList);
