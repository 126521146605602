import { configureStore } from '@reduxjs/toolkit'
import formReducer from '../Common/FormSlice/formSlice'

import loginSlice from '../Pages/Login/redux/login.slice';
import sportSlice from '../Pages/Master/Sport/redux/sport.slice';
import languageSlice from '../Pages/Master/Language/redux/language.slice';
import  countrySlice  from '../Pages/Master/Country/redux/country.slice';
import staffSlice  from '../Pages/Staff/redux/staff.slice';
import moduleSlice from '../Pages/Module/redux/module.slice';
import currencySlice from '../Pages/Master/Currency/redux/currency.slice';
import  companySlice from '../Pages/Company/redux/company.slice';
import  createCompanyReducer  from '../Pages/Company/CompanySlice/CompanyReducers'
import languageAssign  from '../Pages/Master/Country/redux/languageAssign.slice';
import guestSlice  from '../Pages/Company/redux/guest.slice';
import  seriesSlice  from '../Pages/Master/Series/redux/series.slice';
import auctionSettingsSlice from '../Pages/Master/Series/ViewSeriesDetails/AuctionSettings/redux/auctionSettings.slice';
import teamsSlice from '../Pages/Master/Series/ViewSeriesDetails/Teams/redux/teams.slice';
import playersSlice from '../Pages/Master/Series/ViewSeriesDetails/Players/redux/players.slice';
import matchesSlice from '../Pages/Master/Series/ViewSeriesDetails/Matches/redux/matches.slice';
import  assignCompanySlice  from '../Pages/Master/Series/ViewSeriesDetails/AssignCompany/redux/assignCompany.slice';
import profileIconsSlice from '../Pages/Company/ViewCompanyDetails/ProfileIcons/redux/profileIcons.slice';
export const store = configureStore({
  reducer: {
    login: loginSlice,
    module: moduleSlice,
    formSubmit:formReducer,
    staffUser: staffSlice,
    currencies: currencySlice,
    countries:countrySlice,
    sports: sportSlice,
    languages:languageSlice,
    companies: companySlice,
    createCompanyState: createCompanyReducer,
    languageAssign: languageAssign,
    guests:guestSlice,
    series:seriesSlice,
    auctionSettings:auctionSettingsSlice,
    teams:teamsSlice,
    players:playersSlice,
    matches:matchesSlice,
    assignCompanySeries: assignCompanySlice,
    profileIcons: profileIconsSlice
  },
})