import React, { useEffect, useRef, useState } from "react";
import TextInputField from "../../../Common/TextInputField";
import ButtonWithLoader from "../../../Common/ButtonWithLoader";
import SelectDropDown from "../../../Common/SelectDropDown";
import UploadSingleImage from "../../../Common/UploadSingleImage";
import { formValidation } from "@form-validation/bundle/popular";
import { Bootstrap5 } from "@form-validation/plugin-bootstrap5";
import { Trigger } from "@form-validation/plugin-trigger";
import RadioWithoutIcon from "../../../Common/RadioWithoutIcon";
import { useDispatch, useSelector } from "react-redux";

import {
  errorMessage,
  successMessage,
  successResponse,
} from "../../../Common/SwalToast.js";
import { useNavigate } from "react-router";
import { addCurrency, updateCurrency } from "./redux/currency.action.js";
import { getCountryDropdown } from "../Country/redux/country.action.js";
import SelectDropdownWithSearch from "../../../Common/SelectDropdownWithSearch.jsx";

const CreateCurrency = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dropDownList } = useSelector((state) => state.countries);

  const [formData, setFormData] = useState({ });
  const formRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle loading indication
  const [validator, setValidator] = useState(null); // Store form validation instance
  const [countryOptions, setCountryOptions] = useState([]);
  const [currencyID, setCurrencyID] = useState(null);

  const [currencyOptions, setCurrencyOptions] = useState([
    { value: 2, label: "Real" },
    { value: 1, label: "Virtual" },
  ]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]:
        type === "checkbox"
          ? checked
            ? [...formData.notifications, value]
            : formData.notifications.filter((n) => n !== value)
          : value,
    });
  };
  const handleSelectChange = (name, value) => {
    // console.log("handleSelectChange",name, value);
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handlerRadio = (name, value) => {
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const checkValidation = (e) => {
    e.preventDefault();
    if (validator) {
      validator.validate().then(function (status) {
        if (status === "Valid") {
          setIsSubmitting(true);
          if (data) dispatch(updateCurrency({ data: formData, id: data?._id }));
          else dispatch(addCurrency({ data: formData }));
        } else {
          let text =
            "Sorry, looks like there are some errors detected, please try again.";
          errorMessage(text);
        }
      });
    }
  };
  useEffect(() => {
    const form = formRef.current;
    if (!form) return;
    const validationFields = {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Name is required",
            },
            stringLength: {
              verbose: false,
              min: 6,
              max: 30,
              message:
                "The name must be more than 6 and less than 30 characters long",
            },
          },
        },
        code: {
          validators: {
            notEmpty: {
              message: "Code is required",
            },
          },
        },
        // country: {
        //   validators: {
        //     notEmpty: {
        //       message: "Country is required",
        //     },
        //   },
        // },
        // img: {
        //   validators: {
        //     notEmpty: {
        //       message: "Upload image is required",
        //     },
        //   },
        // },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap5({
          rowSelector: ".fv-row",
          eleInvalidclassName: "",
          eleValidclassName: "",
        }),
      },
    };
    const fv = formValidation(form, validationFields);
    setValidator(fv);
    console.log("update data:::", data);
    if (data) {
      setFormData({
        country: data?.country[0],
        name: data?.name,
        symbol: data?.symbol,
        img: data?.image,
        code: data?.code,
        codeISO: data?.codeISO,
        type: data?.type == "Virtual currency" ? 1 : 2,
      });
    }
  }, [data]);
  useEffect(() => {
    setCountryOptions(dropDownList);
  }, [dropDownList]);

  return (
    <>
      <div className="card">
        <div className="card-body" ref={formRef}>
          <div class="row g-9 mb-4">
            <div class="col-md-4 col-sm-6 col-12 mt-2">
              <div className="fv-row mb-7 d-flex flex-column">
                <label class="fs-6 fw-semibold form-label mt-3">
                  <span className={`required`}>Country Code</span>
                </label>
                <SelectDropdownWithSearch
                  name="country"
                  //   required={"true"}
                  //   label="Country"
                  placeholder={"Select a country code"}
                  options={countryOptions}
                  value={
                    countryOptions.find(
                      (option) => option.name === formData?.country?.name
                    ) || formData?.country
                  }
                  onChange={(selectedOption) => {
                    handleSelectChange("country", selectedOption);
                  }}
                  handleClick={() => dispatch(getCountryDropdown({}))}
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-12 mt-2">
              <div className="fv-row mb-7 d-flex flex-column">
                <label class="fs-6 fw-semibold form-label mt-3">
                  <span>Currency Type</span>
                </label>
                <div className="mt-3">
                  {currencyOptions?.map((curr) => (
                    <RadioWithoutIcon
                      label={curr?.label}
                      name={curr?.label}
                      onChange={(val) => handlerRadio("type", curr?.value)}
                      value={formData?.type}
                      checked={curr?.value === formData?.type}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6 col-12 mt-2">
              <TextInputField
                required={"true"}
                label={"name"}
                value={formData?.name}
                name={"name"}
                type={"text"}
                onChange={handleChange}
                placeholder={"Enter name"}
              />
            </div>

            <div class="col-md-4 col-sm-6 col-12 mt-2">
              <TextInputField
                required={"true"}
                label={"Code"}
                value={formData?.code}
                name={"code"}
                type={"text"}
                onChange={handleChange}
                placeholder={"Enter code"}
              />
            </div>

            {formData?.type === 2 && (
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  required={"true"}
                  label={"Code ISO"}
                  value={formData?.codeISO}
                  name={"codeISO"}
                  type={"text"}
                  onChange={handleChange}
                  placeholder={"Enter Code ISO"}
                />
              </div>
            )}
            {formData?.type === 2 && (
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <TextInputField
                  required={"true"}
                  label={"Symbol"}
                  value={formData?.symbol}
                  name={"symbol"}
                  type={"text"}
                  onChange={handleChange}
                  placeholder={"Enter symbol"}
                />
              </div>
            )}
            {formData?.type === 1 && (
              <div class="col-md-4 col-sm-6 col-12 mt-2">
                <UploadSingleImage
                  text={"Upload logo of currency"}
                  name="img"
                  handlerFormData={handleSelectChange}
                  data={formData?.img}
                />
              </div>
            )}

            <div class="col-md-4 col-6 d-flex align-items-center justify-content-start pt-3">
              <ButtonWithLoader
                text={"Submit"}
                handlerSubmit={checkValidation}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCurrency;
