import React from 'react'

const Radios = ({ data, onChange, name }) => {
    return (
        <div className="fv-row mb-7">
            {
                data?.map((ele) => {
                    return (
                        <label className="d-flex flex-stack mb-5 cursor-pointer">

                            <span className="d-flex align-items-center me-2">

                                <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label bg-light-primary">
                                        <i className="ki-outline ki-compass fs-1 text-primary"></i>
                                    </span>
                                </span>


                                <span className="d-flex flex-column">
                                    <span className="fw-bold fs-6">{ele?.label}</span>
                                    <span className="fs-7 text-muted">{ele?.subLevel}</span>
                                </span>

                            </span>


                            <span className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="radio" name={name} value={ele?.value} onChange={()=> onChange(ele?.value)} />
                            </span>

                        </label>
                    )
                })
            }



            <label className="d-flex flex-stack mb-5 cursor-pointer">

                <span className="d-flex align-items-center me-2">

                    <span className="symbol symbol-50px me-6">
                        <span className="symbol-label bg-light-danger">
                            <i className="ki-outline ki-element-11 fs-1 text-danger"></i>
                        </span>
                    </span>


                    <span className="d-flex flex-column">
                        <span className="fw-bold fs-6">Face to Face Discussions</span>
                        <span className="fs-7 text-muted">Creating a clear text structure is just one aspect</span>
                    </span>

                </span>


                <span className="form-check form-check-custom form-check-solid">
                    <input className="form-check-input" type="radio" name="category" value="2" />
                </span>

            </label>


            <label className="d-flex flex-stack cursor-pointer">

                <span className="d-flex align-items-center me-2">

                    <span className="symbol symbol-50px me-6">
                        <span className="symbol-label bg-light-success">
                            <i className="ki-outline ki-timer fs-1 text-success"></i>
                        </span>
                    </span>


                    <span className="d-flex flex-column">
                        <span className="fw-bold fs-6">Full Intro Training</span>
                        <span className="fs-7 text-muted">Creating a clear text structure copywriting</span>
                    </span>

                </span>


                <span className="form-check form-check-custom form-check-solid">
                    <input className="form-check-input" type="radio" name="category" value="3" />
                </span>

            </label>

        </div>
    )
}

export default Radios
