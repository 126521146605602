import React from 'react'
import {Link} from 'react-router-dom'
const ActionsCell = ({ type, table, row, deleteRow }) => {
    // console.log(table)
    return (
        <div>
                {
                    type?.includes('settings') &&
                <Link class="btn btn-sm btn-clean btn-icon mr-2" data-toggle="dropdown">
                    <span class="svg-icon svg-icon-md">
                        <i class="ki-solid ki-gear fs-2"></i>
                    </span>
                </Link>
                }
             {
                type?.includes('edit') &&
            <button class="btn btn-sm btn-clean btn-icon mr-2" title="Edit" onClick={()=> table?.options?.meta?.updateEdit(row?.index)}>
                <span class="svg-icon svg-icon-md">
                <i class="ki-solid ki-pencil fs-2 text-hover-light-primary"></i>
                </span>
            </button>
             }
             {
                type?.includes('remove') &&
                <button class="btn btn-sm btn-clean btn-icon mr-2" title="Delete" onClick={() => table?.options?.meta?.updateDelete(row?.index)}>
                <span class="svg-icon svg-icon-md">
                <i class="ki-solid ki-trash fs-2 text-hover-light-primary"></i>
                </span>
            </button>
             }
        </div>
    )
}

export default ActionsCell
