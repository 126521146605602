import SwitchBoxWithoutLabel from "../../Common/SwitchBoxWithoutLabel"
import ActionsCell from "../../Common/TableWithSubtable/ActionsCell"
import ViewDetailCell from "../../Common/TableWithTan/ViewDetailCell"
import CountryImageCell from "../Master/Country/CountryImageCell"




const companyColumn = [
//   {
//     header:"",
//     size:50,
//     id:"expand",
//     cell:({row}) => <ToggleIconTable row={row} />
//   },
//   {
//     header:(props) => <SelectionHeader props={props} />,
//     size:50,
//     id:"selction",
//     cell:(props) => <SelectionCell props={props} />
//   },

    {
      accessorKey:'name',
      header:'Name',
      size:150,
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'code',
      header:'Code',
      cell: (props) => <p>{props.getValue()}</p>
  
    },
    {
      accessorKey:'image',
      header:'Image',
      cell: (props) => <CountryImageCell props={props} />
  
    },

    {
      accessorKey:'is_active',
      header:'is active',
      cell: (props) => <SwitchBoxWithoutLabel checked={props.getValue()} />
  
    },
    {
        accessorKey:'',
        header:'View Details',
        size:100,
        cell: (props) => <ViewDetailCell props={props} />
    
      },
      {
        accessorKey:'',
        header:'Actions',
        size:100,
        cell: ({table, row}) => <ActionsCell type={['edit', 'remove']} table={table} row={row}/>
    
      }
    
  ]
  
  export {companyColumn}
  