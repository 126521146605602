export const setCompanyGame = (state, {payload}) =>{
    console.log(state, payload)
    let data = {
        selectedGames: payload.selectedGames
    }
    return {...state,...data}
}
export const setCompanyCurrency = (state, {payload}) =>{
    console.log(state, payload)
    let data = {
        currency:{ ...state.currency,...payload.currency}
    }
    return {...state,...data}
}
export const setCompanyCountry = (state, {payload}) =>{
    console.log(state, payload)
    let data = {
        countryConfig: payload.countryConfig
    }
    return {...state,...data}
}
export const setCompanyOtherConfig = (state, {payload}) =>{
    console.log(state, payload)
    let data = {
        otherConfig: payload.otherConfig
    }
    return {...state,...data}
}
export const setCoinPackConfig = (state, {payload}) =>{
    console.log(state, payload)
    let data = {
        coinPackConfig: {...state.coinPackConfig, ...payload.coinPackConfig}
    }
    return {...state,...data}
}
export const setCouponCodeConfig = (state, {payload}) =>{
    console.log(state, payload)
    let data = {
        couponCodeConfig: {...state.couponCodeConfig, ...payload.couponCodeConfig}
    }
    return {...state,...data}
}
export const setPromoCodeConfig = (state, {payload}) =>{
    console.log(state, payload)
    let data = {
        promoCodeConfig: {...state.promoCodeConfig, ...payload.promoCodeConfig}
    }
    return {...state,...data}
}
export const setReferralCodeConfig = (state, {payload}) =>{
    console.log(state, payload)
    let data = {
        referralCodeConfig: {...state.referralCodeConfig, ...payload.referralCodeConfig}
    }
    return {...state,...data}
}
export const setSignUpBonusCodeConfig = (state, {payload}) =>{
    console.log(state, payload)
    let data = {
        signUpBonusConfig: {...state.signUpBonusConfig, ...payload.signUpBonusConfig}
    }
    return {...state,...data}
}