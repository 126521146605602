import React, { useEffect, useState } from 'react'
import Leftbar from './Leftbar'
import Asidebar from './Asidebar'
import { KTToggle } from '../../_metronic/_components/_toggle'
import { KTMenu } from '../../_metronic/_components/_menu'
import { Tab } from 'bootstrap'
import { KTScroll } from '../../_metronic/_components/_scroll'
import { Outlet, useNavigate } from 'react-router'
import NavHeader from './NavHeader'
import { KTDrawer } from '../../_metronic/_components/_drawer'
import { KTThemeMode } from '../../_metronic/_components/_theme-mode'
import instance from "../utilities/axios";


const MasterLayout = () => {
  console.log("MasterLayout")
  const [token, setToken] = useState(null)
  const navigate = useNavigate()
  useEffect(() => {
    let tempToken = localStorage.getItem("token")?.toString() || null
    if (!tempToken) {
      console.log("Token is not Set")
    }else{
      setToken(token)
      instance.refresh();
    }
  }, [])

  useEffect(() => {
    if(token){

    }
  }, [token])



  useEffect(() => {
    KTScroll.init()
    KTToggle.init()
    KTMenu.init()
    KTMenu.updateByLinkAttribute()
    KTDrawer.init()
  },[])

  
  return (
    <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
    <NavHeader />
    <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      <Leftbar />
      <Asidebar />
      <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div class="d-flex flex-column flex-column-fluid">
            <Outlet />
        </div>
        </div>
       
   </div>
   {/* <div class="drawer-overlay"></div> */}
   </div>
  )
}

export default MasterLayout
