import { routesApi } from "../../../RouterFiles/routesApi";
import instance from "../../../utilities/axios";
import url from "../../../utilities/url";

export const getList = (headers,body) => {
    return instance
      .post(`${url.base_api}${routesApi.company}/list`,body)
      .then((res) => {
        return res;
      });
  };
export const getById = (headers, id) => {
    return instance
      .get(`${url.base_api}${routesApi.company}/${id}`, {
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };
export const getConfigById = (headers, id,name) => {
    return instance
      .get(`${url.base_api}${routesApi.company}/${name}/${id}`, {
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };
  export const updateConfigById = (headers, id,name,data) => {
    return instance
      .patch(`${url.base_api}${routesApi.company}/${name}/${id}`, data)
      .then((res) => {
        return res;
      });
  };
  export const auctionSettings = (headers, id,name,data) => {
    return instance
      .patch(`${url.base_api}${routesApi.company}/${routesApi.auctionSettings}${id}`, data)
      .then((res) => {
        return res;
      });
  };
export const getGuestConfigById = (headers, id,name) => {
    return instance
      .get(`${url.base_api}${routesApi.company}/guest/${name}/${id}`, {
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };
  export const updateGuestConfigById = (headers, id,name,data) => {
    return instance
      .patch(`${url.base_api}${routesApi.company}/guest/${name}/${id}`, data)
      .then((res) => {
        return res;
      });
  };
export const add = (headers, data) => {
    console.log("add:::",data)
    return instance
      .post(`${url.base_api}${routesApi.company}`,data)
      .then((res) => {
        return res;
      });
  };
export const update = (headers, data,id) => {
    console.log("update:::",data)
    return instance
      .put(`${url.base_api}${routesApi.company}/${id}`,data)
      .then((res) => {
        return res;
      });
  };
  export const remove = (headers,id) => {
    console.log("delete:::",id)
    return instance
      .delete(`${url.base_api}${routesApi.company}/${id}`)
      .then((res) => {
        return res;
      })
     
      ;
  };
  export const upload = (headers,file) => {
    console.log("filess:::",file)
    return instance
      .post(`${url.base_api}${routesApi.uploadAudio}`,file)
      .then((res) => {
        return res;
      })
     
      ;
  };
  export const getCurrency = (headers,id) => {
    return instance
      .get(`${url.base_api}${routesApi.company}/${routesApi.currency}/${id}`)
      .then((res) => {
        return res;
      });
  };
  export const getCountries = (headers,id) => {
    return instance
      .get(`${url.base_api}${routesApi.company}/${"countries"}`)
      .then((res) => {
        return res;
      });
  };
