import React, { useState } from 'react'
import TextInputField from '../../../../../Common/TextInputField'

const TeamTextField = ({props,data,name}) => {
  const [val, setVal] = useState()
  //updateEdit
  const handlerChange = (e)=>{
    let {name, value} = e.target
    setVal(value)
    props.table.options.meta.updateEdit(props.row.index, name,value)
  }
  return (
    <TextInputField
        name={name}
        value={val || data}
        onChange={(e) => handlerChange(e)}
    />
  )
}

export default TeamTextField