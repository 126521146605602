import TeamIcon from "../Teams/TeamIcon.jsx"
import TeamTextField from "../Teams/TeamTextField.jsx"
import CountryCell from "./CountryCell.jsx"
import RoleCell from './RoleCell.jsx'
import SelectCell from "./SelectCell.jsx"


const assginCompanyColumn = [

    {
      accessorKey:'name',
      header:'Name',
    
  
    },

  
    {
      accessorKey:'is_shared',
      header:'is shared',
     
      cell: (props) => {
       
        return(
          <RoleCell  props={props} data={props.getValue()} />
        )
      }
  
    },
 
    {
      accessorKey:'is_auction',
      header:'Auction Price',
     
      cell: (props) => <SelectCell    props={props} data={props.getValue()} name={"is_auction"} />
  
    },
    {
      accessorKey:'is_dabba',
      header:'is dabba',
     
      cell: (props) => <SelectCell props={props} data={props.getValue()} name={"is_dabba"}/>
  
    },
    {
      accessorKey:'countries',
      header:'Countries',
    
      cell: (props) => <CountryCell props={props} data={props.getValue()} name={"countries"}/>
  
    },

    
   
   
  ]
  
  export {assginCompanyColumn}
  