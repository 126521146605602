import React from 'react'

const TextInputFieldWithIcon = ({required = false,label,name ,type,value,onChange}) => {
  return (
    <div class="fv-row mb-7">

											<label class="fs-6 fw-semibold form-label mt-3">
												<span className={`${required ? "required": ""}`}>CVV</span>
												
											</label>
											<div class=" position-relative">

												<input type={type} class="form-control form-control-solid" minlength="3" maxlength="4" placeholder="CVV" name="card_cvv" />



												<div class="position-absolute translate-middle-y top-50 end-0 me-3">
													<i class="ki-duotone ki-credit-cart fs-2hx"><span class="path1"></span><span class="path2"></span></i>
												</div>

											</div>
										</div>
  )
}

export default TextInputFieldWithIcon
