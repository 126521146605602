import { createAsyncThunk } from "@reduxjs/toolkit"
import { add, getById, getList, remove, update } from "./language.api"


export const fetchList = createAsyncThunk('language/fetchList', async ({pageNo, recordPerPage ,search=''},{rejectWithValue}) => {
  console.log(pageNo,recordPerPage )
    let headers = {
        pageNo:pageNo || 1,
        recordPerPage:recordPerPage || 10,
        sortBy: 'created_at',
        sort:-1
      }
      let body = {
        pagination: {
            pageNo: pageNo || 1,
            recordPerPage: recordPerPage || 1,
            sortBy: "created_at",
            sortDirection: "asc"
        },
        search: search // optional
    }
      try{

          const response = await getList(headers,body)
          console.log("response:::::::",response,pageNo,recordPerPage)
          if(response.success)
          return {
            ...response, 
            pageNo,
            recordPerPage
          }
      }
      catch(error){
        return rejectWithValue(error)
      }
})
export const addLanguage = createAsyncThunk('language/add', async ({data},{rejectWithValue}) => {
  console.log("cratespor", data)
    let headers = {
       
      }
      try{

          const response = await add(headers,data)
          console.log("responseSport:::::::",response)
          if(response?.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error from language", error)
        // throw error
        return rejectWithValue(error)
      }
})
export const updateLanguage = createAsyncThunk('language/update', async ({data,id},{rejectWithValue}) => {
  let headers = {
     
    }
    try{

        const response = await update(headers,data,id)
        console.log("responseSport:::::::",response)
        if(response.success)
        return {
          ...response, 
         
        }
        else{
          return response
        }
    }
    catch(error){
      console.log("error", error)
      // throw error
      return rejectWithValue(error)
    }
})
export const delLanguage = createAsyncThunk('language/delete', async ({id},{rejectWithValue}) => {
  console.log("cratespor", id)
    let headers = {
       
      }
      try{

          const response = await remove(headers,id)
          console.log("responseSport:::::::",response)
          if(response?.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error from language", error)
        // throw error
        return rejectWithValue(error)
      }
})
export const getLanguageById = createAsyncThunk('language/getListById', async ({id}) => {
  console.log("cratespor", id)
    let headers = {
       
      }
      try{

          const response = await getById(headers,id)
          console.log("responseSport:::::::",response)
          if(response.success)
          return {
            ...response, 
           
          }
          else{
            return response
          }
      }
      catch(error){
        console.log("error", error)
        // throw error
        // return rejectWithValue(error)
      }
})