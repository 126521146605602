import React, { useEffect, useState } from "react";
import Content from "../Common/Content";
import SeriesTable from "./FantasyCommon/seriesTable";
import axios from "axios";
import MatchTable from "./FantasyCommon/matchTable";
import DataToStringDiv from "./FantasyCommon/DataToStringDiv";
import TeamTab from "./FantasyCommon/teamTab";

const FantasyPlayerDetails = () => {
  // useState DATA 
  const [matches, setMatches] = useState([]);
  const [seriesDetails, setSeriesDetails] = useState(null);
  const [teamDetails, setTeamDetails] = useState([]);
  const [seriesKey, setSeriesKey] = useState(null);
  const [playerKey, setPlayerKey] = useState(null);
  const [playerDatabyTournament, setPlayerDatabyTournament] = useState(null);
  const [playerDatabyAssociation, setPlayerDatabyAssociation] = useState(null);
  const [associationKey, setAssociationKey] = useState(null);
  const [name, setName] = useState(null);
  const [dataDiv, setshowDataDiv] = useState(true);
  const [activeTab, setactiveTab] = useState(1);
  const [navBarArr, setNavBarArr] = useState([{
    title:"Matches",
    value:1
  }, {
    title: "Teams",
      value: 2
}]);

  // useEffect Handlers
  useEffect(() => {
    let associationKey = window.location.href.split("/")[window.location.href.split("/").length - 1];
    let seriesKey = window.location.href.split("/")[window.location.href.split("/").length - 2];
    let playerKey = window.location.href.split("/")[window.location.href.split("/").length - 3];
    // console.log(associationKey, "associationKey", playerKey,"playerKey")
    setSeriesKey(seriesKey)
    setPlayerKey(playerKey)
    setAssociationKey(associationKey)
    let selectAssociation = localStorage.getItem("associationItem")
    let selectPlayer = localStorage.getItem("itemPlayer")
    let selectSeries = localStorage.getItem("seriesItem")
    setName(`/ association: ${selectAssociation} / series : ${selectSeries} / Player : ${selectPlayer}`)
  }, []);

  useEffect(() => {
    if (seriesKey && playerKey && associationKey) {
      fetchPlayerDetails(seriesKey, playerKey, associationKey)
    }
  }, [seriesKey]);

  useEffect(() => {
   
  }, [seriesDetails]);

  useEffect(() => {
    // console.log("teamDetails", teamDetails);
  }, [teamDetails]);

  // support functions
  const fetchPlayerDetails = async (seriesKey, playerKey, associationKey) => {
    try {
      if (seriesKey && playerKey && associationKey) {
        const resplayerDatabyTournament = await getPlayerDetailsbyTournament(seriesKey,playerKey);
        const resplayerDatabyAssociation = await getPlayerDetailsbyAssociation(associationKey, playerKey);
        console.log()
        if (resplayerDatabyTournament) {
          setPlayerDatabyTournament(resplayerDatabyTournament)
        }
        else {
          console.log("playerDetailsbyTournament: DATA NOT FOUND !!")
        }
        if (resplayerDatabyAssociation) {
          setPlayerDatabyAssociation(resplayerDatabyAssociation)
        }
        else {
          console.log("playerDetailsbyTournament: DATA NOT FOUND !!")
        }


      } else {
        console.log("associationKey NOT FOUND !!")
      }
    } catch (error) {
      console.error("Support Functions ERROR:", error);
    }
  };
  // API functions
  const getPlayerDetailsbyTournament = async (seriesKey, playerKey) => {
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
       
        url: `${process.env.REACT_APP_BASE_API_URL}v5/cricket/${process.env.REACT_APP_PROJECT_KEY}/tournament/${seriesKey}/player/${playerKey}/stats/`,
      };
      return axios.request(config)
        .then((response) => {
          // console.log(response.data);
          return response.data.data
        })
        .catch((error) => {
          console.error("API CALL ERROR:", error);
        });
    } catch (error) {
      console.error("API CALL  Functions ERROR:", error);
    }
  };
  const getPlayerDetailsbyAssociation = async (associationKey, playerKey) => {
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API_URL}v5/cricket/${process.env.REACT_APP_PROJECT_KEY}/association/${associationKey}/player/${playerKey}/stats/`,
      };
      return axios.request(config)
        .then((response) => {
          // console.log("teamresdata",response.data);
          return response.data.data
        })
        .catch((error) => {
          console.error("API CALL ERROR:", error);
        });
    } catch (error) {
      console.error("API CALL  Functions ERROR:", error);
    }
  };

  return (
    <Content>
      <div class="card card-custom">
        <div class="card-header flex-wrap  flex-column border-0 pt-6 pb-0">
          <div class="card-title d-flex flex-column justify-content-between">
            <h1 className="m-1">
              <p>{" "}<span><button className="p-2 m-1" style={{
                border: "0px ",
                borderRadius: "20%",
                backgroundColor: "#ffff",
              }}
                onClick={() => {
                  setshowDataDiv(!dataDiv);
                }}
              >+</button>
              </span>{" "}
                Fantasy Player Details{" "}
              </p>
            </h1>
            <h5 className="p-2">
              <span>{name}</span>
            </h5>
          </div>
          <div class="card-toolbar">
            {dataDiv && (
              <DataToStringDiv
                heading={"Player Data By Association"}
                children={playerDatabyAssociation}
              />
            )}
            {/* {dataDiv && (
              <DataToStringDiv
                heading={"Player Data By Series"}
                children={seriesDetails}
              />
            )} */}
            {dataDiv && (
              <DataToStringDiv
                heading={"Player Data By Tournament"}
                children={playerDatabyTournament}
              />
            )}
          </div>
          <div></div>
        </div>
        {/* <div class="card-body">
          <div class="mb-7">
            <div class="row align-items-center">
              <div class="">
                <ul style={{  display:'flex',flexFlow:"row",listStyleType:'none',margin: 0,padding:0,overflow: 'hidden',backgroundColor: '#333'}} >
                  {navBarArr && navBarArr.map((tab)=>{
                    return <li style={{ color: 'white', textAlign: 'center', padding: '14px 16px', textDecoration: 'none', backgroundColor: activeTab === tab?.value ? '#626fc1' : '#333' }} onClick={(e) => { changeTab(tab?.value)}}>{tab?.title}</li>
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div class="datatable datatable-bordered datatable-head-custom" id="kt_datatable">
            {activeTab===1 && <MatchTable matches={matches} key={matches} />}
            {activeTab === 2 && <TeamTab teamDetails={teamDetails} key={teamDetails} />}
          </div>
        </div> */}
      </div>
    </Content>
  );
};

export default FantasyPlayerDetails;
