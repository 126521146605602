import React, { useEffect, useState } from 'react'
import RadioWithoutIcon from '../../../../../Common/RadioWithoutIcon'
import { useDispatch, useSelector } from 'react-redux'
const RoleCell = ({props}) => {
  console.log(props.table.getRow(props.row.index).original.roles?.[0])
    const {companyCode} = useSelector(state => state.series)
    const [val, setval] = useState()
    useEffect(()=>{
      setval(props.table.getRow(props.row.index).original.roles?.[0])
    },[])
  return (
    <>
    {
        companyCode == 'SAAS' ? 
        <p>{props.getValue()?.map(ele=> ele + ", ")}</p>
        :
        props.getValue()?.map(ele=> {
            return <RadioWithoutIcon
            label = {ele}
            name={ele+props.row.index}
            onChange={(val) => {setval(ele); props.table.options.meta.updateEdit(props.row.index, "roles",[ele])}}
            value={val}
            checked={ele == val}
            />
        })
    }
    </>
  )
}

export default RoleCell