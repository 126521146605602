import { routesApi } from "../../../../RouterFiles/routesApi";
import instance from "../../../../utilities/axios";
import url from "../../../../utilities/url";

export const getList = (headers,body) => {
    return instance
      .post(`${url.base_api}${routesApi.country}/list`, body)
      .then((res) => {
        return res;
      });
  };
export const getLanguage = (headers,id) => {
    return instance
      .get(`${url.base_api}${routesApi.country}/${routesApi.assign}/${routesApi.language}/${id}`)
      .then((res) => {
        return res;
      });
  };
export const patch = (headers,id,body) => {
    return instance
      .patch(`${url.base_api}${routesApi.country}/${routesApi.assign}/${routesApi.language}/${id}`, body)
      .then((res) => {
        return res;
      });
  };
export const getById = (headers, id) => {
    return instance
      .get(`${url.base_api}${routesApi.country}/${id}`, {
        headers:headers
      })
      .then((res) => {
        return res;
      });
  };
  export const getListsDropdown = () => {
    return instance
      .get(`${url.base_api}${routesApi.country}/dropdown`)
      .then((res) => {
        return res;
      });
  }


