import React, { useEffect, useState } from 'react'
import TextInputField from '../../../Common/TextInputField'
import Audio from '../../../assets/media/sound.mp3'
import UploadAudio from '../../../Common/UploadAudio'
import { useDispatch, useSelector } from 'react-redux'
import { auctionSettingsCompany, updateCompanyConfigById, uploadCompanyAudio } from '../redux/company.action'
import ButtonWithLoader from '../../../Common/ButtonWithLoader'
import { successMessage } from '../../../Common/SwalToast'
const AuctionSettings = ({id}) => {
    const [formData, setFormData] = useState()
    const {auctionSettings, updateAuctionSettings} = useSelector(state => state.companies)
    const dispatch = useDispatch()
    // const uploadAudio = (file)=>{
    //     console.log("file::::",file)
    //     dispatch(uploadCompanyAudio({requestOptions:file}))
    // }
  
    const handlerFormData = (name,value) => {
        console.log("name", name,value)
        setFormData(prev => {
            return {
                ...prev,
                [name] : value
            }
        })
    }
    const handlerSubmit = ()=>{
        console.log(formData)
        dispatch(auctionSettingsCompany({id:id,data:formData}))
    }
    // console.log("formData", formData)
    useEffect(()=>{
        if(auctionSettings)
        setFormData(auctionSettings)
    },[auctionSettings])
    useEffect(()=>{
        console.log(updateAuctionSettings,"updateAuctionSettings")
        if(updateAuctionSettings?.message)
        successMessage(updateAuctionSettings?.message)
    },[updateAuctionSettings])
  return (
    <div className="card" >
    <div className="card-header">
      <div className="card-title d-flex align-items-center">
        <h3 className="fw-bold m-0 text-gray-800">Auction Settings</h3>
      </div>
      <div className="card-toolbar me-4">
       
      </div>
    </div>

    <div className="card-body fv-row">
        <div className='row'>
            <div className="col-md-6 col-12">
            <TextInputField
            label={"Bid Slab"} 
            name={"bidSlab"} 
            type={"number"} 
            value={formData?.bid_slab} 
            onChange={(e)=>setFormData(prev=> {
                return {
                    ...prev,
                    bid_slab: e.target.value
                }
            })}
        />
            </div>
            <div className="col-md-6 col-12">
            <TextInputField
            label={"Budget"} 
            name={"budget"} 
            type={"number"} 
            value={formData?.budget} 
            onChange={(e)=>setFormData(prev=> {
                return {
                    ...prev,
                    budget: e.target.value
                }
            })}
        />
            </div>
        </div>
        <div className='row mt-4'>
        <div className="card-title d-flex align-items-center">
        <h3 className="fw-bold m-0 text-gray-800">Audio</h3>
      </div>
            <div className="col-md-9 col-12 d-flex justify-content-between align-items-center">
            <div className="col-4">

            <h6>Buffer Time</h6> 
            </div>
        
            <div className="col-4 text-center">
                 <UploadAudio name={"buffer_time"}  handlerFormData={handlerFormData}  data={formData?.buffer_time}  text={"upload"}   /></div>
           
            </div>
            <div className="col-md-9 col-12 d-flex justify-content-between align-items-center">
            <div className="col-4">

            <h6>Notification Time</h6> 
            </div>
        
            <div className="col-4 text-center">
                 <UploadAudio  handlerFormData={handlerFormData} data={formData?.notification_time}  text={"upload"} name={"notification_time"}  /></div>
           
            </div>
            <div className="col-md-9 col-12 d-flex justify-content-between align-items-center">
            <div className="col-4">

            <h6>Bidding Time</h6> 
            </div>
        
            <div className="col-4 text-center"> 
                <UploadAudio  handlerFormData={handlerFormData} data={formData?.bidding_time}  text={"upload"} name={"bidding_time"}  /></div>
           
            </div>
            <div className="col-md-9 col-12 d-flex justify-content-between align-items-center">
            <div className="col-4">

            <h6>Rules Time</h6> 
            </div>
        
            <div className="col-4 text-center"> 
                <UploadAudio  handlerFormData={handlerFormData} data={formData?.rules_time}  text={"upload"} name={"rules_time"}  /></div>
           
            </div>
          <div>
            <ButtonWithLoader
            text={"submit"}
            handlerSubmit = {handlerSubmit}
            />
          </div>
           
        </div>
    </div>
    </div>
  )
}

export default AuctionSettings
