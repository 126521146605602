import React, { useState } from 'react'
import { KTThemeMode } from '../../_metronic/_components/_theme-mode'

const Asidebar = () => {
    const [mode, setMode] = useState(KTThemeMode.getMode())
    console.log(KTThemeMode.getMode())
  return (
    <div id="kt_app_aside" class="app-aside flex-column" data-kt-drawer="true" data-kt-drawer-name="app-aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="auto" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_aside_mobile_toggle">

    <div id="kt_app_aside_wrapper" class="d-flex flex-column align-items-center hover-scroll-y mt-lg-n3 py-5 py-lg-0 gap-4" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_header" data-kt-scroll-wrappers="#kt_app_aside_wrapper" data-kt-scroll-offset="5px">
        <a href="apps/calendar.html" class="btn btn-icon btn-color-primary bg-hover-body h-45px w-45px flex-shrink-0" data-bs-toggle="tooltip" title="Calendar" data-bs-custom-class="tooltip-inverse">
            <i class="ki-outline ki-calendar fs-2x"></i>
        </a>
        <a href="account/overview.html" class="btn btn-icon btn-color-warning bg-hover-body h-45px w-45px flex-shrink-0" data-bs-toggle="tooltip" title="Profile" data-bs-custom-class="tooltip-inverse">
            <i class="ki-outline ki-address-book fs-2x"></i>
        </a>
        <a href="apps/ecommerce/catalog/products.html" class="btn btn-icon btn-color-success bg-hover-body h-45px w-45px flex-shrink-0" data-bs-toggle="tooltip" title="Messages" data-bs-custom-class="tooltip-inverse">
            <i class="ki-outline ki-tablet-ok fs-2x"></i>
        </a>
        <a href="apps/inbox/listing.html" class="btn btn-icon btn-color-dark bg-hover-body h-45px w-45px flex-shrink-0" data-bs-toggle="tooltip" title="Products" data-bs-custom-class="tooltip-inverse">
            <i class="ki-outline ki-calendar-add fs-2x"></i>
        </a>
        {
            KTThemeMode.getMode() == 'dark' ? 

        <span  class="btn btn-icon btn-color-dark bg-hover-body h-45px w-45px flex-shrink-0" data-bs-toggle="tooltip" title="Products" data-bs-custom-class="tooltip-inverse" 
        onClick={()=> 
       { KTThemeMode.setMode('light')
        setMode('light')
       }
        }>
        <i class="ki-outline ki-sun fs-2x "></i>
        </span>
        :

        <span href="apps/inbox/listing.html" class="btn btn-icon btn-color-dark bg-hover-body h-45px w-45px flex-shrink-0" data-bs-toggle="tooltip" title="Products" data-bs-custom-class="tooltip-inverse" onClick={()=> {
            KTThemeMode.setMode('dark')
            setMode('dark')
        }
            
            }>
        <i class="ki-outline ki-moon fs-2x "></i>
        </span>
        }
    </div>

</div>
  )
}

export default Asidebar
