import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { routesFrontend } from "../RouterFiles/routesFrontend";
// Recursive function to render the menu names and their children
import { MenuList } from './LeftbarSlice/MenuList'
const RenderMenu = ({ menu, active,setActive }) => {
  return (
    <>

      {
        Object.keys(menu).map(key => {
          return (
            <div class="menu-sub menu-sub-accordion">
                  {
                    menu[key]?.child.length > 0 ? 
                    <div 
                    
                    data-kt-menu-trigger="click" 
                    class="menu-item menu-accordion">
                       <span class={`menu-link `}>
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">{menu[key]?.name}</span>
													<span class="menu-arrow"></span>
												</span>
                    <RenderMenu menu={menu[key].child.reduce((acc, item) => ({ ...acc, [item.name]: item }), {})} active={active} setActive={setActive} />
                      </div>
                      :
                      <div class="menu-item" onClick={()=> {
                       
                        if (setActive)
                        setActive(menu[key]?.link)}}>
                       
                      <Link class={`menu-link ${active == menu[key]?.link ? "active": ""}`} to={menu[key]?.link}>
                        <span class="menu-bullet">
                          <span class="bullet bullet-dot"></span>
                        </span>
                        <span class="menu-title">{menu[key]?.name}</span>
                      </Link>
                      

                    </div>
                  }
              </div>
          )
        })
            
       
      }
    </>


  );
};

const Leftbar = () => {
  const [active, setActive] = useState(window.location.pathname)
  const handlerMenu = () => {

  }
  return (
    <div
      id="kt_app_sidebar"
      class="app-sidebar flex-column"
      data-kt-drawer="true"
      d
      ata-kt-drawer-name="app-sidebar"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="250px"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
    >
      <div class="app-sidebar-wrapper">
        <div
          id="kt_app_sidebar_wrapper"
          class="hover-scroll-y my-5 my-lg-2 mx-4"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_app_header"
          data-kt-scroll-wrappers="#kt_app_sidebar_wrapper"
          data-kt-scroll-offset="5px"
        >
          <div
            id="#kt_app_sidebar_menu"
            data-kt-menu="true"
            data-kt-menu-expand="false"
            class="app-sidebar-menu-primary menu menu-column menu-rounded menu-sub-indention menu-state-bullet-primary px-3 mb-5"
          >
            {
        Object.keys(MenuList).map(item => {

          return (
            <div class="menu-item menu-accordion"
              data-kt-menu-trigger="click"
            // onClick={() => handlerMenu()}
            >
              <span class="menu-link">
                <span class="menu-icon">
                  <i class={`ki-outline ki-${MenuList[item].icon} fs-2 `}></i>
                </span>
               
                {
                  MenuList[item].child.length > 0 ? <>
                   <span class="menu-title">{MenuList[item].name}</span>
                  <span class="menu-arrow"></span>
                  </> :
                   <Link class={`menu-link ${active == MenuList[item]?.link ? "active": ""}`} to={MenuList[item]?.link}>
                  
                   <span class="menu-title">{MenuList[item]?.name}</span>
                 </Link>
                }
                
              </span>
                  <RenderMenu menu={MenuList[item].child.reduce((acc, item) => ({ ...acc, [item.name]: item }), {})} setActive={setActive} active={active} />
             </div>
          )
        })
          }
      </div>
      </div>
      </div>
     
    </div>
  );
};

export default Leftbar;
