import React, { useEffect, useRef, useState } from "react";
import ButtonWithLoader from "../../Common/ButtonWithLoader";
import TextInputField from "../../Common/TextInputField";
import { formValidation } from "@form-validation/bundle/popular";
import { Bootstrap5 } from "@form-validation/plugin-bootstrap5";
import { Trigger } from "@form-validation/plugin-trigger";
import { errorMessage, successMessage } from "../../Common/SwalToast";
import SelectDropdownWithSearch from "../../Common/SelectDropdownWithSearch";
const countryOptions = [
  { value: 'Nigeria', label: '+97', image: 'media/flags/afghanistan.svg' },
  { value: 'United States', label: '+975', image: 'media/flags/united-states.svg' },
  { value: 'Israel', label: '+96', image: 'media/flags/israel.svg' },
  { value: 'Romania', label: '+12', image: 'media/flags/romania.svg' },
  { value: 'Germany', label: '+45', image: 'media/flags/germany.svg' },
  { value: 'Bahamas', label: '+15', image: 'media/flags/bahamas.svg' },
  { value: 'China', label: '+48', image: 'media/flags/china.svg' },
  { value: 'Somalia', label: '+973', image: 'media/flags/somalia.svg' }
]
const LoginForm = ({ setOtpTab, handlerSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [validator, setValidator] = useState(0);
  const [selectedTab, setSelectedTab] = useState(1)
  const formRef = useRef();

  const checkValidation = () => {
    setIsLoading(true);
    if (validator) {
      validator.validate().then(function (status) {
        let text = "";
        if (status === "Valid") {
          // Simulate API call delay
          // setOtpTab(true)
          if(selectedTab){
            let tempData={
              login: email,
              type: "request", 
            }
            handlerSubmit(tempData)
          }
          
          // setTimeout(() => {
          //   text = "Form has been successfully submitted!";
          //   successMessage(text, handlerSubmit);

          //   // Submit form data here or trigger actual form submission
          //   // form.submit(); (if needed)
          // }, 1000);
        } else {
          // Show error message
          text =
            "Sorry, looks like there are some errors detected, please try again.";
          errorMessage(text);
        }
      });
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    // console.log("clicked");
  };
  useEffect(() => {
    const form = formRef.current;
    if (!form) return;
    const validationFields = {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },

            regexp: {
              regexp: /^[a-zA-Z0-9_.]+@[a-z]+\.[a-z]{3}$/,
              message: "The email is invalid",
            },
          },
        },
        // number: {
        //   validators: {
        //     notEmpty: {
        //       message: "Number user is required",
        //     },
        //   },
        //   stringLength: {
        //     verbose: false, 
        //     min: 6,
        //     max: 30,
        //     message: 'The name must be more than 6 and less than 30 characters long',
        // },
        // },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap5({
          rowSelector: ".fv-row",
        //   eleInvalidclassName: "",
        //   eleValidclassName: "",
        }),
      },
    };
    const fv = formValidation(form, validationFields);

    setValidator(fv);
  }, []);
  return (
    <div
      class="form w-100"
      novalidate="novalidate"
      id="kt_sign_in_form"
      ref={formRef}
    >
      <div class="card-body">
        <div class="text-start mb-10">
          <h1
            class="text-gray-900 mb-3 fs-3x"
            data-kt-translate="sign-in-title"
          >
            Sign In
          </h1>

          <div
            class="text-gray-500 fw-semibold fs-6"
            data-kt-translate="general-desc"
          >
            Get unlimited access & earn money
          </div>
        </div>
        <div className="bg-white">
          {/* <div class="mb-5 hover-scroll-x">
                      <div class="d-grid">
                        <ul class="nav nav-tabs flex-nowrap text-nowrap login-tab">
                          <li class="nav-item ">
                            <a
                              //   nav-link btn bs-text-white btn-active-color-primary rounded-bottom-0 active
                              class={`nav-link btn bs-text-white btn-active-color-primary rounded-bottom-0 active`}
                              data-bs-toggle="tab"
                              href="#kt_tab_pane_1"
                            >
                              Email
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class={`nav-link btn bs-text-white btn-active-color-primary rounded-bottom-0`}
                              data-bs-toggle="tab"
                              href="#kt_tab_pane_2"
                            >
                              Mobile
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
          <div className="d-flex gap-2">
            <div class="col-md-4 col-lg-6 col-xxl-4" onClick={()=> setSelectedTab(1)}>
              <label
                class={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-3 ${selectedTab === 1 ?"active" : ""}`}
                data-kt-button="true"
              >
                <span class={`form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1 ${selectedTab === 1 ? "active" : ""}`}>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="usage"
                    value="1"
                    checked={selectedTab === 1}
                  />
                </span>

                <span class="ms-5">
                  <span class="fs-4 fw-bold mb-1 d-block">Email</span>
                </span>
              </label>
            </div>
            <div class="col-md-4 col-lg-6 col-xxl-4" onClick={()=> setSelectedTab(2)}>
              <label
                class={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-3 ${selectedTab === 2 ?"active" : ""}`}
                data-kt-button="true"
              >
                <span class={`form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1 ${selectedTab === 2 ? "active" : ""}`}>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="usage"
                    value="2"
                    checked={selectedTab == 2}
                  />
                </span>

                <span class="ms-5">
                  <span class="fs-4 fw-bold mb-1 d-block">Mobile</span>
                </span>
              </label>
            </div>
          </div>
          <div class="tab-content login-tab-content" id="myTabContent">
            <div
              class={`tab-pane fade ${selectedTab === 1 ? "show active" : ""}`}
              id="kt_tab_pane_1"
              role="tabpanel"
            >
              <TextInputField
                label={"Email"}
                required={"true"}
                value={email}
                name={"email"}
                type={"text"}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"Enter your email"}
              />
              <div className="mt-5">
              <ButtonWithLoader
                isLoading={isLoading}
                 text="Submit"
                handlerSubmit={checkValidation}
              />
              </div>
            </div>
            <div class={`tab-pane fade ${selectedTab === 2 ? "show active" : ""}`} id="kt_tab_pane_2" role="tabpanel">
              <div className="row">
                <div className="col-4">
                <label class="fs-6 fw-semibold form-label mt-3">
      <span className={`required`}>Country Code</span>
      
    </label>
            <SelectDropdownWithSearch
                    name="country"
                    //   required={"true"}
                    //   label="Country"
                    placeholder={"Select a country code"}
                    options={countryOptions}
                    // value={countryOptions.find(
                    //   (option) => option.value === formData?.country?.value
                    // )}
                    onChange={(selectedOption) => {
                      // console.log(selectedOption);
                      // handleSelectChange("country", selectedOption);
                    }}
                  />
                </div>
                <div className="col-8">

            <TextInputField
                label={"Mobile"}
                required={"true"}
                value={number}
                name={"number"}
                type={"number"}
                // minlength={"8"}
                // maxlength={"14"}
                onChange={(e) => setNumber(e.target.value)}
                placeholder={"Enter your number"}
              />
                </div>
              </div>
              <div className="mt-5">
              <ButtonWithLoader
              text="Submit"
                isLoading={isLoading}
                handlerSubmit={checkValidation}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
