import React, { useEffect, useRef, useState } from "react";
import TextInputField from "../../../Common/TextInputField";
import ButtonWithLoader from "../../../Common/ButtonWithLoader";
import UploadSingleImage from "../../../Common/UploadSingleImage";
import { formValidation } from '@form-validation/bundle/popular';
import { Bootstrap5 } from '@form-validation/plugin-bootstrap5';
import { Trigger } from '@form-validation/plugin-trigger';
import { addSport, updateSport } from './redux/sport.action'
import Checkbox from "../../../Common/Checkbox";
import { useDispatch } from "react-redux";
import { errorMessage } from "../../../Common/SwalToast";
const gamePlay = ["Auction", "Dabba"];
const CreateSport = ({ data }) => {

  const [formData, setFormData] = useState({

    name: '',
    code: '',
    img: "fhweofhiweffef.jpeg",
    game_play: [],
    order: ''

  });
  const formRef = useRef()
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle loading indication
  const [validator, setValidator] = useState(null); // Store form validation instance
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(name, value)
    setFormData({
      ...formData,
      [name]:
        type === "checkbox"
          ? checked
            ? [...formData.game_play, value]
            : formData.game_play.filter((n) => n !== value)
          : value,
    });
  };
  const checkValidation = (e) => {
    e.preventDefault();
 


    if (validator) {
      validator.validate().then(function (status) {


        if (status === 'Valid') {
          setIsSubmitting(true);

          // Simulate API call delay
          console.log("id::::", data)
          if (data)
{
            dispatch(updateSport({ data: formData, id: data?._id }))
            setIsSubmitting(false);
          }
          else{
            dispatch(addSport({ data: formData }))
            setIsSubmitting(false);
}


        } else {
          // Show error message
          let text = "Sorry, looks like there are some errors detected, please try again."
          errorMessage(text)
          setIsSubmitting(false)
        }
      });
    }

  }
  useEffect(() => {
    const form = formRef.current;
    if (!form) return;

    const validationFields = {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: 'Name is required'
            },
            stringLength: {
              verbose: false,
              min: 3,
              max: 30,
              message: 'The name must be more than 3 and less than 30 characters long',
            },
            // regexp: {
            //     regexp: /^[a-zA-Z0-9_]+$/,
            //     message: 'The name can only consist of alphabetical, number and underscore',
            // },
          }
        },
        game_play: {
          validators: {
            notEmpty: {
              message: 'Game Play is required'
            }
          
          }
        },
        order: {
          validators: {
            notEmpty: {
              message: 'Order is required'
            }
          
          }
        },
        code: {
          validators: {
            notEmpty: {
              message: 'Code is required'
            }
          
          }
        },

        uploadImage: {
          validators: {
            notEmpty: {
              message: 'Upload image is required'
            }
          }
        },


      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap5({
          rowSelector: '.fv-row',
          eleInvalidclassName: '',
          eleValidclassName: ''
        })
      }
    };

    const fv = formValidation(form, validationFields);

    setValidator(fv);
    console.log(data)
    if (data) {
      console.log("data:::", data)
      setFormData(prev => {
        return {
          ...prev,
          name: data?.name,
          code: data?.code,
          game_play: data?.game_play || [],
          order: data?.order
        }
      })
    }
  }, [data])

  return (
    <>
      {/* <Toolbar text={"Create sport"} />
      <Content> */}
      <div className="card">
        <div className="card-body" ref={formRef}>
          <div class="row g-9 mb-4">

            <div class="col-md-4 col-sm-6 col-12 mt-2">
              <TextInputField
                required={"true"}
                label={"name"}
                value={formData?.name}
                name={"name"}
                type={"text"}
                onChange={handleChange}
                placeholder={"Enter name"}
              />
            </div>
            <div class="col-md-4 col-sm-6 col-12 mt-2">
              <TextInputField
                required={"true"}
                label={"Code"}
                value={formData?.code}
                name={"code"}
                type={"text"}
                onChange={handleChange}
                placeholder={"Enter code"}
              />
            </div>
            <div class="col-md-4 col-sm-6 col-12 mt-2">
            <label class="fs-6 fw-semibold form-label mt-3">
                  <span className="required">Game Play</span>

                </label>
              <div class="fv-row mb-0">

                
                {/* <div className="d-flex">  */}
                {gamePlay.map((game, idx) => 
                {
            

                return (
                  <Checkbox
                    key={idx}
                    label={game}
                    value={game}
                    name={"game_play"}
                    onChange={(e) => handleChange(e)}
                    checked={formData.game_play?.includes(game.toLowerCase())}
                  />
                )}
                )}
                {/* </div> */}
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 mt-2">
              <UploadSingleImage
                text={"Upload logo of sport"}
                name="uploadImage"
                // file={formData?.img}
                // setFile={(file) => setFormData(prev => {
                //   return {
                //     ...prev,
                //     image: file
                //   }
                // })}
              />
            </div>
            <div class="col-md-4 col-sm-6 col-12 mt-2">
              <TextInputField
                required={"true"}
                label={"Order"}
                value={formData?.order}
                name={"order"}
                type={"number"}
                onChange={handleChange}
                placeholder={"Enter order"}
              />
            </div>

            <div class="col-md-4 col-6 d-flex align-items-center justify-content-center pt-3">
              <ButtonWithLoader
              isLoading = {isSubmitting}
                text={"Submit"}
                handlerSubmit={checkValidation}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </Content> */}
    </>
  );
};

export default CreateSport;
