import React, { useEffect, useState } from "react";

import { matchColumn} from "./MatchColumn";
import { useNavigate } from "react-router";
import Table from "../../../../../Common/TableWithTan/Table";
import ButtonWithLoader from "../../../../../Common/ButtonWithLoader";
import { useDispatch, useSelector } from "react-redux";
import { getMatchesFetchById, getMatchesListById, saveMatches } from "./redux/matches.action";
import { successMessage } from "../../../../../Common/SwalToast";
import Loader from "../../../../../MasterLayout/Loader";


const MatchesList = ({id}) => {
  const [companies, setCompanies] = useState([]);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {loading,list, matches, save,saveLoad} = useSelector(state => state.matches)
  const {companyCode} = useSelector(state => state.series)
  const [columnVisibility, setColumnVisibility] = useState();
  const [formData, setFormData] = useState([])
  const updateStatus = (rowIndex) => {
    console.log(list[rowIndex])
    dispatch(saveMatches({id:id,companyCode:companyCode,data:{matches:[list[rowIndex]?.key]}}))
    // navigate('/series/details/'+tableData[rowIndex]?.name)
  }
  const updateEdit = (rowIndex, name, value) => {
    setFormData(prevFormData => {
      const keyToUpdate = list[rowIndex]?.key;
  
      // Check if the item with the key already exists in formData
      const existingItemIndex = prevFormData.findIndex(ele => ele?.key === keyToUpdate);
  
      if (existingItemIndex !== -1) {
        // If it exists, update the existing item
        const updatedData = [...prevFormData];
        updatedData[existingItemIndex] = {
          ...updatedData[existingItemIndex],
          [name]: value
        };
        return updatedData;
      } else {
        // If it doesn't exist, add a new item
        return [
          ...prevFormData,
          { key: keyToUpdate, [name]: value }
        ];
      }
    });
  };
  const handleFetch = ()=>{
    dispatch(getMatchesFetchById({id:id,companyCode:'SAAS'}))
  }
  const handlerSave = ()=>{
    let t = null
    if(companyCode == 'SAAS'){

      t = list?.reduce((acc,team)=>{
       acc.push(team?.key)
       console.log("acc",acc)
       return acc
     },[])
     dispatch(saveMatches({id:id, companyCode:companyCode,data:{matches:t}}))
    }
    else{
      dispatch(saveMatches({id:id, companyCode:companyCode,data:{matches:formData}}))
    }
    console.log("t>>>>>>",formData)
    
  }
  useEffect(() => {
    if(companyCode == 'SAAS'){
      setColumnVisibility({
        new_match_name: false,
       
       
      })
    }
    // setCompanies(["Gamerji", "D11", "Vodafone", "Mobily"]);
    dispatch(getMatchesListById({id:id,companyCode:companyCode}))
  }, []);
  
  useEffect(()=>{
      if(matches?.message){
        successMessage(matches?.message)
      }
  },[matches])

  useEffect(()=>{
      if(save?.message){
        successMessage(save?.message)
        dispatch(getMatchesListById({id:id,companyCode:companyCode}))
      }
  },[save])

  useEffect(()=>{
    console.log(",atchess", list)
      // if(list?.message){
      //   successMessage(matches?.message)
      // }
  },[list])
  return (
    <div className="card">
      <div className="card-header card-header-stretch">
        <div className="card-title d-flex align-items-center">
          <h3 className="fw-bold m-0 text-gray-800">Assign Companies</h3>
        </div>
        <div className="card-toolbar col-6 d-flex justify-content-end align-items-center">
           
           
            
             <div className="col-6 text-center">

             <ButtonWithLoader text={"Fetch & Refetch"} 
             handlerSubmit={handleFetch} 
            //  isLoading={isLoading} 
             />
             </div>
          </div>
      </div>

      {/* <div className="card-body">
        <div className="row g-9 mb-8 ">
          {companies.map((company, index) => {
            return (
              <div className="col-md-9 d-flex justify-content-between">
                <h3>{company}</h3>
                <div className="d-flex justify-content-start">
                <div
                  className={`btn py-3 px-3 mw-150px  hover-scale ${
                    selected.includes(company) ? "btn-danger" : "btn-light"
                  }`}
                  onClick={(e) => {
                    console.log(e.target.innerText, e.target.className);
                    if (e.target.innerText == "Add" || e.target.className.includes('ki-plus-square')) {
                      setSelected((prev) => {
                        return [...prev, company];
                      });
                    } else
                      setSelected((prev) => {
                        return prev.filter((ele) => ele !== company);
                      });
                  }}
                >
                  {selected.includes(company) ? (
                    <span className="w-80px d-flex justify-content-between align-items-center">
                      <i className="ki-outline ki-minus-square  fs-1 me-1"></i>
                      Remove
                    </span>
                  ) : (
                    <span className="w-65px d-flex justify-content-between align-items-center">
                      {" "}
                      <i className="ki-outline ki-plus-square fs-1 me-1"></i>
                      Add
                    </span>
                  )}
                </div>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
        <div class="card-body">
          {/* <FilterForm  /> */}
            {/* <Filter columnFilters={columnFilters} setColumnFilters={setColumnFilters} /> */}
            {/* <div class="col-md-4 col-6 mt-2">
                <FilterPopover
                  option={columnFilters}
                  setOption={setColumnFilters}
                  filterValues={employeeTypeFilter}
                  id="employeeType"
                  text="Employee Type"
                />
              </div> */}
              {
                loading ? <Loader /> :
            <div
              class="datatable datatable-bordered datatable-head-custom mt-4 pt-4"
              id="kt_datatable"
            >
              <Table
                column={matchColumn}
                tableData={list}
                updateEdit = {updateEdit}
                updateStatus = {updateStatus}
                columnVisibility={columnVisibility}
                // columnFilters={columnFilters}
                // setColumnFilters={setColumnFilters}
              />
               <div>
                <ButtonWithLoader text="Save" handlerSubmit={handlerSave} isLoading={saveLoad}  />
                </div>
            </div>
              }
           
          </div>
    </div>
  );
};

export default MatchesList;
