import { createSlice } from "@reduxjs/toolkit";
import { getMatchesFetchById, getMatchesListById, saveMatches } from "./matches.action";








export const matchesSlice = createSlice({
  name: "matchState",
  initialState: {
 
    saveLoading:false,
    save:"",
    matches:[],
    load:false,
    list:[],
    loading:false
  },
  reducers: {
  
  },
  extraReducers: (builder) => {

    builder.addCase(getMatchesListById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMatchesListById.fulfilled, (state, action) => {
      state.loading = false;
      state.matches = ""
      state.save=""
      state.list = action.payload?.data;

    });
    builder.addCase(getMatchesListById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error?.message;
    });
    builder.addCase(getMatchesFetchById.pending, (state) => {
      state.load = true;
    });
    builder.addCase(getMatchesFetchById.fulfilled, (state, action) => {
      state.load = false;
      state.list = action.payload?.data;
      state.matches = action.payload?.message;
      state.save=""

    });
    builder.addCase(getMatchesFetchById.rejected, (state, action) => {
      state.load = false;
      state.error = action.payload.error?.message;
    });
   
    builder.addCase(saveMatches.pending, (state) => {
      state.saveLoading = true;
    });
    builder.addCase(saveMatches.fulfilled, (state, action) => {
      state.saveLoading = false;
      
      state.save = action.payload;
    });
    builder.addCase(saveMatches.rejected, (state, action) => {
      state.saveLoading = false;
      state.error = action.payload;
    });
  },
});

// export const {  } = matchesSlice.actions;
export default matchesSlice.reducer;
