import React, { useEffect, useState } from "react";
import Content from "../Common/Content";
import SeriesTable from "./FantasyCommon/seriesTable";
import axios from "axios";
import DataToStringDiv from "./FantasyCommon/DataToStringDiv";

const FantasySeries = () => {
  // useState DATA 
  const [series, setSeries] = useState([]);
  const [associationKey, setAssociationKey] = useState(null);
  const [name, setName] = useState(null);
  const [dataDiv, setshowDataDiv] = useState(false);
  // useEffect Handlers
  useEffect(() => {
    let associationKey = window.location.href.split("/")[window.location.href.split("/").length - 1];
    setAssociationKey(associationKey)
    let selectAssociation = localStorage.getItem("associationItem")
    setName(`/ association: ${selectAssociation} / series`)
  }, []);

  useEffect(() => {
    if (associationKey) {
      fetchSeriesList(associationKey)
    }
  }, [associationKey]);


  // support functions
  const fetchSeriesList = async (associationKey) => {
    try {
      if (associationKey) {
        const res = await getSeriesList(associationKey);
        if (res) {
          setSeries(res.data.tournaments)
        }
        else {
          console.log("DATA NOT FOUND !!")
        }
      } else {
        console.log("associationKey NOT FOUND !!")
      }
    } catch (error) {
      console.error("Support Functions ERROR:", error);	
    }
  };

  // API functions
  const getSeriesList = async (associationKey) => {
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API_URL}v5/cricket/${process.env.REACT_APP_PROJECT_KEY
          }/association/${associationKey}/featured-tournaments/`,
      };
      return axios.request(config)
        .then((response) => {
          console.log(response.data);
          return response.data
        })
        .catch((error) => {
          console.error("API CALL ERROR:", error);
        });
    } catch (error) {
      console.error("API CALL  Functions ERROR:", error);
    }
  };



  return (
    <Content>
      <div class="card card-custom">
        <div class="card-header flex-wrap flex-column border-0 pt-6 pb-0">
          <div class="card-title d-flex flex-column justify-content-between">
            <h1 className="m-1">
              <p>{" "}<span><button className="p-2 m-1" style={{
                border: "0px ",
                borderRadius: "20%",
                backgroundColor: "#ffff",
              }}
                onClick={() => {
                  setshowDataDiv(!dataDiv);
                }}
              >+</button>
              </span>{" "}
                Fantasy Series{" "}
              </p>
            </h1>
            <h5 className="p-2">
              <span>{name}</span>
            </h5>
          </div>
          <div class="card-toolbar">
            {dataDiv && (
              <DataToStringDiv
                heading={"Series Data"}
                api={"featured tournaments API"}
                children={series}
              />
            )}
          </div>
          
        </div>
        <div class="card-body">
          <div class="mb-7">
            <div class="row align-items-center">
              <div class="col-lg-3 col-xl-4 mt-5 mt-lg-0">
              </div>
            </div>
          </div>
          <div class="datatable datatable-bordered datatable-head-custom" id="kt_datatable">
            <SeriesTable series={series} key={series} associationKey={associationKey} />
          </div>
        </div>
      </div>
    </Content>
  );
};

export default FantasySeries;
