import { SignJWT } from 'jose';
// Function to create a JWT token
export async function createToken(data, expiresIn = 86400, secretKey = process.env.REACT_APP_LOGINKEY) {
     const encoder = new TextEncoder(); // To encode the key
     const secret = encoder.encode(secretKey);
     // Sign a JWT token
     const token = await new SignJWT(
          data) // Add your payload data here
          .setProtectedHeader({ alg: 'HS256' })            // HS256 is a commonly used algorithm
          .setIssuedAt()
          .setExpirationTime(convertToHoursAndMinutes(expiresIn))                         // Token expires in 1 hour
          .sign(secret);                                   // Sign with the secret key

     return token;
}

function convertToHoursAndMinutes(seconds) {
     const hours = Math.floor(seconds / 3600);  // Convert seconds to hours
     // Build the output string
     const hourString = hours > 0 ? `${hours}h` : '';
     // If both hours and minutes are 0, return '0m'
     return hourString ? `${hourString}`.trim() : '0m';
}
